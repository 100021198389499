/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react"
import { OnBoardingFormProps } from "../../../Interface/EditContractor/Signup"
import { formatPhoneNumberEditContractor } from "../../../Configs/Constant"


export function OnBoardingForm({ formPayload, handleInputChange, OnBoardError, bindJobtitleDropdown, bindStateDropdown, bindCityDropdown, bindRegionDropdown, bindHeardAboutusDropdown, termsCondition, showOthers, DisableCity, handleNext, nextButtonEnabled, setTermsCondition, handleCancel, DropdownData, isEdit, SaveFromDetails }: OnBoardingFormProps) {

    const [showNonSolicitation, setShowNonSolicitation] = useState(false)
    const [showTermsAndCondition, setShowTermsAndCondition] = useState(false)

    const JobTitle = DropdownData.find((ele) => ele.dropdown_name === "job_title")
    const HeardAboutUs = DropdownData.find((ele) => ele.dropdown_name === "heard_about_us")

    const currentYear = new Date().getFullYear();
    const renderYearOptions = () => {
        const yearOptions = [
            <option key={0} value={""} disabled>
                Select
            </option>,
        ];

        for (let i = currentYear; i >= 1900; i--) {
            yearOptions.push(
                <option key={i} value={i}>
                    {i}
                </option>
            );
        }

        return yearOptions;
    };

    return (
        <>
            <div className="d-flex align-items-center gap-3 mb-5">
                <h2 className="color-black font-600 font-18 mb-0">
                    Onboarding Contact
                </h2>
            </div>
            <div className="col-lg-6 mb-4 mb-lg-5">
                <label
                    htmlFor="service"
                    className="font-semibold font-16 color-black-v2 mb-2"
                >
                    First Name <span className="madatory-text">*</span>
                </label>
                <input
                    type="text"
                    id="service"
                    maxLength={60}
                    className={`form-control service-form-field color-black-v2 font-14 font-medium ${OnBoardError.first_name !== "" ? 'critical-field' : ''}`}
                    placeholder="Enter your First Name"
                    name="first_name"
                    value={formPayload.first_name}
                    onChange={(e) => {
                        if (/^[a-zA-Z]+$/.test(e.target.value) || e.target.value === "") {
                            handleInputChange(e.target.name, e.target.value)
                        }
                    }}
                />
                {OnBoardError.first_name !== "" &&
                    <span className="font-14 font-medium alert-text">
                        {OnBoardError.first_name}
                    </span>
                }
            </div>
            <div className="col-lg-6 mb-4 mb-lg-5">
                <label
                    htmlFor="lname"
                    className="font-semibold font-16 color-black-v2 mb-2"
                >
                    Last Name <span className="madatory-text">*</span>
                </label>
                <input
                    type="text"
                    id="lname"
                    maxLength={60}
                    className={`form-control service-form-field color-black-v2 font-14 font-medium ${OnBoardError.last_name !== "" ? 'critical-field' : ''}`}
                    placeholder="Enter your Last Name"
                    name="last_name"
                    value={formPayload.last_name}
                    onChange={(e) => {
                        if (/^[a-zA-Z]+$/.test(e.target.value) || e.target.value === "") {
                            handleInputChange(e.target.name, e.target.value)
                        }
                    }}
                />
                {OnBoardError.last_name !== "" &&
                    <span className="font-14 font-medium alert-text">
                        {OnBoardError.last_name}
                    </span>
                }
            </div>
            <div className="col-lg-6 mb-4 mb-lg-5">
                <label
                    htmlFor="Incident"
                    className="font-semibold font-16 color-black-v2 mb-2"
                >
                    Job Title <span className="madatory-text">*</span>
                </label>
                <select

                    id="Incident"
                    className={`w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular ${OnBoardError.job_title_dd !== "" ? 'critical-field' : ''}`}
                    name="job_title_dd"
                    value={JobTitle?.dropdown_uuid}
                    onChange={(e) => { handleInputChange(e.target.name, e.target.value) }}
                >
                    <option value="">Select</option>
                    {/* <option value="option1">SAmple2</option> */}
                    {bindJobtitleDropdown()}
                </select>
                {OnBoardError.job_title_dd !== "" &&
                    <span className="font-14 font-medium alert-text">
                        {OnBoardError.job_title_dd}
                    </span>
                }
            </div>
            {showOthers.job_title &&
                <div className="col-lg-6 mb-4 mb-lg-5">
                    <label
                        htmlFor="lname"
                        className="font-semibold font-16 color-black-v2 mb-2"
                    >
                        Please Specify
                        <span className="madatory-text">*</span>
                    </label>
                    <input
                        type="text"
                        id="lname"
                        maxLength={255}
                        className={`form-control service-form-field color-black-v2 font-14 font-medium ${OnBoardError.job_title !== "" ? 'critical-field' : ''}`}
                        placeholder="Enter job title"
                        name="job_title"
                        value={JobTitle?.others}
                        onChange={(e) => { handleInputChange(e.target.name, e.target.value) }}
                    />
                    {OnBoardError.job_title !== "" &&
                        <span className="font-14 font-medium alert-text">
                            {OnBoardError.job_title}
                        </span>
                    }
                </div>}
            <div className="col-lg-6 mb-4 mb-lg-5">
                <label
                    htmlFor="email"
                    className="font-semibold font-16 color-black-v2 mb-2"
                >
                    Email ID
                    <span className="madatory-text">*</span>
                </label>
                <input
                    type="text"
                    id="email"
                    maxLength={60}
                    className={`form-control service-form-field color-black-v2 font-14 font-medium ${OnBoardError.email_id !== "" ? 'critical-field' : ''}`}
                    placeholder="Enter your Email ID"
                    name="email_id"
                    value={formPayload.email_id}
                    disabled={isEdit}
                    onChange={(e) => { handleInputChange(e.target.name, e.target.value.toLowerCase()) }}
                />
                {OnBoardError.email_id !== "" &&
                    <span className="font-14 font-medium alert-text">
                        {OnBoardError.email_id}
                    </span>
                }
            </div>
            <div className="col-lg-6 mb-4 mb-lg-5">
                <label
                    htmlFor="phone"
                    className="font-semibold font-16 color-black-v2 mb-2"
                >
                    Phone #<span className="madatory-text">*</span>
                </label>
                <input
                    type="text"
                    id="phone"
                    placeholder="Enter Phone #"
                    className={`form-control service-form-field color-black-v2 font-14 font-medium ${OnBoardError.phone_no !== "" ? 'critical-field' : ''}`}
                    name="phone_no"
                    // maxLength={17}
                    value={formPayload.phone_no}
                    onChange={(e) => {
                        const formattedValue = formatPhoneNumberEditContractor(e.target.value);
                        handleInputChange(e.target.name, formattedValue);
                    }}
                />
                {OnBoardError.phone_no !== "" &&
                    <span className="font-14 font-medium alert-text">
                        {OnBoardError.phone_no}
                    </span>
                }
            </div>

            <div className="d-flex align-items-center gap-3 mb-5">
                <h2 className="color-black font-600 font-18 mb-0">
                    Business Information
                </h2>
            </div>
            <div className="col-lg-6 mb-4 mb-lg-5">
                <label
                    htmlFor="lbname"
                    className="font-semibold font-16 color-black-v2 mb-2"
                >
                    Legal Business Name <span className="madatory-text">*</span>
                </label>
                <input
                    type="text"
                    id="lbname"
                    maxLength={255}
                    placeholder="Enter Legal Business Name"
                    className={`form-control service-form-field color-black-v2 font-14 font-medium ${OnBoardError.legal_business_name !== "" ? 'critical-field' : ''}`}

                    name="legal_business_name"
                    value={formPayload.legal_business_name}
                    onChange={(e) => { handleInputChange(e.target.name, e.target.value) }}
                />
                {OnBoardError.legal_business_name !== "" &&
                    <span className="font-14 font-medium alert-text">
                        {OnBoardError.legal_business_name}
                    </span>
                }
            </div>
            <div className="col-lg-6 mb-4 mb-lg-5">
                <label
                    htmlFor="dabname"
                    className="font-semibold font-16 color-black-v2 mb-2"
                >
                    DBA Business Name
                    <span className="madatory-text">*</span>
                </label>
                <input
                    type="text"
                    id="dabname"
                    maxLength={255}
                    placeholder="Enter DBA Name"
                    className={`form-control service-form-field color-black-v2 font-14 font-medium ${OnBoardError.doing_business_as !== "" ? 'critical-field' : ''}`}

                    name="doing_business_as"
                    value={formPayload.doing_business_as}
                    onChange={(e) => { handleInputChange(e.target.name, e.target.value) }}
                />
                {OnBoardError.doing_business_as !== "" &&
                    <span className="font-14 font-medium alert-text">
                        {OnBoardError.doing_business_as}
                    </span>
                }
            </div>
            <div className="col-lg-6 mb-4 mb-lg-5">
                <label
                    htmlFor="addl1"
                    className="font-semibold font-16 color-black-v2 mb-2"
                >
                    Address Line 1<span className="madatory-text">*</span>
                </label>
                <input
                    type="text"
                    id="addl1"
                    placeholder="Address Line 1"
                    maxLength={255}
                    className={`form-control service-form-field color-black-v2 font-14 font-medium ${OnBoardError.address_line_1 !== "" ? 'critical-field' : ''}`}

                    name="address_line_1"
                    value={formPayload.address_line_1}
                    onChange={(e) => { handleInputChange(e.target.name, e.target.value) }}
                />
                {OnBoardError.address_line_1 !== "" &&
                    <span className="font-14 font-medium alert-text">
                        {OnBoardError.address_line_1}
                    </span>
                }
            </div>
            <div className="col-lg-6 mb-4 mb-lg-5">
                <label
                    htmlFor="add2"
                    className="font-semibold font-16 color-black-v2 mb-2"
                >
                    Address Line 2
                    {/* <span className="madatory-text">*</span> */}
                </label>
                <input
                    type="text"
                    id="add2"
                    placeholder="Address Line 2"
                    maxLength={255}
                    className={`form-control service-form-field color-black-v2 font-14 font-medium ${OnBoardError.address_line_2 !== "" ? 'critical-field' : ''}`}
                    name="address_line_2"
                    value={formPayload?.address_line_2}
                    onChange={(e) => { handleInputChange(e.target.name, e.target.value) }}
                />
                {OnBoardError.address_line_2 !== "" &&
                    <span className="font-14 font-medium alert-text">
                        {OnBoardError.address_line_2}
                    </span>
                }
            </div>
            <div className="col-lg-6 mb-4 mb-lg-5">
                <label
                    htmlFor="zip"
                    className="font-semibold font-16 color-black-v2 mb-2"
                >
                    Zip Code
                    <span className="madatory-text">*</span>
                </label>
                <input
                    type="text"
                    id="zip"
                    maxLength={9}
                    placeholder="Enter ZIP Code"
                    className={`form-control service-form-field color-black-v2 font-14 font-medium ${OnBoardError.zip_code !== "" ? 'critical-field' : ''}`}
                    // pattern="^\d?$"
                    name="zip_code"
                    value={formPayload?.zip_code}
                    onChange={(e) => {
                        if (/^\d+$/.test(e.target.value)) {
                            handleInputChange(e.target.name, e.target.value)
                        } else if (e.target.value === "") {
                            handleInputChange(e.target.name, e.target.value)
                        }
                    }}
                />
                {OnBoardError.zip_code !== "" &&
                    <span className="font-14 font-medium alert-text">
                        {OnBoardError.zip_code}
                    </span>
                }
            </div>

            <div className="col-lg-6 mb-4 mb-lg-5">
                <label
                    htmlFor="state"
                    className="font-semibold font-16 color-black-v2 mb-2"
                >
                    State <span className="madatory-text">*</span>
                </label>
                <select
                    id="state"
                    className={`w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular ${OnBoardError.state !== "" ? 'critical-field' : ''}`}
                    name="state"
                    value={formPayload?.state}
                    onChange={(e) => { handleInputChange(e.target.name, e.target.value) }}
                >
                    <option value="">Select</option>
                    {bindStateDropdown()}
                </select>
                {OnBoardError.state !== "" &&
                    <span className="font-14 font-medium alert-text">
                        {OnBoardError.state}
                    </span>
                }
            </div>
            <div className="col-lg-6 mb-4 mb-lg-5">
                <label
                    htmlFor="city"
                    className="font-semibold font-16 color-black-v2 mb-2"
                >
                    City <span className="madatory-text">*</span>
                </label>
                <select

                    id="city"
                    className={`w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular ${OnBoardError.city !== "" ? 'critical-field' : ''}`}
                    name="city"
                    value={formPayload?.city}
                    disabled={DisableCity}
                    onChange={(e) => { handleInputChange(e.target.name, e.target.value) }}
                >
                    <option value="">Select</option>
                    {bindCityDropdown()}
                </select>
                {OnBoardError.city !== "" &&
                    <span className="font-14 font-medium alert-text">
                        {OnBoardError.city}
                    </span>
                }
            </div>
            <div className="col-lg-6 mb-4 mb-lg-5">
                <label
                    htmlFor="region"
                    className="font-semibold font-16 color-black-v2 mb-2"
                >
                    Region <span className="madatory-text">*</span>
                </label>
                <select
                    id="region"
                    className={`w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular ${OnBoardError.region !== "" ? 'critical-field' : ''}`}
                    name="region"
                    value={formPayload?.region_id}
                    onChange={(e) => { handleInputChange(e.target.name, e.target.value) }}
                >
                    <option value="">Select</option>
                    {bindRegionDropdown()}
                </select>
                {OnBoardError?.region !== "" &&
                    <span className="font-14 font-medium alert-text">
                        {OnBoardError?.region}
                    </span>
                }
            </div>
            <div className="col-lg-12 mb-4 mb-lg-5">
                <label
                    htmlFor="link"
                    className="font-semibold font-16 color-black-v2 mb-2"
                >
                    Website / Social Media Links
                    <span className="madatory-text">*</span>
                </label>
                <input
                    type="text"
                    id="link"
                    maxLength={255}
                    placeholder="Paste the Link here"
                    className={`form-control service-form-field color-black-v2 font-14 font-medium ${OnBoardError.social_media !== "" ? 'critical-field' : ''}`}

                    name="social_media"
                    value={formPayload?.social_media}
                    onChange={(e) => { handleInputChange(e.target.name, e.target.value) }}
                />
                {OnBoardError?.social_media !== "" &&
                    <span className="font-14 font-medium alert-text">
                        {OnBoardError?.social_media}
                    </span>
                }
            </div>
            <div className="col-lg-6 mb-4 mb-lg-5">
                <label
                    htmlFor="name-12"
                    className="font-semibold font-16 color-black-v2 mb-2"
                >
                    Heard about us from?
                </label>
                <select
                    name="heard_about_us_dd"
                    id="name-12"
                    className="w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular"
                    value={HeardAboutUs?.dropdown_uuid}
                    onChange={(e) => { handleInputChange(e.target.name, e.target.value) }}
                >
                    <option value="">Select</option>
                    {bindHeardAboutusDropdown()}
                </select>
            </div>
            {showOthers.heard_about_us &&
                <div className="col-lg-6 mb-4 mb-lg-5">
                    <label
                        htmlFor="lname"
                        className="font-semibold font-16 color-black-v2 mb-2"
                    >
                        Please Specify
                    </label>
                    <input
                        type="text"
                        id="lname"
                        maxLength={255}
                        className='form-control service-form-field color-black-v2 font-14 font-medium'
                        placeholder="Enter Heard about us"
                        name="heard_about_us"
                        value={HeardAboutUs?.others}
                        onChange={(e) => { handleInputChange(e.target.name, e.target.value) }}
                    />
                </div>}
            <div className="col-lg-6 mb-4 mb-lg-5">
                <label
                    htmlFor="year"
                    className="font-semibold font-16 color-black-v2 mb-2"
                >
                    Year founded?
                </label>
                {/* <input
                    type="text"
                    id="year"
                    placeholder="Enter the year founded"
                    className={`form-control service-form-field color-black-v2 font-14 font-medium `}
                    maxLength={4}
                    name="year_found"
                    value={formPayload?.year_found}
                    onChange={(e) => {
                        if (/^\d+$/.test(e.target.value)) {
                            handleInputChange(e.target.name, e.target.value)
                        } else if (e.target.value === "") {
                            handleInputChange(e.target.name, e.target.value)
                        }
                    }}
                /> */}
                {/* <DatePicker
                    id="year"
                    // placeholder="Enter the year founded"
                    className={`form-control service-form-field color-black-v2 font-14 font-medium `}
                    selected={formPayload?.year_found ? new Date(formPayload?.year_found) : null}
                    maxDate={new Date(1900, 0, 1)}
                    // onChange={(date) => setStartDate(date)}
                    showYearPicker
                    dateFormat="yyyy"
                /> */}
                {/* <YearRangePicker
                    minYear={new Date().getFullYear() - 2}
                    maxYear={new Date().getFullYear() + 2}
                    onSelect={(startYear: number, endYear: number) => {
                        // setYearRange({ startYear, endYear })
                    }}
                    startYear={1900}
                // endYear={yearRange?.endYear}
                /> */}
                <select
                    value={formPayload?.year_found}
                    name="year_found"
                    onChange={(e) => {
                        handleInputChange(e.target.name, e.target.value)
                    }} className={`w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular`
                    }>
                    {renderYearOptions()}
                </select>
            </div>
            <div className="col-lg-12 mb-4 mb-lg-4">
                <label
                    htmlFor="vendor"
                    className="font-semibold font-16 color-black-v2 mb-2"
                >
                    Are you affiliated with any existing vendors in our network?
                </label>
                <input
                    type="text"
                    id="vendor"
                    maxLength={255}
                    placeholder="Enter the Vendor name if any"
                    className={`form-control service-form-field color-black-v2 font-14 font-medium`}
                    name="affiliated"
                    value={formPayload?.affiliated}
                    onChange={(e) => { handleInputChange(e.target.name, e.target.value) }}
                />
            </div>
            <div className="d-flex align-items-center gap-2 mb-lg-4">
                <input
                    id="add"
                    className="form-check-input custom-checkbox m-0"
                    type="checkbox"
                    name="tracktik_willingness"
                    disabled={isEdit}
                    checked={termsCondition?.tracktik_willingness}
                    onChange={(e) => { handleInputChange(e.target.name, e.target.value) }}
                />
                <label
                    htmlFor="add"
                    className="font-semibold font-15 color-black-v1 cursor-pointer"
                >
                    Willing to use Tracktik Application
                </label>
            </div>
            <div className="d-flex align-items-center gap-2 mb-lg-4">
                <input
                    id="solicitation"
                    className="form-check-input custom-checkbox m-0"
                    type="checkbox"
                    name="solicitation"
                    disabled={isEdit}
                    checked={termsCondition?.solicitation}
                    onChange={(e) => { handleInputChange(e.target.name, e.target.value) }}
                />
                <label
                    htmlFor="solicitation"
                    className="font-semibold font-15 color-black-v1 cursor-pointer"
                >
                    I agree to{" "}
                    <a className="link cust-text-underline"
                        onClick={() => { setShowNonSolicitation(true) }}>
                        conditions of non-solicitation
                    </a>{" "}
                    policy of PPS
                </label>
            </div>
            <div className="d-flex align-items-center gap-2 mb-lg-5">
                <input
                    id="indemnification"
                    className="form-check-input custom-checkbox m-0"
                    type="checkbox"
                    name="indemnification"
                    disabled={isEdit}
                    checked={termsCondition?.indemnification}
                    onChange={(e) => { handleInputChange(e.target.name, e.target.value) }}
                />
                <label
                    htmlFor="indemnification"
                    className="font-semibold font-15 color-black-v1 cursor-pointer"
                >
                    I agree to{" "}
                    <a className="link cust-text-underline" onClick={() => { setShowTermsAndCondition(true) }}>
                        conditions of indemnification
                    </a>{" "}
                    policy of PPS
                </label>
            </div>
            <div className="modal-footer justify-content-between border-0 gap-4 pb-5">
                <button className="btn px-0 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn"
                    onClick={() => (handleCancel())}>
                    Cancel
                </button>
                <div className="d-flex gap-3">
                    <button className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                        onClick={() => {
                            handleNext()

                        }}

                        disabled={!nextButtonEnabled}
                    >
                        Next
                    </button>
                    {/* {isEdit &&
                        <button className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                            // disabled={!buttonSubmit}
                            onClick={() => { SaveFromDetails() }}
                        >
                            Update
                        </button>
                    } */}
                </div>
            </div>
            {showTermsAndCondition &&
                <div
                    className="modal fade show"
                    id="exampleModal14"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                    data-backdrop="static"
                    style={{ display: "block", backgroundColor: "#000", opacity: "100%", background: "static" }}
                >
                    <div className="modal-dialog modal-dialog-centered  modal-lg ">
                        <div className="modal-content border-radius-10">
                            <div className="modal-header border-0 px-sm-5 px-4 pt-4 align-items-center">
                                <h5
                                    className="modal-title font-bold font-18 color-black-v2"
                                    id="exampleModalLabel"
                                >
                                    Conditions of &nbsp;Indemnification
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close custom-btn-close"
                                    // data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => { setShowTermsAndCondition(false) }}
                                />
                            </div>
                            <div className="modal-body px-sm-5 px-4 py-0 popup-scroll">
                                <p className="font-14 font-medium color-grey mb-4">
                                    Read and approve the terms and conditions of indemnification
                                </p>
                                <p className="font-medium font-14 mb-0 color-black cust-lh-2 mb-3">
                                    During the term of this agreement and for a period of three (3) years
                                    commencing on the expiration or termination of this Agreement for any
                                    reason whatsoever, SUBCONTRACTED SECURITY PROVIDER shall not directly
                                    or indirectly approach, solicit or accept business from or otherwise
                                    do business with any Account to whom SUBCONTRACTED SECURITY PROVIDER
                                    renders Services hereunder. Furthermore, SUBCONTRACTED SECURITY
                                    PROVIDER shall not seek any monies or payment directly from the
                                    Account.
                                </p>
                            </div>
                            <div className="modal-footer gap-2 border-0 px-sm-5 px-4 pb-4 pt-5 justify-content-end">
                                <button
                                    className="btn px-5 font-semibold font-14 shadow-none reject-btn"
                                    // data-bs-toggle="modal"
                                    // data-bs-target="#exampleModal12"
                                    onClick={(e) => {
                                        setShowTermsAndCondition(false)
                                        setTermsCondition((prev) => ({ ...prev, indemnification: false }))
                                    }}
                                >
                                    Disagree
                                </button>
                                <button
                                    className="btn px-5 font-semibold font-14 shadow-none accept-btn"
                                    // data-bs-toggle="modal"
                                    // data-bs-target="#exampleModal12"
                                    onClick={(e) => {
                                        setShowTermsAndCondition(false)
                                        setTermsCondition((prev) => ({ ...prev, indemnification: true }))
                                    }}
                                >
                                    Agree
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {showNonSolicitation &&
                <div
                    className="modal fade show"
                    id="exampleModal15"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                    data-backdrop="static"
                    style={{ display: "block", backgroundColor: "#000", opacity: "100%", background: "static" }}
                >
                    <div className="modal-dialog modal-dialog-centered   ">
                        <div className="modal-content border-radius-10">
                            <div className="modal-header border-0 px-4 pt-4 align-items-center">
                                <h5
                                    className="modal-title font-bold font-18 color-black-v2"
                                    id="exampleModalLabel"
                                >
                                    Conditions of &nbsp;Non-Solicitation
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close custom-btn-close"
                                    // data-bs-dismiss="modal"
                                    aria-label="Close"

                                    onClick={() => { setShowNonSolicitation(false) }}
                                />
                            </div>
                            <div className="modal-body px-4 py-0 popup-scroll">
                                <p className="font-14 font-medium color-grey mb-4">
                                    Read and approve the terms and conditions of non-solicitation
                                </p>
                                <p className="font-medium font-14 mb-0 color-black cust-lh-2 mb-3">
                                    1. "To the fullest extent permitted by law, SUBCONTRACTED SECURITY
                                    PROVIDER agrees to indemnify, defend and hold harmless (EMPLOYER) and
                                    (Client / Customer for whom work or services are being performed),
                                    including its and their officers, agents, employees and directors for
                                    which work or services are being performed from any and all claims,
                                    suits, damages, liabilities, professional fees, including attorneys’
                                    fees, costs, court costs, expenses and disbursements for &nbsp;bodily
                                    injury including mental anguish, death, personal injury or property
                                    damage (including loss of use thereof) that arises from direct or
                                    indirect acts, errors or omissions of the SUBCONTRACTED SECURITY
                                    PROVIDER, its agents, servants, subcontractors or employees, or the
                                    use by SUBCONTRACTED SECURITY PROVIDER, its agents, servants,
                                    subcontractors or employees, of facilities owned by (EMPLOYER) or
                                    (Client / Customer for whom work or services are being performed), for
                                    which work or services are being performed.
                                </p>
                                <p className="font-medium font-14 mb-0 color-black cust-lh-2 mb-3">
                                    To the fullest extent permitted by law, SUBCONTRACTED SECURITY
                                    PROVIDER agrees to indemnify, defend and hold harmless (EMPLOYER) and
                                    (Client / Customer for whom work or services are being performed),
                                    including its and their officers,agents, employees and directors from
                                    and against any breach
                                </p>
                                <p className="font-medium font-14 mb-0 color-black cust-lh-2 mb-3">
                                    2. "During the term of this agreement and for a period of three (3)
                                    years commencing on the expiration or termination of this Agreement
                                    for any reason whatsoever, SUBCONTRACTED SECURITY PROVIDER shall not
                                    directly or indirectly approach, solicit or accept business from or
                                    otherwise do business with any Account to whom SUBCONTRACTED SECURITY
                                    PROVIDER renders Services hereunder. Furthermore, SUBCONTRACTED
                                    SECURITY PROVIDER shall not seek any monies or payment directly from
                                    the Account."
                                </p>
                            </div>
                            <div className="modal-footer gap-2 border-0 px-4 pb-4 pt-2 justify-content-end">
                                <button
                                    className="btn px-5 font-semibold font-14 shadow-none reject-btn"
                                    // data-bs-toggle="modal"
                                    // data-bs-target="#exampleModal12"
                                    onClick={() => {
                                        setShowNonSolicitation(false)
                                        setTermsCondition((prev) => ({ ...prev, solicitation: false }))
                                    }}
                                >
                                    Disagree
                                </button>
                                <button
                                    className="btn px-5 font-semibold font-14 shadow-none accept-btn"
                                    // data-bs-toggle="modal"
                                    // data-bs-target="#exampleModal12"
                                    onClick={() => {
                                        setShowNonSolicitation(false)
                                        setTermsCondition((prev) => ({ ...prev, solicitation: true }))
                                    }}
                                >
                                    Agree
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

