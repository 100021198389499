/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */

import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClientID, encryptStorage, PoolID } from '../../Configs/Constant';
import { SignInService } from '../../Service/SignIn';
import { LoginDetails, LoginError, UserDetails } from '../../Interface/SignIn';
import Loader from '../Loader/Loader';


function SignIn() {


    const [showPassword, setShowPassword] = useState(false);
    const [showloader, setShowLoader] = useState(false);
    const [loginDetails, setLoginDetails] = useState<LoginDetails>({
        userID: "",
        password: ""
    });

    const [userDetails, setUserDetails] = useState<UserDetails>({
        user_uuid: "",
        role: "",
        region: "",
        loginUserName: "",
        userName: "",
        rmEmail: "",
        rmPhone: ""
    });
    const [loginError, setLoginError] = useState<LoginError>({
        userID: "",
        errPassword: "",
        err: ""
    });

    const navigate = useNavigate();

    const handleSignInDetails = (name: string, value: string) => {
        try {
            setLoginDetails({ ...loginDetails, [name]: value });
            setLoginError((prev) => ({ ...prev, [name]: "", err: "" }))

            if (name === "password") {
                setLoginError((prev) => ({ ...prev, errPassword: "" }))
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const validateFields = (): boolean => {
        let isValid = true;

        if (loginDetails?.userID?.trim() === "") {
            setLoginError(prevState => ({
                ...prevState,
                userID: "Please enter a valid user id"
            }));
            isValid = false;
        } else {
            setLoginError(prevState => ({
                ...prevState,
                userID: ""
            }));
        }

        if (loginDetails?.password?.trim() === "") {
            setLoginError(prevState => ({
                ...prevState,
                errPassword: "Please enter a valid password"
            }));
            isValid = false;
        } else {
            setLoginError(prevState => ({
                ...prevState,
                errPassword: ""
            }));
        }

        return isValid;
    };

    const signIn = async () => {
        // let isValid = true
        let isValid = validateFields()
        let user_id = ""
        if (isValid) {
            setShowLoader(true)
            try {
                let payload = {
                    user_id: loginDetails.userID,
                    role: "admin"
                }

                const response = await SignInService(payload);
                if (response?.code === 200) {

                    // setUserDetails(response?.data?.user_details)
                    setUserDetails((prev) => ({ ...prev, user_id: response?.data?.user_details?.user_id, role: response?.data?.user_details?.role, region: response?.data?.user_details?.region }))

                    user_id = response?.data?.user_details?.user_id

                    encryptStorage.setItem("token", response?.data?.tokens?.jwt)
                    encryptStorage.setItem("refreshToken", response?.data?.tokens?.refreshToken)
                    encryptStorage.setItem("user_id", loginDetails.userID)
                } else {
                    console.error("Error invalid user ");
                    setLoginError((prev) => ({ ...prev, err: "Incorrect UserId or Password" }))
                    setShowLoader(false)
                }
            } catch (error) {
                console.error("Error validating the data");
                setShowLoader(false)
            }
        }
        if (user_id !== "") {
            // showLoader();

            const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
                Username: loginDetails.userID.trim(),
                Password: loginDetails.password.trim(),
            });

            const poolData = {
                UserPoolId: process.env.REACT_APP_USER_POOL_ID || PoolID,
                ClientId: process.env.REACT_APP_CLIENT_ID || ClientID,
            };

            const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
            const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
                Username: loginDetails.userID,
                Pool: userPool,
            });

            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: async function (result) {

                    var idToken = result.getIdToken().getJwtToken();
                    var accessToken = result.getAccessToken().getJwtToken();
                    //PC_REF_38- Store the refresh token
                    var refreshToken = result.getRefreshToken().getToken()

                    localStorage.setItem("accessToken", accessToken)
                    localStorage.setItem("refreshToken", refreshToken)
                    localStorage.setItem("idToken", idToken)
                    window.location.href = '/summary'
                },
                onFailure: function (err) {
                    // Handle failure
                    // hideLoader()
                    console.log(err, "err");
                    // navigate("/Error");
                    setLoginError((prev) => ({ ...prev, err: err }))
                    handleAuthFailure(err);
                    setShowLoader(false)
                },
                newPasswordRequired: function (userAttributes, requiredAttributes) {
                    // hideLoader()
                    setShowLoader(false)
                    navigate('/reset-password', { state: { userID: loginDetails.userID, password: loginDetails.password, type: "NEW_PASSWORD_REQUIRED" } });
                    // Get these details and call
                }
            });
        }
    }

    const handleAuthFailure = (err: any) => {
        if (err.code === "UserNotFoundException" || err.message.includes("Incorrect username or password.")) {
            setLoginError({ ...loginError, userID: "", errPassword: "", err: "Incorrect UserId or Password" });

        } else if (err.code === "NotAuthorizedException") {
            setLoginError({ ...loginError, userID: "", errPassword: "", err: "Sorry, you are unauthorized to access this application" });
        } else {
            setLoginError({ ...loginError, err: err.message });
        }
    }

    return (
        <>
            {showloader && <Loader />}
            <div className="container-fluid position-relative h-100 min-vh-100">
                <div className="login-bg-image position-absolute h-100 min-vh-100" />
                <div className="row d-flex align-items-center justify-content-center h-100 position-relative ">
                    <div className="col-lg-5 col-sm-9 my-4 cust-client-padding">
                        <div className="position-relative">
                            <div className="creation-container pb-5 px-4 px-md-5 position-relative">
                                <span className="text-center d-flex align-items-center justify-content-center logo-container">
                                    <img src="../images/command-app-logo.svg" alt="Logo" className="mb-4" />
                                </span>
                                <h3 className="font-semibold font-27 text-center mb-4 primary-text-color">
                                    Sign In
                                </h3>
                                <div className="row">
                                    <div className="col-lg-12 mb-3">
                                        <label
                                            htmlFor="userID"
                                            className="font-semibold font-15 primary-text-color mb-2"
                                        >
                                            User ID
                                        </label>
                                        <input
                                            type="text"
                                            id="userID"
                                            // className="form-control custom-form-fields color-black-v1 font-16 font-medium"
                                            className={`form-control custom-form-fields color-black-v1 font-16 font-medium ${loginError.userID !== "" ? 'critical-field' : ''}`}
                                            placeholder="Enter your User ID"
                                            name='userID'
                                            value={loginDetails.userID}
                                            onChange={(e: any) => handleSignInDetails(e.target.name, e.target.value)}
                                        />
                                        {loginError.userID !== "" &&
                                            <span className="font-14 font-medium alert-text">
                                                {loginError.userID}
                                            </span>
                                        }
                                    </div>
                                    <div className="col-lg-12 mb-4 position-relative">
                                        <label
                                            htmlFor="password"
                                            className="font-semibold font-15 primary-text-color mb-2"
                                        >
                                            Password
                                        </label>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            id="password"
                                            // className="form-control custom-form-fields color-black-v1 font-16 font-medium pe-5"
                                            className={`form-control custom-form-fields color-black-v1 font-16 font-medium pe-5 ${loginError.errPassword !== "" ? 'critical-field' : ''}`}
                                            placeholder="Enter your password"
                                            name='password'
                                            value={loginDetails.password}
                                            onChange={(e: any) => handleSignInDetails(e.target.name, e.target.value)}
                                            onKeyDown={(e: any) => { if (e.key === "Enter") { signIn() } }}
                                        />
                                        {showPassword ?
                                            <button className="position-absolute field-icons p-0 border-0 bg-transparent">
                                                <img src="../images/eye-icon.svg" alt="eye-icon"
                                                    onClick={() => { setShowPassword(!showPassword) }} />
                                            </button>
                                            :
                                            <button className="position-absolute field-icons p-0 border-0 bg-transparent">
                                                <img src="../images/hidden-grey.svg" alt="eye-icon"
                                                    onClick={() => { setShowPassword(!showPassword) }} />
                                            </button>
                                        }

                                        {loginError.errPassword !== "" &&
                                            <span className="font-14 font-medium alert-text">
                                                {loginError.errPassword}
                                            </span>
                                        }
                                        {loginError.err !== "" &&
                                            <span className="font-14 font-medium alert-text">
                                                {loginError.err}
                                            </span>
                                        }

                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center mt-4 flex-column gap-4">
                                    <button
                                        type="button"
                                        className="btn primary-login-btn font-15 font-semibold text-nowrap w-100"
                                        onClick={signIn}
                                    >
                                        Sign In
                                    </button>
                                    <a className="font-15 font-medium link-v2"
                                        onClick={() => { navigate("/forgot-password") }}>
                                        Forgot Password
                                    </a>
                                </div>
                            </div>
                            <div className="creation-gradient-container"></div>
                        </div>
                    </div>
                </div>
                <p className="color-white font-semibold font-15 text-center position-absolute w-100 helpline mt-2">
                    Reach our command center 24/7 at:{" "}
                    <a href="tel:+1-800-775-0805" className="color-white cust-text-underline">
                        1-800-775-0805
                    </a>{" "}
                    |
                    <a
                        href="mailto:sales@premierprotective.com"
                        className="color-white cust-text-underline"
                    >
                        {" "}sales@premierprotective.com
                    </a>
                </p>
            </div></>
    );
}

export default SignIn;