/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  ClientGridDataInterface,
  IndustryDataInterface,
  InviteUpdateClientFormDataPayloadInterface,
  sortOrderInterfaceType,
} from "../../../Interface/ManageClient";
import TooltipText from "../../JobsAndBids/TooltipText";

const InviteeResponsesGrid = ({
  gridData,
  totalCount,
  handleSort,
  sortOrder,
  setSortOrder,
  handleLoadMore,
  setInviteUpdateClientFormDataPayload,
  setShowDeletePopUp,
  // handleEditClient,
  setShowClientForm,
  industryData,
}: {
  gridData: ClientGridDataInterface[];
  totalCount: number;
  handleSort: (column: string, order: boolean) => void;
  sortOrder: sortOrderInterfaceType;
  setSortOrder: React.Dispatch<React.SetStateAction<sortOrderInterfaceType>>;
  handleLoadMore: () => void;
  setInviteUpdateClientFormDataPayload: React.Dispatch<
    React.SetStateAction<InviteUpdateClientFormDataPayloadInterface>
  >;
  setShowDeletePopUp: React.Dispatch<React.SetStateAction<boolean>>;
  // handleEditClient: (clientData: ClientGridDataInterface) => void;
  setShowClientForm: React.Dispatch<React.SetStateAction<boolean>>;
  industryData: IndustryDataInterface[];
}) => {
  const bindGridData = () => {
    return gridData?.map((data) => {
      return (
        <tr key={data?.customer_uuid}>
          <td className="ps-4">
            {data?.customer_dba !=="" ? data?.customer_dba: "-"}
          </td>
          <td>
            {data?.first_name === "" && data?.last_name === ""
              ? "-"
              : data?.first_name + " " + data?.last_name}
          </td>
          {/* <td>{data.industry_name === "" ?  "-" : data?.industry_name.toLowerCase() === "other" ? data?.other_industry: data.industry_name}</td> */}
          <td>
            <TooltipText text={data?.industry_name === "" ? "-" : data?.industry_name} maxLength={15} />

            </td>
          <td>{data?.business_email}</td>
          {/* <td>{data.industry_name === "" ?  "-" : data?.industry_name.toLowerCase() === "other" ? data?.other_industry: data.industry_name}</td> */}
          <td>
            {/* {data?.phone_number) */}
            {/* {data?.phone_number !== ""
              ? isRegexValidMobile(data?.phone_number)
                ? data?.phone_number
                : usPhoneNumberFormat(data?.phone_number)
              : "-"} */}
            {data.phone_number === "" ? "-": data?.phone_number}
          </td>
          <td>
            <div className="d-flex align-items-center gap-3 justify-content-center">
              <button
                className="btn green-btn px-4 font-12 font-semibold"
                onClick={() => {
                  setInviteUpdateClientFormDataPayload((prevState) => ({
                    ...prevState,
                    business_email: data?.business_email?.toLowerCase(),
                    customer_dba: data?.customer_dba,
                    first_name: data?.first_name,
                    last_name: data?.last_name,
                    customer_uuid: data?.customer_uuid,
                    industry_uuid: data?.industry_uuid,
                    is_mark_active: data?.IsMarkActive,
                    phone_number: data?.phone_number,
                    other_industry: data?.other_industry,
                    post_type: "update",
                  }));
                  // handleEditClient(data);
                  setShowClientForm(true);
                }}
              >
                Edit
              </button>
              <button
                className="btn red-btn px-4 font-12 font-semibold"
                onClick={() => {
                  // handleDeleteClient(data.customer_uuid);
                  setInviteUpdateClientFormDataPayload((prevState) => ({
                    ...prevState,
                    post_type: "delete",
                    customer_uuid: data?.customer_uuid,
                  }));
                  setShowDeletePopUp(true);
                }}
              >
                Delete
              </button>
            </div>
          </td>
        </tr>
      );
    });
  };
  return (
    <div
      className="tab-pane fade show active"
      id="pills-contact"
      role="tabpanel"
      aria-labelledby="pills-contact-tab"
    >
      <div
        className="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <div className="table-responsive theme-table bg-white custom-scroll my-4 w-100">
          <table className="table table-borderless mb-0 acc-view">
            <thead>
              <tr>
                <th className="ps-4 text-nowrap cursor-pointer"  onClick={() => {
                        setSortOrder((prevState) => ({
                          ...prevState,
                          customer_dba: !prevState.customer_dba,
                        }));
                        handleSort("customer_dba", sortOrder.customer_dba);
                      }}>
                  DBA Name
                  <span className="ms-1 cursor-pointer">
                    <img
                      src={`../images/${
                        sortOrder.customer_dba
                          ? "sort-up-icon"
                          : "sort-arrow-down"
                      }.svg`}
                      alt={`${
                        sortOrder.customer_dba
                          ? "sort-up-icon"
                          : "sort-arrow-down"
                      }`}
                      // onClick={() => {
                      //   setSortOrder((prevState) => ({
                      //     ...prevState,
                      //     customer_dba: !prevState.customer_dba,
                      //   }));
                      //   handleSort("customer_dba", sortOrder.customer_dba);
                      // }}
                    />
                  </span>
                </th>
                <th className="text-nowrap cursor-pointer" onClick={() => {
                        setSortOrder((prevState) => ({
                          ...prevState,
                          first_name: !prevState.first_name,
                        }));
                        handleSort("first_name", sortOrder.first_name);
                      }}>
                  Name
                  <span className="ms-1 cursor-pointer">
                    <img
                      src={`../images/${
                        sortOrder.first_name
                          ? "sort-up-icon"
                          : "sort-arrow-down"
                      }.svg`}
                      alt={`${
                        sortOrder.first_name
                          ? "sort-up-icon"
                          : "sort-arrow-down"
                      }`}
                      // onClick={() => {
                      //   setSortOrder((prevState) => ({
                      //     ...prevState,
                      //     first_name: !prevState.first_name,
                      //   }));
                      //   handleSort("first_name", sortOrder.first_name);
                      // }}
                    />
                  </span>
                </th>
                <th className="text-nowrap cursor-pointer" onClick={() => {
                        setSortOrder((prevState) => ({
                          ...prevState,
                          industry_name: !prevState.industry_name,
                        }));
                        handleSort("industry_name", sortOrder.industry_name);
                      }}>
                  Industry
                  <span className="ms-1 cursor-pointer">
                    <img
                      src={`../images/${
                        sortOrder.industry_name
                          ? "sort-up-icon"
                          : "sort-arrow-down"
                      }.svg`}
                      alt={`${
                        sortOrder.industry_name
                          ? "sort-up-icon"
                          : "sort-arrow-down"
                      }`}
                      // onClick={() => {
                      //   setSortOrder((prevState) => ({
                      //     ...prevState,
                      //     industry_name: !prevState.industry_name,
                      //   }));
                      //   handleSort("industry_name", sortOrder.industry_name);
                      // }}
                    />
                  </span>
                </th>
                <th className=" text-nowrap cursor-pointer"  onClick={() => {
                        setSortOrder((prevState) => ({
                          ...prevState,
                          business_email: !prevState.business_email,
                        }));
                        handleSort("business_email", sortOrder.business_email);
                      }}>Email Address
                <span className="ms-1 cursor-pointer">
                    <img
                      src={`../images/${
                        sortOrder.business_email
                          ? "sort-up-icon"
                          : "sort-arrow-down"
                      }.svg`}
                      alt={`${
                        sortOrder.business_email
                          ? "sort-up-icon"
                          : "sort-arrow-down"
                      }`}
                      // onClick={() => {
                      //   setSortOrder((prevState) => ({
                      //     ...prevState,
                      //     business_email: !prevState.business_email,
                      //   }));
                      //   handleSort("business_email", sortOrder.business_email);
                      // }}
                    />
                  </span>
                </th>
                <th className=" text-nowrap">Phone #</th>
                <th className=" text-nowrap text-center">Actions</th>
              </tr>
            </thead>
            <tbody>{bindGridData()}</tbody>
          </table>
        </div>
      </div>
      <p className="font-14 font-medium color-grey-v7">
        Showing{" "}
        <span className="font-semibold color-black">{gridData?.length}</span>{" "}
        out of <span className="font-semibold color-black">{totalCount}</span>{" "}
        Item
      </p>
      {totalCount > gridData?.length && gridData?.length >= 20 && (
        <div className="d-flex align-items-center justify-content-center">
          <button
            className="btn px-4 color-white font-semibold font-14 primary-btn px-4 rounded-3 text-center"
            onClick={() => {
              handleLoadMore();
            }}
          >
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

export default InviteeResponsesGrid;
