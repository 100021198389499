
import React, { useState, useEffect, useCallback } from 'react';
import FilterComponent from './ContractorFilterComponent';
import AllContractorsTab from './AllContractorsTab';
import InviteeResponsesTab from './InviteeResponses';
import { Contractor, ContractorRequest, InviteContractorData } from '../../Interface/ContractorGrid';
import { getContractorDetailsApi, inviteContractorApi } from '../../Service/ServiceRequest/ManageContractor/ManageContractorService';
import AccountRequestsTab from './AccountRequestsTab';
import InviteContractor from './InviteContractor';
import Loader from '../Loader/LoaderComponent';
import { useLocation } from 'react-router-dom';
import Toast from '../Toast';
import { ToastInterface } from '../../Interface/ManageContractor';

interface TabProps {
    data: Contractor[];
    loading: boolean;
    fetchGridData: () => void;
    onEdit: (contractor: InviteContractorData) => void;
    onDelete: (contractor: InviteContractorData) => void;
    handleSort: (sortBy: string, sortOrder: 'ASC' | 'DESC') => void;
    currentSortBy: string;
    currentSortOrder: 'ASC' | 'DESC';
    hasMore: boolean;
    onLoadMore: () => void;
    totalContractors: number;
    noOfContractors: number;
    displayToast: (message: string) => void;
    onEditFeedback: (message: string) => void;
    activeTab: "account-requests" | "all-contractors" | "invitee-responses"
}

function ContractorGrid() {
    
  const initialToastDetails : ToastInterface ={
    isToast: false,
    toastDescription: "",
    toastMessage: "",
    toastType: "",
  }
    const [activeTab, setActiveTab] = useState<'account-requests' | 'all-contractors' | 'invitee-responses'>('account-requests');
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [gridData, setGridData] = useState<Contractor[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [selectedContractor, setSelectedContractor] = useState<InviteContractorData | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [stateNames, setStateNames] = useState<string[]>([]);
    const [noOfContractors, setNoOfContractors] = useState<number>(0);
    const [totalContractors, setTotalContractors] = useState<number>(0);
    const [showToast, setShowToast] = useState(false);
  const [toastDetails, setToastDetails] = useState<ToastInterface>(initialToastDetails)

    const [toastMessage, setToastMessage] = useState<string>('');
    const [requestData, setRequestData] = useState<ContractorRequest>({
        contractor_type: 'account_requests',
        limit: 10,
        sort_by: '',
        sort_order: '',
        search_keyword: '',
        filters: {
            operating_states: '',
            services_offered: 0,
            license_validity: '',
            account_status: '',
            invited_on: {
                from: '',
                to: ''
            },
            requested_on: {
                from: '',
                to: ''
            }
        }
    });

    const displayToast = (message: string) => {
        setToastMessage(message);
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000);
    };

    const resetFilters = () => ({
        operating_states: '',
        services_offered: 0,
        license_validity: '',
        account_status: '',
        invited_on: {
            from: '',
            to: ''
        },
        requested_on: {
            from: '',
            to: ''
        }
    });

    const [filters, setFilters] = useState(resetFilters());

    const fetchGridData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await getContractorDetailsApi(requestData);
            if (response?.code === 200) {
                const newContractors = response?.data?.contractors || [];
                setGridData(newContractors);
                if (response?.data?.all_state_names) {
                    setStateNames(response?.data?.all_state_names);
                }
                setTotalContractors(response?.data?.total_no_of_contractors || 0);
                setNoOfContractors(response?.data?.no_of_contractors || 0)
            } else if (response?.statusCode === 204) {
                setGridData([]);
                setTotalContractors(0);
                setNoOfContractors(0);
            }
        } catch (error) {
            console.error("Error fetching grid data:", error);
        } finally {
            setLoading(false);
        }
    }, [requestData]);

    const handleEditFeedback = (message: string) => {
        displayToast(message);
    };

    const location = useLocation()

    useEffect(() => {
        location?.state?.tab && setActiveTab(location?.state?.tab)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.state?.tab]);


    useEffect(() => {
        fetchGridData();
    }, [fetchGridData]);
    // Change the signature of handleCloseFilter to accept filters
    const handleCloseFilter = () => {
        setShowFilter(false);
        // Probably we don't need to set filters again as closing the filter shouldn't change any filter values.
      };

    const handleCancelFilter = () => {
        setFilters(resetFilters());
        setRequestData(prevData => ({
            ...prevData,
            filters: resetFilters()
        }));
        setShowFilter(false);
    };

    const handleApplyFilter = (newFilters: any) => {
        setFilters(newFilters);
        setRequestData(prevData => ({
            ...prevData,
            filters: newFilters,
            limit: 10
        }));
        setGridData([]);
        setShowFilter(false);
    };

    const handleSort = (sortBy: string, sortOrder: 'ASC' | 'DESC') => {
        setRequestData(prevData => ({
            ...prevData,
            sort_by: sortBy,
            sort_order: sortOrder,
            limit: 10
        }));
        setGridData([]);
    };

    const handleTabChange = (tab: 'account-requests' | 'all-contractors' | 'invitee-responses') => {
        setActiveTab(tab);
        const resetFilterValues = resetFilters();
        setFilters(resetFilterValues);
        setRequestData(prevData => ({
            ...prevData,
            contractor_type: tab === 'account-requests' ? 'account_requests' :
                tab === 'all-contractors' ? 'all_contractors' : 'invitee_responses',
            filters: resetFilterValues,
            limit: 10
        }));
        setGridData([]);
    };

    const handleAddNew = () => {
        setIsEdit(false);
        setSelectedContractor(null);
        setShowInviteModal(true);
    };

    const handleEdit = (contractor: InviteContractorData) => {
        setIsEdit(true);
        setSelectedContractor(contractor);
        setShowInviteModal(true);
    };

    const handleDelete = useCallback(async (contractor: InviteContractorData) => {
        try {
            await inviteContractorApi(contractor);
            fetchGridData();
        } catch (error) {
            console.error("Error deleting contractor:", error);
        }
    }, [fetchGridData]);

    const handleCloseModal = () => {
        setShowInviteModal(false);
        setIsEdit(false);
        setSelectedContractor(null);
        setLoading(true)
    };

    const handleClose = () => {
        setShowInviteModal(false);
        setIsEdit(false);
        setSelectedContractor(null);
    }

    const handleGridRefresh = () => {
        handleTabChange("invitee-responses")
    }

    const handleSearch = () => {
        setRequestData(prevData => ({
            ...prevData,
            search_keyword: searchTerm.trim(),
            limit: 10
        }));
        setGridData([]);
    };

    const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchTerm(value);
        if (value.trim() === '') {
            clearSearch();
        }
    };

    const handleSearchKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const clearSearch = () => {
        setSearchTerm('');
        setRequestData(prevData => ({
            ...prevData,
            search_keyword: '',
            limit: 10
        }));
        setGridData([]);
    };

    const handleLoadMore = () => {
        if (noOfContractors < totalContractors) {
            setRequestData(prevData => ({
                ...prevData,
                limit: noOfContractors + 10
            }));
        }
    };

    const hasMore = noOfContractors < totalContractors;

    const renderActiveTab = () => {
        const props: TabProps = {
            data: gridData,
            loading,
            fetchGridData,
            handleSort,
            currentSortBy: requestData.sort_by,
            currentSortOrder: requestData.sort_order as 'ASC' | 'DESC',
            onEdit: handleEdit,
            onDelete: handleDelete,
            hasMore,
            onLoadMore: handleLoadMore,
            totalContractors,
            noOfContractors,
            displayToast,
            onEditFeedback: handleEditFeedback,
            activeTab: activeTab
        };

        switch (activeTab) {
            case 'account-requests':
                return <AccountRequestsTab {...props} />;
            case 'all-contractors':
                return <AllContractorsTab {...props} />;
            case 'invitee-responses':
                return <InviteeResponsesTab {...props} />;
            default:
                return <AccountRequestsTab {...props} />;
        }
    };

    return (
        <>
            <div className="container-fluid p-4 pt-5">
                <h2 className="color-black font-20 font-bold">Manage Contractors</h2>

                <div className="my-4 d-flex align-items-center justify-content-between flex-wrap gap-3">
                    <div className="tab-scroll">
                        <ul className="nav nav-pills cust-nav-pills flex-nowrap" id="pills-tab" role="tablist">
                            {['account-requests', 'all-contractors', 'invitee-responses'].map((tab) => (
                                <li key={tab} className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link cust-nav-link text-nowrap ${activeTab === tab ? 'active' : ''}`}
                                        onClick={() => handleTabChange(tab as any)}
                                    >
                                        {tab.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="d-flex align-items-center justify-content-md-center gap-3 flex-wrap mt-2 mt-md-0">
                        <div className="position-relative">
                            <input
                                type="search"
                                className="custom-input-search font-14 font-medium color-black custom-search"
                                placeholder="Search here"
                                value={searchTerm}
                                onChange={handleSearchInputChange}
                                onKeyPress={handleSearchKeyPress}
                            />
                            <button
                                className="btn bg-transparent border-0 focus-none search-icon"
                                onClick={handleSearch}
                            >
                                <img className="tab-icon" src="../images/search-icon.svg" alt="search" />
                            </button>
                        </div>
                        <button
                            className="btn common-btn px-3 show"
                            onClick={() => setShowFilter(!showFilter)}
                        >
                            <img src="../images/filter-icons.svg" alt="filter-icons" />
                        </button>
                        {showFilter && (
                            <FilterComponent
                                activeTab={activeTab}
                                onApplyFilter={handleApplyFilter}
                                onClose={handleCloseFilter}
                                onCancel={handleCancelFilter}
                                stateNames={stateNames}
                                initialFilters={filters}
                            />
                        )}
                        <button
                            className="btn border-0 d-flex align-items-center gap-3 color-white font-semibold font-14 primary-btn px-4 rounded-3"
                            onClick={handleAddNew}
                        >
                            <img src="../images/plus-white-icon.svg" alt="Plus Icon" />
                            Add New Contractor
                        </button>
                    </div>
                </div>

                <div className="tab-content pt-1" id="pills-tabContent">
                    {loading ? (
                        <Loader />
                    ) : gridData.length > 0 ? (
                        renderActiveTab()
                    ) : (
                        <div className="d-flex align-items-center justify-content-center p-5 flex-column">
                            <img className="no-record-img" src="../images/no-record.gif" alt="No record" />
                            <p className="font-medium font-20 color-grey-v1 mb-0 mt-3 text-center">No Records Found</p>
                        </div>

                    )}
                </div>
                {showInviteModal && (
                    <InviteContractor
                        onCloseIcon={handleClose}
                        onClose={handleCloseModal}
                        onInviteSent={handleGridRefresh}
                        isEdit={isEdit}
                        existingData={selectedContractor || undefined}
          setToastDetails = {setToastDetails}
                    />
                )}
            </div>
            {toastDetails?.isToast && (
          <Toast
            props={{
              isToast: toastDetails?.isToast,
              setToastDetails: setToastDetails,
              toastMessage: toastDetails?.toastMessage,
              toastType: toastDetails?.toastType,
              toastDescription: toastDetails?.toastDescription
            }}
          />
        )}

            {showToast && (
                <div className="position-fixed top-0 start-50 translate-middle-x p-3" style={{ zIndex: 9999 }}>
                    <div className="toast cust-toast w-100 p-1 toast-border fade show bg-white" role="alert" aria-live="assertive" aria-atomic="true">
                        <div className="toast-body d-flex align-items-center justify-content-between gap-5">
                            <div className="d-flex align-items-center gap-3">
                                <img src="../images/toast-sucess.svg" alt="Successfully" />
                                <div className="d-block">
                                    <p className="mb-0 font-4 font-semibold color-black text-nowrap mb-2">
                                        Action Successful
                                    </p>
                                    <p className="font-medium font-14 mb-0 color-grey-v3 text-nowrap">
                                        {toastMessage}
                                    </p>
                                </div>
                            </div>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => setShowToast(false)}
                                aria-label="Close"
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ContractorGrid;