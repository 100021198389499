import React from 'react';

interface FlagModalProps {
  showModal: boolean;
  onConfirmFlag: () => void; // Replace 'any' with the actual type
  onClose: () => void;
  type?: string;
}

const FlagModal: React.FC<FlagModalProps> = ({ showModal, onConfirmFlag, onClose, type }) => {
  const handleConfirmClick = () => {
    onConfirmFlag();
  };

  if (!showModal) {
    return null;
  }

  return (
    <>{type === "Feedback" &&
      <div className="modal fade show" id="exampleModal6" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog" style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered   ">
          <div className="modal-content border-radius-10">
            <div className="modal-header border-0 px-4 pt-4 align-items-start">
              <h5 className="modal-title font-bold font-18 color-black-v2 mb-2" id="exampleModalLabel">
                Flag Contractor</h5>
              <button type="button" className="btn-close custom-btn-close mt-1" data-bs-dismiss="modal" aria-label="Close" onClick={onClose} />
            </div>
            <div className="modal-body px-4 py-0">
              <p className="font-medium font-16 mb-0 color-black cust-lh mb-4">
                Are you sure, do you want to <span className="font-semibold">flag this contractor?</span>
              </p>
              <p className="font-medium font-16 mb-0 color-black cust-lh mb-3">
                Flagging this contractor would make this contractor state to go <span className="font-semibold">inactive</span> which will prevent them
                from receiving upcoming job requests
              </p>
            </div>
            <div className="modal-footer gap-2 border-0 px-4 pb-4 pt-2 justify-content-end">
              <button onClick={onClose} className="btn px-4 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn">Cancel</button>
              <button onClick={()=>{
                handleConfirmClick()
              }} className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4" data-bs-toggle="modal" data-bs-target="#exampleModal6">Proceed</button>
            </div>
          </div>
        </div>
      </div>
    }
      {
        type === "Users" &&
        <div className="modal fade show" id="exampleModal6" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog modal-dialog-centered   ">
            <div className="modal-content border-radius-10">
              <div className="modal-header border-0 px-4 pt-4 align-items-start">
                <h5 className="modal-title font-bold font-18 color-black-v2 mb-2" id="exampleModalLabel">
                  Inactivate User</h5>
                <button type="button" className="btn-close custom-btn-close mt-1" data-bs-dismiss="modal" aria-label="Close" onClick={onClose} />
              </div>
              <div className="modal-body px-4 py-0">
                <p className="font-medium font-16 mb-0 color-black cust-lh mb-4">
                  Are you sure, do you want to inactivate this user?
                </p>
              </div>
              <div className="modal-footer gap-2 border-0 px-4 pb-4 pt-2 justify-content-end">
                <button onClick={onClose} className="btn px-4 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn">No</button>
                <button onClick={handleConfirmClick} className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4">Yes</button>
              </div>
            </div>
          </div>
        </div>
      }
    </>


  );
};

export default FlagModal;

