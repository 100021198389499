import { client } from '../../../Client/Client';
import { manageContractor } from '../../../Configs/BackendRoutes';
import { backendUrl  } from '../../../Configs/Constant';
import { ContractorRequest } from '../../../Interface/ContractorGrid';


export const getContractorDetailsApi = async (data:ContractorRequest) => {
    const config = {
        method: 'POST',
        url: backendUrl + manageContractor.getContractorDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };
    const response = await client(config);
    return response;
};

export const approveRejectApi = async (data:any) => {
    const config = {
        method: 'POST',
        url: backendUrl + manageContractor.approveOrRejectContractors,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };
    const response = await client(config);
    return response;
};

export const inviteContractorApi = async (data:any) => {
    const config = {
        method: 'POST',
        url: backendUrl + manageContractor.inviteContractors,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };
    const response = await client(config);
    return response;
};



