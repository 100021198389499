import { client } from '../../Client/Client';
import { Summary } from '../../Configs/BackendRoutes';
// import { serviceRequestServices } from '../../Configs/BackendRoutes';
import { backendUrl as URL  } from '../../Configs/Constant';

export const getSummaryData = async (payload : any) => {
    const config = {
        method: 'POST',
        url: URL + Summary,
        headers: {
            'Content-Type': 'application/json'
        },
        data: payload
    };
    const response = await client(config);
    return response?.data;
};


