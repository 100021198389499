/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from "react";
import { GridData } from "../../Interface/JobsAndBids";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/LoaderComponent";
import ContractorReinitiate from './ContractorReinitiate';
import { reinitiateApi } from '../../Service/BidApprovalServices/BidInfoService';
import TooltipText from "./TooltipText";

interface ClosedTabProps {
  role: string;
  data: GridData[];
  totalCount: number;
  onLoadMore: () => void;
  hasMore: boolean;
  loading: boolean;
  currentSortBy: string;
  currentSortOrder: string;
  renderSortIcon: (column: string) => JSX.Element;
  handleSort: (column: string) => void;
  customerUUID?: string;
  contractorUUID?: string;
}

const ClosedTab: React.FC<ClosedTabProps> = ({
  role,
  data,
  totalCount,
  onLoadMore,
  hasMore,
  loading,
  currentSortBy,
  currentSortOrder,
  renderSortIcon,
  handleSort,
  customerUUID,
  contractorUUID,
}) => {
  const navigate = useNavigate();
  const [isContractorReinitiateModalOpen, setIsContractorReinitiateModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState<GridData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const getFeedbackEmoji = (rating: number) => {
    switch (rating) {
      case 1:
        return '../images/not-satisfied-emoji.svg';
      case 2:
        return '../images/neutral-emoji.svg';
      case 3:
        return '../images/satisfied-emoji.svg';
      case 4:
      case 5:
        return '../images/lovely-emoji.svg';
      default:
        return '../images/default-emoji.svg';
    }
  };

  const handleProceed = async () => {
    if (selectedJob) {
      const approvalRequestData = {
        type: 'closed',
        bid_person: 'contractor',
        service_request_id: selectedJob.service_request_uuid,
        contractor_id: '',  // Update this with the actual contractor ID if you have it
        new_offer_price: 0, // Original bid price, intentionally empty
        margin_percentage: 0, // Margin percentage, intentionally empty
        margin_amount: 0, // Margin amount, intentionally empty
        margin_final_value: 0 // Final value including margin, intentionally empty
      };

      try {
        setIsConfirmationModalOpen(false);
        setIsLoading(true);
        const response = await reinitiateApi(approvalRequestData);

        if (response.code === 200) {
          setToastMessage('Bid reinitiated successfully');
          setShowToast(true);

          // Close the modal and reload the grid after a short delay to show the toast
          setTimeout(() => {
            setShowToast(false);
            onLoadMore(); // Reload the grid data


            setSelectedJob(null);
          }, 2000);
        } else {
          console.error('Error reinitiating bid:', response.message);
          setToastMessage('Error reinitiating bid');
          setShowToast(true);
          setTimeout(() => setShowToast(false), 3000);
        }
      } catch (error) {
        console.error('Error reinitiating bid:', error);
        setToastMessage('Error reinitiating bid');
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleReinitiate = (job: GridData) => {
    setSelectedJob(job);
    setIsConfirmationModalOpen(true);
  };

  const closeViewBidsModal = () => {
    setIsContractorReinitiateModalOpen(false);
    setSelectedJob(null);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>

      <div
        className="tab-pane fade show active"
        id="pills-closed"
        role="tabpanel"
        aria-labelledby="pills-closed-tab"
      >
        <div className="table-responsive theme-table bg-white custom-scroll my-4 w-100 vertical-align-top">
          {data?.length > 0 ? (
            <table className="table table-borderless mb-0 acc-view">
              <thead>
                {customerUUID ? (
                  <tr>
                    <th
                      className="text-nowrap cursor-pointer "
                      onClick={() => handleSort("sr.service_request")}
                    >
                      Job Title {renderSortIcon("sr.service_request")}
                    </th>
                    <th
                      className="text-nowrap cursor-pointer "
                      onClick={() => handleSort("sr.service_city")}
                    >
                      Location {renderSortIcon("sr.service_city")}
                    </th>
                    <th
                      className="text-nowrap cursor-pointer "
                      onClick={() => handleSort("sr.created_on")}
                    >
                      Job Raised On {renderSortIcon("sr.created_on")}
                    </th>
                    <th
                      className="text-nowrap cursor-pointer "
                      onClick={() => handleSort("sr.service_start_date")}
                    >
                      Requested On {renderSortIcon("sr.service_start_date")}
                    </th>
                    <th className="text-nowrap text-center">Feedback</th>
                    <th className="text-nowrap text-center">Actions</th>
                  </tr>
                ) : contractorUUID ? (
                  <tr>
                    <th
                      className="text-nowrap cursor-pointer "
                      onClick={() => handleSort("sr.service_request")}
                    >
                      Job Title {renderSortIcon("sr.service_request")}
                    </th>
                    <th
                      className="text-nowrap cursor-pointer "
                      onClick={() => handleSort("sr.service_city")}
                    >
                      Location {renderSortIcon("sr.service_city")}
                    </th>
                    <th
                      className="text-nowrap cursor-pointer "
                      onClick={() => handleSort("sr.created_on")}
                    >
                      Job Raised On {renderSortIcon("sr.created_on")}
                    </th>
                    <th
                      className="text-nowrap cursor-pointer "
                      onClick={() => handleSort("sr.service_start_date")}
                    >
                      Requested On {renderSortIcon("sr.service_start_date")}
                    </th>
                    <th
                      className="text-nowrap cursor-pointer "
                      onClick={() => handleSort("approved_by")}
                    >
                      Approved By {renderSortIcon("approved_by")}
                    </th>
                    <th className="text-nowrap text-center">Feedback</th>
                    <th className="text-nowrap text-center">Actions</th>
                  </tr>
                ) : (
                  <tr>
                    <th
                      className="text-nowrap cursor-pointer "
                      onClick={() => handleSort("sr.service_request")}
                    >
                      Job Title {renderSortIcon("sr.service_request")}
                    </th>
                    <th
                      className="text-nowrap cursor-pointer "
                      onClick={() => handleSort("sr.service_city")}
                    >
                      Location {renderSortIcon("sr.service_city")}
                    </th>
                    <th
                      className="text-nowrap cursor-pointer "
                      onClick={() => handleSort("sr.created_on")}
                    >
                      Job Raised On {renderSortIcon("sr.created_on")}
                    </th>
                    <th
                      className="text-nowrap cursor-pointer "
                      onClick={() => handleSort("sr.service_start_date")}
                    >
                      Requested On {renderSortIcon("sr.service_start_date")}
                    </th>
                    {role === "Admin" && (
                      <th
                        className="text-nowrap cursor-pointer "
                        onClick={() => handleSort("contractor_name")}
                      >
                        Approved Contractor{" "}
                        {renderSortIcon("contractor_name")}
                      </th>
                    )}
                    <th
                      className="text-nowrap cursor-pointer "
                      onClick={() => handleSort("approved_by")}
                    >
                      Approved By {renderSortIcon("approved_by")}
                    </th>
                    <th className="text-nowrap text-center">Feedback</th>
                    <th className="text-nowrap text-center">Actions</th>
                  </tr>
                )}
              </thead>
              {customerUUID ? (
                <tbody>
                  {data?.map((job, index) => (
                    <tr key={index}>
                      <td>
                        <a
                          className="link-text text-decoration-none"
                          onClick={() =>
                            navigate("/service-request-view", {
                              state: {
                                id: job.service_request_uuid,
                                status: "closed",
                              },
                            })
                          }
                        >
                       <TooltipText text={job.service_request} maxLength={15} />
                        </a>
                      </td>
                      <td>{`${job.city || "NA"}, ${job.state || "NA"}`}</td>
                      <td>
                        {dayjs(job.created_on).format("MMM DD, YYYY") || "NA"}
                      </td>
                      <td>
                        {dayjs(job.service_start_date).format("MMM DD, YYYY") ||
                          "NA"}
                      </td>
                      <td className="text-center">
                        {job.feedback_rating && parseInt(job.feedback_rating) ? (
                          <img
                            src={getFeedbackEmoji(parseInt(job.feedback_rating))}
                            alt="emoji"
                            className="icon-width-28"
                          />
                        ) : (
                          "NA"
                        )}
                      </td>
                      <td className="text-center">
                        <button
                          className="btn blue-btn px-4 font-12 font-semibold"
                          onClick={() =>
                            navigate("/service-request-view", {
                              state: {
                                id: job.service_request_uuid,
                                status: "closed",
                              },
                            })
                          }
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : contractorUUID ? (
                <tbody>
                  {data?.map((job, index) => (
                    <tr key={index}>
                      <td>
                        <a
                          className="link-text text-decoration-none"
                          onClick={() =>
                            navigate("/service-request-view", {
                              state: {
                                id: job.service_request_uuid,
                                status: "closed",
                              },
                            })
                          }
                        >
                        <TooltipText text={job.service_request} maxLength={15} />
                        </a>
                      </td>
                      <td>{`${job.city || "NA"}, ${job.state || "NA"}`}</td>
                      <td>
                        {dayjs(job.created_on).format("MMM DD, YYYY") || "NA"}
                      </td>
                      <td>
                        {dayjs(job.service_start_date).format("MMM DD, YYYY") ||
                          "NA"}
                      </td>
                      <td>{job.approved_by || "NA"}</td>
                      <td className="text-center">
                        {job.feedback_rating && parseInt(job.feedback_rating) ? (
                          <img
                            src={getFeedbackEmoji(parseInt(job.feedback_rating))}
                            alt="emoji"
                            className="icon-width-28"
                          />
                        ) : (
                          "NA"
                        )}
                      </td>
                      <td className="text-center">
                        <button
                          className="btn blue-btn px-4 font-12 font-semibold"
                          onClick={() =>
                            navigate("/service-request-view", {
                              state: {
                                id: job.service_request_uuid,
                                status: "closed",
                              },
                            })
                          }
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) :
                <tbody>
                  {data?.map((job, index) => (
                    <tr key={index}>
                      <td>
                        <a
                          className="link-text text-decoration-none cursor-pointer"
                          onClick={() =>
                            navigate("/service-request-view", {
                              state: {
                                id: job.service_request_uuid,
                                status: "closed",
                                tab: "closed",
                              },
                            })
                          }
                        >
                   <TooltipText text={job.service_request} maxLength={15} />
                        </a>
                      </td>
                      <td>{`${job.city || "NA"}, ${job.state || "NA"}`}</td>
                      <td>
                        {dayjs(job.created_on).format("MMM DD, YYYY") || "NA"}
                      </td>
                      <td>
                        {dayjs(job.service_start_date).format("MMM DD, YYYY") ||
                          "NA"}
                      </td>
                      {role === "Admin" && <td>
                        <p className="font-13 font-medium color-black mb-2">
                          {job.contractor_name.trim() || "NA"}
                        </p>

                      </td>}
                      <td>{job.approved_by || "NA"}</td>
                      <td className="text-center">
                        {job.feedback_rating && parseInt(job.feedback_rating) ? (
                          <img
                            src={getFeedbackEmoji(parseInt(job.feedback_rating))}
                            alt="emoji"
                            className="icon-width-28"
                          />
                        ) : (
                          "NA"
                        )}
                      </td>
                      <td className="text-center">
                        {job.is_reinitiate ? (
                          <button className="btn blue-btn px-4 font-12 font-semibold" onClick={() => handleReinitiate(job)}>
                            Reinitiate
                          </button>
                        ) : (
                          <button className="btn blue-btn px-4 font-12 font-semibold" onClick={() => navigate('/service-request-view', {
                            state: {
                              id: job.service_request_uuid,
                              status: "closed",
                              tab: "closed"
                            }
                          })}>
                            View
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>}
            </table>
          ) : (
            <div className="text-center py-5">
              <img
                className="no-record-img mb-3"
                src="../images/no-record.gif"
                alt="No record"
              />
              <p className="font-medium font-20 color-grey-v1 mb-0">
                No records found
              </p>
            </div>
          )}
        </div>
        {hasMore && (
          <div className="d-flex align-items-center justify-content-center mt-4">
            <button
              className="btn px-4 color-white font-semibold font-14 primary-btn rounded-3 text-center"
              onClick={onLoadMore}
              disabled={loading}
            >
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                "Load More"
              )}
            </button>
          </div>
        )}
        <p className="font-14 font-medium color-grey-v7">
          Showing{" "}
          <span className="font-semibold color-black">{data?.length}</span> out of{" "}
          <span className="font-semibold color-black">{totalCount}</span> Item
          {data?.length !== 1 ? "s" : ""}
        </p>
        {isConfirmationModalOpen && (
          <div
            className="modal fade show"
            id="exampleModal16"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content border-radius-10">
                <div className="modal-header border-0 px-4 pt-4 align-items-start">
                  <h5
                    className="modal-title font-bold font-18 color-black-v2"
                    id="exampleModalLabel"
                  >
                    Confirmation on Reinitiate
                  </h5>
                  <button
                    type="button"
                    className="btn-close custom-btn-close"
                    onClick={() => setIsConfirmationModalOpen(false)}
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body px-4 py-0">
                  <p className="font-medium font-16 mb-0 color-black cust-lh mb-4">
                    Job request rejected for{" "}
                    <span className="font-semibold">
                      “Unsatisfactory Past Performance”
                    </span>
                  </p>
                  <p className="font-medium font-16 mb-0 color-black cust-lh mb-3">
                    Reinitiating this request will send this request again for all the
                    contractors except the specific contractor who’ve been approved
                    currently.
                  </p>
                </div>
                <div className="modal-footer gap-2 border-0 px-4 pb-4 pt-2 justify-content-end">
                  <button
                    className="btn px-4 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn"
                    onClick={() => setIsConfirmationModalOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                    onClick={handleProceed}
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {isContractorReinitiateModalOpen && selectedJob && (
          <ContractorReinitiate
            serviceRequestId={selectedJob.service_request_uuid}
            isOpen={isContractorReinitiateModalOpen}
            isClose={closeViewBidsModal}
          />
        )}
      </div>
    </>
  );
};

export default ClosedTab;