/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { GridData } from '../../Interface/JobsAndBids';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useNavigate } from 'react-router-dom';
import CountdownTimer from './CountdownTimer';
import ViewBids from './ViewBids';
import Loader from '../Loader/LoaderComponent';
import OfficerRequestType from './OfficerRequestType';
import ContractorNameDisplay from './ContractorNameDisplay'; // Import the new ContractorNameDisplay component
import { Tooltip } from 'recharts';
import TooltipText from './TooltipText';

// Extend dayjs to use plugins
dayjs.extend(duration);
dayjs.extend(relativeTime);

interface WaitingApprovalTabProps {
  role: string;
  data: GridData[];
  totalCount: number;
  onLoadMore: () => void;
  hasMore: boolean;
  loading: boolean;
  renderSortIcon: (column: string) => JSX.Element;
  handleSort: (column: string) => void;
  onApproveRejectJob: (selectedJob: GridData, action: 'Approve' | 'Reject') => void;
}

const isUrgent = (startDate: string, createdOn: string) => {
  const createdDate = dayjs(createdOn);
  const serviceDate = dayjs(startDate);
  const urgent = serviceDate.diff(createdDate, 'day') <= 7;
  return urgent;
};

const calculateTimeDifference = (createdOn: string) => {
  const startDurationInSeconds = 10800; // 3 hours in seconds
  const now = dayjs();
  const createdTime = dayjs(createdOn);
  const elapsedTimeInSeconds = now.diff(createdTime, 'second');
  return Math.max(0, startDurationInSeconds - elapsedTimeInSeconds);
};

const WaitingApprovalTab: React.FC<WaitingApprovalTabProps> = ({
  role,
  data,
  totalCount,
  onLoadMore,
  hasMore,
  loading,
  renderSortIcon,
  handleSort,
  onApproveRejectJob
}) => {
  const navigate = useNavigate();
  const [modalAction, setModalAction] = useState<'Approve' | 'Reject' | null>(null);
  const [selectedJob, setSelectedJob] = useState<GridData | null>(null);
  const [isViewBidsModalOpen, setIsViewBidsModalOpen] = useState(false);
  const [currentServiceRequestId, setCurrentServiceRequestId] = useState<string | null>(null);

  const openModal = (action: 'Approve' | 'Reject', job: GridData) => {
    setModalAction(action);
    setSelectedJob(job);
  };

  const closeModal = () => {
    setModalAction(null);
    setSelectedJob(null);
  };

  const handleApproveRejectJobWrapper = async () => {
    if (selectedJob && modalAction) {
      await onApproveRejectJob(selectedJob, modalAction);
      closeModal();
      // Ensure the parent component fetches new data from the API
      onLoadMore();
    }
  };

  const openViewBidsModal = (serviceRequestId: string) => {
    setCurrentServiceRequestId(serviceRequestId);
    setIsViewBidsModalOpen(true);
  };

  const closeViewBidsModal = () => {
    setIsViewBidsModalOpen(false);
    setCurrentServiceRequestId(null);
    onLoadMore();
  };

  return (
    <div className="tab-pane fade show active" id="pills-Waiting" role="tabpanel" aria-labelledby="pills-Waiting-tab">
      <div className="table-responsive theme-table bg-white custom-scroll my-4 w-100 vertical-align-top">
        <table className="table table-borderless mb-0 acc-view">
          <thead>
            <tr>
              <th className="text-nowrap cursor-pointer" onClick={() => handleSort("sr.service_request")}>Job Title {renderSortIcon("sr.service_request")}</th>
              <th className="text-nowrap cursor-pointer" onClick={() => handleSort("sr.service_city")}>Location {renderSortIcon("sr.service_city")}</th>
              {role === 'Admin' && <th className="text-nowrap cursor-pointer" onClick={() => handleSort("cd.customer_dba")}>Client DBA Name {renderSortIcon("cd.customer_dba")}</th>}
              {role === 'RM' && <th className="text-nowrap cursor-pointer" onClick={() => handleSort("officers_type")}>Officer Request Type {renderSortIcon("officers_type")}</th>}
              {role === 'RM' && <th className="text-nowrap cursor-pointer" onClick={() => handleSort("security_people_count")}># Security Personnel Required {renderSortIcon("security_people_count")}</th>}
              <th className="text-nowrap cursor-pointer" onClick={() => handleSort("sr.created_on")}>Requested On {renderSortIcon("sr.created_on")}</th>
              {role === 'Admin' && <th className="text-nowrap cursor-pointer" onClick={() => handleSort("contractor_name")}>Contractor {renderSortIcon("contractor_name")}</th>}
              {role === 'Admin' && <th className="text-nowrap cursor-pointer" onClick={() => handleSort("ad.qouted_amount")}>Bid Amount {renderSortIcon("ad.qouted_amount")}</th>}
              <th className="text-nowrap text-center cursor-pointer">Actions</th>
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data?.map((job, index) => {
                const remainingTimeInSeconds = calculateTimeDifference(job.response_time);
                const urgent = isUrgent(job.service_start_date, job.created_on);

                const officerTypes = Array.isArray(job.officers_type)
                  ? job.officers_type
                  : [job.officers_type];

                const securityPeopleCounts = Array.isArray(job.security_people_count)
                  ? job.security_people_count.join(', ')
                  : job.security_people_count;

                const contractorNamesArr = job.contractor_name.split(',').map(name => name.trim() === '' ? 'PPS Contractor' : name.trim());

                return (
                  <tr key={index}>
                    <td className="ps-4">
                      <div className="d-block">
                      <a
                          className="link-text text-decoration-none cursor-pointer"
                          onClick={() =>
                            navigate('/service-request-view', {
                              state: {
                                id: job.service_request_uuid,
                                status: 'awaiting-approval',
                                bidOwner: job.bid_owner,
                                tab: 'awaiting approval',
                              },
                            })
                          }
                        >
                          <TooltipText text={job.service_request} maxLength={15} />
                        </a>
                        {urgent && <span className="batch-red font-semibold ms-2">Urgent</span>}
                        <div className="d-block mt-1">
                          <img className="me-1" src="../images/hour-glass.svg" alt="hour-glass" />
                          <CountdownTimer initialSeconds={remainingTimeInSeconds} />
                        </div>
                      </div>
                    </td>
                    <td>{`${job.city}, ${job.state}`}</td>
                    {role === 'Admin' && <td>{job.doing_business}</td>}
                    {role === 'RM' && <td><OfficerRequestType officerTypes={officerTypes} /></td>}
                    {role === 'RM' && <td>{securityPeopleCounts}</td>}
                    <td>{dayjs(job.created_on).format('MMM DD, YYYY')}</td>
                    {role === 'Admin' && <td><ContractorNameDisplay contractorNames={contractorNamesArr} /></td>}
                    {role === 'Admin' && (
                      <td>
                        {job.amount !== null ? `$${job.amount.toFixed(2)}` : 'Yet to be Decided'}
                      </td>
                    )}
                    <td className="text-center">
                      {role === 'Admin' ? (
                        job?.bid_owner?.toLocaleLowerCase() === 'contractor' ? (
                          <button // Button for 'Admin' to view bids
                            className="btn blue-btn cust-btn-width px-4 font-12 font-semibold me-2"
                            onClick={() => openViewBidsModal(job.service_request_uuid)}
                          >
                            View Bids
                          </button>
                        ) : (
                          <button
                            className="btn green-btn cust-btn-width px-4 font-12 font-semibold me-2"
                            onClick={() => navigate('/service-request-view', {
                              state: {
                                id: job.service_request_uuid,
                                status: "awaiting-approval",
                                bidOwner: "PPS",
                                tab: "awaiting approval"
                              }
                            })}>
                            Provide Quote
                          </button>
                        )
                      ) : (
                        <>
                          <button
                            className="btn green-btn cust-btn-width px-4 font-12 font-semibold me-2"
                            onClick={() => openModal('Approve', job)}
                          >
                            Approve
                          </button>
                          <button
                            className="btn red-btn cust-btn-width px-4 font-12 font-semibold"
                            onClick={() => openModal('Reject', job)}
                          >
                            Reject
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={role === 'Admin' ? 8 : 7} className="text-center">
                  <div className="d-flex align-items-center justify-content-center p-5 flex-column">
                    <img className="no-record-img" src="../images/no-record.gif" alt="No record" />
                    <p className="font-medium font-20 color-grey-v1 mb-0 mt-3 text-center">
                      It looks like there are currently no records to display
                    </p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {hasMore && (
        <div className="d-flex align-items-center justify-content-center mt-4">
          <button
            className="btn px-4 color-white font-semibold font-14 primary-btn rounded-3 text-center"
            onClick={onLoadMore}
            disabled={loading}
          >
            {loading ? (
              <>
                <Loader />
              </>
            ) : 'Load More'}
          </button>
        </div>
      )}
      <p className="font-14 font-medium color-grey-v7">
        Showing <span className="font-semibold color-black">{data?.length}</span> out of <span className="font-semibold color-black">{totalCount}</span> Item{data?.length !== 1 ? 's' : ''}
      </p>
      {modalAction && (
        <div className="modal fade show" id="exampleModal16" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content border-radius-10">
              <div className="modal-header border-0 px-4 pt-4 align-items-start">
                <h5 className="modal-title font-bold font-18 color-black-v2" id="exampleModalLabel">
                  {modalAction === 'Approve' ? 'Confirmation on Approve' : 'Confirmation on Reject'}
                </h5>
                <button type="button" className="btn-close custom-btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
              </div>
              <div className="modal-body px-4 py-0">
                <p className="font-medium font-16 mb-0 color-black cust-lh mb-4">
                  {modalAction === 'Approve'
                    ? 'Are you sure? Approving this request will prevent it from being sent to third-party contractors. Please ensure adequate resource availability to fulfill this request.'
                    : 'Are you sure? Rejecting this request will forward this to third-party contractors.'}
                </p>
              </div>
              <div className="modal-footer gap-2 border-0 px-4 pb-4 pt-2 justify-content-end">
                <button className="btn px-4 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn" data-bs-dismiss="modal" onClick={closeModal}>
                  Cancel
                </button>
                <button
                  className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                  onClick={handleApproveRejectJobWrapper} // Use the wrapper function
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isViewBidsModalOpen && currentServiceRequestId && (
        <ViewBids
          isModalOpen={isViewBidsModalOpen}
          closeModal={closeViewBidsModal}
          serviceRequestId={currentServiceRequestId}
        />
      )}
    </div>
  );
};

export default WaitingApprovalTab;