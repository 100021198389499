import React from "react";
import {
  InvoiceFilterObjectInterface,
  InvoiceStatusDataInterface,
  ManageInvoiceGridPayloadInterface,
} from "../../Interface/ManageInvoice";

const InvoiceFilterComponent = (props: {
  handleFilterChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  manageInvoiceGridFilter: InvoiceFilterObjectInterface;
  manageInvoiceGridPayload: ManageInvoiceGridPayloadInterface;
  applyFilter: () => void;
  clearFilter: () => void;
  setShowFilter: React.Dispatch<React.SetStateAction<boolean>>;
  invoiceStatusData: InvoiceStatusDataInterface[];
  setManageInvoiceGridFilter: React.Dispatch<React.SetStateAction<InvoiceFilterObjectInterface>>;
}) => {
  return (
    <div
      className="dropdown-menu dd-box type-one-box shadow-sm p-4 border-0 show"
      data-popper-placement="bottom-end"
    >
      <div className="col-md-12">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="font-14 font-semibold color-black mb-0">
            Advanced Filter
          </h5>
          <button
            type="button"
            className="btn-close font-12"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              props.setShowFilter(false);
              JSON.stringify(props.manageInvoiceGridFilter) !== JSON.stringify(props.manageInvoiceGridPayload.filter) && props.setManageInvoiceGridFilter(props.manageInvoiceGridPayload.filter)
            }}
          />
        </div>
        <div className="row mt-3">
          <div className="col-md-12 col-sm-12">
            <div className="mb-3">
              <label
                htmlFor="Rating"
                className="form-label color-grey-v1 font-12 font-medium"
              >
                Status
              </label>
              <select
                name="invoice_status_uuid"
                id="Rating"
                className="w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular bg-transparent"
                value={props.manageInvoiceGridFilter.invoice_status_uuid}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  props.handleFilterChange(e);
                }}
              >
                <option value={""}>Select</option>
                {props.invoiceStatusData.map((data) => {
                  return (
                    <option
                      key={data.invoice_status_uuid}
                      value={data.invoice_status_uuid}
                    >
                      {data.invoice_status_name.charAt(0)?.toUpperCase() +
                        data.invoice_status_name.slice(1)?.toLowerCase()}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <h5 className="font-14 mt-3 mb-2 font-semibold color-black mb-0">
            Invoice Date
          </h5>
          <div className="col-md-6 col-sm-12">
            <div className="mb-3">
              <label
                htmlFor="InvoiceFrom"
                className="form-label color-grey-v1 font-12 font-medium"
              >
                From
              </label>
              <input
                type="date"
                id="InvoiceFrom"
                name="invoice_date_from"
                
                max={props.manageInvoiceGridFilter.invoice_date.to_date === "" ? new Date().toISOString().split("T")[0]: props.manageInvoiceGridFilter.invoice_date.to_date}
                value={props.manageInvoiceGridFilter.invoice_date.from_date}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  props.handleFilterChange(e);
                }}
                className="form-control service-form-field color-black-v2 font-12 font-medium bg-transparent filter-field"
                //   defaultValue=""
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="mb-3">
              <label
                htmlFor="InvoiceTo"
                className="form-label color-grey-v1 font-12 font-medium"
              >
                To
              </label>
              <input
                type="date"
                id="InvoiceTo"
                name="invoice_date_to"
                max={new Date().toISOString().split("T")[0]}
                min={props.manageInvoiceGridFilter.invoice_date.from_date}
                value={props.manageInvoiceGridFilter.invoice_date.to_date}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  props.handleFilterChange(e);
                }}
                className="form-control service-form-field color-black-v2 font-12 font-medium bg-transparent filter-field"
                //   defaultValue=""
              />
            </div>
          </div>
          <h5 className="font-14 mt-3 mb-2 font-semibold color-black mb-0">
            Invoice Due Date
          </h5>
          <div className="col-md-6 col-sm-12">
            <div className="mb-3">
              <label
                htmlFor="DueFrom"
                className="form-label color-grey-v1 font-12 font-medium"
              >
                From
              </label>
              <input
                type="date"
                id="DueFrom"
                name="invoice_due_date_from"
                // max={props.manageInvoiceGridFilter.invoice_due_date.to_date}
                max={props.manageInvoiceGridFilter.invoice_due_date.to_date === "" ? "2999-12-31": props.manageInvoiceGridFilter.invoice_due_date.to_date}
                // max={props.manageInvoiceGridFilter.invoice_due_date.to_date }
                value={props.manageInvoiceGridFilter.invoice_due_date.from_date}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  props.handleFilterChange(e);
                }}
                className="form-control service-form-field color-black-v2 font-12 font-medium bg-transparent filter-field"
                //   defaultValue=""
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="mb-3">
              <label
                htmlFor="DueTo"
                className="form-label color-grey-v1 font-12 font-medium"
              >
                To
              </label>
              <input
                type="date"
                id="DueTo"
                name="invoice_due_date_to"
                // max={new Date().toISOString().split("T")[0]}
                 max="2999-12-31"
                min={props.manageInvoiceGridFilter.invoice_due_date.from_date}
                value={props.manageInvoiceGridFilter.invoice_due_date.to_date}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  props.handleFilterChange(e);
                }}
                className="form-control service-form-field color-black-v2 font-12 font-medium bg-transparent filter-field"
                //   defaultValue=""
              />
            </div>
          </div>
        </div>

        {/* <div className="col-md-12 col-sm-12">
          <div className="mb-3">
            <label
              htmlFor="Rating"
              className="form-label color-grey-v1 font-12 font-medium"
            >
              Invoice Amount
            </label>
            <input
              type="number"
              id="phone"
              name="invoice_amount"
              // maxLength={10}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                props.handleFilterChange(e);
              }}
              value={props.manageInvoiceGridFilter.invoice_amount}
              className="form-control service-form-field color-black-v1 font-14 font-medium"
              placeholder="Enter Amount #"
            />
          </div>
        </div> */}

        <div className="d-flex justify-content-end mt-4">
          <button
            className="btn px-4 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn"
            onClick={() => {
              props.clearFilter();
            }}
          >
            Cancel
          </button>
          <button
            className="btn px-4 color-white font-semibold font-14 primary-btn px-4 rounded-3"
            onClick={() => {
              props.applyFilter();
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvoiceFilterComponent;
