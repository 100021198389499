/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import ManageInvoices from "../ManageInvoices/ManageInvoices";
import Profile from "./Profile";
import { useLocation, useNavigate } from "react-router-dom";
// import JobsAndBidsGrid from "./JobsAndBidsGrid";
import { ContractorProfileInterface } from "../../Interface/ManageContractor";
import {
  Contractor,
  ContractorAction,
  ContractorActionResponse,
  ContractorRequest,
} from "../../Interface/ContractorGrid";
import {
  approveRejectApi,
  getContractorDetailsApi,
} from "../../Service/ServiceRequest/ManageContractor/ManageContractorService";
import JobsAndBids from "../JobsAndBids/JobsAndBids";
import Loader from "../Loader";
import { encryptStorage } from "../../Configs/Constant";

const ContractorProfileView = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [requestData, setRequestData] = useState<ContractorRequest>({
    contractor_type: "all_contractors",
    contractor_uuid: "",
    limit: 10,
    sort_by: "",
    sort_order: "",
    search_keyword: "",
    filters: {
      operating_states: "",
      services_offered: 0,
      license_validity: "",
      account_status: "",
      invited_on: {
        from: "",
        to: "",
      },
      requested_on: {
        from: "",
        to: "",
      },
    },
  });
  const [gridData, setGridData] = useState<Contractor[]>([]);
  const locate = useLocation();

  // const locate = useLocation();
  const [contractorProfileTab, setContractorProfileTab] =
    useState<string>("profile info");
  const [contractorUUID, setContractorUUID] = useState<string>("");
  const [contractorProfileDataView, setContractorProfileDataView] =
    useState<ContractorProfileInterface>({} as ContractorProfileInterface);

  const [isLoader, setIsLoader] = useState<boolean>(false);

  useState<ContractorProfileInterface>({} as ContractorProfileInterface);

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState<boolean>(false);
  const [callProfiileAPI, setCallProfileAPI] = useState<string>("");

  const navigate = useNavigate();

  useEffect(() => {
    let id = encryptStorage.getItem("manageContractorProfileID");
    setContractorUUID(id !== null ? id : "");
    if (showToast) {
      const timer = setTimeout(() => {
        setShowToast(false);
      }, 3000); // Hide toast after 3 seconds

      return () => clearTimeout(timer);
    }
    fetchGridData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showToast]);

  const fetchGridData = async () => {
    setIsLoader(true);
    let id = encryptStorage.getItem("manageContractorProfileID");
    try {
      const response = await getContractorDetailsApi({
        ...requestData,
        contractor_uuid: id !== null ? id : "",
      });
      if (response?.code === 200) {
        const newContractors = response?.data?.contractors || [];
        setGridData(newContractors);
      } else if (response?.statusCode === 204) {
        setGridData([]);
      }
    } catch (error) {
      console.error("Error fetching grid data:", error);
    } finally {
      setIsLoader(false);
    }
  };

  const handleAction = async (
    contractorId: string,
    action: "active" | "inactive"
  ) => {
    const actionData: ContractorAction = {
      contractor_id: contractorId,
      action: action,
    };
    setIsLoader(true);
    try {
      const response: ContractorActionResponse = await approveRejectApi(
        actionData
      );
      setIsLoader(false);
      if (response.code === 200) {
        // fetchGridData();
        displayToast(
          `Contractor ${
            action === "active" ? "activated" : "deactivated"
          } successfully`
        );
      }
    } catch (error) {
      console.error("Error performing action:", error);
      displayToast("An error occurred while updating the contractor status");
    } finally {
      setCallProfileAPI("call");
    }
  };

  const displayToast = (message: string) => {
    setToastMessage(message);
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  };

  const renderStarBadge = (rating: string | undefined) => {
    if (!rating || rating === "No rating") return null;

    let badgeSrc = "";
    if (rating === "Gold") {
      badgeSrc = "../images/star-badge.svg";
    } else if (rating === "silver") {
      badgeSrc = "../images/star-badge.svg";
    }

    // eslint-disable-next-line jsx-a11y/alt-text
    return badgeSrc ? <img className="icon-sty" src={badgeSrc} /> : null;
  };

  return isLoader ? (
    <Loader />
  ) : (
    <>
      <div className="container-fluid p-4 py-sm-4 py-3">
        <div className="d-flex align-items-center justify-content-between flex-wrap gap-3">
          <div className="d-flex align-items-start gap-sm-4 gap-3 mt-4">
            <a
              onClick={() => {
                if (locate?.state?.routedFrom === "/feedback") {
                  navigate(-1)
                } else {
                  navigate("/contractor-grid");
                }
              }}
              className="icon-round-btn cust-round-width"
            >
              <img
                className="icon-sty"
                src="../images/back-arrow.svg"
                alt="back-arrow"
              />
            </a>
            <div className="d-flex align-items-start flex-column gap-3">
              <div className="d-flex align-items-center gap-3 flex-wrap">
                <h2 className="color-black font-22 font-semibold mb-0">
                  {/* DefenseOne */}
                  {
                    contractorProfileDataView?.contractor_details
                      ?.dba_business_name
                  }
                </h2>
                <div className="d-flex align-items-center gap-3">
                  <div className="d-flex align-items-center gap-2">
                    <span className="custom-status active-status-legend">
                      <span className="active-status-sub-legend" />
                    </span>
                    <span className="font-15 font-semibold active-text">
                      {/* Active */}
                      {contractorProfileDataView?.contractor_details
                        ?.is_account_activated
                        ? "Active"
                        : "Inactive"}
                    </span>
                  </div>
                  {/* <img
                    className=""
                    src="../images/star-badge.svg"
                    alt="star-badge"
                  /> */}
                  {renderStarBadge(gridData[0]?.contractor_rating)}
                </div>
              </div>
              <span className="font-14 font-medium color-grey-v4 d-flex align-items-center flex-wrap">
                {/* External Contractor */}
                {/* <span className="cust-dot" /> */}
                {/* NY, NJ, CT */}
                {/* {contractorProfileDataView?.contractor_details?.business_state} */}
                {contractorProfileDataView?.documents?.filter((ele)=>(ele.document_type.toLowerCase()==="state licenses"))?.map((ele)=>ele.document_state_name).join(", ")}
                {/* {gridData[0]?.operating_states?.join(", ")} */}
              </span>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-start gap-3 flex-wrap">
            <button
              className="text-nowrap btn action-outline-btn contractor-detail font-13 font-medium d-flex align-items-center justify-content-center gap-2 px-3 px-lg-4"
              onClick={() => {
                navigate("/edit-contractor", {
                  state: {
                    path: "profile",
                    isEdit: true,
                    userID:
                      contractorProfileDataView?.contractor_details
                        ?.contractor_uuid,
                  },
                });
              }}
            >
              <img
                className="user-action-icon"
                src="../images/dark-blue-edit-icon.svg"
                alt="edit"
              />
              Edit Info
            </button>
            {contractorProfileDataView?.contractor_details
              ?.is_account_activated === true ? (
              <>
                <button
                  className="btn red-btn px-4 font-12 font-semibold"
                  onClick={() => handleAction(contractorUUID, "inactive")}
                  disabled = {contractorProfileDataView?.contractor_details?.on_boarding_status=== null ? true : false}
                >
                  Mark Inactive
                </button>
              </>
            ) : (
              <button
                className="btn green-btn px-5 font-12 font-semibold"
                onClick={() => handleAction(contractorUUID, "active")}
                disabled = {contractorProfileDataView?.contractor_details?.on_boarding_status === null ? true : false}
              >
                Mark Active
              </button>
            )}
          </div>
        </div>
        <div className="tab-scroll mt-5 mb-3 px-2">
          <ul
            className="nav nav-pills cust-nav-detail-pills flex-nowrap w-100"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link cust-nav-link text-nowrap ${
                  contractorProfileTab === "profile info" && "active"
                }`}
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="false"
                onClick={() => {
                  setContractorProfileTab("profile info");
                }}
              >
                Profile Info
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link cust-nav-link text-nowrap ${
                  contractorProfileTab === "job info" && "active"
                }`}
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
                onClick={() => {
                  setContractorProfileTab("job info");
                }}
              >
                Job Info
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link cust-nav-link text-nowrap ${
                  contractorProfileTab === "invoices" && "active"
                }`}
                id="invoice-tab"
                data-bs-toggle="pill"
                data-bs-target="#invoice"
                type="button"
                role="tab"
                aria-controls="invoice"
                aria-selected="false"
                onClick={() => {
                  setContractorProfileTab("invoices");
                }}
              >
                Invoices
              </button>
            </li>
          </ul>
        </div>
        <div className="tab-content px-2" id="pills-tabContent">
          {/* 1st tab */}
          {contractorProfileTab === "profile info" && (
            <Profile
              contractorUUID={contractorUUID}
              setContractorProfileDataView={setContractorProfileDataView}
              callProfileAPI={callProfiileAPI}
              setCallProfileAPI={setCallProfileAPI}
              setIsLoader={setIsLoader}
            />
          )}
          {/* 2nd tab */}
          {contractorProfileTab === "job info" && (
            <JobsAndBids contractorUUID={contractorUUID} />
          )}
          {/* 3rd tab */}
          {contractorProfileTab === "invoices" && (
            <ManageInvoices contractorUUID={contractorUUID} />
          )}
        </div>
      </div>
      {showToast && (
        <div
          className="position-fixed top-0 start-50 translate-middle-x p-3"
          style={{ zIndex: 999 }}
        >
          <div
            className="toast cust-toast w-100 p-1 toast-border show"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="toast-body d-flex align-items-center justify-content-between gap-5">
              <div className="d-flex align-items-center gap-3">
                <img src="../images/toast-sucess.svg" alt="Successfully" />
                <div className="d-block">
                  <p className="mb-0 font-4 font-semibold color-black text-nowrap mb-2">
                    Action Successful
                  </p>
                  <p className="font-medium font-14 mb-0 color-grey-v3 text-nowrap">
                    {toastMessage}
                  </p>
                </div>
              </div>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowToast(false)}
                aria-label="Close"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContractorProfileView;
