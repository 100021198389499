import axios from "axios";
import { encryptStorage } from "../Configs/Constant";
 
export const client = async (config: any) => {
       
    try {
        // config.headers.userName = encryptStorage.getItem('username')
         config.headers.authorization = `Bearer ${encryptStorage.getItem('token')}`
        
        let response = await axios(config);
        return response.data;

    }
    catch (error: any) {

        console.log(error.message, "error in client "); 
        // window.location.href = '/error'
    }
}