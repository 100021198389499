import React, { useEffect, useState } from 'react';
import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
//import ServiceRequestForm from './Components/ServiceRequest/ServiceRequestForm';
//import ServiceRequestGrid from './Components/ServiceRequest/ServiceRequestGrid';
import AdminSignIn from './Components/Login/AdminSignIn';
import ForgotPassword from './Components/Login/ForgotPassword';
import { Verifications } from './Components/Login/verification';
import { encryptStorage, HeaderContext, ppsContext } from './Configs/Constant';
import { contextValueInterface, MultiRegions, UserDetails } from './Interface/SignIn';
import ResetPassword from './Components/Login/ResetPassword';
import ResetComfirm from './Components/Login/ResetPswrdComfimation';
import "./App.css";
import { UserGridComponent } from "./Components/ManageUsers/ManageUserGrid";
import FeedbackGridComponent from "./Components/ManageFeedback/ManageFeedbackGrid";
import Loader from "./Components/Loader/LoaderComponent";
import ManageClients from "./Components/ManageClient/ManageClients";
import ManageInvoices from "./Components/ManageInvoices/ManageInvoices";
import { HeaderComponent } from "./Components/Header/Header";
import ContractorGrid from "./Components/Contractor/ContractorGrid";
import JobsAndBids from "./Components/JobsAndBids/JobsAndBids";
import ServiceRequestView from "./Components/JobsAndBids/ServiceRequestView";


import { headerDetailsGet } from './Service/HeaderDetails/HeaderDetail';
import { ApiResponse, Notifications } from './Interface/HeaderDetails';

import { SummaryDashboard } from "./Components/Summay/Summary";
import ClientProfileView from "./Components/ManageClient/ClientProfileView";
import ContractorProfileView from "./Components/ManageContractor/ContractorProfileView";
import { ContractorEdit } from './Components/Contractor/SignUp/EditContractorSignIn';
import AdminReinitiate from './Components/JobsAndBids/AdminReinitiate';

function App() {
  let paths = ['/sign-up', '/forgot-password', '/reset-password', '/reset-confirm', '/verification', '/']

  const [headerState, setHeaderState] = useState<any>({

    showNotifications: false,
    showProfile: false

  })

  const [userDetails, setUserDetails] = useState<UserDetails>({
    user_uuid: "",
    role: "",
    region: "",
    loginUserName: "",
    userName: "",
    rmEmail: "",
    rmPhone: ""
  });
  const [notificationsCount, setNotificationsCount] = useState<number>(0);
  const [notifications, setNotifications] = useState<Notifications[]>([]);
  const [trackTikregions, setTrackTikregions] = useState<MultiRegions[]>([]);
  const contextValue: contextValueInterface = {
    userDetails,
    setUserDetails,
    notifications,
    setNotifications,
    notificationsCount,
    setNotificationsCount,
    trackTikregions,
    setTrackTikregions
  }

  useEffect(() => {

    if (window.location.pathname !== "/") {

      if (!encryptStorage.getItem("user_id") && !paths.includes(window.location.pathname)) window.location.href = "/"

      fetchUserDetails();
      // getRegions()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  async function fetchUserDetails() {
    try {
      const response = await headerDetailsGet();
      let NotificationRes: ApiResponse = response;
      if (NotificationRes?.code === 200) {
        let userDetails = NotificationRes?.data;
        let user_id = encryptStorage.getItem("user_id")
        contextValue.setUserDetails((prev) => ({
          ...prev, user_uuid: userDetails.user_uuid, userName: userDetails.user_name,
          role: userDetails.role,
          region: userDetails.region, loginUserName: user_id, rmEmail: userDetails.rm_email, rmPhone: userDetails.rm_phone
        }))
        contextValue.setNotifications(userDetails.notification_details)
        contextValue.setNotificationsCount(userDetails.unread_notifications_count)
      }

    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  }

  // async function getRegions() {
  //   const response = await getRegionData();
  //   let locRegions;
  //   if (response?.code === 200) {
  //     locRegions = response?.data
  //     console.log(locRegions, "locRegion");
  //     let locRegionOpt: MultiRegions[] = []
  //     // eslint-disable-next-line array-callback-return
  //     locRegions.map((val: any) => {
  //       let options = {
  //         value: val.region_id,
  //         label: val.region_name
  //       }
  //       locRegionOpt.push(options)
  //     })
  //     contextValue.setTrackTikregions(locRegionOpt)
  //   }

  // }
  // user interaction session time out. 

  useEffect(() => {
    const thirtyMinutesInMillis = 30 * 60 * 1000;
    let timeoutId: any;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleExpiration, thirtyMinutesInMillis);
    };

    const handleExpiration = () => {
      try {
        // sessionStorage.clear();
        encryptStorage.clear();
        window.location.href = '/'
        // navigate("/")


      } catch (error) {
        console.error("Error in expiration logic:", error);
      }
    };

    const handleUserInteraction = () => {
      resetTimeout();
      // Additional logic for user interaction, if needed
    };

    // Set up initial timeout
    resetTimeout();

    // Add event listeners for user interaction
    window.addEventListener("mousemove", handleUserInteraction);
    window.addEventListener("keydown", handleUserInteraction);

    // Cleanup: remove event listeners and clear timeout on component unmount
    return () => {
      window.removeEventListener("mousemove", handleUserInteraction);
      window.removeEventListener("keydown", handleUserInteraction);
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    
        <HeaderContext.Provider value={{headerState , setHeaderState}}>
      <div onClick={() => {

        if (headerState.showNotifications) {

          setHeaderState((prev: any) => ({

            ...prev,
            showNotifications: false

          }));
        }

        if(headerState.showProfile){

          setHeaderState((prev: any) => ({

            ...prev,
            showProfile: false

          }));

        }

      }}>
      <Router>
        <ppsContext.Provider value={contextValue}>
          {!paths.includes(window.location.pathname) && <HeaderComponent />}
          <Routes>
            <Route path='/' element={<AdminSignIn />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/reset-password' element={<ResetPassword />} />
            <Route path='/reset-confirm' element={<ResetComfirm />} />
            {/* <Route path='/change-password' element={<ChangePassword />} /> */}
            <Route path='/summary' element={<SummaryDashboard />} />
            <Route path='/verification' element={<Verifications />} />
            <Route path="summary" element={<SummaryDashboard />} />
            <Route path="users" element={<UserGridComponent />} />
            <Route path="feedback" element={<FeedbackGridComponent />} />
            <Route path="loader" element={<Loader />} />
            <Route path="manage-clients" element={<ManageClients />} />
            <Route path="manage-invoices" element={<ManageInvoices />} />
            <Route path='jobs-and-bids' element={<JobsAndBids />} />
            <Route path='service-request-view' element={<ServiceRequestView />} />
            <Route path='admin-reinitiate' element={<AdminReinitiate />} />
            <Route path='contractor-grid' element={<ContractorGrid />} />
            <Route path="/manage-clients-profile" element={<ClientProfileView />} />
            <Route path="/manage-contractors-profile" element={<ContractorProfileView />} />
            <Route path="/edit-contractor" element={<ContractorEdit />} />
          </Routes>
        </ppsContext.Provider>
      </Router>
    </div>
      </HeaderContext.Provider>
  )
}

export default App; 