/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Notifications, readPayload } from "../../Interface/HeaderDetails";
import { readNotifications } from "../../Service/HeaderDetails/HeaderDetail";
import { useNavigate } from "react-router-dom";
import WebsocketClient from "../../Client/WebSocket";

import moment from "moment-timezone";
import { encryptStorage, HeaderContext, ppsContext } from "../../Configs/Constant";
import { contextValueInterface } from "../../Interface/SignIn";

export const HeaderComponent: React.FC = () => {
  const contextValue: contextValueInterface = useContext(ppsContext);
  const websocket = useRef<WebSocket>();
  const navigate = useNavigate();
  // const [showNotifications, setShowNotifications] = useState<boolean>(false);
  // const [showProfile, setshowProfile] = useState<boolean>(false);
  const pathname = window.location.pathname;

  const { headerState: { showNotifications, showProfile }, setHeaderState } = useContext<any>(HeaderContext)

  useEffect(() => {
    let topics = [];
    topics =
      contextValue.userDetails.role.toLowerCase() === "admin" ? ["admin"] : [];
    const socket = WebsocketClient(contextValue.userDetails.user_uuid, topics);
    websocket.current = socket;
    socket.onmessage = handleOnMessage;
    socket.onerror = handleOnError;
    // euserDetails.user_uuidslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextValue]);

  // const requestNotificationPermission = async () => {
  //   debugger
  //   if (!("Notification" in window)) {
  //     console.error("This browser does not support desktop notifications.");
  //     return;
  //   }

  //   if (Notification.permission === "granted") {
  //     return true;
  //   } else if (Notification.permission !== "denied") {
  //     const permission = await Notification.requestPermission();
  //     return permission === "granted";
  //   }

  //   return false;
  // };

  // const showNotification = async (title: string, options: NotificationOptions) => {
  //   debugger
  //   const hasPermission = await requestNotificationPermission();
  //   if (!hasPermission) return;

  //   const notification = new Notification(title, options);

  //   notification.onclick = () => {
  //     window.focus();
  //     console.log("Notification clicked!");
  //   };

  //   notification.onclose = () => {
  //     console.log("Notification closed!");
  //   };

  //   notification.onerror = (error) => {
  //     console.error("Error showing notification: ", error);
  //   };
  // };
  const playNotificationSound = () => {
    const notificationSound = new Audio("../images/notification_sound.mp3");
    notificationSound.play();
  };
  const handleOnMessage = useCallback(
    async (event: any) => {
      try {
        const receivedMessage = JSON.parse(event.data);
        if (receivedMessage?.data?.notification_details) {
          const newNotification =
            receivedMessage?.data?.notification_details[0];

          contextValue.setNotifications(
            (prevNotifications: Notifications[]) => {
              return [newNotification, ...prevNotifications];
            }
          );
          contextValue.setNotificationsCount((prevCount: number) => {
            return (
              prevCount + receivedMessage?.data?.unread_notifications_count
            );
          });
          playNotificationSound();
          // Show a desktop notification
          //  await showNotification("New Notification", {
          //   body: receivedMessage?.data?.notification_details[0].service_request,
          //   icon: "path/to/icon.png", // Optional: replace with your icon path

          // });
        }
      } catch (error: any) {
        console.log("an error occurred: ", error);
      }
    },
    [contextValue.notifications]
  );

  const handleOnError = useCallback((error: any) => {
    console.log("an error occured in websocket:", error);
  }, []);

  //PC_34 User clicks on a single notification to mark as read
  const handleNotificationIconClick = (val: any, ind: number) => {
    let payload: readPayload = {
      is_read: !val.is_read,
      notification_uuid: val.notification_uuid,
    };
    if (!val.is_read) {
      try {
        //PC_39 Call readNotification with single notification UUID
        let response = readNotifications(payload);
      } catch (error) {
        console.log("Error Occured While Marking Notification as read", error);
      }

      //PC_36 update the clicked notification as read
      const updateNotifications = [...contextValue.notifications];
      updateNotifications[ind].is_read = true;
      contextValue.setNotifications(updateNotifications);
      contextValue.setNotificationsCount((prevCount: number) => {
        return prevCount - 1;
      });
    }
    if (val.notification_type !== "contractorDeletesInvoice") {
      navigate(val.notification_path);
    }
  };

  const handleLogoutClick = () => {
    // Cookies.remove('authToken'); // Replace 'authToken' with the actual cookie name you want to remove
    encryptStorage.clear();
    window.location.href = "/" // Redirect to the login page
    // navigate("/")
  };

  const DateDifference = (dateString: string) => {
    // Parse the input date string
    const inputDate = moment(dateString);

    // Get the current date and time in US timezone
    const currentDate = moment().tz("America/New_York");

    // Calculate the difference in hours
    const differenceInHours = currentDate.diff(inputDate, "hours");

    return differenceInHours >= 1
      ? differenceInHours === 1
        ? differenceInHours + " hour ago . "
        : differenceInHours + " hours ago . "
      : "few mins ago . ";
  };

  const bindNotifications = () => {
    return contextValue.notifications.map((val: any, index: number) => {
      const formatedDate = DateDifference(val.created_date);

      if (contextValue.userDetails.role.toLocaleLowerCase() === "admin") {
        return val.notification_type === "serviceRequestSubmission" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  New Job Request Submitted by{" "}
                  <span className="font-bold color-black">
                    {val.customer_name}
                  </span>
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "rmRejectRequest" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  The Regional Manager has rejected the request for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>{" "}
                  from{" "}
                  <span className="font-bold color-black">
                    {val.customer_name}
                  </span>{" "}
                  and has forwarded it to contractors for bidding.
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "rmNoResponse" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  Reminder: The response from the Regional Manager is still
                  pending for{" "}
                  <span className="font-bold color-black">
                    {val.customer_name}
                  </span>
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "rmAcceptRequest" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  The Regional Manager has accepted the request for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>{" "}
                  submitted by{" "}
                  <span className="font-bold color-black">
                    {val.customer_name}
                  </span>
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "rmUnResponsive" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  RM unresponsive for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>{" "}
                  from{" "}
                  <span className="font-bold color-black">
                    {val.customer_name}
                  </span>{" "}
                  more than an hour, sent to contractor bidding.
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "NoContractorSubmitBid" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  No contractors responded to{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>{" "}
                  job request, check this out.
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "oneContractorBidSubmit" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  <span className="font-bold color-black">
                    {val.contractor_name}
                  </span>{" "}
                  contractor submitted bid for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>
                  .
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "AllContractorBidsSubmitted" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  All contractors have submitted bids for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>{" "}
                  job request.
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "clientUpdateSRUnderRM" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  <span className="font-bold color-black">
                    {val.customer_name}
                  </span>{" "}
                  has modified this{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>{" "}
                  Job
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "clientUpdateSRUnderContractor" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  <span className="font-bold color-black">
                    {val.customer_name}
                  </span>{" "}
                  has modified this{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>{" "}
                  Job
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type ===
          "clientUpdateSRAfterBidSubFromConOrUnderAdmin" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  <span className="font-bold color-black">
                    {val.customer_name}
                  </span>{" "}
                  has modified this{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>{" "}
                  Job
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "clientApprPPSOwnedSR" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  Client approved your offered price for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>{" "}
                  service request.
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Client</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "clientApprContractorOwnedSR" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  Client approved your offered price for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>{" "}
                  service request for{" "}
                  <span className="font-bold color-black">
                    {val.contractor_name}
                  </span>
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "clientRejectedPPSOrContractorOwnedSR" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  Client rejected the offered price for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>{" "}
                  service request.
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type ===
          "clientUpdatesPPSOwnedSRAfterClientApproval" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  <span className="font-bold color-black">
                    {val.customer_name}
                  </span>{" "}
                  has updated service request for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>
                  (combination)
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type ===
          "clientUpdatesContractorOwnedSRAfterClientApproval" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  <span className="font-bold color-black">
                    {val.customer_name}
                  </span>{" "}
                  has updated service request for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>
                  (combination)
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "contractorAcceptsExtendedDateSR" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  <span className="font-bold color-black">
                    {val.contractor_name}
                  </span>{" "}
                  has accepted the extended job request for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "contractorRejectsExtendedDateSR" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  <span className="font-bold color-black">
                    {val.contractor_name}
                  </span>{" "}
                  has rejected the extended job request for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>{" "}
                  for{" "}
                  <span className="font-bold color-black">
                    {val.customer_name}
                  </span>{" "}
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type ===
          "NewExtendedActiveJobAcceptedByAnyOneContractor" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  The{" "}
                  <span className="font-bold color-black">
                    {val.customer_name}
                  </span>{" "}
                  extended job request for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>{" "}
                  has been accepted by{" "}
                  <span className="font-bold color-black">
                    {val.contractor_name}
                  </span>
                  .
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type ===
          "NewExtendedActiveJobRejectedByAllContractor" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  All contractors rejected extended active job details for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>{" "}
                  request.
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type ===
          "NewExtendedActiveJobRejectedByRMForPPSOwnedSR" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  Regional manager{" "}
                  <span className="font-bold color-black">
                    {val.admin_name}
                  </span>{" "}
                  rejected extended active job details for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>{" "}
                  request{" "}
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "clientOnboardingSubmit" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  Approval pending for{" "}
                  <span className="font-bold color-black">
                    {val.customer_name}
                  </span>{" "}
                  client.
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Client</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "clientUpdatesInProfile" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  <span className="font-bold color-black">
                    {val.customer_name}
                  </span>{" "}
                  just updated their profile info. Take a quick peek!
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Client</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "contractorOnboardingSubmit" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  Approval pending for the{" "}
                  <span className="font-bold color-black">
                    {val.contractor_name}
                  </span>{" "}
                  contractor.
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Contractor</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "contractorUpdatesInProfile" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  <span className="font-bold color-black">
                    {val.contractor_name}
                  </span>
                  just updated their profile info. Take a quick peek!
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Contractor</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "contractorSubmitsInvoice" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  <span className="font-bold color-black">
                    {val.contractor_name}
                  </span>{" "}
                  contractor submitted the invoice for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>{" "}
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Invoice</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "contractorUpdatesInvoice" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  <span className="font-bold color-black">
                    {val.contractor_name}
                  </span>{" "}
                  contractor updated the invoice for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Invoice</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "contractorUpdatesInvoiceAsPaid" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  Invoice has been updated as paid by the{" "}
                  <span className="font-bold color-black">
                    {val.contractor_name}
                  </span>{" "}
                  contractor for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>
                  .
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Invoice</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "contractorDeletesInvoice" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  Invoice deleted by the{" "}
                  <span className="font-bold color-black">
                    {val.contractor_name}
                  </span>{" "}
                  contractor for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Invoice</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "SRComesUnderClosed" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>{" "}
                  job completed and moved to closed
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "ClientsSubmitsFeedbacks" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  Fresh insights just in!{" "}
                  <span className="font-bold color-black">
                    {val.customer_name}
                  </span>{" "}
                  shared their thoughts on{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>
                  . Curious? Tap to uncover their review!
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Client</span>
                </span>
              </div>
            </a>
          </li>
        ) : null;
      } else {
        return val.notification_type === "serviceRequestSubmission" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  New Job Request Submitted by{" "}
                  <span className="font-bold color-black">
                    {val.customer_name}
                  </span>
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "rmNoResponse" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  Please review the new job request for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>{" "}
                  from{" "}
                  <span className="font-bold color-black">
                    {val.customer_name}
                  </span>
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "adminSetTimerUnderRM" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  The bid submission time has been extended by the admin. Please
                  acknowledge within the updated timeframe.
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "contractorNoResponse" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  Contractors response pending for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>{" "}
                  from{" "}
                  <span className="font-bold color-black">
                    {val.customer_name}
                  </span>
                  .
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "clientUpdateSRUnderRM" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  <span className="font-bold color-black">
                    {val.customer_name}
                  </span>{" "}
                  has modified this{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>{" "}
                  Job.
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "clientUpdateSRUnderContractor" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  <span className="font-bold color-black">
                    {val.customer_name}
                  </span>{" "}
                  has modified this{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>{" "}
                  Job.
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type ===
          "clientUpdateSRAfterBidSubFromConOrUnderAdmin" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  <span className="font-bold color-black">
                    {val.customer_name}
                  </span>{" "}
                  has modified this{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>{" "}
                  Job.
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "clientApprPPSOwnedSR" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  Client approved your offered price for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>{" "}
                  service request.
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Client</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "clientApprContractorOwnedSR" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  Client approved your offered price for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>{" "}
                  service request for{" "}
                  <span className="font-bold color-black">
                    {val.contractor_name}
                  </span>
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type ===
          "clientUpdatesPPSOwnedSRAfterClientApproval" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  <span className="font-bold color-black">
                    {val.customer_name}
                  </span>{" "}
                  has updated service request for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>
                  (combination)
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type ===
          "clientUpdatesContractorOwnedSRAfterClientApproval" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  <span className="font-bold color-black">
                    {val.customer_name}
                  </span>{" "}
                  has updated service request for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>
                  (combination)
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "contractorAcceptsExtendedDateSR" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  <span className="font-bold color-black">
                    {val.contractor_name}
                  </span>{" "}
                  has accepted the extended job request for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "contractorRejectsExtendedDateSR" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  <span className="font-bold color-black">
                    {val.contractor_name}
                  </span>{" "}
                  has rejected the extended job request for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>{" "}
                  for{" "}
                  <span className="font-bold color-black">
                    {val.customer_name}
                  </span>{" "}
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type ===
          "NewExtendedActiveJobAcceptedByAnyOneContractor" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  The{" "}
                  <span className="font-bold color-black">
                    {val.customer_name}
                  </span>{" "}
                  extended job request for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>{" "}
                  has been accepted by{" "}
                  <span className="font-bold color-black">
                    {val.contractor_name}
                  </span>
                  .
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type ===
          "NewExtendedActiveJobRejectedByAllContractor" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  All contractors rejected extended active job details for{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>{" "}
                  request.
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "clientUpdatesInProfile" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  <span className="font-bold color-black">
                    {val.customer_name}
                  </span>{" "}
                  just updated their profile info. Take a quick peek!
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Client</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "contractorOnboardingSubmit" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  Approval pending for the{" "}
                  <span className="font-bold color-black">
                    {val.contractor_name}
                  </span>{" "}
                  contractor.
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Contractor</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "contractorUpdatesInProfile" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  <span className="font-bold color-black">
                    {val.contractor_name}
                  </span>
                  just updated their profile info. Take a quick peek!
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Contractor</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "SRComesUnderClosed" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>{" "}
                  job completed and moved to closed
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Bids</span>
                </span>
              </div>
            </a>
          </li>
        ) : val.notification_type === "ClientsSubmitsFeedbacks" ? (
          <li
            className="cust-border-bottom unread show"
            key={val.notification_uuid}
          >
            <span className={val.is_read ? "" : "unread-msg"} />
            <a className="font-medium font-16 color-black text-decoration-none px-4 d-flex align-items-center gap-3 py-3">
              <img
                className="ms-2 notification-icon-sty"
                src="../images/work-icon.svg"
                alt="work-icon"
              />
              <div
                className="d-block"
                onClick={() => {
                  handleNotificationIconClick(val, index);
                }}
              >
                <span className="font-14 font-medium color-grey-v4 d-block">
                  Fresh insights just in!{" "}
                  <span className="font-bold color-black">
                    {val.customer_name}
                  </span>{" "}
                  shared their thoughts on{" "}
                  <span className="font-bold color-black">
                    {val.service_request}
                  </span>
                  . Curious? Tap to uncover their review!
                </span>
                <span className="font-13 font-regular color-grey-v3">
                  {formatedDate} <span>Client</span>
                </span>
              </div>
            </a>
          </li>
        ) : null;
      }
    });
  };


  const jobsandbids = ["/jobs-and-bids", "/service-request-view"]
  const contractorgrid = ["/contractor-grid", "/manage-contractors-profile"]
  const manageclients = ["/manage-clients", "/manage-clients-profile"]

  return (
    <nav className="navbar navbar-expand-xl color-white nav-bg">
      <div className="container-fluid position-relative nav-bar-ht py-4 px-0">
        <a
          className="navbar-logo"
          onClick={() => {
            navigate("/summary");
          }}
        >
          <img src="../images/command-logo.svg" alt="command logo" />
        </a>
        <button
          className="navbar-toggler shadow-none  px-0 py-0 "
          type="button"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon cursor-pointer p-0" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
            <span className="navbar-toggler-icon d-none" />
            <input id="menu__toggle" type="checkbox" />
            <label className="menu__btn" htmlFor="menu__toggle">
              <span />
            </label>
          </span>
        </button>
        <div
          className="collapse navbar-collapse nav-bg pt-5 mt-3 "
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 custom-navbar-nav">
            <li className="nav-item custom-nav-item">
              <a
                className={`nav-link cursor-pointer ${pathname === "/summary" ? "active" : ""
                  }`}
                aria-current="page"
                onClick={() => {
                  navigate("/summary");
                }}
              >
                Summary
              </a>
            </li>
            <li className="nav-item custom-nav-item">
              <a
                className={`nav-link cursor-pointer ${jobsandbids.includes(pathname) ? "active" : ""
                  }`}
                onClick={() => {
                  navigate("/jobs-and-bids");
                }}
              >
                Jobs & Bids
              </a>
            </li>
            <li className="nav-item custom-nav-item">
              <a
                className={`nav-link cursor-pointer ${contractorgrid.includes(pathname) ? "active" : ""
                  }`}
                onClick={() => {
                  navigate("/contractor-grid");
                }}
              >
                Contractors
              </a>
            </li>
            <li className="nav-item custom-nav-item">
              <a
                className={`nav-link cursor-pointer ${manageclients.includes(pathname) ? "active" : ""
                  }`}
                onClick={() => {
                  navigate("/manage-clients");
                }}
              >
                Clients
              </a>
            </li>
            {contextValue.userDetails.role.toLocaleLowerCase() === "admin" && (
              <li className="nav-item custom-nav-item">
                <a
                  className={`nav-link cursor-pointer ${pathname === "/manage-invoices" ? "active" : ""
                    }`}
                  onClick={() => {
                    navigate("/manage-invoices");
                  }}
                >
                  Invoices
                </a>
              </li>
            )}
            <li className="nav-item custom-nav-item">
              <a
                className={`nav-link cursor-pointer ${pathname === "/feedback" ? "active" : ""
                  }`}
                onClick={() => {
                  navigate("/feedback");
                }}
              >
                Feedback
              </a>
            </li>
            {contextValue.userDetails.role.toLocaleLowerCase() === "admin" && (
              <li className="nav-item custom-nav-item">
                <a
                  className={`nav-link cursor-pointer ${pathname === "/users" ? "active" : ""
                    }`}
                  onClick={() => {
                    navigate("/users");
                  }}
                >
                  User
                </a>
              </li>
            )}
          </ul>
          <div className="d-flex align-items-center gap-2 d-xl-flex d-none pe-4">
            {/* desktop notification */}
            <div className="notification-drp position-relative">
              <button
                type="button"
                className="position-relative font-16 color-white text-white shadow-none font-semibold p-0 mx-3 btn border-0 bg-transparent text-nowrap"
                // onClick={() => {
                //   setShowNotifications((prevState) => !prevState);
                // }}
                onClick={() => {
                  // setShowNotifications((prevState) => !prevState);
                  setHeaderState({
                    showProfile: false,
                    showNotifications: !showNotifications

                  })
                }}
                aria-expanded="false"
              >
                <img
                  className="notify-icon"
                  src="../images/notification-icon.svg"
                  alt="notification-icon"
                />
                {/* notification number */}
                <span className="notify-count font-bold">
                  {contextValue.notificationsCount}
                </span>
              </button>
              {showNotifications && contextValue.notifications.length > 0 ? (
                <ul
                  className="dropdown-menu custom-drp-menu border-0 p-0 show"
                  aria-labelledby="dropdownMenuLink"
                >
                  <li className="font-14 font-medium color-grey-v1 d-flex align-items-center gap-2 p-3 py-2 cust-border-bottom">
                    Notification
                    <span className="notification-count font-12 color-white rounded-3 font-bold">
                      {contextValue.notificationsCount}
                    </span>
                  </li>
                  <ul className="notification-scroll me-1">
                    {contextValue.notifications.length > 0
                      ? bindNotifications()
                      : null}
                  </ul>
                </ul>
              ) : null}
            </div>
            <div className="download-option-dropdown position-relative">
              <button
                type="button"
                className="position-relative font-16 color-white text-white shadow-none font-semibold btn border-0 bg-transparent text-nowrap"
                // onClick={() => {
                //   setshowProfile(!showProfile);
                // }}
                onClick={() => {
                  // setshowProfile(!showProfile);
                  setHeaderState({
                    showProfile: !showProfile,
                    showNotifications: false

                  })
                }}
                aria-expanded="false"
              >
                {`Hi, ${contextValue.userDetails.userName}`}
                <img
                  className="drp-icon ms-2"
                  src="../images/white-drp-arrow.svg"
                  alt="dropdown-arrow"
                />
              </button>
              {showProfile && (
                <ul className="dropdown-menu custom-drp-menu border-0 px-1 show">
                  <li className="font-medium font-16 color-black px-4 logout-btn-hover cursor-pointer"
                    onClick={() => {
                      handleLogoutClick();
                    }}>
                    <a
                      className="red-color text-decoration-none px-1">
                      Logout
                    </a>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end w-100 d-xl-none custom-profile-info">
          {/* hambergur notification */}
          <div className="notification-drp position-relative">
            <button
              type="button"
              className="position-relative font-16 color-white text-white shadow-none font-semibold p-0 mx-3 btn border-0 bg-transparent text-nowrap"
              // onClick={() => {
              //   setShowNotifications((prevState) => !prevState);
              // }}
              onClick={() => {
                // setShowNotifications((prevState) => !prevState);
                setHeaderState({
                  showProfile: false,
                  showNotifications: !showNotifications

                })
              }}
              aria-expanded="false"
            >
              <img
                className="notify-icon"
                src="../images/notification-icon.svg"
                alt="notification-icon"
              />
              {/* notification number */}
              <span className="notify-count font-bold">
                {contextValue.notificationsCount}
              </span>
            </button>
            {showNotifications && contextValue.notifications.length > 0 ? (
              <ul
                className="dropdown-menu custom-drp-menu border-0 p-0 show"
                aria-labelledby="dropdownMenuLink"
              >
                <li className="font-14 font-medium color-grey-v1 d-flex align-items-center gap-2 p-3 py-2 cust-border-bottom">
                  Notification
                  <span className="notification-count font-12 color-white rounded-3 font-bold">
                    {contextValue.notificationsCount}
                  </span>
                </li>
                <ul className="notification-scroll me-1">
                  {contextValue.notifications.length > 0
                    ? bindNotifications()
                    : null}
                </ul>
              </ul>
            ) : null}
          </div>
          <div className="download-option-dropdown position-relative">
            <button
              type="button"
              className="position-relative font-16 color-white text-white shadow-none font-semibold btn border-0 bg-transparent text-nowrap"
              // onClick={() => {
              //   setshowProfile(!showProfile);
              // }}
              onClick={() => {
                // setshowProfile(!showProfile);

                setHeaderState({
                  showProfile: !showProfile,
                  showNotifications: false

                })
              }}
              aria-expanded="false"
            >
              <img
                className="profile-icon"
                src="../images/profile-logo.svg"
                alt="profile-logo"
              />
              <img
                className="drp-icon ms-2"
                src="../images/white-drp-arrow.svg"
                alt="dropdown-arrow"
              />
            </button>
            {showProfile && (
              <ul className="dropdown-menu custom-drp-menu border-0 px-1 py-3 show">
                <li className="font-medium font-16 color-black px-4 logout-btn-hover cursor-pointer"
                  onClick={() => {
                    handleLogoutClick();
                  }}>
                  <a
                    className="red-color text-decoration-none px-1">
                    Logout
                  </a>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};
