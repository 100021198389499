
import { client } from '../../Client/Client';
import { manageUserServices } from '../../Configs/BackendRoutes';
import { backendUrl as URL  } from '../../Configs/Constant';

export const getUserGridApiData = async (data: any) => {
    const config = {
        method: 'POST',
        url: URL + manageUserServices.manageUserDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data
    };
    const response = await client(config);
    return response;
};
export const manageUsers = async (data: any) => {
    const config = {
        method: 'POST',
        url: URL + manageUserServices.manageUserPost,
        headers: {
            'Content-Type': 'application/json'
        },
        data
    };
    const response = await client(config);
    return response;
};