import React, { useState } from "react";
import {
  Contractor,
  InviteContractorData,
} from "../../Interface/ContractorGrid";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/LoaderComponent";
import { encryptStorage } from "../../Configs/Constant";

interface InviteeResponsesTabProps {
  data: Contractor[];
  loading: boolean;
  fetchGridData: () => void;
  onEdit: (contractor: InviteContractorData) => void;
  onDelete: (contractor: InviteContractorData) => void;
  handleSort: (sortBy: string, sortOrder: "ASC" | "DESC") => void;
  currentSortBy: string;
  currentSortOrder: "ASC" | "DESC";
  hasMore: boolean;
  onLoadMore: () => void;
  totalContractors: number;
  noOfContractors: number;
  displayToast: (message: string) => void;
  onEditFeedback: (message: string) => void; // New prop
}

const InviteeResponsesTab: React.FC<InviteeResponsesTabProps> = ({
  data,
  loading,
  fetchGridData,
  onEdit,
  onDelete,
  handleSort,
  currentSortBy,
  currentSortOrder,
  hasMore,
  onLoadMore,
  totalContractors,
  noOfContractors,
  displayToast,
  onEditFeedback,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [contractorToDelete, setContractorToDelete] =
    useState<Contractor | null>(null);
  const [showToast, setShowToast] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [toastMessage, setToastMessage] = useState<string>("");
  const navigate = useNavigate();

  const handleEdit = (invitee: Contractor) => {
    const editData: InviteContractorData = {
      contractor_id: invitee.contractor_id,
      dba_name: invitee.dba_name,
      user_full_name:
        `${invitee.contractor_first_name} ${invitee.contractor_last_name}`.trim(),
      email_id: invitee.email_address,
      phone_number: invitee.phone_number,
      mode: "update",
    };
    onEdit(editData);
    // Remove toast logic from here
  };

  const openDeleteModal = (invitee: Contractor) => {
    setContractorToDelete(invitee);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setContractorToDelete(null);
  };

  const handleDelete = () => {
    if (contractorToDelete) {
      const deleteData: InviteContractorData = {
        contractor_id: contractorToDelete.contractor_id,
        dba_name: contractorToDelete.dba_name,
        user_full_name:
          `${contractorToDelete.contractor_first_name} ${contractorToDelete.contractor_last_name}`.trim(),
        email_id: contractorToDelete.email_address,
        phone_number: contractorToDelete.phone_number,
        mode: "delete",
      };
      onDelete(deleteData);
      closeDeleteModal();
      fetchGridData();
      displayToast("Contractor deleted successfully.");
    }
  };

  const onSort = (column: string) => {
    let newSortOrder: "ASC" | "DESC";
    if (currentSortBy === column) {
      newSortOrder = currentSortOrder === "DESC" ? "ASC" : "DESC";
    } else {
      newSortOrder = "DESC";
    }
    handleSort(column, newSortOrder);
  };

  const renderSortIcon = (column: string) => {
    let iconSrc = "../images/sort-arrow-down.svg";
    if (currentSortBy === column) {
      iconSrc =
        currentSortOrder === "DESC"
          ? "../images/sort-up-icon.svg"
          : "../images/sort-arrow-down.svg";
    }

    return (
      <span className="ms-1 cursor-pointer" onClick={() => onSort(column)}>
        <img
          src={iconSrc}
          alt={
            currentSortBy === column
              ? `sort-${currentSortOrder.toLowerCase()}`
              : "sort-arrow-down"
          }
        />
      </span>
    );
  };

  if (loading && data.length === 0) {
    return <Loader />;
  }

  return (
    <>
      <div
        className="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        {data.length === 0 ? (
          <div className="text-center mt-4">No invitee responses found</div>
        ) : (
          <>
            <div className="table-responsive theme-table bg-white custom-scroll mb-4 w-100">
              <table className="table table-borderless mb-0 acc-view">
                <thead>
                <tr>
                  <th className="ps-4 text-nowrap cursor-pointer" onClick={() => onSort("dba_name")}>
                    DBA Name
                    {renderSortIcon("dba_name")}
                  </th>
                  <th className="text-nowrap cursor-pointer" onClick={() => onSort("contractor_status")}>
                    Contractor Type
                    {renderSortIcon("contractor_status")}
                  </th>
                  <th className="text-nowrap cursor-pointer" onClick={() => onSort("contractor_first_name")}>
                    User Full Name
                    {renderSortIcon("contractor_first_name")}
                  </th>
                  <th className="text-nowrap cursor-pointer" onClick={() => onSort("email_address")}>
                    Email ID
                    {renderSortIcon("email_address")}
                  </th>
                  <th className="text-nowrap cursor-pointer" onClick={() => onSort("phone_number")}>
                    Phone #
                    {renderSortIcon("phone_number")}
                  </th>
                  <th className="text-nowrap cursor-pointer" onClick={() => onSort("account_status")}>
                    Sign Up Status
                    {renderSortIcon("account_status")}
                  </th>
                  <th className="text-nowrap text-center">Actions</th>
                </tr>

                </thead>
                <tbody>
                  {data.map((invitee, index) => (
                    <tr key={index}>
                      <td
                        className="ps-4"
                        onClick={() => {
                          encryptStorage.setItem(
                            "manageContractorProfileID",
                            invitee.contractor_id
                          );
                          navigate("/manage-contractors-profile", {
                            state: invitee.contractor_id,
                          });
                        }}
                      >
                        {invitee.dba_name || "-"}
                      </td>
                      <td>{invitee.contractor_status || "-"}</td>
                      <td>
                        {`${invitee.contractor_first_name} ${invitee.contractor_last_name}`.trim() ||
                          "-"}
                      </td>
                      <td>{invitee.email_address || "-"}</td>
                      <td>{invitee.phone_number || "-"}</td>
                      <td>{invitee.account_status || "-"}</td>
                      <td>
                        <div className="d-flex align-items-center gap-3 justify-content-center">
                          <button
                            className="btn green-btn px-4 font-12 font-semibold"
                            onClick={() => handleEdit(invitee)}
                          >
                            Edit
                          </button>
                          <button
                            className="btn red-btn px-4 font-12 font-semibold"
                            onClick={() => openDeleteModal(invitee)}
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <p className="font-14 font-medium color-grey-v7">
              Showing{" "}
              <span className="font-semibold color-black">
                {noOfContractors}
              </span>{" "}
              out of{" "}
              <span className="font-semibold color-black">
                {totalContractors}
              </span>{" "}
              Items
            </p>
            {hasMore && (
              <div className="d-flex align-items-center justify-content-center mt-4">
                <button
                  className="btn px-4 color-white font-semibold font-14 primary-btn px-4 rounded-3 text-center"
                  onClick={onLoadMore}
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </>
                  ) : (
                    "Load More"
                  )}
                </button>
              </div>
            )}
          </>
        )}
      </div>

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <div
          className="modal fade show"
          id="exampleModal11"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content border-radius-10">
              <div className="modal-header border-0 px-4 pt-4 align-items-center">
                <h5
                  className="modal-title font-bold font-18 color-black-v2"
                  id="exampleModalLabel"
                >
                  Delete Confirmation
                </h5>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  onClick={closeDeleteModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body px-4 py-0">
                <p className="font-medium font-16 mb-0 color-black cust-lh mb-3">
                  Are you sure you want to delete this contractor?
                </p>
              </div>
              <div className="modal-footer gap-2 border-0 px-4 pb-4 pt-2 justify-content-end">
                <button
                  className="btn tertiary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                  onClick={closeDeleteModal}
                >
                  No
                </button>
                <button
                  className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                  onClick={handleDelete}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Toast Component */}
      {showToast && (
        <div
          className="position-fixed top-0 start-50 translate-middle-x p-3"
          style={{ zIndex: 999 }}
        >
          <div
            className="toast cust-toast w-100 p-1 toast-border show"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="toast-body d-flex align-items-center justify-content-between gap-5">
              <div className="d-flex align-items-center gap-3">
                <img src="../images/toast-success.svg" alt="Successfully" />
                <div className="d-block">
                  <p className="mb-0 font-4 font-semibold color-black text-nowrap mb-2">
                    Action Successful
                  </p>
                  <p className="font-medium font-14 mb-0 color-grey-v3 text-nowrap">
                    {toastMessage}
                  </p>
                </div>
              </div>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowToast(false)}
                aria-label="Close"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InviteeResponsesTab;
