const Loader = () => {
 
    return (
        <div className="custom-loader">
        <div className="d-flex align-items-center overlay justify-content-center h-100">
            <div
                id="loader"
                role="alert"
                aria-busy="true"
                aria-label="Loading animation"
            >
                <img src="images/Loader.gif" alt="loading" style={{width: '100px'}} ></img>
            </div>
        </div>
    </div>
    );
};
 
export default Loader;