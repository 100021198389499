/* eslint-disable @typescript-eslint/no-unused-vars */
import { url } from "inspector";
import { client } from "../../Client/Client";
import { jobsAndBids, jobsAndBidsService } from "../../Configs/BackendRoutes";
import { backendUrl as URL } from "../../Configs/Constant";



export const getJobsAndBids = async (data: any) => {
    const config = {
        method: 'POST',
        url: URL + jobsAndBids.getJobsAndBids,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };
    const response = await client(config);
    return response;
};

export const rmApproveRejectApi = async (data: any) => {
    const config = {
        method: 'POST',
        url: URL + jobsAndBids.rmApproveRejectBids,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };
    const response = await client(config);
    return response;
};


export const manageServiceRequest = async (data: any) => {
    const config = {
        method: 'POST',
        url: URL + jobsAndBidsService.manageJobsAndBids,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    };
    const response = await client(config);
    return response;
};

export const getServiceRequest = async (id: string) => {
    const config = {
        method: 'POST',
        url: URL + jobsAndBidsService.getServiceRequest,
        headers: {
            'Content-Type': 'application/json',
        },
        data: { service_request_id: id }
    };
    const response = await client(config);
    return response;
};

export const updateJobsAndBidsTimer = async (data: any) => {
    const config = {
        method: 'POST',
        url: URL + jobsAndBidsService.updateJobsAndBidsTimer,
        headers: {
            'Content-Type': 'application/json',
        },
        data: data
    };
    const response = await client(config);
    return response;
}