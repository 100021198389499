/* eslint-disable @typescript-eslint/no-unused-vars */
import { client } from "../../Client/Client";
import { jobsAndBids } from "../../Configs/BackendRoutes";
import { backendUrl as URL} from "../../Configs/Constant";
// import { ServiceRequest } from "../../Configs/ServiceRequestRoutes";


// export const acceptServiceRequest = async (id: string) => {
//     const config = {
//         method: 'GET',
//         url: URL + ServiceRequest.acceptRequest + `?service_request_uuid=${id}`,
//         headers: {
//             'Content-Type': 'application/json'
//         },
//     };
//     console.log(config.url)

//     const response = await client(config);
//     console.log(response, "accept modified request")
//     return response;
// }

// export const rejectServiceRequest = async (id: string) => {
//     const config = {
//         method: 'GET',
//         url: URL + ServiceRequest.rejectRequest + `?service_request_uuid=${id}`,
//         headers: {
//             'Content-Type': 'application/json'
//         },

//     };

//     const response = await client(config);
//     console.log(response, "reject modified request")
//     return response;
// }

export const offerBidPrice = async (data: any) => {
    const config = {
        method: 'POST',
        url: URL +jobsAndBids.UpdateOfferPrice,
        headers: {
            'Content-Type': 'application/json'
        },
        data

    };

    const response = await client(config);
    return response;
}


export const rmApproveRejectBids = async (data: any) => {
    const config = {
        method: 'POST',
        url: URL+jobsAndBids.rmApproveRejectBids,
        headers: {
            'Content-Type': 'application/json'
        },
        data
    };

    const response = await client(config);
    return response;
}

export const getServiceRequest = async (id: string) => {
    const config = {
        method: 'POST',
        url:  URL + jobsAndBids.ServiceRequestView,
        headers: {
            'Content-Type': 'application/json',
        },
        data: {service_request_id : id}
    };
    const response = await client(config);
    return response;
};