
import React, { useState, useEffect } from 'react';

interface FilterProps {
  activeTab: string;
  onApplyFilter: (filters: any) => void;
  onClose: () => void;
  onCancel: () => void;
  filterConfig: Array<{ key: string, label: string, type: string, options?: string }>;
  contractorNames: string[];
  adminNames: string[];
  initialFilters: {
    bid_amount: null,
    approved_by: "",
    raised_on_date_range_from: "",
    raised_on_date_range_to: "",
    requested_on_date_range_from: "",
    requested_on_date_range_to: "",
    contractor: ""
  };
}

const FilterComponent: React.FC<FilterProps> = ({
  activeTab,
  onApplyFilter,
  onClose,
  onCancel,
  filterConfig,
  contractorNames,
  adminNames,
  initialFilters
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filters, setFilters] = useState(initialFilters);
  const [tempFilters, setTempFilters] = useState(initialFilters);

  useEffect(() => {
    setFilters(initialFilters);
    setTempFilters(initialFilters);
  }, [activeTab, initialFilters]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, dateType?: string) => {
    const { name, value } = e.target;
    setTempFilters((prev: any) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleApply = () => {
    setFilters(tempFilters);
    onApplyFilter(tempFilters);
  };

  const handleClose = () => {
    onClose(); // Call onClose without passing tempFilters
};

  const handleCancel = () => {
    setFilters(initialFilters);
    setTempFilters(initialFilters);
    onCancel();
  };

  const renderDateInputs = (dateType: string, fromName: string, toName: string) => (
    <>
      <div className="col-md-6 col-sm-12">
        <div className="mb-3">
          <label htmlFor={`${dateType}_from`} className="form-label color-grey-v1 font-12 font-medium">
            From
          </label>
          <input
            type="date"
            id={`${dateType}_from`}
            name={fromName}
            max={(tempFilters as any)[toName] ? new Date((tempFilters as any)[toName]).toISOString().split('T')[0] : ""}
            className="form-control service-form-field color-black-v2 font-14 font-regular bg-transparent filter-field"
            onChange={handleInputChange}
            value={(tempFilters as any)[fromName] || ''}
          />
        </div>
      </div>
      <div className="col-md-6 col-sm-12">
        <div className="mb-3">
          <label htmlFor={`${dateType}_to`} className="form-label color-grey-v1 font-12 font-medium">
            To
          </label>
          <input
            type="date"
            id={`${dateType}_to`}
            name={toName}
            min={(tempFilters as any)[fromName] ? new Date((tempFilters as any)[fromName]).toISOString().split('T')[0] : ""}
            className="form-control service-form-field color-black-v2 font-14 font-regular bg-transparent filter-field"
            onChange={handleInputChange}
            value={(tempFilters as any)[toName] || ''}
          />
        </div>
      </div>
    </>
  );

  const renderDropdown = (label: string, key: string, options: string[]) => (
    <div key={key} className="mb-3">
      <label className="form-label color-grey-v1 font-12 font-medium">{label}</label>
      <select
        name={key}
        className="form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular bg-transparent"
        onChange={handleInputChange}
        value={(tempFilters as any)[key] || ''}
        style={{ maxHeight: '100px', overflowY: 'auto' }}
      >
        <option value="">Select</option>
        {options.map((option: string, index: number) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );

  return (
    <div className="dropdown-menu dd-box shadow-sm p-4 border-0 show" style={{ position: 'absolute', transform: 'translate3d(0, 0, 0)' }}>
      <div className="col-md-12">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="font-16 font-semibold color-black mb-0">Advanced Filter</h5>
          <button className="btn-close font-12" aria-label="Close" onClick={handleClose} />
        </div>
        <div className="row mt-3">
          {filterConfig.map((field) => {
            switch (field.type) {
              case 'dateRange':
                return renderDateInputs(field.key, `${field.key}_from`, `${field.key}_to`);
              case 'dropdown':
                const options = field.options === 'contractor_names' ? contractorNames : adminNames;
                return (
                  <div className="col-md-12 col-sm-12" key={field.key}>
                    {renderDropdown(field.label, field.key, options)}
                  </div>
                );
              case 'number':
                return (
                  <div key={field.key} className="col-md-6 col-sm-12 mb-3">
                    <label className="form-label color-grey-v1 font-12 font-medium">{field.label}</label>
                    <input
                      type="text"
                      name={field.key}
                      className="form-control service-form-field color-black-v2 font-14 font-regular bg-transparent"
                      onChange={handleInputChange}
                      value={(tempFilters as any)[field.key] || ''}
                      placeholder={`Enter ${field.label.toLowerCase()}`}
                    />
                  </div>
                );
              default:
                return null;
            }
          })}
        </div>
        <div className="d-flex justify-content-end mt-4">
          <button className="btn px-4 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn" onClick={handleCancel}>
            Clear
          </button>
          <button className="btn px-4 color-white font-semibold font-14 primary-btn px-4 rounded-3" onClick={handleApply}>
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterComponent;