
import React from 'react';
import { padZero } from '../../../Configs/Constant';

// interface FunnelData {
//     job_requests_submitted: number;
//     bids_completed: number;
//     user_accepted_bids: number;
// }

interface FunnelItem {
    value: number;
    name: string;
    count: number;
    startColor: string;
    endColor: string;
}

interface CustomFunnelShapeProps {
    points: { x: number; y: number }[];
    payload: FunnelItem;
    borderRadius: number;
    id: number;
}

const CustomFunnelShape: React.FC<CustomFunnelShapeProps> = (props) => {
    const { points, payload, borderRadius, id } = props;
    const [p1, p2, p3, p4] = points;
    const path = `
    M${p1.x},${p1.y + borderRadius}
    Q${p1.x},${p1.y} ${p1.x + borderRadius},${p1.y}
    L${p2.x - borderRadius},${p2.y}
    Q${p2.x},${p2.y} ${p2.x},${p2.y + borderRadius}
    L${p3.x},${p3.y - borderRadius}
    Q${p3.x},${p3.y} ${p3.x - borderRadius},${p3.y}
    L${p4.x + borderRadius},${p4.y}
    Q${p4.x},${p4.y} ${p4.x},${p4.y - borderRadius}
    Z
  `;

    return (
        <g>
            <defs>
                <linearGradient id={`gradient-${id}`} x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stopColor={payload.startColor} />
                    <stop offset="100%" stopColor={payload.endColor} />
                </linearGradient>
            </defs>
            <path d={path} stroke="none" fill={`url(#gradient-${id})`} />
        </g>
    );
};
const JobCreationFunnel = ({ props }: any) => {
    const width = 900; // Static width
    const height = 220; // Static height
    const borderRadius = 5;
    const spacing = 2; // Space between segments
    const topMargin = 60; // Space for labels above the funnel

    const data: FunnelItem[] = [
        {
            value: props?.job_requests_submitted,
            name: 'Job Requests Submitted',
            count: props?.job_requests_submitted,
            startColor: '#8dd1e1',
            endColor: '#7bc8d9'
        },
        {
            value: props?.bids_completed,
            name: 'Bids Completed',
            count: props?.bids_completed,
            startColor: '#83a6ed',
            endColor: '#6f96e5'
        },
        {
            value: props?.user_accepted_bids,
            name: 'User Accepted Bids',
            count: props?.user_accepted_bids,
            startColor: '#8884d8',
            endColor: '#7470d0'
        },
    ];

    const trapezoids = data.map((entry, index) => {
        const leftHeight = ((height - topMargin) * entry.value) / data[0].value;
        const rightHeight = data[index + 1] ? ((height - topMargin) * data[index + 1].value) / data[0].value : leftHeight;
        const leftX = (width / data.length) * index;
        const rightX = (width / data.length) * (index + 1) - spacing;
        const points = [
            { x: leftX, y: topMargin + (height - topMargin - leftHeight) / 2 },
            { x: rightX, y: topMargin + (height - topMargin - rightHeight) / 2 },
            { x: rightX, y: topMargin + (height - topMargin + rightHeight) / 2 },
            { x: leftX, y: topMargin + (height - topMargin + leftHeight) / 2 },
        ];
        return {
            points,
            payload: entry,
        };
    });

    const calculatePercentage = (index: number) => {
        if (index === 0) return '100%';
        const percentage = (data[index].value / data[0].value) * 100;
        return `${percentage.toFixed(0)}%`;
    };

    return (
        <div style={{ width: `${width}px`, height: `${height}px` }}>
            <svg width={width} height={height}>
                {trapezoids.map((trapezoid, index) => (
                    <g key={`trapezoid-${index}`}>
                        <CustomFunnelShape {...trapezoid} borderRadius={borderRadius} id={index} />
                        <text
                            x={((trapezoid.points[0].x + trapezoid.points[1].x) / 2)}
                            y={topMargin - 40}
                            textAnchor="middle"
                            dominantBaseline="middle"
                            fill="#868686"
                            enableBackground={'linear-gradient(to right, #33ccff, #ff99cc)'}
                            fontSize="13"
                            fontWeight="light"
                        >
                            {trapezoid.payload.name}
                        </text>
                        <text
                            x={trapezoid.points[0].x + 10} // Left corner
                            y={topMargin - 20}
                            textAnchor="start"
                            dominantBaseline="middle"
                            fill="#C0C0C0"
                            fontSize="12"
                            width="25px"
                        // fontWeight="bold"
                        >
                            {calculatePercentage((index))}
                        </text>
                        <text
                            x={trapezoid.points[1].x - 10} // Right corner
                            y={topMargin - 20}
                            textAnchor="end"
                            dominantBaseline="middle"
                            fill="#080808"
                            fontSize="20"
                            fontWeight="bold"
                        >
                            {trapezoid.payload.count ? padZero(trapezoid.payload.count) : "0"}
                        </text>

                    </g>
                ))}
                {trapezoids.slice(0, -1).map((trapezoid, index) => (
                    <line
                        key={`stroke-${index}`}
                        x1={trapezoid.points[1].x - spacing / 2}
                        y1={0}
                        x2={trapezoid.points[2].x - spacing / 2}
                        y2={height}
                        stroke="#EDEDED"
                        strokeWidth="3"
                    />
                ))}
            </svg>
        </div>
    );
};

export default JobCreationFunnel;