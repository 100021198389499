
import { client } from '../../Client/Client';
import { manageFeedbackServices} from '../../Configs/BackendRoutes';
import { backendUrl as URL  } from '../../Configs/Constant';

export const getGridData = async (data: any) => {
    const config = {
        method: 'POST',
        url: URL + manageFeedbackServices.getFeedbackDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data
    };
    const response = await client(config);
    return response?.data;
};
export const updateContractorFlag = async (data: any) => {
    const config = {
        method: 'POST',
        url: URL + manageFeedbackServices.FlagContractor,
        headers: {
            'Content-Type': 'application/json'
        },
        data
    };
    const response = await client(config);
    return response;
};