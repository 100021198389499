import { convertStringToObject } from "../../../Configs/Constant";
import {
  ContractorDetailsInterface,
  ContractorProfileInterface,
  ContractorServiceMappingInterface,
  OnBoardingDropdownInterface,
  ProfileAcknowledgmentsInterface,
  SecurityRolesInterface,
} from "../../../Interface/ManageContractor";

const Personel = ({
  contractorDetails,
  securityPersonnelInfo,
  profileData
}: {
  contractorDetails: ContractorDetailsInterface;
  securityPersonnelInfo: ContractorServiceMappingInterface[];
  profileData: ContractorProfileInterface;
}) => {
  const securityRoles: SecurityRolesInterface = {
    Armed: "armed-guards",
    Unarmed: "unarmed-guards",
    Concierge: "concierge",
    "Plain-clothed": "plain-clothed",
    "Off-duty PD": "off-duty",
    "Marked Vehicle": "marked-vehicle",
    "Private Investigator": "private-investigatore",
    "Mobile Patrol": "mobile-patrol",
    Robotics: "robot",
    "Executive Protection": "executive-protection",
    Others: "more",
  };

  const securityScope: string[] = profileData?.dropdowns
    ?.filter(
      (e: OnBoardingDropdownInterface) =>
        e.dropdown_name.toLowerCase() === "security scope"
    )
    .map((e: OnBoardingDropdownInterface) => {
        return e.dropdown_value
    });


  const bindAcknowledgments = () => {
    const ackString: string = contractorDetails?.ack
      ? `${contractorDetails?.ack}`
      : "";
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const acknowledgements: ProfileAcknowledgmentsInterface =
      convertStringToObject(ackString);
    // console.log("After convertion, " + acknowledgements);

    return (
      <div className="row">
        {/* <div className="col-12 col-12 mb-5">
        <p className="font-14 font-semibold primary-text-color mb-3">
          Subcontracting of any PPS work orders assigned to your company is
          strictly prohibited. Do you agree to comply with this requirement?
        </p>
        <p className="font-semibold color-black-v1 mb-0 font-14">{acknowledgements.q_one}</p>
      </div> */}
        <div className="col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-3">
            Subcontracting of any PPS work orders assigned to your company is
            strictly prohibited. Do you agree to comply with this requirement?
          </p>
          <p className="font-semibold color-black-v1 mb-0 font-14">
            {/* {acknowledgements?.subcontracting ? "Yes" : "No"} */}
            Yes
          </p>
        </div>
        <div className="col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-3">
            Any guard assigned to a Protos work order must have a negative drug
            screen on file with your company BEFORE arriving at our client’s
            location for their first shift. Do you agree to comply with this
            requirement?
          </p>
          <p className="font-semibold color-black-v1 mb-0 font-14">
            {/* {acknowledgements?.drugScreen ? "Yes" : "No"} */}
            Yes
          </p>
        </div>
        <div className="col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-3">
            At your discretion, do you agree to request additional random drug
            screening of any guards assigned to a Protos work order?
          </p>
          <p className="font-semibold color-black-v1 mb-0 font-14">
            {/* {acknowledgements?.randomDrugScreening ? "Yes" : "No"} */}
            Yes
          </p>
        </div>
        <div className="col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-3">
            Any guard assigned to a Protos work order must have a successfully
            passed industry standard background check on file with your company
            BEFORE arriving at our client’s location for their first shift. Do
            you agree to comply with this requirement?
          </p>
          <p className="font-semibold color-black-v1 mb-0 font-14">
            {/* {acknowledgements?.backgroundCheck ? "Yes" : "No"} */}
            Yes
          </p>
        </div>
      </div>
    );
  };
  return (
    <div
      // added - "show active"
      className="tab-pane fade show active"
      id="personnel"
      role="tabpanel"
      aria-labelledby="personnel-tab"
    >
      <h3 className="font-18 color-black font-semibold mb-4">
        Security Personnel Information
      </h3>
      <p className="font-14 font-semibold primary-text-color mb-2">
        Officer Services
      </p>
      <div className="d-flex align-items-center flex-wrap gap-3 mb-5">
        {/* <button className="outline-btn font-medium font-12 d-flex align-items-center gap-2 active">
          <img src="../images/armed-guards.svg" alt="armed-guards" />
          Armed Guards
        </button> */}
        {securityPersonnelInfo
          ?.filter(
            (e: ContractorServiceMappingInterface) =>
              e?.is_past_service === false
          )
          ?.map((e: ContractorServiceMappingInterface) => {
            if (e.request_type_name?.toLowerCase() === "others") {
              return (
                <button
                  className="outline-btn font-medium font-12 d-flex align-items-center gap-2 active"
                  key={e?.request_type_uuid}
                >
                  <img
                    src={`../images/${
                      securityRoles[
                        // e?.others_name !== undefined ? e?.others_name : "Others"
                        "Others"
                      ]
                    }.svg`}
                    alt={`${e.others_name}`}
                  />
                  {e.others_name}
                </button>
              );
            } else {
              return (
                <button
                  className="outline-btn font-medium font-12 d-flex align-items-center gap-2 active"
                  key={e?.request_type_uuid}
                >
                  <img
                    src={`../images/${
                      securityRoles[
                        e?.request_type_name !== undefined
                          ? e?.request_type_name
                          : "Others"
                      ]
                    }.svg`}
                    alt={`${e.request_type_name}`}
                  />
                  {e.request_type_name}
                </button>
              );
            }
          })}
        {/* <button className="outline-btn font-medium font-12 d-flex align-items-center gap-2 active">
          <img src="../images/unarmed-guards.svg" alt="unarmed-guards" />
          Unarmed Guards
        </button>
        <button className="outline-btn font-medium font-12 d-flex align-items-center gap-2 active">
          <img src="../images/concierge.svg" alt="concierge" />
          Concierge
        </button> */}
      </div>
      <div className="row">
        {securityPersonnelInfo
          ?.filter(
            (e: ContractorServiceMappingInterface) =>
              e?.is_past_service === false
          )
          ?.map((e) => {
            if (e?.request_type_name?.toLowerCase() === "others") {
              return (
                <div
                  className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5"
                  key={e.request_type_uuid}
                >
                  <p className="font-14 font-semibold primary-text-color mb-2">
                    {`# of ${e.others_name} on payroll`}
                  </p>
                  <p className="font-medium color-black-v1 mb-0 font-14">
                    {e.guard_count}
                  </p>
                </div>
              );
            } else {
              return (
                <div
                  className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5"
                  key={e.request_type_uuid}
                >
                  <p className="font-14 font-semibold primary-text-color mb-2">
                    {`# of ${e.request_type_name} on payroll`}
                  </p>
                  <p className="font-medium color-black-v1 mb-0 font-14">
                    {e.guard_count}
                  </p>
                </div>
              );
            }
          })}
        {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">
            # of unarmed guards on payroll
          </p>
          <p className="font-medium color-black-v1 mb-0 font-14">29</p>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">
            # of Private Investigator on Payroll
          </p>
          <p className="font-medium color-black-v1 mb-0 font-14">32</p>
        </div> */}
      </div>

      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">
          Security Scopes
          </p>
          <p className="font-medium color-black-v1 mb-0 font-14">
            {
              // experiencedIndustries?.length >0 ? experiencedIndustries.join(", ") : ""
              securityScope?.length >0 ? securityScope.join(", ") : "-"
            }
          </p>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">
          Company Size
          </p>
          <p className="font-medium color-black-v1 mb-0 font-14">
            {
              profileData?.dropdowns?.find((e: OnBoardingDropdownInterface)=>e.dropdown_name.toLowerCase() === "company size")?.dropdown_value
            }
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-3">
            Ability to Place Off Duty Police Officers
          </p>
          <p className="font-semibold color-black-v1 mb-0 font-14">
            {contractorDetails?.is_abled_off_duty_police}
          </p>
        </div>
      </div>
      <h3 className="font-18 color-black font-semibold mb-4">Acknowledge</h3>
      {bindAcknowledgments()}
    </div>
  );
};

export default Personel;

// <div className="col-lg-12 mb-4 mb-lg-5 d-flex justify-content-between flex-column">
// <label className="font-semibold font-16 color-black-v2 mb-2">
//   Applied Discounts
// </label>
// {/* <p className="font-regular font-12 color-grey cust-mb">
// Receive discounts on services by making timely payments.
// </p> */}
// <div className="row align-items-center flex-wrap">
//   <div className="col-md-12">
//     <label
//       className="form-check-label form-control service-form-field py-2 mb-3 ps-5 position-relative active-discount"
//       htmlFor="optiontext"
//     >
//       <input
//         className="form-check-input custom-form-check-input"
//         type="radio"
//         name="inlineRadioOptions"
//         id="optiontext"
//         defaultValue="option1"
//         defaultChecked={contractorDetails?.contractor_payment?.contractor_payment_uuid !== ""}
//       />
//       <span className="font-semibold font-12 color-black mb-2">
//         {/* 1.75% Discount in Invoiced amount */}
//         {contractorDetails?.contractor_payment?.fee_percentage===0 ? "Free Service Fee":`${contractorDetails?.contractor_payment?.fee_percentage}% Service Fee Applied`}
//       </span>
//       <span className="font-regular font-12 color-black mb-0 d-block">
//         Receive payment after {contractorDetails?.contractor_payment?.payment_days} of service {contractorDetails?.contractor_payment?.fee_percentage===0 && "and enjoy a free service fee offer"}
//       </span>
//     </label>
//   </div>
// </div>
// </div>
