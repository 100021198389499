/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import {
  ApproveClientRequestInterface,
  ClientGridDataInterface,
  IndustryDataInterface,
  sortOrderInterfaceType,
} from "../../../Interface/ManageClient";
import { useNavigate } from "react-router-dom";
import {
  encryptStorage,
  isRegexValidMobile,
  usPhoneNumberFormat,
} from "../../../Configs/Constant";
import TooltipText from "../../JobsAndBids/TooltipText";

const NewClientRequestsGrid = ({
  gridData,
  totalCount,
  handleSort,
  sortOrder,
  setSortOrder,
  handleLoadMore,
  postApproveRejectClient,
  industryData,
}: {
  gridData: ClientGridDataInterface[];
  totalCount: number;
  handleSort: (column: string, order: boolean) => void;
  sortOrder: sortOrderInterfaceType;
  setSortOrder: React.Dispatch<React.SetStateAction<sortOrderInterfaceType>>;
  handleLoadMore: () => void;
  postApproveRejectClient: (
    payload: ApproveClientRequestInterface
  ) => Promise<void>;
  industryData: IndustryDataInterface[];
}) => {
  const [showRequestPopUp, setShowRequestPopUp] =
    useState<ClientGridDataInterface>({} as ClientGridDataInterface);
  const navigate = useNavigate();
  const bindGridData = () => {
    return gridData?.map((data) => {
      return (
        <tr key={data?.customer_uuid}>
          <td className="ps-4">
            <a
              style={{ cursor: "pointer" }}
              onClick={() => {
                encryptStorage.setItem(
                  "manageCustomerProfileID",
                  data?.customer_uuid
                );
                navigate("/manage-clients-profile", {
                  state: {
                    customerUUID: data?.customer_uuid,
                    industryData: industryData,
                    other_industry: data?.other_industry,
                    tab: "NEW_CLIENT_REQUESTS",
                  },
                });
              }}
              className="link-text text-decoration-none"
            >
              {data?.customer_dba}
            </a>
          </td>
          <td>
            {data?.first_name === "" && data?.last_name === ""
              ? "-"
              : data?.first_name + " " + data?.last_name}
          </td>
          <td>
            {data?.referred_by === "" && data?.other_referrel === ""
              ? "-"
              : data?.referred_by !=="" ? data?.referred_by : data?.other_referrel}
          </td>
          {/* <td>{data.industry_name === "" ?  "-" : data.industry_name.toLowerCase() === "other" ? data.other_industry: data.industry_name}</td> */}
          <td>
            
            <TooltipText text={data?.industry_name === "" ? "-" : data?.industry_name} maxLength={15} />


          </td>
          <td>{data?.business_email}</td>
          <td>
            {data.phone_number}
            {/* {data?.phone_number !== ""
              ? isRegexValidMobile(data?.phone_number)
                ? data?.phone_number
                : usPhoneNumberFormat(data?.phone_number)
              : "-"} */}
          </td>
          <td>{data.region}</td>
          <td>
            <div className="d-flex align-items-center gap-3 justify-content-center">
              <button
                className="btn green-btn px-4 font-12 font-semibold"
                onClick={() => {
                  setShowRequestPopUp(data);
                  // postApproveRejectClient({customer_uuid: data.customer_uuid, is_approved: true})
                }}
              >
                Approve
              </button>
              <button
                className="btn red-btn px-4 font-12 font-semibold"
                onClick={() => {
                  setShowRequestPopUp(data);
                  // postApproveRejectClient({customer_uuid: data.customer_uuid, is_approved: false})
                }}
              >
                Reject
              </button>
            </div>
          </td>
        </tr>
      );
    });
  };
  return (
    <>
      <div
        className={`tab-pane fade show active`}
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <div className="table-responsive theme-table bg-white custom-scroll my-4 w-100">
          <table className="table table-borderless mb-0 acc-view">
            <thead>
              <tr>
                <th className="ps-4 text-nowrap cursor-pointer"  onClick={() => {
                        setSortOrder((prevState) => ({
                          ...prevState,
                          customer_dba: !prevState.customer_dba,
                        }));
                        handleSort("customer_dba", sortOrder.customer_dba);
                      }}>
                  DBA Name
                  <span className="ms-1 cursor-pointer">
                    <img
                      src={`../images/${
                        sortOrder.customer_dba
                          ? "sort-up-icon"
                          : "sort-arrow-down"
                      }.svg`}
                      alt={`${
                        sortOrder.customer_dba
                          ? "sort-up-icon"
                          : "sort-arrow-down"
                      }`}
                      // onClick={() => {
                      //   setSortOrder((prevState) => ({
                      //     ...prevState,
                      //     customer_dba: !prevState.customer_dba,
                      //   }));
                      //   handleSort("customer_dba", sortOrder.customer_dba);
                      // }}
                    />
                  </span>
                </th>
                <th className="text-nowrap cursor-pointer" onClick={() => {
                        setSortOrder((prevState) => ({
                          ...prevState,
                          first_name: !prevState.first_name,
                        }));
                        handleSort("first_name", sortOrder.first_name);
                      }}>
                  Name
                  <span className="ms-1 cursor-pointer">
                    <img
                      src={`../images/${
                        sortOrder.first_name
                          ? "sort-up-icon"
                          : "sort-arrow-down"
                      }.svg`}
                      alt={`${
                        sortOrder.first_name
                          ? "sort-up-icon"
                          : "sort-arrow-down"
                      }`}
                      // onClick={() => {
                      //   setSortOrder((prevState) => ({
                      //     ...prevState,
                      //     first_name: !prevState.first_name,
                      //   }));
                      //   handleSort("first_name", sortOrder.first_name);
                      // }}
                    />
                  </span>
                </th>
                <th className="text-nowrap cursor-pointer" onClick={() => {
                        setSortOrder((prevState) => ({
                          ...prevState,
                          referred_by: !prevState.referred_by,
                        }));
                        handleSort("referred_by", sortOrder.referred_by);
                      }}>
                  Referred By
                  <span className="ms-1 cursor-pointer">
                    <img
                      src={`../images/${
                        sortOrder.referred_by
                          ? "sort-up-icon"
                          : "sort-arrow-down"
                      }.svg`}
                      alt={`${
                        sortOrder.referred_by
                          ? "sort-up-icon"
                          : "sort-arrow-down"
                      }`}
                    />
                  </span>
                </th> 
                <th className="text-nowrap cursor-pointer"  onClick={() => {
                        setSortOrder((prevState) => ({
                          ...prevState,
                          industry_name: !prevState.industry_name,
                        }));
                        handleSort("industry_name", sortOrder.industry_name);
                      }}>
                  Industry
                  <span className="ms-1 cursor-pointer">
                    <img
                      src={`../images/${
                        sortOrder.industry_name
                          ? "sort-up-icon"
                          : "sort-arrow-down"
                      }.svg`}
                      alt={`${
                        sortOrder.industry_name
                          ? "sort-up-icon"
                          : "sort-arrow-down"
                      }`}
                      // onClick={() => {
                      //   setSortOrder((prevState) => ({
                      //     ...prevState,
                      //     industry_name: !prevState.industry_name,
                      //   }));
                      //   handleSort("industry_name", sortOrder.industry_name);
                      // }}
                    />
                  </span>
                </th>
                <th className=" text-nowrap cursor-pointer" onClick={() => {
                        setSortOrder((prevState) => ({
                          ...prevState,
                          business_email: !prevState.business_email,
                        }));
                        handleSort("business_email", sortOrder.business_email);
                      }}>Email ID
                <span className="ms-1 cursor-pointer">
                    <img
                      src={`../images/${
                        sortOrder.business_email
                          ? "sort-up-icon"
                          : "sort-arrow-down"
                      }.svg`}
                      alt={`${
                        sortOrder.business_email
                          ? "sort-up-icon"
                          : "sort-arrow-down"
                      }`}
                      // onClick={() => {
                      //   setSortOrder((prevState) => ({
                      //     ...prevState,
                      //     business_email: !prevState.business_email,
                      //   }));
                      //   handleSort("business_email", sortOrder.business_email);
                      // }}
                    />
                  </span>
                </th>
                <th className=" text-nowrap">Phone #</th>
                <th className=" text-nowrap cursor-pointer"  onClick={() => {
                        setSortOrder((prevState) => ({
                          ...prevState,
                          region: !prevState.region,
                        }));
                        handleSort("region", sortOrder.region);
                      }}>
                  Region
                  <span className="ms-1 cursor-pointer">
                    <img
                      src={`../images/${
                        sortOrder.region ? "sort-up-icon" : "sort-arrow-down"
                      }.svg`}
                      alt={`${
                        sortOrder.region ? "sort-up-icon" : "sort-arrow-down"
                      }`}
                      // onClick={() => {
                      //   setSortOrder((prevState) => ({
                      //     ...prevState,
                      //     region: !prevState.region,
                      //   }));
                      //   handleSort("region", sortOrder.region);
                      // }}
                    />
                  </span>
                </th>
                <th className=" text-nowrap text-center">Actions</th>
              </tr>
            </thead>
            <tbody>{bindGridData()}</tbody>
          </table>
        </div>
        <p className="font-14 font-medium color-grey-v7">
          Showing{" "}
          <span className="font-semibold color-black">{gridData?.length}</span>{" "}
          out of <span className="font-semibold color-black">{totalCount}</span>{" "}
          Item
        </p>
        {totalCount > gridData?.length && gridData?.length >= 20 && (
          <div className="d-flex align-items-center justify-content-center">
            <button
              className="btn px-4 color-white font-semibold font-14 primary-btn px-4 rounded-3 text-center"
              onClick={() => {
                handleLoadMore();
              }}
            >
              Load More
            </button>
          </div>
        )}
      </div>
      {Object.keys(showRequestPopUp).length !== 0 && (
        <div
          className="modal fade show active"
          id="exampleModal2"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
      style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}

        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content border-radius-10">
              <div className="modal-header border-0 px-4 pt-4 align-items-center">
                <div className="d-block">
                  <h5
                    className="modal-title font-bold font-18 color-black-v2 mb-0"
                    id="exampleModalLabel"
                  >
                    Client Request for Account Creation
                  </h5>
                </div>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setShowRequestPopUp({} as ClientGridDataInterface);
                  }}
                />
              </div>
              <div className="modal-body px-4 pt-3 pb-0">
                <div className="row">
                  <div className=" col-md-6 col-sm-6 col-12 mb-sm-5 mb-4">
                    <p className="font-14 font-semibold primary-text-color mb-2">
                      Name
                    </p>
                    <p className="font-medium color-black-v1 mb-0 font-14">
                      {showRequestPopUp?.first_name +
                        " " +
                        showRequestPopUp?.last_name}
                    </p>
                  </div>
                  <div className=" col-md-6 col-sm-6 col-12 mb-sm-5 mb-4">
                    <p className="font-14 font-semibold primary-text-color mb-2">
                      DBA Name
                    </p>
                    <p className="font-medium color-black-v1 mb-0 font-14">
                      {showRequestPopUp?.customer_dba}
                    </p>
                  </div>
                  <div className=" col-md-6 col-sm-6 col-12 mb-sm-5 mb-4">
                    <p className="font-14 font-semibold primary-text-color mb-2">
                      Operations Email
                    </p>
                    <p className="font-medium color-black-v1 mb-0 font-14">
                      {showRequestPopUp?.business_email}
                    </p>
                  </div>
                  {/* <div className=" col-md-6 col-sm-6 col-12 mb-sm-5 mb-4">
                    <p className="font-14 font-semibold primary-text-color mb-2">
                      CEO Email
                    </p>
                    <p className="font-medium color-black-v1 mb-0 font-14">
                      **Needs to check
                    </p>
                  </div> */}
                  <div className=" col-md-6 col-sm-6 col-12 mb-sm-5 mb-4">
                    <p className="font-14 font-semibold primary-text-color mb-2">
                      Phone #
                    </p>
                    <p className="font-medium color-black-v1 mb-0 font-14">
                      {/* {formatPhoneNumber(showRequestPopUp?.phone_number) &&
                        "+1 " +
                          formatPhoneNumber(showRequestPopUp.phone_number)} */}
                      {/* {showRequestPopUp.phone_number} */}
                      {showRequestPopUp.phone_number !== ""
                        ? isRegexValidMobile(showRequestPopUp.phone_number)
                          ? showRequestPopUp.phone_number
                          : usPhoneNumberFormat(showRequestPopUp.phone_number)
                        : "-"}
                    </p>
                  </div>
                  <div className=" col-md-6 col-sm-6 col-12 mb-sm-5 mb-4">
                    <p className="font-14 font-semibold primary-text-color mb-2">
                      States Operating
                    </p>
                    <p className="font-medium color-black-v1 mb-0 font-14">
                      {showRequestPopUp?.state}
                    </p>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-between gap-2 border-0 gap-2 px-4 pb-4 pt-2">
                <button
                  className="btn font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn px-0"
                  onClick={() => {
                    setShowRequestPopUp({} as ClientGridDataInterface);
                  }}
                >
                  Cancel
                </button>
                <div className="d-flex align-items-center gap-2">
                  <button
                    className="btn px-4 font-semibold font-14 shadow-none reject-btn"
                    onClick={() => {
                      setShowRequestPopUp({} as ClientGridDataInterface);
                      postApproveRejectClient({
                        customer_uuid: showRequestPopUp.customer_uuid,
                        is_approved: false,
                      });
                    }}
                  >
                    Reject
                  </button>
                  <button
                    className="btn px-4 font-semibold font-14 shadow-none accept-btn"
                    onClick={() => {
                      setShowRequestPopUp({} as ClientGridDataInterface);
                      postApproveRejectClient({
                        customer_uuid: showRequestPopUp.customer_uuid,
                        is_approved: true,
                      });
                    }}
                  >
                    Approve
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NewClientRequestsGrid;
