/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */

// Import necessary dependencies
import React, { useEffect, useState } from "react";
import { Contractor } from "../../Interface/ContractorGrid";
import { approveRejectApi } from "../../Service/ServiceRequest/ManageContractor/ManageContractorService";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/LoaderComponent";
import TooltipText from "../JobsAndBids/TooltipText";
import { encryptStorage } from "../../Configs/Constant";

// Define the props interface for the component (props and their types)
interface AllContractorsTabProps {
  data: Contractor[]; // Array of contractors
  loading: boolean; // Loading state
  fetchGridData: () => void; // Function to fetch grid data
  handleSort: (sortBy: string, sortOrder: "ASC" | "DESC") => void; // Function to handle sorting
  currentSortBy: string; // Current column being sorted
  currentSortOrder: "ASC" | "DESC"; // Current sort order
  hasMore: boolean; // Whether there are more contractors to load
  onLoadMore: () => void; // Function to load more contractors
  totalContractors: number; // Total number of contractors
  noOfContractors: number; // Number of contractors currently displayed
  displayToast: (message: string) => void; // Function to display a toast message
  activeTab: "account-requests" | "all-contractors" | "invitee-responses"; // Current active tab
}

interface ContractorAction {
  contractor_id: string; // ID of the contractor
  action: "active" | "inactive"; // Action to perform on the contractor
}

interface ContractorActionResponse {
  code: number; // Response code
  // Add other properties as needed
}

function AllContractorsTab({
  data,
  loading,
  fetchGridData,
  handleSort,
  currentSortBy,
  currentSortOrder,
  hasMore,
  onLoadMore,
  totalContractors,
  noOfContractors,
  displayToast,
  activeTab,
}: AllContractorsTabProps) {
  // State for showing toast messages
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState<boolean>(false);
  const navigate = useNavigate();

  // Effect to hide toast message after specific duration
  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        setShowToast(false);
      }, 3000); // Hide toast after 3 seconds

      return () => clearTimeout(timer);
    }
  }, [showToast]);

  // Show loader if loading and there's no data yet
  if (loading && data.length === 0) {
    return <Loader />;
  }

  // Function to handle sorting
  const onSort = (column: string) => {
    let newSortOrder: "ASC" | "DESC";
    if (currentSortBy === column) {
      newSortOrder = currentSortOrder === "DESC" ? "ASC" : "DESC";
    } else {
      newSortOrder = "DESC";
    }
    handleSort(column, newSortOrder);
  };

  // Function to render sort icon
  const renderSortIcon = (column: string) => {
    let iconSrc = "../images/sort-arrow-down.svg";
    if (currentSortBy === column) {
      iconSrc =
        currentSortOrder === "DESC"
          ? "../images/sort-up-icon.svg"
          : "../images/sort-arrow-down.svg";
    }

    return (
      <span className="ms-1 cursor-pointer" onClick={() => onSort(column)}>
        <img
          src={iconSrc}
          alt={
            currentSortBy === column
              ? `sort-${currentSortOrder.toLowerCase()}`
              : "sort-arrow-down"
          }
        />
      </span>
    );
  };

  // Function to handle action (active/inactive)
  const handleAction = async (
    contractorId: string,
    action: "active" | "inactive"
  ) => {
    const actionData: ContractorAction = {
      contractor_id: contractorId,
      action: action,
    };

    try {
      const response: ContractorActionResponse = await approveRejectApi(
        actionData
      );
      if (response.code === 200) {
        fetchGridData();
        displayToast(
          `Contractor ${
            action === "active" ? "activated" : "deactivated"
          } successfully`
        );
      }
    } catch (error) {
      console.error("Error performing action:", error);
      displayToast("An error occurred while updating the contractor status");
    }
  };

  // Function to render star badge based on rating
  const renderStarBadge = (rating: string | undefined) => {
    if (!rating || rating === "No rating") return null;

    let badgeSrc = "";
    if (rating === "Gold") {
      badgeSrc = "../images/star-badge.svg";
    } else if (rating === "silver") {
      badgeSrc = "../images/star-badge.svg";
    }

    // eslint-disable-next-line jsx-a11y/alt-text
    return badgeSrc ? <img className="icon-sty" src={badgeSrc} /> : null;
  };

  // Function to get license validity status
  const getLicenseValidityStatus = (licenses: string[]): string => {
    const validCount = licenses.filter(
      (license) => license.toLowerCase() === "valid"
    ).length;
    const invalidCount = licenses.length - validCount;

    if (validCount === licenses.length) {
      return "Valid";
    } else if (invalidCount === licenses.length) {
      return "Invalid";
    } else {
      return `${invalidCount} of ${licenses.length} are Invalid`;
    }
  };

  return (
    <>
      <div
        className="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <div className="table-responsive theme-table bg-white custom-scroll mb-4 w-100">
          <table className="table table-borderless mb-0 acc-view">
            <thead>
              <tr>
                <th
                  className="ps-4 text-nowrap cursor-pointer"
                  onClick={() => onSort("dba_name")}
                >
                  DBA Name
                  {renderSortIcon("dba_name")}
                </th>
                <th
                  className="text-nowrap cursor-pointer"
                  onClick={() => onSort("business_address")}
                >
                  Business Address
                  {renderSortIcon("business_address")}
                </th>
                <th
                  className="text-nowrap cursor-pointer"
                  onClick={() => onSort("email_address")}
                >
                  Email &amp; Phone
                  {renderSortIcon("email_address")}
                </th>
                <th
                  className="text-nowrap cursor-pointer"
                  onClick={() => onSort("operating_states")}
                >
                  Operating States
                  {renderSortIcon("operating_states")}
                </th>
                <th
                  className="text-nowrap cursor-pointer"
                  onClick={() => onSort("services_offered")}
                >
                  # Services
                  {renderSortIcon("services_offered")}
                </th>
                <th
                  className="text-nowrap cursor-pointer"
                  onClick={() => onSort("license_validity")}
                >
                  License Validity
                  {renderSortIcon("license_validity")}
                </th>
                <th
                  className="text-nowrap cursor-pointer"
                  onClick={() => onSort("account_status")}
                >
                  Status
                  {renderSortIcon("account_status")}
                </th>
                <th className="text-nowrap text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((contractor, index) => (
                <tr key={index}>
                  <td className="ps-4">
                    <div className="d-flex align-items-center gap-2">
                      <a
                        className="link-text text-decoration-none"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          encryptStorage.setItem(
                            "manageContractorProfileID",
                            contractor.contractor_id
                          );
                          navigate("/manage-contractors-profile", {
                            state: contractor.contractor_id,
                          });
                        }}
                      >
                        {contractor.dba_name || "-"}
                      </a>
                      {renderStarBadge(contractor.contractor_rating)}
                    </div>
                    <span className="font-12 font-medium color-grey-v3 d-block mt-2">
                      {contractor.contractor_status || "-"}
                    </span>
                  </td>
                  <td>
                  <TooltipText text={contractor.business_address.address_line_1 ? `${contractor.business_address.address_line_1}, ${contractor.business_address.city_state}` : "-"} maxLength={15} />
                  </td>
                  <td>
                    <span className="d-block font-13 color-black font-medium mb-2">
                      {contractor.email_address || "-"}
                    </span>
                    <span className="font-13 color-black font-medium">
                      {contractor.phone_number || "-"}
                    </span>
                  </td>
                  <td>
                    {contractor.operating_states && contractor.operating_states.filter(state => state.trim() !== "").length > 0
                      ? contractor.operating_states.filter(state => state.trim() !== "").join(", ")
                      : "-"
                    }
                  </td>
                  <td>
                    <span className="link-text">
                      {contractor.services_offered || "-"}
                    </span>
                  </td>
                  <td>
                    {contractor.license_validity &&
                    contractor.license_validity.length > 0
                      ? getLicenseValidityStatus(contractor.license_validity)
                      : "-"}
                  </td>
                  <td>{contractor.account_status || "-"}</td>
                  <td className="text-center">
                    <div className="d-flex align-items-center gap-3 justify-content-center">
                      {contractor.account_status === "Active" ? (
                        <>
                          <button
                            className="btn green-btn px-4 font-12 font-semibold"
                            onClick={() =>
                              navigate("/edit-contractor", {
                                state: {
                                  userID: contractor.contractor_id,
                                  isEdit: true,
                                },
                              })
                            }
                          >
                            Edit
                          </button>
                          <button
                            className="btn red-btn px-4 font-12 font-semibold"
                            onClick={() =>
                              handleAction(
                                contractor.contractor_id,
                                "inactive"
                              )
                            }
                          >
                            Mark Inactive
                          </button>
                        </>
                      ) : (
                        <button
                          className="btn green-btn px-5 font-12 font-semibold"
                          onClick={() =>
                            handleAction(contractor.contractor_id, "active")
                          }
                        >
                          Mark Active
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <p className="font-14 font-medium color-grey-v7">
          Showing{" "}
          <span className="font-semibold color-black">{noOfContractors}</span>{" "}
          out of{" "}
          <span className="font-semibold color-black">{totalContractors}</span>{" "}
          Items
        </p>
        {hasMore && (
          <div className="d-flex align-items-center justify-content-center mt-4">
            <button
              className="btn px-4 color-white font-semibold font-14 primary-btn px-4 rounded-3 text-center"
              onClick={onLoadMore}
              disabled={loading}
            >
              {loading ? "Loading..." : "Load More"}
            </button>
          </div>
        )}
      </div>
      {showToast && (
        <div
          className="position-fixed top-0 start-50 translate-middle-x p-3"
          style={{ zIndex: 999 }}
        >
          <div
            className="toast cust-toast w-100 p-1 toast-border show"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="toast-body d-flex align-items-center justify-content-between gap-5">
              <div className="d-flex align-items-center gap-3">
                <img src="../images/toast-sucess.svg" alt="Successfully" />
                <div className="d-block">
                  <p className="mb-0 font-4 font-semibold color-black text-nowrap mb-2">
                    Action Successful
                  </p>
                  <p className="font-medium font-14 mb-0 color-grey-v3 text-nowrap">
                    Approved Successfully
                  </p>
                </div>
              </div>
              {/* <button
                                type="button"
                                className="btn-close"
                                onClick={() => setShowToast(false)}
                                aria-label="Close"
                            /> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AllContractorsTab;