import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  getServiceRequest,
  offerBidPrice,
} from "../../Service/ServiceRequestView/ServiceRequestView";
import Loader from "../Loader/LoaderComponent";
import {
  BidApprovalRequest,
  BidInfoRequest,
} from "../../Interface/BidApproval";
import {
  approveBidApi,
  getBidInfoApi,
} from "../../Service/BidApprovalServices/BidInfoService";
import InfiniteScroll from "react-infinite-scroll-component";
import { calculateTimeDifference } from "./OpenTab";
import CountdownTimer from "./CountdownTimer";
import JobsAndBidsTimer from "./JobsAndBidsTimer";
import { contextValueInterface } from "../../Interface/HeaderDetails";
import { ppsContext } from "../../Configs/Constant";

/* eslint-disable jsx-a11y/anchor-is-valid */
function ServiceRequestView() {
  const navigate = useNavigate();
  const { state } = useLocation();
  let { id, status, bidOwner, tab, routedFrom } = state || {};
  const contextValue: contextValueInterface = useContext(ppsContext);
  const userRole = contextValue.userDetails.role;

  useEffect(() => {
    pageload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [serviceRequestDetails, setServiceRequestDetails] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditTimer, setIsEditTimer] = useState<boolean>(false);
  const [responseTime, setResponseTime] = useState<string>("");
  const [serviceRequestId, setServiceRequestId] = useState<string>("");

  const pageload = async () => {
    try {
      await fetchServiceRequest();
    } catch (error) {
      console.log(error, "pageload");
    }
  };

  const fetchServiceRequest = async () => {
    try {
      // id = "4b92de55-7b0d-43e5-9f1e-706344362e7f"
      setIsLoading(true);
      let response = await getServiceRequest(id);
      if (response.code === 200) {
        setServiceRequestDetails(response.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error, "fetchServiceRequest");
    }
  };

  const [bids, setBids] = useState<any>([]);
  const [bidRequest, setBidRequest] = useState<BidInfoRequest>({
    type: "bidInfo",
    service_request_id: id,
    user_id: "SampleId",
    sort_by: "",
    sort_order: "",
    limit: 10,
  });
  const [marginPercentage, setMarginPercentage] = useState<string>("");
  const [marginAmount, setMarginAmount] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState(true);
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showToast, setShowToast] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [toastMessage, setToastMessage] = useState<string>("");

  const [bidPrice, setBidPrice] = useState<string>(""); // State for bid price

  const fetchBids = useCallback(async () => {
    try {
      const response = await getBidInfoApi(bidRequest);
      if (response.code === 200) {
        if (bidRequest.limit > response.data.bids.length) {
          setHasMore(false);
        }
        setBids(response.data.bids);
      } else {
        console.error("Error fetching bids:", response.message);
      }
    } catch (error) {
      console.error("Error fetching bids:", error);
    }
  }, [bidRequest]);

  useEffect(() => {
    fetchBids();
  }, [fetchBids]);

  const loadMoreData = () => {
    setBidRequest((prev) => ({
      ...prev,
      limit: prev.limit + 5,
    }));
  };

  const handleSort = (newSortBy: string) => {
    setBidRequest((prev) => {
      const newSortOrder =
        prev.sort_by === newSortBy && prev.sort_order === "desc"
          ? "asc"
          : "desc";
      return {
        ...prev,
        sort_by: newSortBy,
        sort_order: newSortOrder,
      };
    });
  };

  // Function to calculate total amount with margin and handle default values
  const calculateTotalWithMargin = (
    bid: any,
    marginPercentage?: string,
    marginAmount?: string
  ) => {
    const bidValue = parseFloat(bid.bid_amount.replace(/[^0-9.]/g, ""));
    const totalAmountWithMarginValue = parseFloat(
      bid.total_amount_with_margin.replace(/[^0-9.]/g, "")
    );

    let resultAmount = bidValue;

    if (marginPercentage && parseFloat(marginPercentage) > 0) {
      const percentage = parseFloat(marginPercentage) / 100;
      resultAmount = bidValue * (1 + percentage);
    } else if (marginAmount && parseFloat(marginAmount) > 0) {
      const amount = parseFloat(marginAmount);
      resultAmount = bidValue + amount;
    } else if (totalAmountWithMarginValue > 0) {
      resultAmount = totalAmountWithMarginValue;
    }

    return `$${resultAmount.toFixed(2)}/hr`;
  };

  const handleMarginPercentageChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    if (value === "" || /^\d*\.?\d*$/.test(value)) {
      setMarginPercentage(value);
      setMarginAmount("");
    }
  };

  const handleMarginAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "" || /^\d*\.?\d*$/.test(value)) {
      setMarginAmount(value);
      setMarginPercentage("");
    }
  };

  const handleApprove = async (bid: any) => {
    setIsLoading(true);
    const bidValue = parseFloat(bid.bid_amount.replace(/[^0-9.]/g, ""));
    let totalAmountWithMargin = bidValue;
    let marginPercentageValue = 0;
    let marginValueAmount = 0;

    if (marginPercentage) {
      marginPercentageValue = parseFloat(marginPercentage);
      totalAmountWithMargin = bidValue * (1 + marginPercentageValue / 100);
      marginValueAmount = totalAmountWithMargin - bidValue;
    } else if (marginAmount) {
      marginValueAmount = parseFloat(marginAmount);
      totalAmountWithMargin = bidValue + marginValueAmount;
      marginPercentageValue = (marginValueAmount / bidValue) * 100;
    }

    const approvalRequestData: BidApprovalRequest = {
      service_request_id: id,
      contractor_id: bid.contractor_id,
      bid_id: bid.bid_id,
      total_amount_with_margin: totalAmountWithMargin,
      margin_percentage: marginPercentageValue,
      margin_value: marginValueAmount,
    };

    try {
      const response = await approveBidApi(approvalRequestData);

      if (response.code === 200) {
        setToastMessage("Bid approved successfully");
        setShowToast(true);
        await fetchBids();
        setIsModalOpen(false); // Close the modal
        setTimeout(() => setShowToast(false), 3000); // Hide toast after 3 seconds
      } else {
        console.error("Error approving bid:", response.message);
        setToastMessage("Error approving bid");
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000);
      }
    } catch (error) {
      console.error("Error approving bid:", error);
      setToastMessage("Error approving bid");
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    } finally {
      setIsLoading(false);
    }
  };

  const getSortIcon = (columnName: string) => {
    if (bidRequest.sort_by === columnName) {
      return bidRequest.sort_order === "desc"
        ? "../images/sort-up-icon.svg"
        : "../images/sort-arrow-down.svg";
    }
    return "../images/sort-arrow-down.svg";
  };

  const handleBidPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // This regex will allow numbers including decimal point, but no other characters
    const regex = /^[0-9]*[.]?[0-9]*$/;

    if (regex.test(value) || value === "") {
      setBidPrice(value);
      adjustInputWidth();
    }
  };

  const bidSubmission = async () => {
    const bidPriceInt = parseInt(bidPrice, 10);
    const payload = {
      bid_price: bidPriceInt,
      service_request_uuid:
        serviceRequestDetails?.service_details?.service_request_uuid,
      type: "admin",
      bid_uuid: serviceRequestDetails?.service_details?.bid_uuid,
    };
    setIsLoading(true);
    try {
      const response = await offerBidPrice(payload); // Assuming approveBidApi is the function for making the API call
      if (response.code === 200) {
        setToastMessage("Bid submitted successfully");
        setShowToast(true);
        setIsLoading(false);
        await fetchBids(); // Refresh the bids if needed
        await fetchServiceRequest();
        setTimeout(() => {
          setShowToast(false);
        }, 3000); // Hide toast after 3 seconds
      } else {
        console.error("Error submitting bid:", response.message);
        setToastMessage("Error submitting bid");
        setShowToast(true);
        setIsLoading(false);
        setTimeout(() => setShowToast(false), 3000);
      }
    } catch (error) {
      console.error("Error submitting bid:", error);
      setToastMessage("Error submitting bid");
      setShowToast(true);
      setIsLoading(false);
      setTimeout(() => setShowToast(false), 3000);
    }
  };
  console.log(
    serviceRequestDetails?.bid_history,
    "serviceRequestDetails?.bid_history"
  );


  useEffect(() => {
    adjustInputWidth();
  }, []);

  const hiddenSpanRef: any = useRef(null);

  // Function to adjust input width
  const adjustInputWidth = () => {
    if (hiddenSpanRef.current) {
      return hiddenSpanRef.current.offsetWidth;
    }
    return 0;
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="container-fluid p-sm-4 p-2 pt-md-5 pt-4 mt-md-4">
            <div className="d-flex justify-content-between align-items-start w-100 gap-3 px-4 ">
              <div className="d-flex align-items-start flex-wrap gap-3 justify-content-between w-100">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center gap-3 flex-wrap">
                    <div className="d-flex align-items-center gap-lg-4 gap-2">
                      <button
                        className="icon-round-btn cust-round-width me-1"
                        onClick={() => {
                          if (routedFrom === "/manage-clients-profile") {
                            navigate(-1)
                          } else if (routedFrom === "/feedback") {
                            navigate(-1)
                          } else {
                            navigate("/jobs-and-bids", { state: { tab: tab } });
                          }
                        }}
                      >
                        <img
                          className="icon-sty"
                          src="../images/back-arrow.svg"
                          alt="back-arrow"
                        />
                      </button>
                      <h2 className="color-black font-22 font-semibold mb-0 lh-inherit">
                        {
                          serviceRequestDetails?.service_details
                            ?.service_request
                        }
                      </h2>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <span className="custom-status await-status-legend">
                        <span className="await-status-sub-legend" />
                      </span>
                      <span className="font-15 font-semibold delay-color">
                        {status === 'closed' ? "Closed" : (
                          userRole === "Regional Manager" ?
                            serviceRequestDetails?.service_details?.rm_status === "Awaiting Approval" ? "Waiting for Your Response" :
                              serviceRequestDetails?.service_details?.rm_status === "Open" ? "Open" :
                                serviceRequestDetails?.service_details?.rm_status === "In Progress" ? "In Progress" :
                                  serviceRequestDetails?.service_details?.rm_status === "Closed" ? "Closed" : "" :
                            userRole === "Admin" ?
                              serviceRequestDetails?.service_details?.admin_status === "Open" ? "Open" :
                                serviceRequestDetails?.service_details?.admin_status === "Awaiting Approval" ? "Awaiting Approval" :
                                  serviceRequestDetails?.service_details?.admin_status === "In Progress" ? "In Progress" :
                                    "Closed" : ""
                        )}
                      </span>
                      {serviceRequestDetails?.service_details?.is_extended && <>
                        <span className="custom-status reject-status-legend">
                          <span className="reject-status-sub-legend" />
                        </span>
                        <span className="font-15 font-semibold alert-color">
                          Extended Active Details
                        </span>
                      </>}
                    </div>
                  </div>
                  <span className="font-14 font-medium color-grey-v4 d-flex align-items-center flex-wrap mt-2 cust-ms">
                    {moment(
                      serviceRequestDetails?.service_details?.service_start_date
                    )?.format("ddd, DD MMM YYYY")}{" "}
                    -{" "}
                    {moment(
                      serviceRequestDetails?.service_details?.service_end_date
                    )?.format("ddd, DD MMM YYYY")}{" "}
                    {moment(
                      serviceRequestDetails?.service_details
                        ?.service_start_time,
                      "HH:mm"
                    )?.format("h:mm A")}{" "}
                    -{" "}
                    {moment(
                      serviceRequestDetails?.service_details?.service_end_time,
                      "HH:mm"
                    )?.format("h:mm A")}
                    <span className="cust-dot" />
                    {serviceRequestDetails?.service_details?.service_state}
                    <span className="cust-dot" />
                    {serviceRequestDetails?.service_details?.incident_name}
                  </span>
                </div>
                {(status === "awaiting-approval" || status === "open") && (userRole === "Admin") && (
                  <div className="d-flex cust-ms">
                    <div className="text-nowrap">
                      <span className="font-semibold font-12 color-grey-v3 me-3">
                        Timer
                      </span>
                      <img
                        className="me-1"
                        src="../images/hour-glass.svg"
                        alt="hour-glass"
                      />
                      <span className="font-semibold font-12 color-grey-v3 align-items-center text-nowrap">
                        <CountdownTimer
                          initialSeconds={calculateTimeDifference(
                            serviceRequestDetails?.service_details
                              ?.response_time
                          )}
                        />
                      </span>
                    </div>
                    <button
                      className="btn blue-btn py-1 font-12 font-semibold px-2 ms-4 text-nowrap"
                      onClick={() => {
                        setIsEditTimer(!isEditTimer);
                        setResponseTime(
                          serviceRequestDetails?.service_details?.response_time
                        );
                        setServiceRequestId(
                          serviceRequestDetails?.service_details
                            ?.service_request_uuid
                        );
                      }}
                    >
                      Edit Timer
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="px-md-5 px-sm-3 px-0 mx-3 mx-sm-4">
              {/* tabs */}
              <div className="tab-scroll my-4 px-3">
                {status !== 'closed' && userRole === 'Admin' && serviceRequestDetails?.service_details?.admin_status !== "Open" ? (
                  <ul className="nav nav-pills cust-nav-detail-pills flex-nowrap w-100" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link cust-nav-link active text-nowrap" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
                        Job Info
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link cust-nav-link text-nowrap" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
                        Bid Info
                      </button>
                    </li>
                  </ul>
                ) : null}
              </div>
              <div className="tab-content" id="pills-tabContent">
                {/* 1st tab */}
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  {/* {status === "in-review" ?
                                    getDifferenceInMinutes(serviceRequestDetails?.service_details?.response_time) < 180 ?
                                        <div className="d-flex align-items-center cust-padding gap-2">
                                            <img src="../images/await-time.svg" alt="await-time" />
                                            <span className="font-medium font-14 sucess-color">
                                                {`We're doing our best to provide you with a best Amount. Please wait `}
                                                <Countdown startValue={180 - getDifferenceInMinutes(serviceRequestDetails?.service_details?.response_time)} /> {`minutes.`}
                                            </span>
                                        </div> :
                                        <div className="d-flex align-items-start cust-padding gap-2 delay-msg">
                                            <img className="mt-1" src="../images/delay-time.svg" alt="delay-time" />
                                            <span className="font-medium font-14 ">It’s taking longer than expected.</span>
                                        </div> :
                                    <></>
                                } */}
                  <div className="row mt-2">
                    <div className="col-md-3 p-3 mb-md-4 mb-0">
                      <div className="d-flex flex-column justify-content-between  gap-2">
                        <h4 className="font-14 font-medium color-grey-v4 mb-0">
                          Client DBA Name
                        </h4>
                        <p className="mb-0 color-black font-14 font-medium cust-lh">
                          {serviceRequestDetails?.service_details?.customer_dba}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3 p-3 mb-md-4 mb-0">
                      <div className="d-flex flex-column justify-content-between  gap-2">
                        <h4 className="font-14 font-medium color-grey-v4 mb-0">
                          Job Raised Date
                        </h4>
                        <p className="mb-0 color-black font-14 font-medium cust-lh">
                          {moment(
                            serviceRequestDetails?.service_details?.created_on
                          )?.format("MMM DD, YYYY")}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3 p-3 mb-md-4 mb-0">
                      <div className="d-flex flex-column justify-content-between  gap-2">
                        <h4 className="font-14 font-medium color-grey-v4 mb-0">
                          Location Type
                        </h4>
                        <p className="mb-0 color-black font-14 font-medium cust-lh">
                          {
                            serviceRequestDetails?.service_details
                              ?.location_type
                          }
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3 p-3 mb-md-4 mb-0">
                      <div className="d-flex flex-column justify-content-between  gap-2">
                        <h4 className="font-14 font-medium color-grey-v4 mb-0">
                          Officer Request Type
                        </h4>
                        <p className="mb-0 color-black font-14 font-medium cust-lh">
                          {serviceRequestDetails?.request_types?.map(
                            (value: any) =>
                              value?.request_type_name +
                              "(" +
                              value?.security_people_count +
                              ") "
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3 p-3 mb-md-4 mb-0">
                      <div className="d-flex flex-column justify-content-between  gap-2">
                        <h4 className="font-14 font-medium color-grey-v4 mb-0">
                          Officer Duties Type
                        </h4>
                        <p className="mb-0 color-black font-14 font-medium cust-lh">
                          {serviceRequestDetails?.requested_duties?.map(
                            (value: any) => value?.duty_name
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3 p-3 mb-md-4 mb-0">
                      <div className="d-flex flex-column justify-content-between  gap-2">
                        <h4 className="font-14 font-medium color-grey-v4 mb-0">
                          Service Location
                        </h4>
                        <p className="mb-0 color-black font-14 font-medium cust-lh">
                          {serviceRequestDetails?.service_details
                            ?.address_line_one +
                            ", " +
                            serviceRequestDetails?.service_details
                              ?.address_line_two +
                            ", " +
                            serviceRequestDetails?.service_details
                              ?.service_city +
                            ", " +
                            serviceRequestDetails?.service_details
                              ?.service_state +
                            ", " +
                            serviceRequestDetails?.service_details
                              ?.service_zip_code}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 p-3 mb-0">
                      <div className="d-flex flex-column justify-content-between  gap-2">
                        <h4 className="font-14 font-medium color-grey-v4 mb-0">
                          About Service Request
                        </h4>
                        <p className="mb-0 color-black font-14 font-medium cust-lh">
                          {
                            serviceRequestDetails?.service_details
                              ?.about_service_request
                          }
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 p-3 mb-4">
                      <div className="d-flex flex-column justify-content-between  gap-2">
                        <h4 className="font-14 font-medium color-grey-v4 mb-0">
                          Availed Discounts
                        </h4>
                        <p className="mb-0 color-black font-14 font-medium cust-lh">
                          {serviceRequestDetails?.service_details
                            ?.discount_percentage !== "No"
                            ? `Complete payment in ${serviceRequestDetails?.service_details?.discount_days} Days and get the ${serviceRequestDetails?.service_details?.discount_percentage}% Discount offer`
                            : "No offer applied"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* 2nd tab */}
                {/* common tab */}

                <div
                  className="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  {status !== "awaiting-approval" &&
                    serviceRequestDetails?.bid_history?.map(
                      (value: any, index: number) => (
                        <div className="bid-info-container mt-4">
                          <div className="d-flex align-items-center justify-content-between flex-wrap gap-md-3 gap-4 gap-md-0">
                            <div className="d-block">
                              <h4 className="font-bold font-20 color-black">
                                Bid Accepted
                              </h4>
                              <p className="font-14 font-medium color-grey">
                                A quote amount has been provided for this
                                request:{" "}
                                <span className="font-bold font-20 color-black">
                                  ${state?.amount}
                                </span>
                                /per Hour
                              </p>
                              {/* <div className="d-flex align-items-center flex-wrap gap-3 gap-sm-5">
                                                            <p className="font-14 font-medium color-grey mb-0">
                                                                # of Bids Received:{" "}
                                                                <span className="font-semibold color-grey-v5">11</span>
                                                            </p>
                                                            <p className="font-14 font-medium color-grey mb-0">
                                                                Bid Ranges from:{" "}
                                                                <span className="font-semibold color-grey-v5">$12 - $58</span>
                                                            </p>
                                                        </div> */}
                            </div>
                            <div className="d-flex align-items-start align-items-lg-end flex-column gap-2">
                              <div className="d-flex align-items-center gap-2 cust-indicator font-14 font-semibold bid-accepted">
                                <img
                                  className="tab-icon"
                                  src={` ${status === "in-progress"
                                    ? `../images/accepted-tick.svg`
                                    : `../images/accepted-tick.svg`
                                    }`}
                                  alt="accepted"
                                />
                                {status === "in-progress"
                                  ? "Accepted"
                                  : "Rejected"}
                              </div>
                              <span className="font-12 font-medium color-grey-v3">
                                <img
                                  className="me-1 tab-icon"
                                  src="../images/tooltip-icon.svg"
                                  alt="tooltip-icon"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Tooltip on top"
                                />
                                quoted amount here excludes sales taxes.
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  {/* contractor bids */}
                  {status === "awaiting-approval" &&
                    bidOwner === "Contractor" && (
                      <div className="d-block mt-3">
                        <div className="modal-body px-4 py-2">
                          <div className="row my-2">
                            <div className="col-md-6 mb-4">
                              <div className="position-relative">
                                <label
                                  htmlFor="MarginPercentage"
                                  className="font-semibold font-14 primary-text-color mb-2"
                                >
                                  Margin %
                                </label>
                                <div className="d-flex align-items-center invoice-form-field p-0">
                                  <button className="border-0 h-100 amount-btn margin"></button>
                                  <input
                                    type="text"
                                    id="MarginPercentage"
                                    className="form-control bg-grey color-black-v2 font-14 font-medium invoice-form-field border-0 rounded-0"
                                    value={marginPercentage}
                                    onChange={handleMarginPercentageChange}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6 mb-4">
                              <div className="position-relative">
                                <label
                                  htmlFor="MarginAmount"
                                  className="font-semibold font-14 primary-text-color mb-2"
                                >
                                  Margin Amount (USD)
                                </label>
                                <div className="d-flex align-items-center invoice-form-field p-0">
                                  <button className="border-0 h-100 amount-btn"></button>
                                  <input
                                    type="text"
                                    id="MarginAmount"
                                    className="form-control bg-grey color-black-v2 font-14 font-medium invoice-form-field border-0 rounded-0"
                                    value={marginAmount}
                                    onChange={handleMarginAmountChange}
                                  />
                                </div>
                              </div>
                            </div>

                            <div
                              className="table-responsive theme-table bg-white custom-scroll my-4 w-100 vertical-align-top popup-scroll table-scroll"
                              id="scrollableDiv"
                            >
                              <InfiniteScroll
                                dataLength={bids.length}
                                next={loadMoreData}
                                hasMore={hasMore}
                                loader={<></>}
                                scrollableTarget="scrollableDiv"
                              >
                                <table
                                  className="table table-borderless mb-0 acc-view"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr>
                                      <th
                                        className="text-nowrap text-start"
                                        onClick={() =>
                                          handleSort("contractor_name")
                                        }
                                      >
                                        Contractor
                                        <span className="ms-1 cursor-pointer">
                                          <img
                                            src={getSortIcon("contractor_name")}
                                            alt="sort-icon"
                                          />
                                        </span>
                                      </th>
                                      <th
                                        className="text-nowrap text-start"
                                        onClick={() =>
                                          handleSort("fee_percentage")
                                        }
                                      >
                                        Payment Terms
                                        <span className="ms-1 cursor-pointer">
                                          <img
                                            src={getSortIcon("fee_percentage")}
                                            alt="sort-icon"
                                          />
                                        </span>
                                      </th>
                                      <th
                                        className="text-nowrap text-end"
                                        onClick={() => handleSort("bid_amount")}
                                      >
                                        Bid Amount
                                        <span className="ms-1 cursor-pointer">
                                          <img
                                            src={getSortIcon("bid_amount")}
                                            alt="sort-icon"
                                          />
                                        </span>
                                      </th>
                                      <th
                                        className="text-nowrap text-end"
                                        onClick={() =>
                                          handleSort("total_amount_with_margin")
                                        }
                                      >
                                        Including Margin Value
                                        <span className="ms-1 cursor-pointer">
                                          <img
                                            src={getSortIcon(
                                              "total_amount_with_margin"
                                            )}
                                            alt="sort-icon"
                                          />
                                        </span>
                                      </th>
                                      <th className="text-nowrap text-center">
                                        Actions
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {bids.map((bid: any) => (
                                      <tr key={bid.bid_id}>
                                        <td className="text-start">
                                          <p className="font-13 font-medium color-black mb-2">
                                            {bid.contractor_name}
                                          </p>
                                          <p className="font-11 font-medium primary-text-color-light mb-2">
                                            {bid.contractor_type}
                                          </p>
                                        </td>
                                        <td className="text-start">
                                          <p className="text-wrap font-13 font-medium color-black mb-2">
                                            {bid.fee_percentage}% Discount in
                                            Invoiced amount
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          $
                                          {parseInt(
                                            bid.bid_amount.replace(
                                              /[^0-9.]/g,
                                              ""
                                            ),
                                            10
                                          )}
                                          /hr
                                        </td>
                                        <td className="text-end">
                                          {calculateTotalWithMargin(
                                            bid,
                                            marginPercentage,
                                            marginAmount
                                          )}
                                        </td>

                                        <td className="text-center">
                                          <button
                                            className="btn green-btn px-4 font-12 font-semibold me-2"
                                            onClick={() => handleApprove(bid)}
                                            disabled={isLoading}
                                          >
                                            {isLoading ? (
                                              <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                              ></span>
                                            ) : (
                                              "Approve"
                                            )}
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </InfiniteScroll>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  {/* Admin bids */}

                  {status === "awaiting-approval" && bidOwner === "PPS" && (
                    <div className="d-block mt-3">
                      <h3 className="font-22 color-black font-semibold mb-4">
                        Make an Offer
                      </h3>
                      {/* input field */}
                      <div className="enter-price-field p-3 px-5 d-flex align-items-center flex-column pt-4 justify-content-center">
                        <span className="font-14 font-semibold color-grey-purple text-center">
                          Enter Per Hour Quote Price
                        </span>
                        <div className="d-flex position-relative align-items-center justify-content-center custom-enter-price my-3">
                          <span className="font-32 color-black-v2 secondary-font-medium">
                            $
                          </span>
                          <input
                            type="text"
                            id="service"
                            name="bid_price"
                            className="border-0 d-block text-start bg-transparent font-32 color-black-v2 secondary-font-medium enter-price-input w-100"
                            placeholder="0"
                            value={bidPrice}
                            onChange={handleBidPriceChange}
                            style={{ width: 'auto', minWidth: '100px' }}  // Set minWidth to ensure it's not too small
                          />
                          {/* Hidden span to measure input width */}
                          <span ref={hiddenSpanRef} className="d-none position-absolute">
                            {bidPrice}
                          </span>
                        </div>
                      </div>

                      <button
                        className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4 mt-4"
                        onClick={bidSubmission}
                      >
                        Confirm Bid
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {isEditTimer && (
            <JobsAndBidsTimer
              isEditTimer={isEditTimer}
              setIsEditTimer={setIsEditTimer}
              remainingTimeInSeconds={calculateTimeDifference(responseTime)}
              responseTime={responseTime}
              serviceRequestId={serviceRequestId}
              setShowToast={setShowToast}
              setToastMessage={setToastMessage}
            />
          )}
        </>
      )}
    </>
  );
}

export default ServiceRequestView;