/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import OnboardingContact from "./ProfileInfoComponents/OnboardingContact";
import ServiceProfile from "./ProfileInfoComponents/ServiceProfile";
import {
  APIResponseInterface,
  ContractorProfileInterface,
  ProfileInfoSection,
  RetrieveProfileDataPayloadInterface,
} from "../../Interface/ManageContractor";
import { getUserProfileDataService } from "../../Service/ManageClients/ManageClientsService";
import Personel from "./ProfileInfoComponents/Personel";
import Contacts from "./ProfileInfoComponents/Contacts";
import Branches from "./ProfileInfoComponents/Branches";
import Documents from "./ProfileInfoComponents/Documents";
import Loader from "../Loader";

function Profile(props: { contractorUUID: string, setContractorProfileDataView: React.Dispatch<React.SetStateAction<ContractorProfileInterface>>, setCallProfileAPI: React.Dispatch<React.SetStateAction<string>>, callProfileAPI: string, setIsLoader: React.Dispatch<React.SetStateAction<boolean>>}) {
  // const [retrieveProfileDataPayload, setRetrieveProfileDataPayload] =
  //   useState<RetrieveProfileDataPayloadInterface>(
  //     {} as RetrieveProfileDataPayloadInterface
  //   );
  // const [profileData, setProfileData] = useState<ProfileDataInterface>({} as ProfileDataInterface)
  const [contractorProfileData, setContractorProfileData] =
    useState<ContractorProfileInterface>({} as ContractorProfileInterface);

  const [currentProfileInfo, setCurrentProfileInfo] =
    useState<ProfileInfoSection>(ProfileInfoSection.OnboardingContact);

  const [showDropDown, setShowDropDown] = useState<boolean>(false);

  const [isLoader, setIsLoader] = useState<boolean>();



  // const [customerProfileData, setCustomerProfileData] = useState<CustomerProfileInterface | null>(null);



  useEffect(() => {
    
      fetchUserProfileData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.contractorUUID]);

  useEffect(()=>{
    if (props.callProfileAPI === "call") {
      fetchUserProfileData()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.callProfileAPI])

  const fetchUserProfileData = async () => {
    setIsLoader(true)
    try {
      const response: APIResponseInterface | undefined =
        await getUserProfileDataService({
          type: "contractor",
          profile_uuid: props.contractorUUID,
        } as RetrieveProfileDataPayloadInterface);
        setIsLoader(false)
      if (response !== undefined && response?.status === "Success") {
        // setCustomerProfile(response.data.customer_profile_data);
        // setContractorProfile(response.data.contractor_profile_data);
        if (response?.data?.contractor_profile_data !== null) {
          setContractorProfileData(response?.data?.contractor_profile_data);
          props.setContractorProfileDataView(response?.data?.contractor_profile_data);
        }
      }
    } catch (error) {
      console.log(error);
    } finally{
      setIsLoader(false)
      props.setCallProfileAPI("")
    }
  };


  return  isLoader ? (<Loader/>) : (
    // <div className="container-fluid p-sm-4 p-2 pt-5 mt-4">
      <div
        className="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <div className="row">
          <div className="col-lg-3 col-md-4">
            <div className="onboarding-contact-container pb-1 pt-3 d-md-block d-none">
              <ul
                className="nav nav-pills w-100 d-block onboard-contact-list"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item p-2" role="presentation">
                  <button
                    className={`nav-link dropdown-item py-1 px-3 font-15 bg-transparent ${
                      currentProfileInfo ===
                        ProfileInfoSection.OnboardingContact && "active"
                    }`}
                    id="onboard-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#onboard-contact"
                    type="button"
                    role="tab"
                    onClick={() => {
                      setCurrentProfileInfo(
                        ProfileInfoSection.OnboardingContact
                      );
                    }}
                    aria-controls="onboard-contact"
                    aria-selected="true"
                  >
                    Onboarding Contact
                  </button>
                </li>
                <li className="nav-item p-2" role="presentation">
                  <button
                    className={`nav-link dropdown-item py-1 px-3 font-15 bg-transparent ${
                      currentProfileInfo ===
                        ProfileInfoSection.ServiceProfile && "active"
                    }`}
                    id="service-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#service-profile"
                    type="button"
                    role="tab"
                    onClick={() => {
                      setCurrentProfileInfo(ProfileInfoSection.ServiceProfile);
                    }}
                    aria-controls="service-profile"
                    aria-selected="false"
                  >
                    Service Profile
                  </button>
                </li>
                <li className="nav-item p-2" role="presentation">
                  <button
                    className={`nav-link dropdown-item py-1 px-3 font-15 bg-transparent ${
                      currentProfileInfo === ProfileInfoSection.Personel &&
                      "active"
                    }`}
                    id="personnel-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#personnel"
                    type="button"
                    role="tab"
                    onClick={() => {
                      setCurrentProfileInfo(ProfileInfoSection.Personel);
                    }}
                    aria-controls="personnel"
                    aria-selected="false"
                  >
                    Personnel
                  </button>
                </li>
                <li className="nav-item p-2" role="presentation">
                  <button
                    className={`nav-link dropdown-item py-1 px-3 font-15 bg-transparent ${
                      currentProfileInfo === ProfileInfoSection.Contacts &&
                      "active"
                    }`}
                    id="Contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#Contact"
                    type="button"
                    role="tab"
                    onClick={() => {
                      setCurrentProfileInfo(ProfileInfoSection.Contacts);
                    }}
                    aria-controls="Contact"
                    aria-selected="false"
                  >
                    Contact
                  </button>
                </li>
                <li className="nav-item p-2" role="presentation">
                  <button
                    className={`nav-link dropdown-item py-1 px-3 font-15 bg-transparent ${
                      currentProfileInfo === ProfileInfoSection.Branches &&
                      "active"
                    }`}
                    id="branch-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#branch"
                    type="button"
                    role="tab"
                    onClick={() => {
                      setCurrentProfileInfo(ProfileInfoSection.Branches);
                    }}
                    aria-controls="branch"
                    aria-selected="false"
                  >
                    Branches
                  </button>
                </li>
                <li className="nav-item p-2" role="presentation">
                  <button
                    className={`nav-link dropdown-item py-1 px-3 font-15 bg-transparent ${
                      currentProfileInfo === ProfileInfoSection.Documents &&
                      "active"
                    }`}
                    id="Documents-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#Documents"
                    type="button"
                    role="tab"
                    onClick={() => {
                      setCurrentProfileInfo(ProfileInfoSection.Documents);
                    }}
                    aria-controls="Documents"
                    aria-selected="false"
                  >
                    Documents
                  </button>
                </li>
              </ul>
            </div>
            <div className="dropdown position-relative d-md-none d-block">
              <button
                className={`btn font-15 font-semibold purple-color px-4 py-3 w-100 d-flex align-items-center justify-content-between cust-border rounded-3 ${
                  showDropDown && "show"
                }`}
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                onClick={() => {
                  setShowDropDown(!showDropDown);
                }}
              >
                {currentProfileInfo === ProfileInfoSection.OnboardingContact &&
                  "Onboarding Contact"}
                {currentProfileInfo === ProfileInfoSection.Branches &&
                  "Branches"}
                {currentProfileInfo === ProfileInfoSection.Contacts &&
                  "Contact"}
                {currentProfileInfo === ProfileInfoSection.Documents &&
                  "Documents"}
                {currentProfileInfo === ProfileInfoSection.Personel &&
                  "Personel"}
                {currentProfileInfo === ProfileInfoSection.ServiceProfile &&
                  "Service Profile"}
                <img
                  className="ms-2 icon-sty"
                  src="../images/grey-drp-arrow.svg"
                  alt="grey-drp-arrow"
                />
              </button>
              <ul
                className={`dropdown-menu onboard-contact-list w-100 ${
                  showDropDown && "show"
                }`}
                aria-labelledby="dropdownMenuButton1"
              >
                <li className="p-2">
                  <a
                    className={`dropdown-item font-medium font-14 bg-transparent ${
                      currentProfileInfo ===
                        ProfileInfoSection.OnboardingContact && "active"
                    }`}
                    onClick={() => {
                      setCurrentProfileInfo(
                        ProfileInfoSection.OnboardingContact
                      );
                      setShowDropDown(false);
                    }}
                  >
                    Onboarding Contact
                  </a>
                </li>
                <li className="p-2">
                  <a
                    className={`dropdown-item font-medium font-14 bg-transparent ${
                      currentProfileInfo ===
                        ProfileInfoSection.ServiceProfile && "active"
                    }`}
                    onClick={() => {
                      setCurrentProfileInfo(ProfileInfoSection.ServiceProfile);
                      setShowDropDown(false);
                    }}
                  >
                    Service Profile
                  </a>
                </li>
                <li className="p-2">
                  <a
                    className={`dropdown-item font-medium font-14 bg-transparent ${
                      currentProfileInfo === ProfileInfoSection.Personel &&
                      "active"
                    }`}
                    onClick={() => {
                      setCurrentProfileInfo(ProfileInfoSection.Personel);
                      setShowDropDown(false);
                    }}
                  >
                    Personnel
                  </a>
                </li>
                <li className="p-2">
                  <a
                    className={`dropdown-item font-medium font-14 bg-transparent ${
                      currentProfileInfo === ProfileInfoSection.Contacts &&
                      "active"
                    }`}
                    onClick={() => {
                      setCurrentProfileInfo(ProfileInfoSection.Contacts);
                      setShowDropDown(false);
                    }}
                  >
                    Contacts
                  </a>
                </li>
                <li className="p-2">
                  <a
                    className={`dropdown-item font-medium font-14 bg-transparent ${
                      currentProfileInfo === ProfileInfoSection.Branches &&
                      "active"
                    }`}
                    onClick={() => {
                      setCurrentProfileInfo(ProfileInfoSection.Branches);
                      setShowDropDown(false);
                    }}
                  >
                    Branches
                  </a>
                </li>
                <li className="p-2">
                  <a
                    className={`dropdown-item font-medium font-14 bg-transparent ${
                      currentProfileInfo === ProfileInfoSection.Documents &&
                      "active"
                    }`}
                    onClick={() => {
                      setCurrentProfileInfo(ProfileInfoSection.Documents);
                      setShowDropDown(false);
                    }}
                  >
                    Documents
                  </a>
                </li>
              </ul>
            </div>
            {/* <div class="dropdown position-relative d-md-none d-block">
                      <button
                          class="btn font-15 font-semibold purple-color px-4 py-3 w-100 d-flex align-items-center justify-content-between cust-border rounded-3"
                          type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                          Onboarding Contact
                          <img class="ms-2 icon-sty" src="../images/grey-drp-arrow.svg" alt="grey-drp-arrow">
                      </button>
                      <ul class="dropdown-menu onboard-contact-list w-100" aria-labelledby="dropdownMenuButton1">
                          <li class="p-2"><a class="dropdown-item font-medium font-14 bg-transparent" href="#">
                                  Service Profile</a></li>
                          <li class="p-2"><a class="dropdown-item font-medium font-14 bg-transparent"
                                  href="#">Personnel</a></li>
                          <li class="p-2"><a class="dropdown-item font-medium font-14 bg-transparent"
                                  href="#">Contacts</a></li>
                          <li class="p-2"><a class="dropdown-item font-medium font-14 bg-transparent"
                                  href="#">Branches</a></li>
                          <li class="p-2"><a class="dropdown-item font-medium font-14 bg-transparent"
                                  href="#">Documents</a></li>
                      </ul>
                  </div> */}
          </div>
          <div className="col-lg-9 col-md-8 px-4 mt-5 mt-md-3 ">
            <div className="tab-content px-2" id="innertable-tabContent">
              {/* 1st tab Onboarding Contact*/}
              {currentProfileInfo === ProfileInfoSection.OnboardingContact && (
                <OnboardingContact profileData={contractorProfileData} />
              )}
              {/* 2nd tab */}
              {currentProfileInfo === ProfileInfoSection.ServiceProfile && (
                <ServiceProfile profileData={contractorProfileData} />
              )}
              {/* 3rd tab */}
              {currentProfileInfo === ProfileInfoSection.Personel && (
                <Personel
                  contractorDetails={contractorProfileData?.contractor_details}
                  securityPersonnelInfo={
                    contractorProfileData?.service_mappings
                  }
                  profileData={contractorProfileData}
                />
              )}
              {/* 4th tab */}
              {currentProfileInfo === ProfileInfoSection.Contacts && (
                <Contacts profileData={contractorProfileData} />
              )}
              {/* 5th tab */}
              {currentProfileInfo === ProfileInfoSection.Branches && (
                <Branches profileData={contractorProfileData} />
              )}
              {/* 6th tab */}
              {currentProfileInfo === ProfileInfoSection.Documents && (
                <Documents documents={contractorProfileData?.documents} />
              )}
            </div>
          </div>
        </div>
      </div>
    // </div>
  );
}

export default Profile;
