import {
  ContractorBranchLocationsInterface,
  ContractorProfileInterface,
} from "../../../Interface/ManageContractor";

const Branches = ({
  profileData,
}: {
  profileData: ContractorProfileInterface;
}) => {
  const bindBranchDetails = () => {
    return profileData?.branch_locations?.map(
      (data: ContractorBranchLocationsInterface) => {
        return (
          <div className="row">
            <h3 className="font-16 font-semibold color-black mb-4">
              Branch Details
            </h3>
            <div className="col-sm-6 mb-3 mb-md-5">
              <label className="font-semibold font-15 primary-text-color mb-2">
                Address Line 1
              </label>
              <p className="mb-0 font-15 font-medium primary-text-color">
                {/* 1234 Oak Drive */}
                {data?.address_line_one}
              </p>
            </div>
            <div className="col-sm-6 mb-3 mb-md-5">
              <label className="font-semibold font-15 primary-text-color mb-2">
                Address Line 2
              </label>
              <p className="mb-0 font-15 font-medium primary-text-color">
                {/* 2nd Midway Sides */}
                {data?.address_line_two !== "" ? data?.address_line_two : "-"}
              </p>
            </div>
            {/* <div class="row justify-content-md-between justify-content-center mb-md-5 mb-3"> */}
            <div className="col-md-4 col-sm-6 mb-3 mb-md-5">
              <label className="font-semibold font-15 primary-text-color mb-2">
                ZIP
              </label>
              <p className="mb-0 font-15 font-medium primary-text-color">
                {/* Metropolis */}
                {data?.zip_code}
              </p>
            </div>
            <div className="col-md-4 col-sm-6 mb-3 mb-md-5">
              <label className="font-semibold font-15 primary-text-color mb-2">
                City
              </label>
              <p className="mb-0 font-15 font-medium primary-text-color">
                {/* NY */}
                {data?.city}
              </p>
            </div>
            <div className="col-md-3 col-12 mb-3 mb-md-5">
              <label className="font-semibold font-15 primary-text-color mb-2 text-nowrap">
                State
              </label>
              <p className="mb-0 font-15 font-medium primary-text-color">
                {/* 29920 */}
                {data?.state}
              </p>
            </div>
          </div>
        );
      }
    );
  };

  return (
    <div
      // added - "show active"
      className="tab-pane fade show active"
      id="branch"
      role="tabpanel"
      aria-labelledby="branch-tab"
    >
      <h3 className="font-18 color-black font-semibold mb-4">
        Branch Name/Locations
      </h3>
      <div className="col-12 mb-5">
        <p className="font-14 font-semibold primary-text-color mb-3">
          Does this security company have branch offices?
        </p>
        <p className="font-medium color-black-v1 mb-0 font-14">
          {profileData?.contractor_details?.has_branches?.toLowerCase() === "yes"
            ? "Yes, we have other branch offices"
            : "No, we only have the one address (headquarters)."}
        </p>
      </div>
      {profileData?.branch_locations?.length > 0 && bindBranchDetails()}
    </div>
  );
};

export default Branches;
