import {
  FilterInterface,
  GetClientsGridDataRequestInterface,
  IndustryDataInterface,
  ManageClientsTabName,
} from "../../Interface/ManageClient";

const ClientFilterComponent = (props: {
  handleFilterChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  manageClientsGridFilter: FilterInterface;
  manageClientsGridPayload: GetClientsGridDataRequestInterface;
  applyFilter: () => void;
  clearFilter: () => void;
  setShowFilter: React.Dispatch<React.SetStateAction<boolean>>;
  industryData: IndustryDataInterface[];
  setManageClientsGridFilter: React.Dispatch<React.SetStateAction<FilterInterface>>
}) => {
  return (
    <div
      className="dropdown-menu dd-box type-two-box shadow-sm p-4 border-0 show"
      data-popper-placement="bottom-end"
    >
      <div className="col-md-12">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="font-16 font-semibold color-black mb-0">
            Advanced Filter
          </h5>
          <button
            type="button"
            className="btn-close font-12"
            aria-label="Close"
            onClick={() => {
              props.setShowFilter(false);
              JSON.stringify(props.manageClientsGridFilter) !== JSON.stringify(props.manageClientsGridPayload.filter) && props.setManageClientsGridFilter(props.manageClientsGridPayload.filter)
            }}
          />
        </div>
        <div className="row mt-3">
          <div className="col-md-12 col-sm-12">
            <div className="mb-3">
              <label
                htmlFor="Rating"
                className="form-label color-grey-v1 font-12 font-medium"
              >
                Industry
              </label>
              <select
                name="industry_uuid"
                id="Incident"
                value={props.manageClientsGridFilter.industry_uuid}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  props.handleFilterChange(e);
                }}
                className="w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular bg-transparent"
              >
                {/* needs to bind the industry drop down */}
                <option value={""}>Select</option>
                {props.industryData.map((data) => {
                  return (
                    <option key={data.industry_uuid} value={data.industry_uuid}>
                      {data.industry_name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          {props.manageClientsGridPayload.tab_name ===
            ManageClientsTabName.AllClients && (
            <>
              <div className="col-md-12 col-sm-12">
                <div className="mb-3">
                  <label
                    htmlFor="Rating"
                    className="form-label color-grey-v1 font-12 font-medium"
                  >
                    Source
                  </label>
                  <select
                    name="source"
                    id="Incident"
                    value={props.manageClientsGridFilter.source}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      props.handleFilterChange(e);
                    }}
                    className="w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular bg-transparent"
                  >
                    {/* needs to bind the industry drop down */}
                    <option value="">Select</option>
                    <option key={"PPS"} value="PPS">
                      PPS
                    </option>
                    <option key={"Direct"} value="Direct">
                      Direct
                    </option>
                  </select>
                </div>
              </div>
              <div className="col-md-12 col-sm-12">
                <div className="mb-3">
                  <label
                    htmlFor="Rating"
                    className="form-label color-grey-v1 font-12 font-medium"
                  >
                    Status
                  </label>
                  <select
                    name="status"
                    id="Incident"
                    value={props.manageClientsGridFilter.status}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      props.handleFilterChange(e);
                    }}
                    className="w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular bg-transparent"
                  >
                    <option value={""}>Select</option>
                    <option key={"active"} value="Active">
                      Active
                    </option>
                    <option key={"inactive"} value="Inactive">
                      Inactive
                    </option>
                    <option key={"rejected"} value="Rejected">
                      Rejected
                    </option>
                  </select>
                </div>
              </div>
            </>
          )}
           <h5 className="font-14 mt-3 mb-2 font-semibold color-black mb-0">
            {props.manageClientsGridPayload.tab_name === ManageClientsTabName.AllClients && "Created On"}
            {props.manageClientsGridPayload.tab_name === ManageClientsTabName.NewClientRequests && "Requested On"}
            {props.manageClientsGridPayload.tab_name === ManageClientsTabName.InviteeResponses && "Invited On"}
          </h5>
          <div className="col-md-6 col-sm-12">
            <div className="mb-3">
              <label
                htmlFor="From"
                className="form-label color-grey-v1 font-12 font-medium"
              >
                From
              </label>
              <input
                type="date"
                id="From"
                name="from_date"
                max={props.manageClientsGridFilter.date_range.to_date === "" ? new Date().toISOString().split("T")[0]: props.manageClientsGridFilter.date_range.to_date}
                value={props.manageClientsGridFilter.date_range.from_date}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  props.handleFilterChange(e);
                }}
                className="form-control service-form-field color-black-v2 font-12 font-medium bg-transparent filter-field"
                placeholder="mm/dd/yyyy"
                // defaultValue="11 Sep, 2024"
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="mb-3">
              <label
                htmlFor="To"
                className="form-label color-grey-v1 font-12 font-medium"
              >
                To
              </label>
              <input
                type="date"
                id="To"
                name="to_date"
                max={new Date().toISOString().split("T")[0]}
                min={props.manageClientsGridFilter.date_range.from_date}
                value={props.manageClientsGridFilter.date_range.to_date}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  props.handleFilterChange(e);
                }}
                className="form-control service-form-field color-black-v2 font-12 font-medium bg-transparent filter-field"
                placeholder="mm/dd/yyyy"
                defaultValue={props.manageClientsGridFilter.date_range.to_date}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-4">
          <button
            className="btn px-4 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn"
            onClick={() => {
              props.clearFilter();
            }}
          >
            Cancel
          </button>
          <button
            className="btn px-4 color-white font-semibold font-14 primary-btn px-4 rounded-3"
            onClick={() => {
              props.applyFilter();
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClientFilterComponent;
