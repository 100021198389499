/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  APIResponseInterface,
  CustomerProfileInterface,
  IndustryDataInterface,
  InviteUpdateClientFormDataPayloadInterface,
  RetrieveProfileDataPayloadInterface,
  ToastInterface,
} from "../../Interface/ManageClient";
import {
  getUserProfileDataService,
  postInviteUpdateClientService,
} from "../../Service/ManageClients/ManageClientsService";
import ConfirmationPopUp from "../ConfirmationPopUp";
import Toast from "../Toast";
import InviteClientForm from "./InviteClientForm";
import Loader from "../Loader";
import { encryptStorage, ppsContext } from "../../Configs/Constant";
import JobsAndBids from "../JobsAndBids/JobsAndBids";
import { contextValueInterface } from "../../Interface/HeaderDetails";

const ClientProfileView = () => {
  const contextValue: contextValueInterface = useContext(ppsContext)
  const initialToastDetails: ToastInterface = {
    isToast: false,
    toastDescription: "",
    toastMessage: "",
    toastType: "",
  };

  const initialInviteUpdateFormPayload: InviteUpdateClientFormDataPayloadInterface =
  {
    post_type: "invite",
    customer_uuid: "",
    customer_dba: "",
    last_name: "",
    first_name: "",
    business_email: "",
    industry_uuid: "",
    other_industry: "",
    phone_number: "+1",
    is_mark_active: null,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customerProfileData, setCustomerProfileData] =
    useState<CustomerProfileInterface>({} as CustomerProfileInterface);

  const [customerUUID, setCustomerUUID] = useState<string>("");


  const [showAction, setShowAction] = useState<boolean>(false);

  const [showDeletePopUp, setShowDeletePopUp] = useState<boolean>(false);
  const [toastDetails, setToastDetails] =
    useState<ToastInterface>(initialToastDetails);
  const [showClientForm, setShowClientForm] = useState<boolean>(false);

  const [callGridAPI, setCallGridAPI] = useState<string>("");

  const navigate = useNavigate();
  const locate: {
    state: { customerUUID: string; industryData: IndustryDataInterface[], other_industry: string, tab: string };
  } = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [inviteUpdateClientFormData, setInviteUpdateClientFormData] =
    useState<InviteUpdateClientFormDataPayloadInterface>(
      initialInviteUpdateFormPayload
    );

  const [isLoader, setIsLoader] = useState<boolean>(false);

  useEffect(() => {
    let id = encryptStorage.getItem("manageCustomerProfileID");
    setCustomerUUID(id ? id : "");
    if (locate?.state?.customerUUID || callGridAPI === "call") {
      fetchUserProfileData({
        type: "customer",
        profile_uuid: locate?.state?.customerUUID,
      } as RetrieveProfileDataPayloadInterface);
    }
  }, [locate?.state?.customerUUID, callGridAPI]);

  const fetchUserProfileData = async (
    payload: RetrieveProfileDataPayloadInterface
  ) => {
    setIsLoader(true);
    try {
      const response: APIResponseInterface | undefined =
        await getUserProfileDataService(payload);
      if (response !== undefined && response?.status === "Success") {
        if (
          response?.data?.customer_profile_data !== null &&
          response?.data?.customer_profile_data !== undefined
        ) {
          setCustomerProfileData(response?.data?.customer_profile_data);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoader(false);
      setCallGridAPI("");
    }
  };


  const handleMarkActiveInactive: () => Promise<void> = async () => {
    setIsLoader(true);
    try {
      const response = await postInviteUpdateClientService({
        post_type: "status",
        is_mark_active: !customerProfileData?.is_mark_active,
        customer_uuid: customerProfileData?.customer_uuid,
      } as InviteUpdateClientFormDataPayloadInterface);

      if (response?.status === "Success") {
        if (response?.message === "Client Status updated Successfully") {
          setToastDetails((prevState) => ({
            ...prevState,
            isToast: true,
            toastDescription: response?.message,
            toastMessage: `Client Status Updated`,
            toastType: "Success",
          }));
        }
        setCallGridAPI(() => "call");
      }
    } catch (error) {
      console.error("Error update the status of the client:", error);
    } finally {
      setIsLoader(false);
    }
  };

  const handleStatusPopUp: (confirmation: string) => void = (
    confirmation: string
  ) => {
    if (confirmation === "close" || confirmation === "no") {
      setShowDeletePopUp(false);
    } else if (confirmation === "yes") {
      handleMarkActiveInactive();
      setShowDeletePopUp(false);
    }
  };

  return isLoader ? <Loader /> : (
    <div className="container-fluid p-4 p-2">
      {showDeletePopUp && (
        <ConfirmationPopUp
          handlePopUp={handleStatusPopUp}
          title={`Mark ${customerProfileData?.is_mark_active ? "Inactive" : "Active"
            } the Client`}
          message={`Are you sure want to ${customerProfileData?.is_mark_active
            ? "mark inactive"
            : "mark active"
            } this Client?`}
        />
      )}
      {toastDetails?.isToast && (
        <Toast
          props={{
            isToast: toastDetails?.isToast,
            setToastDetails: setToastDetails,
            toastMessage: toastDetails?.toastMessage,
            toastType: toastDetails?.toastType,
            toastDescription: toastDetails?.toastDescription,
          }}
        />
      )}
      {showClientForm && (
        <InviteClientForm
          setShowClientForm={setShowClientForm}
          setCallGridAPI={setCallGridAPI}
          industryData={locate?.state?.industryData}
          receivedPayload={
            {
              business_email: customerProfileData?.business_email,
              customer_dba: customerProfileData?.customer_dba,
              first_name: customerProfileData?.first_name,
              last_name: customerProfileData?.last_name,
              customer_uuid: customerProfileData?.customer_uuid,
              industry_uuid: customerProfileData?.industry_uuid,
              other_industry: locate.state.other_industry,
              is_mark_active: customerProfileData?.is_mark_active,
              phone_number: customerProfileData?.phone_number,
              post_type: "update",
            } as InviteUpdateClientFormDataPayloadInterface
          }
          setToastDetails={setToastDetails}
          setIsLoader={setIsLoader}
        />
      )}
      <div className="d-flex align-items-center justify-content-between flex-wrap gap-3">
        <div className="d-flex align-items-center gap-sm-4 gap-3 flex-wrap">
          <a
            onClick={() => {
              encryptStorage.removeItem("manageCustomerProfileID");
              navigate("/manage-clients", { state: locate.state.tab });
            }}
            className="icon-round-btn"
          >
            <img
              className="icon-sty"
              src="../images/back-arrow.svg"
              alt="back-arrow"
            />
          </a>
          <div className="d-flex align-items-center gap-3 flex-wrap">
            <h2 className="color-black font-22 font-semibold mb-0">
              {/* Guardian Groups */}
              {customerProfileData?.customer_dba !== ""
                ? customerProfileData?.customer_dba
                : ""}
            </h2>
            <div className="d-flex align-items-center gap-3">
              <div className="d-flex align-items-center gap-2">
                <span className="custom-status active-status-legend">
                  <span className="active-status-sub-legend" />
                </span>
                <span className="font-15 font-semibold active-text">
                  {/* Active */}
                  {customerProfileData?.is_account_activated &&
                    customerProfileData?.is_mark_active
                    ? "Active"
                    : "Inactive"}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="download-option-dropdown position-relative">
          <button
            type="button"
            className="position-relative font-14 color-white text-white shadow-none font-semibold btn border-0 nav-bg text-nowrap py-2 px-3"
            aria-expanded="false"
            onClick={() => {
              setShowAction((prevshowAction) => !prevshowAction);
            }}
          >
            Actions
            <img
              className="drp-icon ms-2"
              src="../images/white-drp-arrow.svg"
              alt="dropdown-arrow"
            />
          </button>
          {showAction && (
            <ul
              className="dropdown-menu custom-drp-menu border-0 cust-min-width show"
              onMouseLeave={() => {
                setShowAction(false);
              }}
            >
              {customerProfileData?.onboarding_status === true &&
                customerProfileData?.is_account_activated && (
                  <li
                    className="font-medium font-16 color-black px-4"
                    onClick={() => {
                      setShowDeletePopUp(true);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {customerProfileData?.is_mark_active && "Mark Inactive"}
                    {!customerProfileData?.is_mark_active && "Mark Active"}
                  </li>
                )}
              {/* {customerProfileData?.onboarding_status === true && (
                <li className="font-medium font-16 color-black px-4" style={{cursor: "pointer"}}>
                 
                </li>
              )} */}
              <li
                className="font-medium font-16 color-black px-4"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowClientForm(true);
                }}
              >
                Edit Profile
              </li>
            </ul>
          )}
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-4 px-4 px-sm-4 py-4">
          <div className="row cust-border-right-2">
            <div className="col-md-6 col-sm-6 mb-4">
              <p className="font-medium font-14 color-grey-v3 mb-1">DBA Name</p>
              <span className="color-black font-14 font-semibold">
                {/* Guardian Groups */}
                {customerProfileData?.customer_dba !== ""
                  ? customerProfileData?.customer_dba
                  : ""}
              </span>
            </div>
            <div className="col-md-6 col-sm-6 mb-4">
              <p className="font-medium font-14 color-grey-v3 mb-1">
                States Operating
              </p>
              <span className="color-black font-14 font-semibold">
                {customerProfileData?.state !== ""
                  ? customerProfileData?.state
                  : ""}
              </span>
            </div>
            <div className="col-md-6 col-sm-6 mb-4">
              <p className="font-medium font-14 color-grey-v3 mb-1">
                First Name
              </p>
              <span className="color-black font-14 font-semibold">
                {/* Guardian Groups */}
                {customerProfileData?.first_name === ""
                  ? "-"
                  : customerProfileData?.first_name}
              </span>
            </div>
            <div className="col-md-6 col-sm-6 mb-4">
              <p className="font-medium font-14 color-grey-v3 mb-1">
                Last Name
              </p>
              <span className="color-black font-14 font-semibold">
                {/* Guardian Groups */}
                {customerProfileData?.last_name === ""
                  ? "-"
                  : customerProfileData?.last_name}
              </span>
            </div>
            <h4 className="font-16 color-black font-semibold mb-4 mt-4">
              Contact Information
            </h4>
            <div className="col-lg-12 col-md-6 mb-4">
              <p className="font-medium font-14 color-grey-v3 mb-1">Email ID</p>
              <a
                href="mailto:sales@premierprotective.com"
                className="link-text font-14 font-semibold text-decoration-none"
              >
                {/* gianaphilips@guardian_grp.com */}
                {customerProfileData?.business_email !== ""
                  ? customerProfileData?.business_email
                  : ""}
              </a>
            </div>
            <div className="col-lg-12 col-md-6 mb-4">
              <p className="font-medium font-14 color-grey-v3 mb-1">
                Mobile Phone #
              </p>
              <a
                href="tel:+1-800-775-0805"
                className="link-text font-14 font-semibold text-decoration-none"
              >
                {customerProfileData?.phone_number}

                {/* {customerProfileData?.phone_number !== ""
                  ? isRegexValidMobile(customerProfileData?.phone_number)
                    ? customerProfileData?.phone_number
                    : usPhoneNumberFormat(customerProfileData?.phone_number)
                  : "-"} */}
              </a>
            </div>
            {contextValue.userDetails.role.toLowerCase() === "admin" &&
              <div className="col-md-12 mb-4">
                <p className="font-medium font-14 color-grey-v3 mb-2">
                  Address Details
                </p>
                <p className="color-black font-14 font-semibold mb-2">
                  {customerProfileData?.address_line_one !== ""
                    ? customerProfileData?.address_line_one
                    : ""}
                  {customerProfileData?.address_line_two !== ""
                    ? `, ${customerProfileData?.address_line_two}`
                    : ""}
                  {customerProfileData?.city !== ""
                    ? `, ${customerProfileData?.city}`
                    : ""}
                  {customerProfileData?.state !== ""
                    ? `, ${customerProfileData?.state}`
                    : ""}
                  {customerProfileData?.zip_code !== ""
                    ? `, ${customerProfileData?.zip_code}`
                    : ""}
                </p>
                {/* <p className="color-black font-14 font-semibold mb-0">
                31 Spring Street, Cleeveland, OH, 20322
              </p> */}
              </div>}
          </div>
        </div>
        <div className="col-lg-8 px-4 px-sm-4">
          <h4 className="font-18 color-black font-semibold">Jobs and Bids</h4>
          <JobsAndBids customerUUID={customerUUID} />
        </div>
      </div>
    </div>
  );
};

export default ClientProfileView;
