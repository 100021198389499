import { client } from "../../Client/Client";
import { manageInvoiceServices } from "../../Configs/BackendRoutes";
import {  backendUrl } from "../../Configs/Constant";
import { APIResponseInterface } from "../../Interface/ManageClient";
import { AcceptOrRejectInvoicePayloadInterface, ManageInvoiceGridPayloadInterface } from "../../Interface/ManageInvoice";


export const fetchManageInvoicesGridDataService = async (payload: ManageInvoiceGridPayloadInterface ) => {
    // PS_09, PS_20, PS_33, PS_44, PS_55

      const config = {
        method: 'POST',
        url: backendUrl + manageInvoiceServices.retrieveInvoiceDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: payload
    };
    const response : APIResponseInterface= await client(config);

    return response;
};



export const postAcceptOrRejectInvoiceServices = async (payload: AcceptOrRejectInvoicePayloadInterface ) => {
    // PS_09, PS_20, PS_33, PS_44, PS_55

      const config = {
        method: 'POST',
        url: backendUrl + manageInvoiceServices.acceptRejectInvoice,
        headers: {
            'Content-Type': 'application/json'
        },
        data: payload
    };
    const response : APIResponseInterface= await client(config);

    return response;
};