/* eslint-disable jsx-a11y/anchor-is-valid */
// feedbackGridComponent.tsx
//PC_03, PC_04, PC_05 Imports
import React, { useState, useEffect } from "react";
import {
  getFeedbackData,
  gridUserDataModel,
  FeedbacksRating,
  APIResponse,
  FlagPayload,
} from "../../Interface/ManageFeedbackGrid";
import {
  getGridData,
  updateContractorFlag,
} from "../../Service/ManageFeedbackGrid/ManageFeedback";
import { useNavigate } from "react-router-dom";
import AdvancedFilter from "../Filters/FilterComponent";
import FlagPopUp from "../PopUps/Popup";
import Toast from "../Toasts/Toast";
import Loader from "../../Components/Loader/LoaderComponent";
import { encryptStorage } from "../../Configs/Constant";

const FeedbackGridComponent: React.FC = () => {
  //PC_06 Extract useNavigate
  const navigate = useNavigate();
  //PC_07 Declare the state variables
  const [gridData, setGridData] = useState<gridUserDataModel[]>([]);
  const [search, setSearch] = useState<string>("");
  const [filterData, setFilterData] = useState({ rating: 0, contractor: "" });
  const [sortData, setSortData] = useState({ column: "", sortBy: "DESC" });
  const [limit, setLimit] = useState<number>(20);
  const [isShowFilterPopup, setIsShowFilterPopup] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [externalContractors, setExternalContractors] = useState<string[]>([]);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [flagPayload, setFlagPayload] = useState<FlagPayload>({
    contractor_uuid: "",
    is_flagged: false,
  });
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    type: "",
    message: "",
    desc: "",
  });
  const [handleFilter, setHandleFilter] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const FeedbackRating: FeedbacksRating[] = [
    {
      Rating: 1,
      Rating_Name: "Very dissatisfied",
      Rating_Image_URL: "images/extremely-bad-emoji.svg",
    },
    {
      Rating: 2,
      Rating_Name: "Dissatisfied",
      Rating_Image_URL: "images/neutral-emoji.svg",
    },
    {
      Rating: 3,
      Rating_Name: "Neutral",
      Rating_Image_URL: "images/not-satisfied-emoji.svg",
    },
    {
      Rating: 4,
      Rating_Name: "Satisfied",
      Rating_Image_URL: "images/satisfied-emoji.svg",
    },
    {
      Rating: 5,
      Rating_Name: "Very Satisfied",
      Rating_Image_URL: "images/extremely-good-emoji.svg",
    },
  ];
  
  //PC_10 Define getAndUpdateGridData
  const getAndUpdateGridData = (): void => {
    const payload: getFeedbackData = {
      sort_column: sortData.column,
      sort_by: sortData.sortBy,
      load_more_count: limit,
      filter_rating: filterData.rating,
      filter_contractor: filterData.contractor,
      search: search,
    };
    try {
      setShowLoader(true);
      getGridData(payload).then((response: APIResponse) => {
        setShowLoader(false);
        setGridData(response?.feedback_requests);
        setTotalCount(response?.feedback_count);

        // Filter for "External Contractor" type and update the dropdown list
        const contractorsList = response?.feedback_requests
          .filter(
            (item) =>
              item.contractor_type.toLowerCase() === "external contractor"
          )
          .map((item) => item.contractor);

        // To ensure we have a unique list of contractors, we can use a Set
        setExternalContractors(Array.from(new Set(contractorsList)));
      });
    } catch (error) {
      setShowLoader(false);
      setToastMessage({
        message: "Issue in fetching feedback details",
        type: "error",
        desc: "",
      });
      setShowToast(true);
    }
  };

  //PC_09 Define useEffect() with getAndUpdateGridData() and dependencies
  useEffect(() => {
    getAndUpdateGridData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortData, limit, handleFilter]);

  //PC_18 capture the input values
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "search") {
      setSearch(value);
    }
  };

  // PC_20 this is to trigger when the filter apply is clicked
  const handleApplyFilter = () => {
    setIsShowFilterPopup(true);
  };

  const ApplyFilterClick = (
    selectedContractor: string,
    selectedRating: number
  ) => {
    const newFilterData = {
      ...filterData,
      contractor: selectedContractor,
      rating: selectedRating,
    };
    setFilterData(newFilterData);
    setIsShowFilterPopup(false);
    setHandleFilter(!handleFilter);
  };
  //PC_19 handle search to get the search results
  const handleSearch = () => {
    getAndUpdateGridData();
  };

  const handleCloseAdvancedFilter = () => {
    // setFilterData({ contractor: "", rating: 0 });
    setIsShowFilterPopup(false);
    // setHandleFilter(!handleFilter);
  };
  const flagClick = (contractorUUID: string, isCurrentlyFlagged: boolean) => {
    if (isCurrentlyFlagged) {
      FlagContractor(contractorUUID, isCurrentlyFlagged);
    } else {
      setFlagPayload({
        contractor_uuid: contractorUUID,
        is_flagged: isCurrentlyFlagged,
      });
      setShowConfirmation(true);
    }
  };

  const handleFlagToggle = async () => {
    setShowConfirmation(false);
    FlagContractor(flagPayload.contractor_uuid, flagPayload.is_flagged);
  };

  const FlagContractor = async (
    contractorUUID: string,
    isCurrentlyFlagged: boolean
  ) => {
    try {
      //PC_56 call updateContractorFlag to flag
      setShowLoader(true);
      const response = await updateContractorFlag({
        contractor_uuid: contractorUUID,
        is_flagged: !isCurrentlyFlagged,
      });
      if (response?.code === 200) {
        let Message = isCurrentlyFlagged
          ? "Successfully UnFlagged Contractor"
          : "Successfully Flagged Contractor";
        setShowLoader(false);
        setToastMessage({ message: Message, type: "Success", desc: "" });
        setShowToast(true);
        setHandleFilter(!handleFilter);
        setHandleFilter(!handleFilter);
      }
    } catch (error) {
      console.log("Error", error);
      setShowLoader(false);
      setToastMessage({
        message: "Issue in Contractor Flag/UnFlag",
        type: "error",
        desc: "",
      });
      setShowToast(true);
    }

    
  }
  function formatDate(dateString: string): string {
    const date = new Date(dateString);

    const formatter = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    const parts = formatter.formatToParts(date);
    const formattedParts: { [key: string]: string } = {};

    parts.forEach((part) => {
      formattedParts[part.type] = part.value;
    });

    return `${formattedParts.month} ${formattedParts.day}, ${formattedParts.year} ${formattedParts.hour}:${formattedParts.minute} ${formattedParts.dayPeriod}`;
  }
  const handleCloseModal = () => {
    setShowConfirmation(false);
  };
  // const triggerToast = (type:string,message: string) => {
  //   setToastMessage({type:'',message:''});
  //   setShowToast(true);
  //   // Optionally, auto-hide the toast after a few seconds
  //   setTimeout(() => setShowToast(false), 3000); // hide after 3 seconds
  // };

  // // Function to handle the closing of the toast
  // const closeToast = () => {
  //   setShowToast(false);
  // };
  // const closeToast = () => {
  //   setShowToast(false);
  //   setHandleFilter(!handleFilter)
  // };
  const handleKeyDown = (e: any) => {
    // Check if the key pressed is 'Enter'
    if (e.key === "Enter" || e.keyCode === 13) {
      // Call the function you want to execute when Enter is pressed
      getAndUpdateGridData();
    }
  };
  //PC_11 To bind the grid data
  const bindGrid = () => {
    if (gridData.length > 0) {
      return gridData.map((item, index) => {
        let emojiObj = FeedbackRating.filter(
          (x) => x.Rating === item.customer_rating
        );

        return (
          <tr key={index}>
            <td className="ps-4">
              <a
                className="link-text text-decoration-none mb-2 d-block cursor-pointer"
                onClick={() =>
                  navigate("/service-request-view", {
                    state: {
                      id: item.service_request_uuid,
                      routedFrom: "/feedback"
                    },
                  })
                }
              >
                {item.job_title}
              </a>
              {(item.customer_rating === 1 || item.customer_rating === 2) &&
              item.feedback_count > 2 ? (
                <span className="alert-color font-13 font-medium d-inline-flex align-items-center gap-2">
                  <img src="../images/alert-icon.svg" alt="" />
                  {`Repeated feedback (${item.feedback_count})`}
                </span>
              ) : null}
            </td>
            <td>{formatDate(item.date_time)}</td>
            <td>
              {item.contractor_type === "External contractor" ? (
                !item.is_flagged ? (
                  <a
                    className="link-text text-decoration-none cursor-pointer"
                    onClick={() => {
                      encryptStorage.setItem(
                        "manageContractorProfileID",
                        item.contractor_uuid
                      );
                      navigate("/manage-contractors-profile", {
                        state: {
                          routedFrom: "/feedback"
                        },
                      });
                    }
                  }
                  >
                    {item.contractor}
                  </a>
                ) : (
                  <span className="font-12 font-medium color-grey-v3 d-block" >
                    {item.contractor}
                  </span>
                )
              ) : (
                <span className="font-13 font-medium color-black-v3 d-block" >
                  {item.contractor}
                </span>
              )}
              <span className="font-12 font-medium color-grey-v3 d-block mt-1">
                {item.contractor_type}
              </span>
            </td>
            <td className="text-center">
              <img
                className="emoji-sty"
                src={emojiObj[0].Rating_Image_URL}
                alt={emojiObj[0].Rating_Name}
                title={emojiObj[0].Rating_Name}
              />
            </td>
            <td>
              <p
                className="text-wrap cust-text-width cust-lh mb-0"
                style={{ cursor: "default" }}
                title={
                  item.detailed_feedback.length > 115
                    ? item.detailed_feedback
                    : undefined
                }
              >
                {item.detailed_feedback.length > 115
                  ? item.detailed_feedback.substring(0, 115) + " ..."
                  : item.detailed_feedback}
              </p>
            </td>
            <td className="text-center">
              <button
                className={`btn flag-unflag ${
                  item.contractor_type === "External contractor"
                    ? "red-btn"
                    : "grey-btn"
                } px-4 font-12 font-semibold`}
                disabled={
                  item.contractor_type.toLowerCase() === "pps contractor"
                }
                onClick={() => flagClick(item.contractor_uuid, item.is_flagged)}
              >
                {item.contractor_type.toLowerCase() === "external contractor"
                  ? item.is_flagged === true
                    ? "UnFlag"
                    : "Flag"
                  : "Flag"}
              </button>
            </td>
          </tr>
        );
      });
    }
  };
  const handleSortClick = (column: string) => {
    // Determine new sort direction
    const sortBy =
      sortData.column === column && sortData.sortBy === "ASC" ? "DESC" : "ASC";

    // Update the sort state
    setSortData({ column, sortBy });
  };
  return (
    <>
      {showLoader ? (
        <Loader />
      ) : (
        <div className="container-fluid p-4 custom-top-padding">
          {/* Manage Feedback Title */}
          <div className="d-flex align-items-center justify-content-between flex-wrap gap-2">
            <h2 className="color-black font-20 font-bold mb-0">
              Manage Feedback
            </h2>
            <div className="d-flex align-items-center justify-content-md-center gap-3 flex-wrap mt-2 mt-md-0">
              {/* search */}
              <div className="position-relative  ">
                <input
                  type="search"
                  name="search"
                  // className=" bg-transparent custom-search custom-input-search font-14 font-medium color-black"
                className="custom-input-search font-14 font-medium color-black custom-search"

                  placeholder="Search here"
                  value={search}
                  onKeyDown={handleKeyDown}
                  onChange={handleInputChange}
                />
                <button
                  className="btn bg-transparent border-0 focus-none search-icon"
                  onClick={handleSearch}
                >
                  <img
                    className="tab-icon"
                    src="../images/search-icon.svg"
                    alt="search"
                    onClick={() => {
                      setSearch("");
                    }}
                  />
                </button>
              </div>
              <button
                className="btn common-btn px-3 show"
                aria-expanded="true"
                data-bs-auto-close="outside"
                onClick={handleApplyFilter}
              >
                <img src="../images/filter-icons.svg" alt="filter-icons" />
              </button>

              {isShowFilterPopup && (
                <AdvancedFilter
                  contractorOptions={externalContractors}
                  ratingOptions={FeedbackRating}
                  onApply={ApplyFilterClick}
                  onCancel={handleCloseAdvancedFilter}
                  filter={filterData}
                  type="Feedback"
                />
              )}
            </div>
          </div>

          {/* Your Table */}
          {totalCount > 0 ? (
            <div className="table-responsive theme-table bg-white custom-scroll my-4 w-100 vertical-align-top">
              <table className="table table-borderless mb-0 acc-view">
                {/* Table Head */}
                <thead>
                  <tr>
                    <th className="ps-4 text-nowrap cursor-pointer" onClick={() => {
                            handleSortClick("srd.service_request");
                          }}>
                      Job Title
                      <span className="ms-1 cursor-pointer">
                        <img
                          src={
                            sortData.column === "srd.service_request"
                              ? sortData.sortBy === "DESC"
                                ? "../images/sort-up-icon.svg"
                                : "../images/sort-arrow-down.svg"
                              : "../images/sort-up-icon.svg"
                          }
                          alt="sort-arrow-down"
                        />
                      </span>
                    </th>
                    <th className="ps-4 text-nowrap cursor-pointer" onClick={() => {
                            handleSortClick("fd.created_on");
                          }}>
                      Date &amp; Time
                      <span className="ms-1 cursor-pointer">
                        <img
                          src={
                            sortData.column === "fd.created_on"
                              ? sortData.sortBy === "DESC"
                                ? "../images/sort-up-icon.svg"
                                : "../images/sort-arrow-down.svg"
                              : "../images/sort-up-icon.svg"
                          }
                          alt="sort-arrow-down"
                        />
                      </span>
                    </th>
                    <th className="text-nowrap">Contractor</th>
                    <th className="text-nowrap text-center w-25">
                      Feedback Rated
                    </th>
                    <th className=" text-nowrap">Detailed Feedback</th>
                    <th className=" text-nowrap text-center">Actions</th>
                  </tr>
                </thead>
                {/* Table Body */}
                <tbody>{bindGrid()}</tbody>
              </table>
            </div>
          ) : (
            <div className="d-flex align-items-center justify-content-center p-5 flex-column">
              <img
                className="no-record-img"
                src="../images/no-record.gif"
                alt="No record"
              />
              {/* <img src="../images/no-record.svg" alt="no-record"> */}
              <p className="font-medium font-20 color-grey-v1 mb-0 mt-3 text-center">
                It looks like there are currently no feedback entries to display
              </p>
            </div>
          )}

          {/* Pagination/Footer */}
          {totalCount > 0 ? (
            <p className="font-14 font-medium color-grey-v7">
              Showing{" "}
              <span className="font-semibold color-black">
                {String(gridData.length).padStart(2, "0")}
              </span>{" "}
              out of{" "}
              <span className="font-semibold color-black">
                {String(totalCount).padStart(2, "0")}
              </span>{" "}
              {totalCount > 1 ? "Items" : "Item"}
              {gridData.length < totalCount &&
        gridData.length >= 20 &&  (
                <div className="d-flex align-items-center justify-content-center">
                  <button
                    className="btn px-4 color-white font-semibold font-14 primary-btn px-4 rounded-3 text-center"
                    disabled={gridData.length === totalCount}
                    onClick={() => {
                      setLimit(limit + 20);
                    }}
                  >
                    Load More
                  </button>
                </div>
              ) }
            </p>
          ) : null}
          {showConfirmation && (
            <FlagPopUp
              showModal={true}
              onConfirmFlag={handleFlagToggle}
              onClose={handleCloseModal}
              type="Feedback"
            />
          )}
          {showConfirmation && <div className="modal-backdrop fade show"></div>}
          {showToast && (
            <Toast
              setToastDetails={setShowToast}
              toastType={toastMessage.type === "Success" ? "Success" : "error"}
              toastMessage={toastMessage.message}
              toastDescription={toastMessage.desc}
              // You can dynamically set this based on the situation
            />
          )}
        </div>
      )}
    </>
  );
};

export default FeedbackGridComponent;
