import { EncryptStorage } from "encrypt-storage";
// import CryptoJS from 'crypto-js';
import { createContext } from "react";
import AWS from 'aws-sdk';
import { ProfileAcknowledgmentsInterface } from "../Interface/ManageContractor";

export const encryptStorage = new EncryptStorage(
  "jhvklrgdjldfflsdbfajnlksvdbsfalksdbsglvkfbdvnsalsdlnsvassvjan",
  {
    stateManagementUse: true,
    storageType: "localStorage",
  }
)


export const calculateDaysLeft = (expiryDate: string): number => {
  const currentDate = new Date();
  const expiry = new Date(expiryDate);
  const timeDiff = expiry.getTime() - currentDate.getTime();
  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

  return daysDiff;
};



export function getDifferenceInMinutes(date1: string): number {
  // Parse the dates
  const d1 = new Date(new Date().toISOString());
  const d2 = new Date(date1);

  // Get the difference in milliseconds
  const diffInMs = Math.abs(d1.getTime() - d2.getTime());

  // Convert difference from milliseconds to minutes
  const diffInMinutes = Math.floor(diffInMs / (1000 * 60));

  return diffInMinutes;
}

export const ppsContext: any = createContext("");
export const HeaderContext: any = createContext(null);

export const backendUrl = "https://osx1rtyrih.execute-api.us-east-2.amazonaws.com/prod";
export const AIendpoint = 'https://osx1rtyrih.execute-api.us-east-2.amazonaws.com/prod/extract-details'

export const equipmentCorrection = {
  websocket: "/v1/api/pps-notification/subscribe",
}


export const padZero = (number: number) => {
  return number < 10 ? '0' + number : number.toString();
};

export function formatPhoneNumber(phoneNumber: string): string | undefined {
  // Ensure the input is exactly 9 digits
  try {
    if (phoneNumber.length !== 10 || !/^\d+$/.test(phoneNumber)) {
      // throw new Error("Input must be a 10-digit string.");
    }

    // Extract parts of the phone number
    const areaCode = phoneNumber.slice(0, 3);
    const firstPart = phoneNumber.slice(3, 6);
    const secondPart = phoneNumber.slice(6, 10);

    // Format the phone number
    return `(${areaCode}) ${firstPart}-${secondPart}`;
  } catch (error) {
    console.log(error, "Error in showing Phone Number in Profile");
  }
}

export function formatDate(dateString: string) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(dateString); // Parse the date string into a Date object
  const year = date.getFullYear();

  // Get the month name by index
  const monthIndex = date.getMonth();
  const monthName = months[monthIndex];

  const day = date.getDate().toString().padStart(2, "0");

  return `${monthName} ${day}, ${year}`;
}

export const textRegexFormat: RegExp = /^[a-zA-Z]+$/;
// export const emailRegexFormat: RegExp =
//   /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const contactRegexFormat: RegExp = /^\+1 \(\d{3}\) \d{3}-\d{4}$/;

export const validateEmail = (email: string) => {
  // const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const emailRegex = /^[a-zA-Z0-9.]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,}$/;
  const excludedDomains = [
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "outlook.com",
    "aol.com",
  ];
  const domain = email.split("@")[1]; // Get the domain of the email

  return emailRegex.test(email) && !excludedDomains.includes(domain);
};

export function usPhoneNumberFormat(phoneNumber: string) {
  // Remove non-numeric characters
  const cleaned = phoneNumber?.replace(/\D/g, '');

  // Ensure the cleaned number has exactly 10 digits
  if (cleaned?.length !== 10) {
    return '-';
  }

  // Extract area code, first 3 digits, and last 4 digits
  const match = cleaned?.match(/(\d{3})(\d{3})(\d{4})/);

  if (match) {
    return `+1 (${match[1]}) ${match[2]}-${match[3]}`;
  }

  return '-';
}


export function daysUntilExpiry(expiryDateStr: string): number {
  // Parse the expiry date string
  // const [day, month, year] = expiryDateStr.split('-').map(Number);
  const [year, month, day] = expiryDateStr.split('-').map(Number);

  const expiryDate = new Date(year, month - 1, day); // month is 0-indexed in JS Date

  // Get today's date
  const today = new Date();

  // Reset the time part of the dates to ensure we're only comparing dates
  expiryDate.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);

  // Calculate the difference between the dates
  const diffTime = expiryDate.getTime() - today.getTime();
  const daysLeft = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return daysLeft;
}


export function convertStringToObject(inputString: string): ProfileAcknowledgmentsInterface {
  // Split the string by commas to get key-value pairs
  const pairs = inputString?.split(',');

  // Initialize an empty object with the specified interface type
  const result: ProfileAcknowledgmentsInterface = {
    subcontracting: false,
    drugScreen: false,
    randomDrugScreening: false,
    backgroundCheck: false
  };

  // Iterate over each pair and split by colon to get key and value
  pairs.forEach(pair => {
    const [key, value] = pair?.split(':');
    // Convert value to boolean and add to object
    result[key as keyof ProfileAcknowledgmentsInterface] = value?.toLowerCase() === 'true';
  });

  return result;
}


export function extractWebsiteName(url: string): string {
  // Regular expression to match the domain name
  // eslint-disable-next-line no-useless-escape
  const domainRegex = /^(?:https?:\/\/)?(?:www\.)?([^\/]+)/i;

  // Try to match the domain
  const match = url?.match(domainRegex);

  if (match && match[1]) {
    // Extract the domain name
    let domain = match[1];

    // Remove TLD (Top Level Domain) if present
    domain = domain.split('.').slice(0, -1).join('.');

    // Handle common social media sites
    switch (domain?.toLowerCase()) {
      case 'facebook':
      case 'fb':
        return 'Facebook';
      case 'instagram':
        return 'Instagram';
      case 'twitter':
        return 'Twitter';
      case 'linkedin':
        return 'LinkedIn';
      case 'youtube':
        return 'YouTube';
      default:
        // Capitalize the first letter of each word for other websites
        return domain.split('.').map(word =>
          word.charAt(0).toUpperCase() + word.slice(1)
        ).join(' ');
    }
  }

  // Return null or a default value if no match is found
  return 'Link';
}

export const isRegexValidText: (value: string) => boolean = (value: string) => {
  return textRegexFormat.test(value);
};

// export const isRegexValidEmail: (value: string) => boolean = (
//   value: string
// ) => {
//   return emailRegexFormat.test(value);
// };

export const isRegexValidMobile: (value: string) => boolean = (
  value: string
) => {
  return contactRegexFormat.test(value);
};

export const formatDollars = (amount: string) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })
    .format(parseFloat(amount))
    .replace("$", "$ ");
};

export const formatPhoneNumberOnChange = (phoneNumber: string): string => {
  if (!phoneNumber) return "";

  // Check if the number starts with '+1' and remove it
  let cleaned = phoneNumber.startsWith("")
    ? phoneNumber.slice(2).replace(/\D/g, "")
    : phoneNumber.replace(/\D/g, "");

  // Limit to 10 digits
  cleaned = cleaned.slice(0, 10);

  // Match the digits into groups
  const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

  if (match) {
    const [, areaCode, middle, last] = match;
    let formatted = "+1 ";
    if (areaCode) formatted += `(${areaCode}`;
    if (middle) formatted += `) ${middle}`;
    if (last) formatted += `-${last}`;
    return formatted;
  }

  // If no match (shouldn't happen with our cleaning), return the cleaned input
  return cleaned;
};

// export const formatPhoneNumberOnChange = (phoneNumber: string): string => {
//   if (!phoneNumber) return '';

//   // Remove all non-digit characters
//   let cleaned = phoneNumber.replace(/\D/g, '');

//   // Limit to 10 digits
//   cleaned = cleaned.slice(0, 10);

//   // Match the digits into groups
//   const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

//   if (match) {
//     const [, areaCode, middle, last] = match;
//     let formatted = "";
//     if (areaCode) formatted += `(${areaCode}`;
//     if (middle) formatted += `) ${middle}`;
//     if (last) formatted += `-${last}`;
//     return formatted.trim();
//   }

//   // If no match (shouldn't happen with our cleaning), return the cleaned input
//   return cleaned;
// };


// export const generateSecretHash = (username: string, clientId: string, clientSecret: string) => {
//     return CryptoJS.HmacSHA256(username + clientId, clientSecret).toString(CryptoJS.enc.Base64);
// }

// // dev
// export const PoolID = 'us-east-2_bxASDPt57'
// export const ClientID = '1vc83uecbohp8rk37ctr7ainl1'

// stage
// export const PoolID = 'us-east-2_bxASDPt57'
// export const ClientID = '2pb4jr19ic1esim4olqdap1r0p'

// prod
export const PoolID = 'us-east-2_kdQfGF8Oo'
export const ClientID = '48410r4g3fk0h538noe0c71ot5'

// export const PoolID = 'us-east-2_bxASDPt57'
// export const ClientID = '3ab5a7iso3ssoofdrign7finm2'
export const Region = "us-east-2"

// export const generateSecretHash = (username: string, clientId: string, clientSecret: string) => {
//     return CryptoJS.HmacSHA256(username + clientId, clientSecret).toString(CryptoJS.enc.Base64);
// }

const credentials = new AWS.Credentials({
  accessKeyId: "AKIA46ZDE4MRQT5WSHVJ",
  secretAccessKey: "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"
});
AWS.config.update({
  region: "us-east-2",
  credentials,
});

const S3 = new AWS.S3();

export const RegexValidation = {
  contactRegexFormat: /^(\d{0,3})(\d{0,3})(\d{0,4})$/,
  emailRegex: /^[a-zA-Z0-9._%+-]+@(?!gmail\.com|yahoo\.com|hotmail\.com|outlook\.com|aol\.com)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
}


export async function uploadToS3(path: string, file: File): Promise<string> {
  const currentDate = new Date().toString();
  // const bucketName = "pps-private-bucket-dev/PPS-Documents-DEV"
  const bucketName = "pps-prod-eu2-privates3bucket/PPS-Documents-PROD"
  const params = {
    Bucket: bucketName,
    Key: path + currentDate,
    Body: file,
    ContentType: file.type,
  };

  try {
    const response = await S3.upload(params).promise();

    return response.Location;
  } catch (error) {
    console.log('Error uploading file to S3:', error);
    return "failed to upload"
  }
}

export async function DownloadS3File(fileUrl: string) {
  try {

    // Parse the URL to extract bucket and key
    const url = new URL(fileUrl);
    const bucketName = url.hostname.split('.')[0];
    const fileName = decodeURIComponent(url.pathname.slice(1)); // Remove leading '/'

    // Generate pre-signed URL
    const params = {
      Bucket: bucketName,
      Key: fileName,
      Expires: 432000, // URL expiration time in seconds
    };

    const signedUrl = await S3.getSignedUrl('getObject', params);

    // Download the file
    const response = await fetch(signedUrl);
    //   const blob = await response.blob();

    // Create a temporary URL for the blob
    //   const downloadUrl = window.URL.createObjectURL(blob);
    return response

    // Create a temporary anchor element and trigger the download
    //   const link = document.createElement('a');
    //   link.href = downloadUrl;
    //   link.download = fileName.split('/').pop() || 'download';
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    //   // Revoke the temporary URL
    //   window.URL.revokeObjectURL(downloadUrl);

  } catch (err) {

    console.error('Error downloading file:', err);
  }

};




export async function invoiceDownloadS3File(fileUrl: string) {
  try {


    // Parse the URL to extract bucket and key
    const url = new URL(fileUrl);
    const bucketName = url.hostname.split('.')[0];
    const fileName = decodeURIComponent(url.pathname.slice(1)); // Remove leading '/'





    // Generate pre-signed URL
    const params = {
      Bucket: bucketName,
      Key: fileName,
      Expires: 60, // URL expiration time in seconds
    };

    const signedUrl = await S3.getSignedUrlPromise('getObject', params);

    // Download the file
    const response = await fetch(signedUrl);
    const blob = await response.blob();

    // Create a temporary URL for the blob
    const downloadUrl = window.URL.createObjectURL(blob);

    // Create a temporary anchor element and trigger the download
    window.open(downloadUrl, '_blank');

  } catch (err) {

    console.error('Error downloading file:', err);
  }

};

export const ConvertSecondsToHHMMSS = (totalSeconds: number) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const secs = totalSeconds % 60;
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
};

export const phoneNumberRegex = /^\+1 \(\d{3}\) \d{3}-\d{4}$/;
export const emailRegex = /^[a-zA-Z0-9._%+-]+@(?!gmail\.com|yahoo\.com|hotmail\.com|outlook\.com|aol\.com)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const formatPhoneNumberEditContractor = (phoneNumber: string): string => {
  if (!phoneNumber) return '';

  let cleaned = phoneNumber.startsWith('+1')
      ? phoneNumber.slice(2).replace(/\D/g, '')
      : phoneNumber.replace(/\D/g, '');

  cleaned = cleaned.slice(0, 10);
  if (cleaned === "") {
      return cleaned
  }

  const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

  if (match) {
      const [, areaCode, middle, last] = match;
      let formatted = "+1 ";
      if (areaCode) formatted += `(${areaCode}`;
      if (middle) formatted += `) ${middle}`;
      if (last) formatted += `-${last}`;
      return formatted;
  }

  return cleaned;
};