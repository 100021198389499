//endpoints Interface
export interface ManageClientsServicesInterface {
  retrieveClientDetails: string;
  approveRejectClient: string;
  inviteUpdateClient: string;
  retrieveProfileData: string;
}

//Approve Reject client 

export interface ApproveClientRequestInterface {
  customer_uuid: string;
  is_approved: boolean;
}


//Manage Clients Grid
export interface GetClientsGridDataRequestInterface {
  search: string;
  filter: FilterInterface;
  sort: SortInterface;
  load_more: number;
  tab_name: string; // validate:"required,oneof=ALL_CLIENTS NEW_CLIENT_REQUESTS INVITEE_RESPONSES"
}

export interface sortOrderInterfaceType {
  customer_dba: boolean;
  first_name: boolean;
  referred_by: boolean;
  industry_name: boolean;
  region: boolean;
  created_on: boolean;
  source: boolean;
  service_request_count: boolean;
  business_email: boolean;
}

      

export interface FilterInterface {
  industry_uuid: string;
  source: string; // validate:"omitempty,oneof=PPS Direct"
  date_range: DateRangeInterface;
  status: string // validate:"omitempty,oneof=Active Inactive Accepted"
}

export interface DateRangeInterface {
  from_date: string;
  to_date: string;
}



export interface SortInterface {
  column: string; // validate:"required,oneof=customer_dba first_name industry_name region created_on source service_request_count"
  order: string; // validate:"required,oneof=asc desc"
}

export interface APIResponseInterface{
  status: "Success" | "Error";
  code: number;
  message: string;
  data?: any;
  errors?: ErrorInterface[];
  meta?: MetaInterface;
  request_id: string;
  timestamp: string;
}

export interface ErrorInterface {
  field?: string; // Optional because of 'omitempty'
  message: string; // Descriptive error message
  code: string; // Custom error code
}

export enum ManageClientsTabName {
  NewClientRequests = "NEW_CLIENT_REQUESTS",
  AllClients = "ALL_CLIENTS",
  InviteeResponses = "INVITEE_RESPONSES",
}

export interface ManageClientsDataInterface {
  grid_data: ClientGridDataInterface[];
  industry_data: IndustryDataInterface[];
}

export interface IndustryDataInterface {
  industry_uuid: string;
  industry_name: string;
}

export interface MetaInterface {
  total_count: number; // Total number of items
}

export interface ClientGridDataInterface {
  customer_uuid: string;
  customer_dba: string;
  first_name: string;
  last_name: string;
  referred_by: string;
  admin_uuid: string;
  other_referrel: string;
  industry_uuid: string;
  industry_name: string;
  other_industry: string;
  business_email: string;
  phone_number: string;
  address_line_one: string;
  address_line_two: string;
  zip_code: string;
  city: string;
  state: string;
  region: string;
  onboarding_status: boolean;
  created_on: string;
  is_admin_invited: boolean;
  source: string
  is_account_activated: boolean;
  is_account_requested: boolean;
  IsMarkActive: boolean;
  service_request_count: number;
  is_active: boolean;
}



//Post - invite/update/delete/status change client

export interface InviteUpdateClientFormDataPayloadInterface {
  post_type: string; // validate:"required,oneof=invite update delete status"
  customer_uuid: string;
  last_name: string;
  first_name: string;
  customer_dba: string;
  industry_uuid: string;
  other_industry: string;
  business_email: string;
  phone_number: string;
  is_mark_active: boolean | null;
}

export interface ValidationErrorsInterfaceType{
  // customer_name: string;
  // customer_dba: string;
  // industry_uuid: string;
  business_email: string;
  phone_number: string;
}

export interface ToastInterface {
  isToast: boolean;
  toastMessage: string;
  toastDescription: string;
  toastType: string;
}

export interface PopUpInterface {
  showPopUp: boolean;
  popUpDescription: string;
  popUpMessage: string;
  popUptype: string;
}

export interface RetrieveProfileDataPayloadInterface {
  type: "customer" | "contractor"; // "required,alpha"
  profile_uuid: string; // "required"
}


export interface CustomerProfileInterface {
  customer_uuid: string;
  first_name: string;
  last_name: string;
  customer_dba: string;
  industry_uuid: string;
  industry_name: string;
  other_industry: string;
  business_email: string;
  phone_number: string;
  address_line_one: string;
  address_line_two: string;
  zip_code: string;
  city: string;
  state: string;
  region: string;
  region_id: string;
  created_on: string;
  is_mark_active: boolean;
  is_account_activated: boolean;
  onboarding_status?: boolean;
  is_admin_invited: boolean;
  is_account_requested: boolean;
}