import { client } from '../../Client/Client';
import { getHeaderDetails,SignUpEndpoint} from '../../Configs/BackendRoutes';
import { backendUrl as URL  } from '../../Configs/Constant';

export const headerDetailsGet = async () => {
    const config = {
        method: 'GET',
        url: URL + getHeaderDetails.getHeaderDet,
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const response = await client(config);
    return response;
};
//PC_40 Format API request body with single notification UUID
export const readNotifications = async (data: any) => {
    const config = {
        method: 'POST',
        url: URL + getHeaderDetails.readNotifications,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    };
    const response = await client(config);
    return response;
};
export const getRegionData = async () => {
    const config = {
        method: 'GET',
        url: URL + SignUpEndpoint.GetRegion,
        headers: {
            'Content-Type': 'application/json'
        },
        data: null
    };
    const response = await client(config);
    return response;
};