
// import client from "../../Client/Client";
// import { EditResponse } from "../../Interface/EditSignUp";

import { client } from "../../../Client/Client";
import { manageClientServices, SignUpEndpoint } from "../../../Configs/BackendRoutes";
import { AIendpoint, backendUrl } from "../../../Configs/Constant";

export const getDynamicDropdownData = async () => {
    const config = {
        method: 'GET',
        url: backendUrl + SignUpEndpoint.GetDropdown,
        headers: {
            'Content-Type': 'application/json'
        },
        data: null
    };
    const response = await client(config);
    return response;
};

export const getRegionData = async () => {
    const config = {
        method: 'GET',
        url: backendUrl + SignUpEndpoint.GetRegion,
        headers: {
            'Content-Type': 'application/json'
        },
        data: null
    };
    const response = await client(config);
    return response;
};

export const SaveSignUp = async (payload: any) => {
    const config = {
        method: 'POST',
        url: backendUrl + SignUpEndpoint.SignUp,
        // url: URL + Endpoints.SignUp,
        headers: {
            'Content-Type': 'application/json'
        },
        data: payload
    };
    const response = await client(config);
    return response;
};

export const getContractorDetailsById = async (payload: any) => {
    const config = {
        method: 'POST',
        // url: URL + "",
        url: backendUrl + manageClientServices.retrieveProfileData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: payload
    };
    const response = await client(config);
    return response;
};

export const AIVerifyDoc = async (payload: any) => {
    const config = {
        method: 'POST',
        url: AIendpoint,
        headers: {
            'Content-Type': 'application/json'
        },
        data: payload
    };

    const response = await client(config);


    return response;
};
