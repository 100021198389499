/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import {
  AcceptOrRejectInvoicePayloadInterface,
  InvoiceFilterObjectInterface,
  InvoiceGridDataInterface,
  InvoiceStatusDataInterface,
  ManageInvoiceGridPayloadInterface,
  sortOrderInterfaceType,
} from "../../Interface/ManageInvoice";
import {
  fetchManageInvoicesGridDataService,
  postAcceptOrRejectInvoiceServices,
} from "../../Service/ManageInvoices/ManageInvoicesService";
import InvoiceFilterComponent from "./InvoiceFilterComponent";
import { encryptStorage, formatDate, formatDollars, invoiceDownloadS3File } from "../../Configs/Constant";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import { ToastInterface } from "../../Interface/ManageClient";
import Toast from "../Toast";

const ManageInvoices = (props?: { contractorUUID?: string}) => {
  const initialGridPayload: ManageInvoiceGridPayloadInterface = {
    contractor_uuid: props?.contractorUUID ==="" ? "": props?.contractorUUID,
    filter: {
      invoice_amount: "",
      invoice_date: {
        from_date: "",
        to_date: "",
      },
      invoice_due_date: {
        from_date: "",
        to_date: "",
      },
      invoice_status_uuid: "",
    },
    load_more: 20,
    search: "",
    sort: {
      column: "created_on",
      order: "desc",
    },
  };
  const initialSortOrder: sortOrderInterfaceType = {
    dba_business_name: false,
    invoice_amount: false,
    invoice_date: false,
    invoice_due_date: false,
    invoice_name: false,
    invoice_status_name: false,
  };

  const initialAcceptOrRejectPayload: AcceptOrRejectInvoicePayloadInterface = {
    action: "",
    invoice_uuid: "",
  };

  const initialToastDetails : ToastInterface ={
    isToast: false,
    toastDescription: "",
    toastMessage: "",
    toastType: "",
  }
  const navigate = useNavigate()
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [manageInvoiceGridData, setManageInvoiceGridData] = useState<
    InvoiceGridDataInterface[]
  >([] as InvoiceGridDataInterface[]);
  const [showConfirmPopUp, setShowConfirmPopUp] = useState<boolean>(false);
  const [manageInvoiceGridPayload, setManageInvoiceGridPayload] =
    useState<ManageInvoiceGridPayloadInterface>(initialGridPayload);
  const [invoiceStatusData, setInvoiceStatusData] = useState<
    InvoiceStatusDataInterface[]
  >([] as InvoiceStatusDataInterface[]);

  const [sortOrder, setSortOrder] =
    useState<sortOrderInterfaceType>(initialSortOrder);
    

  const [toastDetails, setToastDetails] = useState<ToastInterface>(initialToastDetails)


  const [searchTerm, setSearchTerm] = useState("");

  const [totalCount, setTotalCount] = useState<number>(0);

  const [manageInvoiceGridFilter, setManageInvoiceGridFilter] =
    useState<InvoiceFilterObjectInterface>(initialGridPayload.filter);

  const [callGridAPI, setCallGridAPI] = useState<string>("");

  const [acceptOrRejectInvoicePayload, setAcceptOrRejectInvoicePayload] =
    useState<AcceptOrRejectInvoicePayloadInterface>(
      initialAcceptOrRejectPayload
    );
  const [isLoader, setIsLoader] = useState<boolean>(false)
  


  useEffect(() => {
    fetchManageInvoicesGridData();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageInvoiceGridPayload]);

  useEffect(() => {
    if (callGridAPI === "call") {
      fetchManageInvoicesGridData();
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callGridAPI]);

  // PS_07: Call fetchManageInvoicesGridData() inside useEffect
  const fetchManageInvoicesGridData = async () => {
    setIsLoader(true)
    try {
      const response = await fetchManageInvoicesGridDataService(
        manageInvoiceGridPayload
      );
      if (response?.status === "Success") {
        if (response?.meta?.total_count !== undefined) {
          setTotalCount(response?.meta?.total_count);
          setManageInvoiceGridData(response?.data?.grid_data); // PS_14, PS_25, PS_38, PS_49
        }
        setInvoiceStatusData(response?.data?.invoice_status_data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoader(false)
      setCallGridAPI(() => "");
    }
  };

  const postAcceptOrRejectInvoice = async (
    payload: AcceptOrRejectInvoicePayloadInterface
  ) => {
    setIsLoader(true)
    try {
      const response = await postAcceptOrRejectInvoiceServices(payload);
      setIsLoader(false)
      if (response?.status === "Success") {
        if (response?.message === "Invoice Status updated to Accepted") {
          setToastDetails((prevState)=>({
            ...prevState,
            isToast: true,
            toastDescription: "Invoice is accepted successfully",
            toastMessage: "Accepted",
            toastType: "Success"
          }))          
        } else if (response?.message === "Invoice Status updated to Rejected") {
          setToastDetails((prevState)=>({
            ...prevState,
            isToast: true,
            toastDescription: "Invoice is rejected successfully",
            toastMessage: "Rejected",
            toastType: "Success"
          }))  
        } 
        setCallGridAPI("call");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAcceptOrRejectInvoice = (
    payload: AcceptOrRejectInvoicePayloadInterface
  ) => {
    postAcceptOrRejectInvoice(payload);
  };

  const handleConfirmPopUp = (message: string) => {
    if (message === "close" || message === "no") {
      setShowConfirmPopUp(false);
    } else {
      handleAcceptOrRejectInvoice(acceptOrRejectInvoicePayload);
      setShowConfirmPopUp(false);
    }
  };

  const handleOnKeyDownSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setManageInvoiceGridPayload({
        ...manageInvoiceGridPayload,
        search: e.currentTarget?.value?.trim(),
      }); // PS_18
    }
  };

  const handleSearch = () => {
    setManageInvoiceGridPayload({
      ...manageInvoiceGridPayload,
      search: searchTerm.trim(),
    }); // PS_18
  };

  // PS_28: handleFilterChange
  const handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    // PS_29
    if (event.target.name === "invoice_date_from") {
      setManageInvoiceGridFilter((prevState) => ({
        ...prevState,
        invoice_date: {
          ...prevState.invoice_date,
          from_date: event.target.value,
        },
      }));
    } else if (event.target.name === "invoice_date_to") {
      setManageInvoiceGridFilter((prevState) => ({
        ...prevState,
        invoice_date: {
          ...prevState.invoice_date,
          to_date: event.target.value,
        },
      }));
    } else if (event.target.name === "invoice_due_date_from") {
      setManageInvoiceGridFilter((prevState) => ({
        ...prevState,
        invoice_due_date: {
          ...prevState.invoice_due_date,
          from_date: event.target.value,
        },
      }));
    } else if (event.target.name === "invoice_due_date_to") {
      setManageInvoiceGridFilter((prevState) => ({
        ...prevState,
        invoice_due_date: {
          ...prevState.invoice_due_date,
          to_date: event.target.value,
        },
      }));
    }
    // else if (event.target.name === "number") {
    //   if (/^\d*$/.test(event.target.value)) {
    //     setManageInvoiceGridFilter((prevState) => ({
    //       ...prevState,
    //       invoice_amount: event.target.value,
    //     }));
    //   }
    // }
    else {
      setManageInvoiceGridFilter((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
    }
  };

  // PS_30: applyFilter
  const applyFilter: () => void = () => {
    // const today = new Date().toISOString().split('T')[0]
    setManageInvoiceGridPayload((prevState) => ({
      ...prevState,
      filter: manageInvoiceGridFilter,
    })); // PS_31
    setShowFilter(false);
  };

  // PS_30: clearFilter
  const clearFilter: () => void = () => {
    setManageInvoiceGridPayload((prevState) => ({
      ...prevState,
      filter: initialGridPayload.filter,
    })); // PS_31
    setManageInvoiceGridFilter(initialGridPayload.filter); // PS_31
    setShowFilter(false)
  };

  const handleSort: (column: string, order: boolean) => void = (
    column: string,
    order: boolean
  ) => {
    setManageInvoiceGridPayload({
      ...manageInvoiceGridPayload,
      sort: {
        column,
        // order: "desc",
        order: order ? "asc" : "desc",
      }, // PS_42
    });
  };

  // PS_52: handleLoadMore
  const handleLoadMore: () => void = () => {
    if (manageInvoiceGridData.length < totalCount) {
      setManageInvoiceGridPayload((prevState) => ({
        ...prevState,
        load_more: prevState.load_more + 20,
      })); // PS_53
    }
  };

  const bindGridData = () => {
    return manageInvoiceGridData?.map((data) => {
      return (
        <tr key={data.invoice_uuid}>
          <td className="ps-4">
            <a
              onClick={()=>{
                invoiceDownloadS3File(data.invoice_doc_url)
              }}
              style={{cursor: "pointer"}}
              className="link-text text-decoration-none"
            >
              {data.invoice_name}
            </a>
          </td>
          <td>
            <a
              onClick={()=>{
                encryptStorage.setItem("manageContractorProfileID", data.contractor_uuid)
                // navigate("/manage-contractors-profile", {state: data.contractor_uuid})
                navigate("/manage-contractors-profile", {
                  state: {
                    routedFrom: "/feedback"
                  },
                });
              }}
              style={{cursor: "pointer"}}
              className="link-text text-decoration-none"
            >
              {/* Castle Security */}
              {data.dba_business_name}
            </a>
            {/* <span className="font-12 font-medium color-grey-v3 d-block mt-1">
              External Contractor
            </span> */}
          </td>
          <td>{formatDate(data.invoice_date)}</td>
          <td 
          // className="text-end"
          >{formatDollars(data.invoice_amount)}</td>
          <td>{formatDate(data.invoice_due_date)}</td>
          {/* <td className="text-center">
            <a href="#" className="link-text text-decoration-none">
              View
            </a>
          </td> */}
          <td className="text-center">
            <span className="alert-color">
              {data.invoice_status_name.charAt(0).toUpperCase() +
                data.invoice_status_name.slice(1).toLowerCase()}
            </span>
          </td>
          <td>
            <div className="d-flex align-items-center gap-3 justify-content-center">
              <button
                className="btn green-btn px-4 font-12 font-semibold"
                disabled={
                  data.invoice_status_name?.toLowerCase() === "open"
                    ? // data.invoice_status_name.toLowerCase() === "rejected"
                      false
                    : true
                }
                onClick={() => {
                  setAcceptOrRejectInvoicePayload((prevState) => ({
                    action: "Accepted",
                    invoice_uuid: data.invoice_uuid,
                  }));
                  setShowConfirmPopUp(true);
                }}
              >
                Accept
              </button>
              <button
                className="btn red-btn px-4 font-12 font-semibold"
                disabled={
                  data.invoice_status_name?.toLowerCase() === "open"
                    ? // data.invoice_status_name.toLowerCase() === "Accepted" ||
                      // data.invoice_status_name.toLowerCase() === "Rejected"
                      false
                    : true
                }
                onClick={() => {
                  setAcceptOrRejectInvoicePayload((prevState) => ({
                    action: "Rejected",
                    invoice_uuid: data.invoice_uuid,
                  }));
                  setShowConfirmPopUp(true);
                }}
              >
                Reject
              </button>
            </div>
          </td>
        </tr>
      );
    });
  };

    
  return  isLoader ? (<Loader/>) : (
    <div className="container-fluid p-4 pt-5">
      {showConfirmPopUp && (
        <div
          className="modal fade show"
          id="exampleModal11"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content border-radius-10">
              <div className="modal-header border-0 px-4 pt-4 align-items-center">
                <h5
                  className="modal-title font-bold font-18 color-black-v2"
                  id="exampleModalLabel"
                >
                  {acceptOrRejectInvoicePayload.action?.toLowerCase() ===
                  "accepted"
                    ? "Accept"
                    : "Reject"}{" "}
                  Confirmation
                </h5>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  onClick={() => {
                    handleConfirmPopUp("close");
                  }}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body px-4 py-0">
                <p className="font-medium font-16 mb-0 color-black cust-lh mb-3">
                  Are you sure you want to{" "}
                  {acceptOrRejectInvoicePayload.action?.toLowerCase() ===
                  "accepted"
                    ? "Accept"
                    : "Reject"}{" "}
                  this invoice?
                </p>
              </div>
              <div className="modal-footer gap-2 border-0 px-4 pb-4 pt-2 justify-content-end">
                <button
                  className="btn tertiary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                  onClick={() => {
                    handleConfirmPopUp("no");
                  }}
                >
                  No
                </button>
                <button
                  className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                  onClick={() => {
                    handleConfirmPopUp("yes");
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {toastDetails?.isToast && (
          <Toast
            props={{
              isToast: toastDetails?.isToast,
              setToastDetails: setToastDetails,
              toastMessage: toastDetails?.toastMessage,
              toastType: toastDetails?.toastType,
              toastDescription: toastDetails?.toastDescription
            }}
          />
        )}
      
      {(props?.contractorUUID === "" ||
        props?.contractorUUID === undefined) && (
        <div className="d-flex align-items-center justify-content-between flex-wrap gap-3">
          <h2 className="color-black font-20 font-bold">Manage Invoices</h2>
          <div className="d-flex align-items-center justify-content-md-center gap-3 flex-wrap mt-2 mt-md-0">
            {/* search */}
            <div className="position-relative">
              <input
                type="search"
                className="custom-input-search font-14 font-medium color-black custom-search"
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  // e.code == "Enter" ? handleSearch(e) : <></>;
                  handleOnKeyDownSearch(e);
                }}
                onInput={(e: React.FormEvent<HTMLInputElement>) => {
                  setSearchTerm((e.target as HTMLInputElement).value);
                  // setManageInvoiceGridPayload((prevState) => ({
                  //   ...prevState,
                  //   search: (e.target as HTMLInputElement).value,
                  // }));
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchTerm(e.target.value);
                }}
                value={searchTerm}
                placeholder="Search here"
              />
              <button
                className="btn bg-transparent border-0 focus-none search-icon"
                onClick={() => {
                  handleSearch();
                }}
              >
                <img
                  className="tab-icon"
                  src="../images/search-icon.svg"
                  alt="search"
                />
              </button>
            </div>
            {/* filter */}
            <button
              type="button"
              className="btn common-btn px-3"
              aria-expanded="true"
              onClick={() => setShowFilter(!showFilter)}
            >
              <img src="../images/filter-icons.svg" alt="filter-icons" />
            </button>
            {showFilter && (
              <InvoiceFilterComponent
                handleFilterChange={handleFilterChange}
                manageInvoiceGridFilter={manageInvoiceGridFilter}
                manageInvoiceGridPayload={manageInvoiceGridPayload}
                applyFilter={applyFilter}
                clearFilter={clearFilter}
                setShowFilter={setShowFilter}
                invoiceStatusData={invoiceStatusData}
                setManageInvoiceGridFilter = {setManageInvoiceGridFilter}
              />
            )}
          </div>
        </div>
      )}
      {manageInvoiceGridData.length === 0 ? (
        <div className="d-flex align-items-center justify-content-center p-5 flex-column">
          <img
            className="no-record-img"
            src="../images/no-record.gif"
            alt="No reord"
          />
          {/* <img src="../images/no-record.svg" alt="no-record"> */}
          <p className="font-medium font-20 color-grey-v1 mb-0 mt-3 text-center">
            No Records Found
          </p>
        </div>
      ) : (
        <>
          {" "}
          <div className={`table-responsive theme-table bg-white custom-scroll ${props?.contractorUUID ? "mb-4": "my-4"} w-100 `}>
            <table className="table table-borderless mb-0 acc-view">
              <thead>
                <tr>
                  <th className="ps-4 text-nowrap cursor-pointer"  onClick={() => {
                          setSortOrder((prevState) => ({
                            ...prevState,
                            invoice_name: !prevState.invoice_name,
                          }));
                          handleSort("invoice_name", sortOrder.invoice_name);
                        }}>
                    Invoice #
                    <span className="ms-1 cursor-pointer">
                      <img
                        src={`../images/${
                          sortOrder.invoice_name
                            ? "sort-up-icon"
                            : "sort-arrow-down"
                        }.svg`}
                        alt={`${
                          sortOrder.invoice_name
                            ? "sort-up-icon"
                            : "sort-arrow-down"
                        }`}
                        // onClick={() => {
                        //   setSortOrder((prevState) => ({
                        //     ...prevState,
                        //     invoice_name: !prevState.invoice_name,
                        //   }));
                        //   handleSort("invoice_name", sortOrder.invoice_name);
                        // }}
                      />
                    </span>
                  </th>
                  <th className="ps-4 text-nowrap cursor-pointer" onClick={() => {
                          setSortOrder((prevState) => ({
                            ...prevState,
                            dba_business_name: !prevState.dba_business_name,
                          }));
                          handleSort(
                            "dba_business_name",
                            sortOrder.dba_business_name
                          );
                        }}>
                    Contractor
                    <span className="ms-1 cursor-pointer">
                      <img
                        src={`../images/${
                          sortOrder.dba_business_name
                            ? "sort-up-icon"
                            : "sort-arrow-down"
                        }.svg`}
                        alt={`${
                          sortOrder.dba_business_name
                            ? "sort-up-icon"
                            : "sort-arrow-down"
                        }`}
                        // onClick={() => {
                        //   setSortOrder((prevState) => ({
                        //     ...prevState,
                        //     dba_business_name: !prevState.dba_business_name,
                        //   }));
                        //   handleSort(
                        //     "dba_business_name",
                        //     sortOrder.dba_business_name
                        //   );
                        // }}
                      />
                    </span>
                  </th>
                  <th className="text-nowrap cursor-pointer" onClick={() => {
                          setSortOrder((prevState) => ({
                            ...prevState,
                            invoice_date: !prevState.invoice_date,
                          }));
                          handleSort("invoice_date", sortOrder.invoice_date);
                        }}>
                    Invoice Date
                    <span className="ms-1 cursor-pointer">
                      <img
                        src={`../images/${
                          sortOrder.invoice_date
                            ? "sort-up-icon"
                            : "sort-arrow-down"
                        }.svg`}
                        alt={`${
                          sortOrder.invoice_date
                            ? "sort-up-icon"
                            : "sort-arrow-down"
                        }`}
                        // onClick={() => {
                        //   setSortOrder((prevState) => ({
                        //     ...prevState,
                        //     invoice_date: !prevState.invoice_date,
                        //   }));
                        //   handleSort("invoice_date", sortOrder.invoice_date);
                        // }}
                      />
                    </span>
                  </th>
                  <th className="text-nowrap cursor-pointer" onClick={() => {
                          setSortOrder((prevState) => ({
                            ...prevState,
                            invoice_amount: !prevState.invoice_amount,
                          }));
                          handleSort(
                            "invoice_amount",
                            sortOrder.invoice_amount
                          );
                        }}>
                    Invoice Amount
                    <span className="ms-1 cursor-pointer">
                      <img
                        src={`../images/${
                          sortOrder.invoice_amount
                            ? "sort-up-icon"
                            : "sort-arrow-down"
                        }.svg`}
                        alt={`${
                          sortOrder.invoice_amount
                            ? "sort-up-icon"
                            : "sort-arrow-down"
                        }`}
                        // onClick={() => {
                        //   setSortOrder((prevState) => ({
                        //     ...prevState,
                        //     invoice_amount: !prevState.invoice_amount,
                        //   }));
                        //   handleSort(
                        //     "invoice_amount",
                        //     sortOrder.invoice_amount
                        //   );
                        // }}
                      />
                    </span>
                  </th>
                  <th className=" text-nowrap cursor-pointer" onClick={() => {
                          setSortOrder((prevState) => ({
                            ...prevState,
                            invoice_due_date: !prevState.invoice_due_date,
                          }));
                          handleSort(
                            "invoice_due_date",
                            sortOrder.invoice_due_date
                          );
                        }}>
                    Due Date
                    <span className="ms-1 cursor-pointer">
                      <img
                        src={`../images/${
                          sortOrder.invoice_due_date
                            ? "sort-up-icon"
                            : "sort-arrow-down"
                        }.svg`}
                        alt={`${
                          sortOrder.invoice_due_date
                            ? "sort-up-icon"
                            : "sort-arrow-down"
                        }`}
                        // onClick={() => {
                        //   setSortOrder((prevState) => ({
                        //     ...prevState,
                        //     invoice_due_date: !prevState.invoice_due_date,
                        //   }));
                        //   handleSort(
                        //     "invoice_due_date",
                        //     sortOrder.invoice_due_date
                        //   );
                        // }}
                      />
                    </span>
                  </th>
                  {/* <th className=" text-nowrap">Description</th> */}
                  <th className=" text-nowrap text-center cursor-pointer" onClick={() => {
                          setSortOrder((prevState) => ({
                            ...prevState,
                            invoice_status_name: !prevState.invoice_status_name,
                          }));
                          handleSort(
                            "invoice_status_name",
                            sortOrder.invoice_status_name
                          );
                        }}>
                    Status
                    <span className="ms-1 cursor-pointer">
                      <img
                        src={`../images/${
                          sortOrder.invoice_status_name
                            ? "sort-up-icon"
                            : "sort-arrow-down"
                        }.svg`}
                        alt={`${
                          sortOrder.invoice_status_name
                            ? "sort-up-icon"
                            : "sort-arrow-down"
                        }`}
                        // onClick={() => {
                        //   setSortOrder((prevState) => ({
                        //     ...prevState,
                        //     invoice_status_name: !prevState.invoice_status_name,
                        //   }));
                        //   handleSort(
                        //     "invoice_status_name",
                        //     sortOrder.invoice_status_name
                        //   );
                        // }}
                      />
                    </span>
                  </th>
                  <th className=" text-nowrap text-center">Actions</th>
                </tr>
              </thead>
              <tbody>{bindGridData()}</tbody>
            </table>
          </div>
          <p className="font-14 font-medium color-grey-v7">
            Showing
            <span className="font-semibold color-black">
              {" "}
              {` ${
                manageInvoiceGridData?.length !== undefined
                  ? manageInvoiceGridData.length
                  : 0
              }`}
            </span>{" "}
            out of{" "}
            <span className="font-semibold color-black">{totalCount}</span> Item
          </p>
        </>
      )}
      {manageInvoiceGridData.length < totalCount &&
        manageInvoiceGridData.length >= 20 && (
          <div className="d-flex align-items-center justify-content-center">
            <button
              className="btn px-4 color-white font-semibold font-14 primary-btn px-4 rounded-3 text-center"
              onClick={() => {
                handleLoadMore();
              }}
            >
              Load More
            </button>
          </div>
        )}
    </div> 
  );
};

export default ManageInvoices;
