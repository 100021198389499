
import React, { useState, useEffect } from 'react';
import { ContactFormProps, FromPayload, TeamContact } from '../../../Interface/EditContractor/Signup';
import { emailRegex, formatPhoneNumberEditContractor, phoneNumberRegex } from '../../../Configs/Constant';

export function ContactForm({ formPayload, setFormPayload, contacts, setContacts, handlePrevious, handleNext, currentStep }: ContactFormProps) {
    const [isOnlyContact, setIsOnlyContact] = useState<string>(formPayload.only_contact);
    const [isFormValid, setIsFormValid] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [AfterHourerrors, setAfterHourErrors] = useState({ phone_no: "", name: "", email_id: "" });
    const [touched, setTouched] = useState<{ [key: string]: boolean }>({});

    const teamNames = [
        'new work opportunities',
        'guard punctuality or missed shifts',
        'timesheets/invoice docs',
        'license or insurance questions'
    ];

    // PS_CSU__47
    // useEffect(() => {
    //     if (!isOnlyContact && contacts.length === 0) {
    //         setContacts(teamNames.map(team_name => ({ team_name, name: '', phone_on: '', email_id: '' })));
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isOnlyContact]);

    // PS_CSU__48
    useEffect(() => {
        setFormPayload(prev => ({ ...prev, only_contact: isOnlyContact }));
        validContactsForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contacts, formPayload, isOnlyContact]);

    // PS_CSU__49
    const validateField = (name: string, value: string) => {
        let error = '';
        switch (name) {
            case 'name':
            case 'after_hours_contact_name':
                if (!/^[a-zA-Z\s]+$/.test(value) || "") {
                    error = "Please enter a valid Name";
                } else {
                    error = "";
                }
                break;
            case 'email_id':
            case 'after_hours_contact_email':
                if (!emailRegex.test(value) || "") {
                    error = "Please enter a valid Email ID";
                } else {
                    error = "";
                }
                break;
            case 'phone_on':
            case 'after_hours_contact_phone':
                if (!phoneNumberRegex.test(value) || value === "") {
                    error = "Please enter a valid 10-digit Phone Number";
                } else {
                    error = "";
                }
                break;
        }
        return error;
    };
    // PS_CSU__50
    const handleChange = (name: string, value: string, index?: number) => {
        console.log("name", name, "value", value, "index", index);

        let error = '';
        if (name.startsWith('after_hours_contact_')) {
            error = validateField(name, value);
            setAfterHourErrors((prev) => ({ ...prev, [name]: error }))
        } else {
            error = validateField(name, value);
        }

        if (typeof index === 'number') {
            setContacts(prev => {
                const newContacts = [...prev];
                newContacts[index] = { ...newContacts[index], [name]: value };
                return newContacts;
            });
            setErrors(prev => ({
                ...prev,
                [`contact_${index}_${name}`]: error
            }));
            setTouched(prev => ({
                ...prev,
                [`contact_${index}_${name}`]: true
            }));
        } else if (name.startsWith('after_hours_contact_')) {

            setFormPayload(prev => ({
                ...prev,
                [name]: value
            }));
            setErrors(prev => ({
                ...prev,
                [name]: error
            }));
            setTouched(prev => ({
                ...prev,
                [name]: true
            }));
        } else if (name === 'only_contact') {
            // if (value === "Yes") {
            setContacts(teamNames.map(team_name => ({ team_name, name: '', phone_on: '', email_id: '' })));
            // }
            setIsOnlyContact(value);
            setErrors({ name: "", phone_no: "", email_id: "" });
        }
    };

    // PS_CSU__51
    const validContactsForm = () => {
        let isValid = true;

        // Validate Team Contact fields if not the only contact
        if (isOnlyContact === 'No') {
            if (contacts.length) {
                contacts.forEach((contact, index) => {
                    ['name', 'phone_on', 'email_id'].forEach(field => {
                        const value = contact[field as keyof TeamContact];
                        const error = validateField(field, value);
                        if (error) {
                            isValid = false;
                            // setErrors(prev => ({ ...prev, [`contact_${index}_${field}`]: error }));
                        }
                    });
                });
            }
        }
        // Validate After Hours Contact fields
        ['after_hours_contact_name', 'after_hours_contact_phone', 'after_hours_contact_email'].forEach(field => {
            const value = formPayload[field as keyof FromPayload] as string;
            const error = validateField(field, value || '');
            if (error) {
                isValid = false;
                // setErrors(prev => ({ ...prev, [field]: error }));
            }
        });
        if (isOnlyContact === "") {
            isValid = false
        }
        setIsFormValid(isValid);
        return isValid;
    };
    // PS_CSU__52
    const getFieldStatus = (fieldName: string) => {
        if (!touched[fieldName]) return '';
        return errors[fieldName] ? 'critical-field' : '';
    };

    // PS_CSU__53
    const handleCancel = () => {
        // Reset formPayload to initial state
        setFormPayload({
            ...formPayload,
            only_contact: '',
            after_hours_contact_name: '',
            after_hours_contact_phone: '',
            after_hours_contact_email: '',
            // ... reset other fields
        });
        // Reset contacts to initial state (empty array or default contacts)
        setContacts(teamNames.map(team_name => ({ team_name, name: '', phone_on: '', email_id: '' })));
        setIsOnlyContact("");
        setErrors({ name: "", phone_no: "", email_id: "" });
        // If you have any other state that needs to be reset, do it here
        setAfterHourErrors({ name: "", phone_no: "", email_id: "" })
    };

    return (
        <>
            {/* ... (radio buttons for isOnlyContact remain the same) ... */}
            <h2 className="color-black font-600 font-18 mb-4">
                Company Contact Information
            </h2>
            <div className="col-lg-12 mb-4 mb-lg-5">
                <label className="font-semibold font-16 color-black-v2 mb-3">
                    Are you the only contact for all communications?
                    <span className="madatory-text">*</span>
                </label>
                <div className="">
                    <div className="form-check mb-3">
                        <input
                            className="form-check-input custom-radio-input"
                            type="radio"
                            name="only_contact"
                            id="flexRadioDefault11"
                            value="yes"
                            checked={isOnlyContact === 'yes' || isOnlyContact === "Yes"}
                            onChange={(e) => handleChange(e.target.name, "Yes")}
                        />
                        <label
                            className="font-medium font-14 color-black-v2 mt-1 ps-2"
                            htmlFor="flexRadioDefault11"
                        >
                            Yes, I handle all communication.
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input custom-radio-input"
                            type="radio"
                            name="only_contact"
                            id="flexRadioDefault21"
                            value="no"
                            checked={isOnlyContact === 'No' || isOnlyContact === "no"}
                            onChange={(e) => handleChange(e.target.name, 'No')}
                        />
                        <label
                            className="font-medium font-14 color-black-v2 mt-1 ps-2"
                            htmlFor="flexRadioDefault21"
                        >
                            No, we have a team.
                        </label>
                    </div>
                </div>
            </div>

            {isOnlyContact === 'No' && contacts.map((contact, index) => (
                <div key={index} className="col-lg-12 mb-4 mb-lg-5 row">
                    <h2 className="color-black font-600 font-18 mb-4">
                        Who do we contact about {contact.team_name}? (Headquarters)
                    </h2>
                    <div className="col-lg-4 mb-4 mb-lg-5">
                        <label htmlFor={`name${index}`} className="font-semibold font-16 color-black-v2 mb-2">
                            Name<span className="madatory-text">*</span>
                        </label>
                        <input
                            type="text"
                            id={`name${index}`}
                            name="name"
                            className={`form-control service-form-field color-black-v2 font-14 font-regular ${getFieldStatus(`contact_${index}_name`)}`}
                            maxLength={60}
                            placeholder="Enter Name"
                            value={contact?.name}
                            // onChange={(e) => handleChange(e.target.name, e.target.value, index)}
                            onChange={(e) => {
                                if (/^[a-zA-Z\s]+$/.test(e.target.value) || e.target.value === "") {
                                    handleChange(e.target.name, e.target.value, index)
                                }
                            }}
                            required
                        />
                        {touched[`contact_${index}_name`] && errors[`contact_${index}_name`] &&
                            <span className="font-14 font-medium alert-text">{errors[`contact_${index}_name`]}</span>}
                    </div>
                    {/* ... (similar changes for phone and email inputs) ... */}
                    <div className="col-lg-4 mb-4 mb-lg-5">
                        <label htmlFor={`phone${index}`} className="font-semibold font-16 color-black-v2 mb-2">
                            Phone #<span className="madatory-text">*</span>
                        </label>
                        <input
                            type="text"
                            id={`phone${index}`}
                            name="phone_on"
                            className="form-control service-form-field color-black-v2 font-14 font-regular"
                            placeholder="Enter Phone #"
                            value={contact?.phone_on}
                            // maxLength={10}
                            onChange={(e) => {
                                let phoneNum = formatPhoneNumberEditContractor(e.target.value);
                                handleChange(e.target.name, phoneNum, index)
                            }}
                            required
                        />
                        {touched[`contact_${index}_phone_on`] && errors[`contact_${index}_phone_on`] && <span className="font-14 font-medium alert-text">{errors[`contact_${index}_phone_on`]}</span>}
                    </div>
                    <div className="col-lg-4 mb-4 mb-lg-5">
                        <label htmlFor={`email${index}`} className="font-semibold font-16 color-black-v2 mb-2">
                            Email ID<span className="madatory-text">*</span>
                        </label>
                        <input
                            type="text"
                            id={`email${index}`}
                            name="email_id"
                            maxLength={60}
                            className="form-control service-form-field color-black-v2 font-14 font-regular"
                            placeholder="Enter Email ID"
                            value={contact?.email_id}
                            onChange={(e) => handleChange(e.target.name, e.target.value.toLowerCase(), index)}
                            required
                        />
                        {touched[`contact_${index}_email_id`] && errors[`contact_${index}_email_id`] && <span className="font-14 font-medium alert-text">{errors[`contact_${index}_email_id`]}</span>}
                    </div>
                </div>
            ))}

            <h2 className="color-black font-600 font-18 mb-4">After Hours Contact</h2>
            <div className="col-lg-4 mb-4 mb-lg-5">
                <label htmlFor="name5" className="font-semibold font-16 color-black-v2 mb-2">
                    Name<span className="madatory-text">*</span>
                </label>
                <input
                    type="text"
                    id="name5"
                    maxLength={60}
                    className={`form-control service-form-field color-black-v2 font-14 font-regular  ${getFieldStatus('after_hours_contact_name')}`}
                    placeholder="Enter Name"
                    value={formPayload?.after_hours_contact_name}
                    // onChange={(e) => handleChange(e.target.name, e.target.value)}
                    onChange={(e) => {
                        if (/^[a-zA-Z\s]+$/.test(e.target.value) || e.target.value === "") {
                            handleChange(e.target.name, e.target.value)
                        }
                    }}
                    name="after_hours_contact_name"
                    required
                />
                {touched?.after_hours_contact_name && errors.after_hours_contact_name &&
                    <span className="font-14 font-medium alert-text">{errors.after_hours_contact_name}</span>}
            </div>
            {/* ... (similar changes for after hours phone and email inputs) ... */}
            <div className="col-lg-4 mb-4 mb-lg-5">
                <label htmlFor="phone5" className="font-semibold font-16 color-black-v2 mb-2">
                    Phone #<span className="madatory-text">*</span>
                </label>
                <input
                    type="text"
                    id="phone5"
                    className="form-control service-form-field color-black-v2 font-14 font-regular"
                    placeholder="Enter Phone #"
                    value={formPayload?.after_hours_contact_phone}
                    // onChange={(e) => {
                    //     if (/^\d+$/.test(e.target.value)) {
                    //         handleChange(e.target.name, e.target.value)
                    //     } else if (e.target.value === "") {
                    //         handleChange(e.target.name, e.target.value)
                    //     }
                    // }}
                    onChange={(e) => {
                        let phoneNum = formatPhoneNumberEditContractor(e.target.value);
                        handleChange(e.target.name, phoneNum)
                    }}
                    name="after_hours_contact_phone"
                    // maxLength={10}
                    required
                />
                {touched?.after_hours_contact_phone && errors.after_hours_contact_phone && <span className="font-14 font-medium alert-text">{errors.after_hours_contact_phone}</span>}
                {/* {touched[`contact_${index}_name`] && errors[`contact_${index}_name`] &&
                            <span className="font-14 font-medium alert-text">{errors[`contact_${index}_name`]}</span>} */}
            </div>
            <div className="col-lg-4 mb-4 mb-lg-5">
                <label htmlFor="email5" className="font-semibold font-16 color-black-v2 mb-2">
                    Email ID<span className="madatory-text">*</span>
                </label>
                <input
                    type="text"
                    id="email5"
                    maxLength={60}
                    className="form-control service-form-field color-black-v2 font-14 font-regular"
                    placeholder="Enter Email ID"
                    value={formPayload?.after_hours_contact_email}
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                    name="after_hours_contact_email"
                    required
                />
                {touched?.after_hours_contact_email && errors.after_hours_contact_email && <span className="font-14 font-medium alert-text">{errors.after_hours_contact_email}</span>}
            </div>
            <div className="modal-footer justify-content-between border-0 gap-4 pb-5">
                <button className="btn px-0 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn"
                    onClick={handleCancel}>
                    Cancel
                </button>
                <div className="d-flex gap-3">
                    <button className="btn tertiary-btn px-4 color-white font-semibold font-14 px-4"
                        onClick={handlePrevious}
                        disabled={currentStep === 0}>
                        Previous
                    </button>
                    <button className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                        onClick={() => {
                            handleNext()
                        }}
                        disabled={!isFormValid}
                    >
                        Next
                    </button>
                    {/* {isEdit &&
                        <button className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                            // disabled={!buttonSubmit}
                            onClick={() => { SaveFromDetails() }}
                        >
                            Update
                        </button>
                    } */}
                </div>
            </div>
        </>
    );
}
