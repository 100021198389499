import React, { useState } from 'react';
import CountdownTimer from "./CountdownTimer";
import { updateJobsAndBidsTimer } from '../../Service/JobsAndBidsServices/JobsAndBidsServices';
import { ConvertSecondsToHHMMSS } from '../../Configs/Constant';
import Loader from "../Loader/LoaderComponent";

function JobsAndBidsTimer({
    isEditTimer,
    setIsEditTimer,
    remainingTimeInSeconds,
    responseTime,
    serviceRequestId,
    setShowToast,
    setShowToastProp,
    fetchGridData,  // Add fetchGridData as a prop
    setSelectedTab,
    selectedTab
}: any) {

    // This is the given time in HH:MM:SS format
    const initialTime = new Date(responseTime);
    const initialTimeString = ConvertSecondsToHHMMSS(remainingTimeInSeconds); // Example initial time in HH:MM:SS format
    const [hours, setHours] = useState<number>(0);
    const [minutes, setMinutes] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // Parse the initial time string
    const [initialHours, initialMinutes, initialSeconds] = initialTimeString.split(":").map(Number);

    const handleHoursChange = (event: any) => {
        const newHours = parseInt(event.target.value, 10);
        setHours(isNaN(newHours) ? 0 : newHours);
    };

    const handleMinutesChange = (event: any) => {
        const newMinutes = parseInt(event.target.value, 10);
        setMinutes(isNaN(newMinutes) ? 0 : newMinutes);
    };

    const handleIncrement = (incMinutes: number) => {
        const totalMinutes = hours * 60 + minutes + incMinutes;
        setHours(Math.floor(totalMinutes / 60));
        setMinutes(totalMinutes % 60);
    };

    const isValidTimeDifference = () => {
        const totalInitialSeconds = (initialHours * 3600) + (initialMinutes * 60) + initialSeconds;
        const incrementInSeconds = (hours * 3600) + (minutes * 60);
        const totalSeconds = totalInitialSeconds + incrementInSeconds;

        const totalHours = totalSeconds / 3600;
        return totalHours > 1 && totalHours < 3;
    };

    const handleUpdate = async () => {
        if (!isValidTimeDifference()) {
            setShowToast(true);
            setShowToastProp({ type: "Error", title: "Validation Error", message: "The total time should be between 1 hour and 3 hours." });
            return;
        }

        const newResponseTime = new Date(initialTime);
        newResponseTime.setHours(newResponseTime.getHours() + hours);
        newResponseTime.setMinutes(newResponseTime.getMinutes() + minutes);

        let updateResponseTimeObj = {
            response_time: newResponseTime.toISOString(),
            service_request_uuid: serviceRequestId
        };

        setIsLoading(true);
        let response = await updateJobsAndBidsTimer(updateResponseTimeObj);

        if (response.code === 200) {
            // Ensure fetchGridData is called here
            await fetchGridData();
            // navigate("/jobs-and-bids", { state: { tab: selectedTab } });
            setSelectedTab(selectedTab);
            setShowToast(true);
            setShowToastProp({ type: "Success", title: "Action Successful", message: "Successfully updated the service request Timer" });
            setIsEditTimer(false);
            setIsLoading(false);
        } else {
            setIsLoading(false);
            setShowToast(true);
            setShowToastProp({ type: "Error", title: "Failure", message: "Failed to update the service request Timer" });
        }
    };

    return (
        isLoading ? <Loader /> : <div
            className="modal fade show"
            id="exampleModal17"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            data-backdrop="static"
            data-keyboard="false"
            aria-modal="true"
            role="dialog"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content border-radius-10">
                    <div className="modal-header border-0 px-4 pt-4 align-items-start">
                        <h5
                            className="modal-title font-semibold font-18 color-black-v2 mb-2"
                            id="exampleModalLabel"
                        >
                            Timer Configuration
                        </h5>
                        <button
                            type="button"
                            className="btn-close custom-btn-close mt-1"
                            aria-label="Close"
                            onClick={() => setIsEditTimer(!isEditTimer)}
                        />
                    </div>
                    <div className="modal-body px-4 py-2">
                        <div className="row align-items-center">
                            <div className="d-flex align-items-center gap-3 mb-3">
                                <p className="mb-0 font-15 font-medium color-grey-v8">
                                    Current Timer
                                </p>
                                <div className="d-block">
                                    <img
                                        className="me-1"
                                        src="../images/hour-glass.svg"
                                        alt="hour-glass"
                                    />
                                    <span className="font-semibold font-12 color-black-v3 align-items-center">
                                        <CountdownTimer initialSeconds={remainingTimeInSeconds} />
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-12 custom-mb">
                                <div className="position-relative">
                                    <label
                                        htmlFor="hours"
                                        className="font-semibold font-14 primary-text-color mb-2"
                                    >
                                        Increase Timer (Hours and Minutes)
                                    </label>
                                    <div className="d-flex gap-3">
                                        <input
                                            type="number"
                                            id="hours"
                                            className="form-control bg-white color-grey-v6 font-16 font-regular invoice-form-field"
                                            placeholder="Hours"
                                            value={hours}
                                            onChange={handleHoursChange}
                                        />
                                        <input
                                            type="number"
                                            id="minutes"
                                            className="form-control bg-white color-grey-v6 font-16 font-regular invoice-form-field"
                                            placeholder="Minutes"
                                            value={minutes}
                                            onChange={handleMinutesChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center gap-3 mb-3 flex-wrap">
                                <button className="btn timer-btn font-12 font-medium color-black" onClick={() => handleIncrement(25)}>
                                    +25 m
                                </button>
                                <button className="btn timer-btn font-12 font-medium color-black" onClick={() => handleIncrement(50)}>
                                    +50 m
                                </button>
                                <button className="btn timer-btn font-12 font-medium color-black" onClick={() => handleIncrement(75)}>
                                    +1 h 15 m
                                </button>
                                <button className="btn timer-btn font-12 font-medium color-black" onClick={() => handleIncrement(90)}>
                                    +1 h 30 m
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer gap-2 border-0 px-4 py-4 justify-content-end">
                        <button
                            className="btn px-4 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn"
                            onClick={() => setIsEditTimer(false)}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                            onClick={handleUpdate}
                        >
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default JobsAndBidsTimer;