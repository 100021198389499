/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  ClientGridDataInterface,
  IndustryDataInterface,
  InviteUpdateClientFormDataPayloadInterface,
  sortOrderInterfaceType,
} from "../../../Interface/ManageClient";
import { encryptStorage, formatDate } from "../../../Configs/Constant";
import { useNavigate } from "react-router-dom";
import TooltipText from "../../JobsAndBids/TooltipText";

const AllClientsGrid = ({
  gridData,
  setInviteUpdateClientFormDataPayload,
  totalCount,
  handleSort,
  sortOrder,
  setSortOrder,
  handleLoadMore,
  setShowDeletePopUp,
  industryData,
}: {
  gridData: ClientGridDataInterface[];
  setInviteUpdateClientFormDataPayload: React.Dispatch<
    React.SetStateAction<InviteUpdateClientFormDataPayloadInterface>
  >;
  totalCount: number;
  handleSort: (column: string, order: boolean) => void;
  sortOrder: sortOrderInterfaceType;
  setSortOrder: React.Dispatch<React.SetStateAction<sortOrderInterfaceType>>;
  handleLoadMore: () => void;
  setShowDeletePopUp: React.Dispatch<React.SetStateAction<boolean>>;
  industryData: IndustryDataInterface[];
}) => {
  const navigate = useNavigate();
  const bindGridData = () => {
    return gridData?.map((data) => {
      return (
        <tr key={data?.customer_uuid}>
          <td className="ps-4">
            <a
              className="link-text text-decoration-none"
              style={{cursor: "pointer"}}  onClick={() =>
              {
                encryptStorage.setItem("manageCustomerProfileID", data?.customer_uuid)
                navigate("/manage-clients-profile", {
                  state: {
                    customerUUID: data?.customer_uuid,
                    industryData: industryData,
                    other_industry: data?.other_industry,
                    tab: "ALL_CLIENTS"
                  },
                })
              }
              } 
            >
              {data?.customer_dba}
            </a>
            <span className="font-12 font-medium color-grey-v3 d-block mt-1 alert-color">
              {data?.onboarding_status === false && "Rejected"}
            </span>
          </td>
          <td>{data?.first_name +" "+data?.last_name}</td>
          <td>
            {data?.referred_by === "" && data?.other_referrel === ""
              ? "-"
              : data?.referred_by !=="" ? data?.referred_by : data?.other_referrel}
          </td>
          <td>{formatDate(data.created_on)}</td>
          {/* <td>{data.industry_name === "" ?  "-" : data.industry_name.toLowerCase() === "other" ? data.other_industry: data.industry_name}</td> */}
          <td>
            
            <TooltipText text={data?.industry_name === "" ?  "-" : data.industry_name} maxLength={15} />

            </td>
          <td>{data?.business_email}</td>
          <td>
            {data.phone_number}
             {/* {data.phone_number !== ""
              ? isRegexValidMobile(data.phone_number)
                ? data.phone_number
                : usPhoneNumberFormat(data.phone_number) 
              : "-"} */}
          </td>
          <td>{data.source}</td>
          <td className="text-end">{data.service_request_count}</td>
          <td>
            <div className="d-flex align-items-center gap-3 justify-content-center">
              <button
                className="btn blue-btn px-4 font-12 font-semibold"
                onClick={() =>{
                  encryptStorage.setItem("manageCustomerProfileID", data?.customer_uuid)

                  navigate("/manage-clients-profile", {
                    state: {
                      customerUUID: data.customer_uuid,
                      industryData: industryData,
                    },
                  })
                }
                }
              >
                View
              </button>
              <button
                className="btn red-btn px-4 font-12 font-semibold"
                onClick={() => {
                  // handleDeleteClient(data.customer_uuid);
                  setInviteUpdateClientFormDataPayload((prevState) => ({
                    ...prevState,
                    post_type: "delete",
                    customer_uuid: data.customer_uuid,
                  }));
                  setShowDeletePopUp(true);
                }}
              >
                Delete
              </button>
            </div>
          </td>
        </tr>
      );
    });
  };
  return (
    <div
      className="tab-pane fade show active"
      id="pills-profile"
      role="tabpanel"
      aria-labelledby="pills-profile-tab"
    >
      <div className="table-responsive theme-table bg-white custom-scroll my-4 w-100">
        <table className="table table-borderless mb-0 acc-view">
          <thead>
            <tr>
              <th className="ps-4 text-nowrap" style={{cursor: "pointer"}} onClick={() => {
                      setSortOrder((prevState) => ({
                        ...prevState,
                        customer_dba: !prevState.customer_dba,
                      }));
                      handleSort("customer_dba", sortOrder.customer_dba);
                    }}>
                DBA Name
                <span className="ms-1 cursor-pointer">
                  <img
                    // sort-up-icon
                    src={`../images/${
                      sortOrder.customer_dba
                        ? "sort-up-icon"
                        : "sort-arrow-down"
                    }.svg`}
                    alt={`${
                      sortOrder.customer_dba
                        ? "sort-up-icon"
                        : "sort-arrow-down"
                    }`}
                    // onClick={() => {
                    //   setSortOrder((prevState) => ({
                    //     ...prevState,
                    //     customer_dba: !prevState.customer_dba,
                    //   }));
                    //   handleSort("customer_dba", sortOrder.customer_dba);
                    // }}
                  />
                </span>
              </th>
              <th className="text-nowrap cursor-pointer"  onClick={() => {
                      setSortOrder((prevState) => ({
                        ...prevState,
                        first_name: !prevState.first_name,
                      }));
                      handleSort("first_name", sortOrder.first_name);
                    }}>
                Name
                <span className="ms-1 cursor-pointer">
                  <img
                    src={`../images/${
                      sortOrder.first_name
                        ? "sort-up-icon"
                        : "sort-arrow-down"
                    }.svg`}
                    alt={`${
                      sortOrder.first_name
                        ? "sort-up-icon"
                        : "sort-arrow-down"
                    }`}
                    // onClick={() => {
                    //   setSortOrder((prevState) => ({
                    //     ...prevState,
                    //     first_name: !prevState.first_name,
                    //   }));
                    //   handleSort("first_name", sortOrder.first_name);
                    // }}
                  />
                </span>
              </th>
              <th className="text-nowrap cursor-pointer" onClick={() => {
                        setSortOrder((prevState) => ({
                          ...prevState,
                          referred_by: !prevState.referred_by,
                        }));
                        handleSort("referred_by", sortOrder.referred_by);
                      }}>
                  Referred By
                  <span className="ms-1 cursor-pointer">
                    <img
                      src={`../images/${
                        sortOrder.referred_by
                          ? "sort-up-icon"
                          : "sort-arrow-down"
                      }.svg`}
                      alt={`${
                        sortOrder.referred_by
                          ? "sort-up-icon"
                          : "sort-arrow-down"
                      }`}
                    />
                  </span>
                </th>
              <th className="text-nowrap cursor-pointer" onClick={() => {
                      setSortOrder((prevState) => ({
                        ...prevState,
                        created_on: !prevState.created_on,
                      }));
                      handleSort("created_on", sortOrder.created_on);
                    }}>
                Account Created
                <span className="ms-1 cursor-pointer">
                  <img
                    src={`../images/${
                      sortOrder.created_on ? "sort-up-icon" : "sort-arrow-down"
                    }.svg`}
                    alt={`${
                      sortOrder.created_on ? "sort-up-icon" : "sort-arrow-down"
                    }`}
                    // onClick={() => {
                    //   setSortOrder((prevState) => ({
                    //     ...prevState,
                    //     created_on: !prevState.created_on,
                    //   }));
                    //   handleSort("created_on", sortOrder.created_on);
                    // }}
                  />
                </span>
              </th>
              <th className="text-nowrap cursor-pointer" onClick={() => {
                      setSortOrder((prevState) => ({
                        ...prevState,
                        industry_name: !prevState.industry_name,
                      }));
                      handleSort("industry_name", sortOrder.industry_name);
                    }}>
                Industry
                <span className="ms-1 cursor-pointer">
                  <img
                    src={`../images/${
                      sortOrder.industry_name
                        ? "sort-up-icon"
                        : "sort-arrow-down"
                    }.svg`}
                    alt={`${
                      sortOrder.industry_name
                        ? "sort-up-icon"
                        : "sort-arrow-down"
                    }`}
                    // onClick={() => {
                    //   setSortOrder((prevState) => ({
                    //     ...prevState,
                    //     industry_name: !prevState.industry_name,
                    //   }));
                    //   handleSort("industry_name", sortOrder.industry_name);
                    // }}
                  />
                </span>
              </th>
              <th className=" text-nowrap cursor-pointer" onClick={() => {
                        setSortOrder((prevState) => ({
                          ...prevState,
                          business_email: !prevState.business_email,
                        }));
                        handleSort("business_email", sortOrder.business_email);
                      }}  >Email ID
              <span className="ms-1 cursor-pointer">
                    <img
                      src={`../images/${
                        sortOrder.business_email
                          ? "sort-up-icon"
                          : "sort-arrow-down"
                      }.svg`}
                      alt={`${
                        sortOrder.business_email
                          ? "sort-up-icon"
                          : "sort-arrow-down"
                      }`}
                      // onClick={() => {
                      //   setSortOrder((prevState) => ({
                      //     ...prevState,
                      //     business_email: !prevState.business_email,
                      //   }));
                      //   handleSort("business_email", sortOrder.business_email);
                      // }}
                    />
                  </span>
              </th>
              <th className=" text-nowrap">Phone #</th>
              <th className="text-nowrap cursor-pointer" onClick={() => {
                      setSortOrder((prevState) => ({
                        ...prevState,
                        source: !prevState.source,
                      }));
                      handleSort("source", sortOrder.source);
                    }}>
                Source
                <span className="ms-1 cursor-pointer" >
                  <img
                    src={`../images/${
                      sortOrder.source ? "sort-up-icon" : "sort-arrow-down"
                    }.svg`}
                    alt={`${
                      sortOrder.source ? "sort-up-icon" : "sort-arrow-down"
                    }`}
                    // onClick={() => {
                    //   setSortOrder((prevState) => ({
                    //     ...prevState,
                    //     source: !prevState.source,
                    //   }));
                    //   handleSort("source", sortOrder.source);
                    // }}
                  />
                </span>
              </th>
              <th className="text-nowrap text-end cursor-pointer" onClick={() => {
                      setSortOrder((prevState) => ({
                        ...prevState,
                        service_request_count: !prevState.service_request_count,
                      }));
                      handleSort(
                        "service_request_count",
                        sortOrder.service_request_count
                      );
                    }}>
                # Requests
                <span className="ms-1 cursor-pointer" >
                  <img
                    src={`../images/${
                      sortOrder.service_request_count
                        ? "sort-up-icon"
                        : "sort-arrow-down"
                    }.svg`}
                    alt={`${
                      sortOrder.service_request_count
                        ? "sort-up-icon"
                        : "sort-arrow-down"
                    }`}
                    // onClick={() => {
                    //   setSortOrder((prevState) => ({
                    //     ...prevState,
                    //     service_request_count: !prevState.service_request_count,
                    //   }));
                    //   handleSort(
                    //     "service_request_count",
                    //     sortOrder.service_request_count
                    //   );
                    // }}
                  />
                </span>
              </th>
              <th className=" text-nowrap text-center">Actions</th>
            </tr>
          </thead>
          <tbody>{bindGridData()}</tbody>
        </table>
      </div>
      <p className="font-14 font-medium color-grey-v7">
        Showing{" "}
        <span className="font-semibold color-black">
          {gridData?.length !== undefined ? gridData?.length : 0}
        </span>{" "}
        out of <span className="font-semibold color-black">{totalCount}</span>{" "}
        Item
      </p>
      {totalCount > gridData?.length && gridData?.length >= 20 && (
        <div className="d-flex align-items-center justify-content-center">
          <button
            className="btn px-4 color-white font-semibold font-14 primary-btn px-4 rounded-3 text-center"
            onClick={() => {
              handleLoadMore();
            }}
          >
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

export default AllClientsGrid;
