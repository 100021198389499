
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { GridData } from "../../Interface/JobsAndBids";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/LoaderComponent";
import TooltipText from "./TooltipText";

interface InProgressTabProps {
  role: string;
  data: GridData[];
  totalCount: number;
  onLoadMore: () => void;
  hasMore: boolean;
  loading: boolean;
  customerUUID?: string;
  contractorUUID?: string;
  renderSortIcon: (column: string) => JSX.Element;
  handleSort: (column: string) => void;
}

// Component definition
const InProgressTab: React.FC<InProgressTabProps> = ({
  role,
  data,
  totalCount,
  onLoadMore,
  hasMore,
  loading,
  customerUUID,
  contractorUUID,
  renderSortIcon,
  handleSort
}) => {
  const navigate = useNavigate();

  return (
    <div
      className="tab-pane fade show active"
      id="pills-Waiting"
      role="tabpanel"
      aria-labelledby="pills-Waiting-tab"
    >
      <div className="table-responsive theme-table bg-white custom-scroll my-4 w-100 vertical-align-top">
        {data?.length > 0 ? (
          <table className="table table-borderless mb-0 acc-view">
            <thead>
              {customerUUID ? (
                <tr>
                  <th className="text-nowrap cursor-pointer " onClick={() => handleSort("sr.service_request")}>Job Title {renderSortIcon("sr.service_request")}</th>
                  <th className="text-nowrap cursor-pointer " onClick={() => handleSort("sr.service_city")}>Location {renderSortIcon("sr.service_city")}</th>
                  <th className="text-nowrap cursor-pointer " onClick={() => handleSort("sr.created_on")}>Job Raised On {renderSortIcon("sr.created_on")}</th>
                  <th className="text-nowrap cursor-pointer " onClick={() => handleSort("sr.service_start_date")}>Requested On {renderSortIcon("sr.service_start_date")}</th>
                  <th className="text-nowrap cursor-pointer " onClick={() => handleSort("quoted_amount")}>Bid Amount {renderSortIcon("quoted_amount")}</th>
                  <th className="text-nowrap text-center">Actions</th>
                </tr>
              ) : contractorUUID ? (
                <tr>
                  <th className="text-nowrap cursor-pointer " onClick={() => handleSort("sr.service_request")}>Job Title {renderSortIcon("sr.service_request")}</th>
                  <th className="text-nowrap cursor-pointer " onClick={() => handleSort("sr.service_city")}>Location {renderSortIcon("sr.service_city")}</th>
                  <th className="text-nowrap cursor-pointer " onClick={() => handleSort("sr.created_on")}>Job Raised On {renderSortIcon("sr.created_on")}</th>
                  <th className="text-nowrap cursor-pointer " onClick={() => handleSort("sr.service_start_date")}>Requested On {renderSortIcon("sr.service_start_date")}</th>
                  <th className="text-nowrap text-end" onClick={() => handleSort("quoted_amount")}>Bid Amount {renderSortIcon("quoted_amount")}</th>
                  <th className="text-nowrap cursor-pointer " onClick={() => handleSort("adm.admin_name")}>Approved By {renderSortIcon("adm.admin_name")}</th>
                  {role === "Admin" && (
                    <th className="text-nowrap text-center">Actions</th>
                  )}
                </tr>
              ) : (
                <tr>
                  <th className="text-nowrap cursor-pointer " onClick={() => handleSort("sr.service_request")}>Job Title {renderSortIcon("sr.service_request")}</th>
                  <th className="text-nowrap cursor-pointer " onClick={() => handleSort("sr.service_city")}>Location {renderSortIcon("sr.service_city")}</th>
                  <th className="text-nowrap cursor-pointer " onClick={() => handleSort("sr.created_on")}>Job Raised On {renderSortIcon("sr.created_on")}</th>
                  <th className="text-nowrap cursor-pointer " onClick={() => handleSort("sr.service_start_date")}>Requested On {renderSortIcon("sr.service_start_date")}</th>
                  {role === "Admin" && (
                    <th className="text-nowrap cursor-pointer " onClick={() => handleSort("contractor_name")}>Approved Contractor {renderSortIcon("contractor_name")}</th>
                  )}
                  <th className="text-nowrap text-end" onClick={() => handleSort("quoted_amount")}>Bid Amount {renderSortIcon("quoted_amount")}</th>
                  <th className="text-nowrap cursor-pointer " onClick={() => handleSort("adm.admin_name")}>Approved By {renderSortIcon("adm.admin_name")}</th>
                  {role === "Admin" && (
                    <th className="text-nowrap text-center">Actions</th>
                  )}
                </tr>)}
            </thead>
            {customerUUID ? (
              <tbody>
                {data?.map((job, index) => (
                  <tr key={index}>
                    <td>
                      <a
                        className="link-text text-decoration-none"
                        onClick={() =>
                          navigate("/service-request-view", {
                            state: {
                              id: job.service_request_uuid,
                              status: "in-progress",
                              amount: job.amount
                            },
                          })
                        }
                      >
                        <TooltipText text={job.service_request} maxLength={15} />
                      </a>
                    </td>
                    <td>{`${job.city}, ${job.state}`}</td>
                    <td>{dayjs(job.created_on).format("MMM DD, YYYY")}</td>
                    <td>
                      {dayjs(job.service_start_date).format("MMM DD, YYYY")}
                    </td>
                    <td className="text-end">
                      {job.amount !== null
                        ? `$${job.amount.toFixed(2)}`
                        : "Yet to be Decided"}
                    </td>
                    {role === "Admin" && (
                      <td className="text-center">
                        <button
                          className="btn blue-btn px-4 font-12 font-semibold"
                          onClick={() =>
                            navigate("/service-request-view", {
                              state: {
                                id: job.service_request_uuid,
                                status: "in-progress",
                                amount: job.amount
                              },
                            })
                          }
                        >
                          View
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            ) : contractorUUID ? (
              <tbody>
                {data?.map((job, index) => (
                  <tr key={index}>
                    <td>
                      <a
                        className="link-text text-decoration-none"
                        onClick={() =>
                          navigate("/service-request-view", {
                            state: {
                              id: job.service_request_uuid,
                              status: "in-progress",
                              amount: job.amount
                            },
                          })
                        }
                      >
                        <TooltipText text={job.service_request} maxLength={15} />
                      </a>
                    </td>
                    <td>{`${job.city}, ${job.state}`}</td>
                    <td>{dayjs(job.created_on).format("MMM DD, YYYY")}</td>
                    <td>
                      {dayjs(job.service_start_date).format("MMM DD, YYYY")}
                    </td>
                    <td className="text-end">
                      {job.amount !== null
                        ? `$${job.amount.toFixed(2)}`
                        : "Yet to be Decided"}
                    </td>
                    <td>
                      <p className="font-13 font-medium color-black mb-2">
                        {job.approved_by.trim() || "NA"}
                      </p>
                      <p className="font-11 font-medium primary-text-color-light mb-0">
                        Admin
                      </p>
                    </td>
                    {role === "Admin" && (
                      <td className="text-center">
                        <button
                          className="btn blue-btn px-4 font-12 font-semibold"
                          onClick={() =>
                            navigate("/service-request-view", {
                              state: {
                                id: job.service_request_uuid,
                                status: "in-progress",
                                amount: job.amount
                              },
                            })
                          }
                        >
                          View
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                {data?.map((job, index) => (
                  <tr key={index}>
                    <td>
                      <a
                        className="link-text text-decoration-none cursor-pointer"
                        onClick={() =>
                          navigate("/service-request-view", {
                            state: {
                              id: job.service_request_uuid,
                              status: "in-progress",
                              tab: "in progress",
                              amount: job.amount
                            },
                          })
                        }
                      >
                        <TooltipText text={job.service_request} maxLength={15} />
                      </a>
                    </td>
                    <td>{`${job.city}, ${job.state}`}</td>
                    <td>{dayjs(job.created_on).format("MMM DD, YYYY")}</td>
                    <td>
                      {dayjs(job.service_start_date).format("MMM DD, YYYY")}
                    </td>
                    {role === "Admin" && (
                      <td>
                        <p className="font-13 font-medium color-black mb-2">
                        {job.contractor_name.trim() || "PPS Contractor"}
                        </p>
                        <p className="font-11 font-medium primary-text-color-light mb-0">
                          {job.assignment_type}
                        </p>
                      </td>
                    )}
                    <td className="text-end">
                      {job.amount !== null
                        ? `$${job.amount.toFixed(2)}`
                        : "Yet to be Decided"}
                    </td>
                    <td>
                      <p className="font-13 font-medium color-black mb-2">
                        {job.approved_by.trim() || "NA"}
                      </p>
                      <p className="font-11 font-medium primary-text-color-light mb-0">
                        Admin
                      </p>
                    </td>
                    {role === "Admin" && (
                      <td className="text-center">
                        <button
                          className="btn blue-btn px-4 font-12 font-semibold"
                          onClick={() =>
                            navigate("/service-request-view", {
                              state: {
                                id: job.service_request_uuid,
                                status: "in-progress",
                                tab: "in progress",
                                amount: job.amount
                              },
                            })
                          }
                        >
                          View
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>)}
          </table>
        ) : (
          <div className="text-center py-5">
            <img
              className="no-record-img mb-3"
              src="../images/no-record.gif"
              alt="No record"
            />
            <p className="font-medium font-20 color-grey-v1 mb-0">
              No records found
            </p>
          </div>
        )}
      </div>
      {hasMore && (
        <div className="d-flex align-items-center justify-content-center mt-4">
          <button
            className="btn px-4 color-white font-semibold font-14 primary-btn rounded-3 text-center"
            onClick={onLoadMore}
            disabled={loading}
          >
            {loading ? (
              <>
                <Loader />
              </>
            ) : (
              "Load More"
            )}
          </button>
        </div>
      )}
      <p className="font-14 font-medium color-grey-v7">
        Showing{" "}
        <span className="font-semibold color-black">{data?.length}</span> out of{" "}
        <span className="font-semibold color-black">{totalCount}</span> Item
        {data?.length !== 1 ? "s" : ""}
      </p>
    </div>
  );
};

export default InProgressTab;