import { useContext, useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate, useLocation } from "react-router-dom";
import { ClientID, PoolID, ppsContext } from "../../Configs/Constant";
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

import { contextValueInterface } from "../../Interface/SignIn";
import Loader from "../Loader/Loader";


export default function ResetPassword() {

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [newpassError, setNewpassError] = useState(false);
    const [showloader, setShowLoader] = useState(false);

    //PC_SI_09-PC_SI_14 - Declaration and initialization of state variables 
    const [resetDetails, setResetDetails] = useState({
        userID: "",
        oldPassword: "",
        newPassword: ""
    });
    const [resetError, setResetError] = useState({
        userID: "",
        oldPassword: "",
        newPassword: "",
        Error: ""
    });

    //PC_SI_16-PC_SI_17 - Handle onchange values of fields
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setResetDetails({ ...resetDetails, [e.target.name]: e.target.value.trim() });
        setResetError((prev) => ({ ...prev, [e.target.name]: "", Error: "" }))
    }
    const location = useLocation();
    const navigate = useNavigate();
    const StateData = location.state;

    const contextValue: contextValueInterface = useContext(ppsContext)

    useEffect(() => {
        setResetDetails((prev) => ({ ...prev, userID: contextValue.userDetails.loginUserName }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //PC_SI_23-PC_SI_30-Method to authenticate the user in cognito and invoke Post Login APIs

    const ChangeNewPassword = () => {
        // let isValid = true
        let isValid = validateFields()
        if (isValid) {
            // showLoader()
            setShowLoader(true);
            const poolData = {
                UserPoolId: process.env.REACT_APP_USER_POOL_ID || PoolID,
                ClientId: process.env.REACT_APP_CLIENT_ID || ClientID,
            };

            const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
            const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
                Username: resetDetails.userID.trim(),
                Pool: userPool,
            });
            const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
                Username: resetDetails.userID.trim(),
                Password: resetDetails.oldPassword.trim()
            });


            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: function (result) {
                },
                onFailure: function (err) {
                    console.log(err);
                    setShowLoader(false);
                },

                newPasswordRequired: function (userAttributes, requiredAttributes) {
                    cognitoUser.completeNewPasswordChallenge(

                        resetDetails.newPassword.trim(), // Use the current password as the new password
                        requiredAttributes,
                        {
                            onSuccess: async function (result) {

                                var idToken = result.getIdToken().getJwtToken();
                                var accessToken = result.getAccessToken().getJwtToken();
                                //PC_REF_39- Store the refresh token
                                var refreshToken = result.getRefreshToken().getToken()

                                localStorage.setItem("accessToken", accessToken)
                                localStorage.setItem("idToken", idToken)
                                localStorage.setItem("refreshToken", refreshToken)

                                const decodedToken = jwtDecode(accessToken) as { [key: string]: any };
                                // const userGroups = decodedToken["cognito:groups"];
                                const username = decodedToken["username"];
                                const expirationTime = decodedToken["exp"]; // Extract the expiration time from the decoded token                       

                                if (username) {
                                    localStorage.setItem("emailId", username)
                                }
                                if (expirationTime) {
                                    const expirationDate = new Date(expirationTime * 1000); // Convert expiration time to milliseconds
                                    localStorage.setItem("accessTokenExpiration", expirationDate.toISOString()); // Store expiration time as a date string in session storage
                                } else {
                                    console.error("Access token expiration time not found in decoded token.");
                                    // Handle the absence of expiration time, set a default value, or throw an error as appropriate
                                }
                                setShowLoader(false);
                                navigate("/");
                                // Check if the user's role matches the required role

                            },
                            onFailure: function (err) {
                                // hideLoader()
                                setShowLoader(false);
                                setResetError({ ...resetError, Error: err.message })
                            }
                        }
                    );
                }
            });

        }

    }

    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const validateFields = () => {
        let isValid = true;
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;

        if (resetDetails?.userID?.trim() === "") {
            setResetError(prevState => ({
                ...prevState,
                userID: "Please enter a valid user id",
            }));
            isValid = false;
        } else {
            setResetError(prevState => ({
                ...prevState,
                userID: "",
            }));
        }

        if (resetDetails?.oldPassword?.trim() === "") {
            setResetError(prevState => ({
                ...prevState,
                oldPassword: "Please enter your current password",
            }));
            isValid = false;
        } else {
            setResetError(prevState => ({
                ...prevState,
                oldPassword: "",
            }));
        }

        if (resetDetails?.newPassword?.trim() !== "") {

            if (!passwordRegex.test(resetDetails.newPassword.trim())) {
                isValid = false;
                setResetError(prevState => ({
                    ...prevState,
                    newPassword: "Please enter valid password",
                }));
                setNewpassError(true)

            } else if (resetDetails?.newPassword?.trim() === resetDetails?.oldPassword?.trim()) {
                setResetError(prevState => ({
                    ...prevState,
                    newPassword: "New password must be different from the old password",
                }));
                isValid = false;
            } else {
                setNewpassError(false)
                setResetError(prevState => ({
                    ...prevState,
                    newPassword: "",
                }));
            }
        } else {
            setResetError(prevState => ({
                ...prevState,
                newPassword: "Please enter new password",
            }));
            setNewpassError(false)

        }



        return isValid;
    }

    return (
        <>
            {showloader && <Loader />}
            <div className="container-fluid position-relative h-100 min-vh-100">
                <div className="login-bg-image position-absolute h-100 min-vh-100" />
                <div className="row d-flex align-items-center justify-content-center h-100 position-relative ">
                    <div className="col-lg-5 col-sm-9 my-4 cust-client-padding">
                        <div className="position-relative">
                            <div className="creation-container pb-4 position-relative">
                                <span className="text-center d-flex align-items-center justify-content-center logo-container">
                                    <img
                                        src="../images/command-app-logo.svg"
                                        alt="Logo"
                                        className="mb-4"
                                    />
                                </span>
                                <h3 className="font-semibold font-27 text-center mb-4 primary-text-color">
                                    Set New Password
                                </h3>
                                <div className="row">
                                    <div className="col-lg-12 mb-3">
                                        <label
                                            htmlFor="userID"
                                            className="font-semibold font-15 primary-text-color mb-2"
                                        >
                                            User ID
                                        </label>
                                        <input
                                            type="text"
                                            id="userID"
                                            // className="form-control custom-form-fields color-black-v1 font-16 font-medium"
                                            className={`form-control custom-form-fields color-black-v1 font-16 font-medium ${resetError.userID ? 'critical-field' : ''}`}
                                            placeholder="Enter your User ID"
                                            name="userID"
                                            value={resetDetails.userID}
                                            onChange={handleOnChange}
                                        />
                                        {resetError.userID !== "" &&
                                            <span className="font-14 font-medium alert-text">
                                                {resetError.userID}
                                            </span>
                                        }
                                    </div>
                                    <div className="col-lg-12 mb-4 position-relative">
                                        <label
                                            htmlFor="oldPassword"
                                            className="font-semibold font-15 primary-text-color mb-2"
                                        >
                                            Current Password
                                        </label>
                                        <input
                                            type={showOldPassword ? 'text' : 'password'}
                                            id="oldPassword"
                                            // className="form-control custom-form-fields color-black-v1 font-16 font-medium pe-5"
                                            className={`form-control custom-form-fields color-black-v1 font-16 font-medium pe-5 ${resetError.oldPassword ? 'critical-field' : ''}`}
                                            placeholder="Enter your current password"
                                            name="oldPassword"
                                            value={resetDetails.oldPassword}
                                            onChange={handleOnChange}
                                        />
                                        {showOldPassword ?
                                            <button className="position-absolute field-icons p-0 border-0 bg-transparent"
                                                onClick={() => setShowOldPassword(!showOldPassword)}
                                            >
                                                <img src="../images/eye-icon.svg" alt="eye-icon" />
                                            </button>
                                            :
                                            <button className="position-absolute field-icons p-0 border-0 bg-transparent"
                                                onClick={() => setShowOldPassword(!showOldPassword)}
                                            >
                                                <img src="../images/hidden-grey.svg" alt="eye-icon" />
                                            </button>
                                        }

                                        {resetError.oldPassword !== "" &&
                                            <span className="font-14 font-medium alert-text">
                                                {resetError.oldPassword}
                                            </span>
                                        }
                                    </div>
                                    <div className="col-lg-12 mb-4 position-relative">
                                        <label
                                            htmlFor="newPassword"
                                            className="font-semibold font-15 primary-text-color mb-2"
                                        >
                                            New Password
                                        </label>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            id="newPassword"
                                            // className="form-control custom-form-fields color-black-v1 font-16 font-medium pe-5"
                                            className={`form-control custom-form-fields color-black-v1 font-16 font-medium pe-5 ${resetError.newPassword ? 'critical-field' : ''}`}
                                            placeholder="Enter your new password"
                                            name="newPassword"
                                            value={resetDetails.newPassword}
                                            onChange={handleOnChange}
                                            onKeyDown={(e) => { if (e.key === "Enter") ChangeNewPassword() }}
                                        />

                                        {showPassword ?
                                            <button className="position-absolute field-icons p-0 border-0 bg-transparent"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                <img src="../images/eye-icon.svg" alt="eye-icon" />
                                            </button>
                                            :
                                            <button className="position-absolute field-icons p-0 border-0 bg-transparent"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                <img src="../images/hidden-grey.svg" alt="eye-icon" />
                                            </button>
                                        }
                                        {resetError.newPassword !== "" &&
                                            <span className="font-14 font-medium alert-text">
                                                {resetError.newPassword}
                                            </span>
                                        }
                                        {newpassError && <ul
                                            className="ps-3 mb-4"
                                        // style={{ paddingLeft: "33px" }}
                                        >
                                            <li className="required-color font-14 font-medium">Minimum length should be atleast 8 characters</li>
                                            <li className="required-color font-14 font-medium">It should have lowercase letters</li>
                                            <li className="required-color font-14 font-medium">It should have uppercase letters</li>
                                            <li className="required-color font-14 font-medium">It should have symbols</li>
                                            <li className="required-color font-14 font-medium">It should contain numbers</li>
                                        </ul>}
                                    </div>
                                    {resetError.Error !== "" &&
                                        <span className="font-14 font-medium alert-text">
                                            {resetError.Error}
                                        </span>
                                    }

                                </div>
                                <div className="d-flex align-items-center justify-content-center mt-4 flex-column gap-4">
                                    {StateData?.type === "NEW_PASSWORD_REQUIRED" || "Change Password" ?
                                        <button
                                            type="button"
                                            className="btn primary-login-btn font-15 font-semibold text-nowrap w-100"
                                            onClick={ChangeNewPassword}
                                        >
                                            Change Password
                                        </button>
                                        :
                                        null
                                    }
                                    {StateData?.type === "Change Password" &&
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        <a href="#" className="font-15 font-medium link-v2"
                                            onClick={() => { navigate("/forgot-password") }}>
                                            Forgot Password
                                        </a>
                                    }
                                </div>
                            </div>
                            <div className="creation-gradient-container"></div>
                        </div>
                    </div>
                </div>
                <p className="color-white font-semibold font-15 text-center position-absolute w-100 helpline mt-2">
                    Reach our command center 24/7 at:{" "}
                    <a href="tel:+1-800-775-0805" className="color-white cust-text-underline">
                        1-800-775-0805
                    </a>{" "}
                    |
                    <a
                        href="mailto:sales@premierprotective.com"
                        className="color-white cust-text-underline"
                    >
                        {" "}sales@premierprotective.com
                    </a>
                </p>
            </div>
        </>
    )
}