
import React, { useState } from 'react';

interface OfficerRequestTypeProps {
  officerTypes: string[];
}

const OfficerRequestType: React.FC<OfficerRequestTypeProps> = ({ officerTypes }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const displayedTypes = officerTypes.slice(0, 2);
  const remainingTypes = officerTypes.slice(2);
  const remainingCount = remainingTypes.length;

  return (
    <div
      className="officer-request-type"
      style={{ position: 'relative', display: 'inline-block' }}
    >
      {displayedTypes.join(', ')}
      {remainingCount > 0 && (
        <span
          className="badge"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{
            marginLeft: '8px',
            padding: '2px 6px',
            borderRadius: '12px',
            backgroundColor: '#000', // Black background color
            color: '#fff', // White text color
            border: '1px solid #000', // Black border color
            cursor: 'pointer',
          }}
        >
          {`+${remainingCount}`}
        </span>
      )}
      {isHovered && remainingCount > 0 && (
        <div
          className="tooltip"
          style={{
            position: 'absolute',
            bottom: '100%', // Position above the badge
            right: '0', // Align to the right side
            backgroundColor: '#000', // Black background color
            color: '#fff', // White text color
            borderRadius: '4px',
            padding: '8px',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
            zIndex: 1,
            whiteSpace: 'nowrap',
            marginBottom: '4px', // Add some margin to separate the tooltip from the badge
            opacity: 0.9, // Slight transparency for the tooltip
          }}
        >
          {remainingTypes.join(', ')}
        </div>
      )}
    </div>
  );
};

export default OfficerRequestType;