/* eslint-disable array-callback-return */
import React, { useEffect, useState, useContext } from "react";
import { MultiSelect } from "react-multi-select-component";
import {
  filterDataModel,
  gridData,
  gridUserDataModel,
  sortDataModel,
  apiUserData,
  filter,
  MultiRegions,
  regionInput,
  ValidateFields,
  DeletePayload,
  //getUserData,
} from "../../Interface/ManageUserGrid";
import AdvancedFilter from "../Filters/FilterComponent";
import {
  getUserGridApiData,
  manageUsers,
} from "../../Service/ManageUserGrid/ManageUserGrid";
import FlagPopUp from "../PopUps/Popup";
import Toast from "../Toasts/Toast";
import Loader from "../../Components/Loader/LoaderComponent";
import { ppsContext } from "../../Configs/Constant";
import { contextValueInterface } from "../../Interface/SignIn";
import { getRegionData } from "../../Service/ServiceRequest/ManageContractor/SignUp";
import TooltipText from "../JobsAndBids/TooltipText";

export function UserGridComponent() {
  const contextValue: contextValueInterface = useContext(ppsContext);
  const [gridData, setGridData] = useState<gridData[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filterData, setFilterData] = useState<filter>({
    Role: "",
    Region: "",
    Status: "",
  });
  const [sortData, setSortData] = useState<sortDataModel>({
    Column: "",
    Direction: "DESC",
  });
  const [limit, setLimit] = useState<number>(20);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [roleDropDown, setRoleDropDown] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [selectedRegions, setSelectedRegions] = useState<MultiRegions[]>([]);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);
  const [isShowFilterPopup, setIsShowFilterPopup] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [deletePayload, setDeletePayload] = useState<DeletePayload>({
    admin_uuid: "",
    Regions: [],
    role: "",
  });
  const [handleGrid, setHandleGrid] = useState<boolean>(false);
  const [UsedRegions, SetUsedRegions] = useState<MultiRegions[]>([]);
  const [PendingRegions, SetPendingRegions] = useState<MultiRegions[]>([]);
  const [UserMails, SetUserMails] = useState<string[]>([]);
  const [toastMessage, setToastMessage] = useState({
    type: "",
    message: "",
    desc: "",
  });
  const [showToast, setShowToast] = useState(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    full_name: "",
    email_id: "",
    phone_number: "",
    role: "Admin",
    regions: [],
  });

  const [regionOptions, setRegionOptions] = useState<MultiRegions[]>([])
  const [regionsArray, setRegionsArray] = useState<string[]>([])


  useEffect(() => {
    getRegions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, sortData, handleGrid]);

  useEffect(() => {
    // Check all fields are filled and valid
    let validPayload: ValidateFields = {
      Name: formData.full_name,
      Email: formData.email_id,
      Phone: formData.phone_number,
      Role: formData.role,
      Regions: selectedRegions,
    };
    const isFormValid = validateAllFields(validPayload);

    setIsAddButtonDisabled(!isFormValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, selectedRegions]);

  async function getRegions() {
    setShowLoader(true);
    const response = await getRegionData();
    let locRegions;
    if (response?.code === 200) {
      locRegions = response?.data
      let locRegionOpt: MultiRegions[] = []
      // eslint-disable-next-line array-callback-return
      locRegions.map((val: any) => {
        let options = {
          value: val.region_id,
          label: val.region_name
        }
        locRegionOpt.push(options)
      })
      contextValue.setTrackTikregions(locRegionOpt)
      setRegionOptions(locRegionOpt)
      setRegionsArray(regionOptions.map((option) => option.label))
      // return 
      getGridData(locRegionOpt);
    }
  }

  async function getGridData(data: MultiRegions[] = []) {
    try {

      const regionOptions_data: MultiRegions[] = data.length ? data : regionOptions

      let gridPayload: filterDataModel = {
        SelectedRole: roleDropDown,
        Search: search,
        sort_column: sortData.Column,
        Filter: {
          Role: filterData.Role,
          Status: filterData.Status,
          Region: filterData.Region,
        },
        sort_by: sortData.Direction,
        Limit: limit,
      };
      setShowLoader(true);
      const gridApiData = await getUserGridApiData(gridPayload);
      setShowLoader(false);
      if (gridApiData?.code === 200) {
        if (gridApiData.data) {

          //let APIRes: apiUserData= gridApiData.data;
          //let GridRec:gridData[]=APIRes.user_requests;
          let APIRes = gridApiData?.data as apiUserData; // Type assertion
          let GridRec = gridApiData?.data?.user_requests as gridData[];
          let UsedRegions: MultiRegions[] = [];
          let Usermails: string[] = [];

          if (gridApiData?.data?.user_requests?.length > 0) {
            const updatedGridRec = GridRec?.map((val: gridData) => {
              Usermails.push(val?.email_id);
              let regions: MultiRegions[] = [];

              if (val?.regions?.length > 0) {
                val?.regions?.map((y: any) => {
                  let SelectReg = regionOptions_data?.filter((x) => x.label === y);
                  console.log(SelectReg, "y", y);
                  if (SelectReg[0]) {
                    UsedRegions.push(SelectReg[0]);
                    regions.push(SelectReg[0]);
                  }
                });
              }

              // const PendingRegions = regionOptions.filter(
              //   (option) =>
              //     !UsedRegions.some(
              //       (usedRegion) => usedRegion.value === option.value
              //     )
              // );

              // SetPendingRegions(PendingRegions);


              return {
                ...val, // Copies all properties as they are named in the API response
                EditedFullName: val?.full_name, // Note the lowercase property name
                EditedPhoneNumber: val?.phone_number, // Same here
                EditedEmailID: val?.email_id, // And here
                RegionsGrid:
                  val?.regions && val?.regions?.length > 0
                    ? val.regions.join(", ")
                    : "NA",
                IsEdited: false,
                EditedRole: val?.role,
                SelectRegions: regions,
              };
            });

            const PendingRegions = regionOptions_data.filter(
              (option) =>
                !UsedRegions?.some(
                  (usedRegion) => {
                    console.log(usedRegion?.value, option?.value, "option?.value");

                    return usedRegion?.value === option?.value
                  }
                )
            );

            // setShowLoader(false)
            SetUserMails(Usermails);
            SetUsedRegions(UsedRegions);
            SetPendingRegions(PendingRegions);
            setGridData(updatedGridRec);
            setTotalCount(APIRes.user_count);
          } else {
            // setShowLoader(false)
            setGridData([]);
            setTotalCount(0);
          }
        }

      } else {
        // setShowLoader(false)
        setToastMessage({
          message: "Failed to get user data",
          type: "error",
          desc: "",
        });
        setShowToast(true);
      }
    } catch (error) {
      // setShowLoader(false)
      setToastMessage({
        message: "Failed to get user data",
        type: "error",
        desc: "",
      });
      setShowToast(true);
    }
  }

  const handleChange = (field: any, value: any) => {
    if (field === "search") {
      setSearch(value);
    } else if (field === "phone_number") {
      const formattedPhoneNumber = formatPhoneNumber(value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [field]: formattedPhoneNumber,
      }));
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, [field]: value }));
    }
  };

  const validateEmail = (email: string, originalEmail?: string) => {
    const emailRegex = /^[a-zA-Z0-9.%+-]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,}$/;

    const excludedDomains = [
      "gmail.com",
      "yahoo.com",
      "hotmail.com",
      "outlook.com",
      "aol.com",
    ];
    const domain = email.split("@")[1];

    // Check if email address is in the correct format and not from excluded domains
    const isFormatAndDomainValid =
      emailRegex.test(email) && !excludedDomains.includes(domain);

    // If originalEmail is provided and it matches the current email, consider it valid
    const isEmailUnchanged = originalEmail === email;

    // Check if email is not part of Usermails or it's unchanged
    const isUniqueOrUnchanged = isEmailUnchanged || !UserMails.includes(email);

    // Return true only if all conditions are met
    return isFormatAndDomainValid && isUniqueOrUnchanged;
  };

  const validatePhoneNumber = (phone: string) => {
    // Adjust the regex to whatever format you need
    const phoneRegex = /^\d{3} \d{3} \d{4}$/;
    return phoneRegex.test(phone);
  };

  const formatPhoneNumber = (value: any) => {
    if (!value) return value;

    // Remove all non-digits, then slice to limit to 10 characters
    const phoneNumber = value.replace(/[^\d]/g, "").slice(0, 10);

    // Groups for the standard US phone number format
    const phoneNumberGroups = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (phoneNumberGroups) {
      return `${phoneNumberGroups[1]} ${phoneNumberGroups[2]} ${phoneNumberGroups[3]}`;
    }

    return phoneNumber;
  };

  const validateAllFields = (
    validPayload: ValidateFields,
    originalEmail?: string
  ) => {
    const isFormValid =
      validPayload.Name.trim() !== "" &&
      validateEmail(validPayload.Email, originalEmail) &&
      validatePhoneNumber(formatPhoneNumber(validPayload.Phone)) &&
      validPayload.Role !== "";
    if (validPayload.Role.toLowerCase() === "regional manager") {
      return isFormValid && validPayload.Regions.length > 0;
    } else {
      return isFormValid;
    }
  };

  const handleApplyFilter = () => {
    setIsShowFilterPopup(true);
  };

  const handleCloseAdvancedFilter = () => {
    setIsShowFilterPopup(false);
  };

  const ApplyFilterClick = (filterData: filter) => {
    setIsShowFilterPopup(false);
    setFilterData(filterData);
    setHandleGrid(!handleGrid);
  };


  function handleSearchClick() {
    getGridData();
  }

  async function handleSaveClick(type: string, ind: number) {
    const newGridData = [...gridData];
    // Create a copy of the object you want to mutate
    let regions: regionInput[] = [];
    newGridData[ind].SelectRegions.map((val) => {
      regions.push({ region: val.label, region_id: val.value });
    });
    if (type === "Save") {
      let RegionsUsed = [...newGridData[ind].SelectRegions, ...UsedRegions];
      RegionsUsed = RegionsUsed.filter(
        (item, index, self) =>
          index === self.findIndex((findItem) => findItem.value === item.value)
      );
      const PendingRegions = regionOptions.filter(
        (option) =>
          !RegionsUsed.some((usedRegion) => usedRegion.value === option.value)
      );
      SetPendingRegions(PendingRegions);
      SetUsedRegions(RegionsUsed);

      const managePayload: gridUserDataModel = {
        full_name: newGridData[ind].EditedFullName,
        email_id: newGridData[ind].EditedEmailID,
        phone_number: newGridData[ind].EditedPhoneNumber,
        role: newGridData[ind].EditedRole,
        regions: regions,
        admin_uuid: newGridData[ind].admin_uuid,
        action: "update",
      };
      setShowLoader(true);
      const response = await manageUsers(managePayload);
      setShowLoader(false);
      if (response?.code === 200) {
        setToastMessage({
          message: "User Details Updated Successfully",
          type: "Success",
          desc: "",
        });
        setShowToast(true);
        // setHandleGrid(true)
      } else {
        setToastMessage({
          message: "Failed to Save the changes",
          type: "error",
          desc: "",
        });
        setShowToast(true);
        // setHandleGrid(true)
      }
      handleEditClick("Cancel", ind)
      setHandleGrid(!handleGrid)

    } else if (type === "Delete") {
      setDeletePayload({
        admin_uuid: newGridData[ind].admin_uuid,
        Regions: regions,
        role: newGridData[ind].role,
      });
      setShowConfirmation(true);
    }
  }

  const handleGridRegion = (e: any, index: number) => {
    const newGridData = [...gridData];
    // Create a copy of the object you want to mutate
    // let regions =  newGridData[index].SelectRegions
    // regions.push(e[0])
    let isSave = newGridData[index].IsSave;
    if (newGridData[index].EditedRole?.toLowerCase() === "regional manager") {
      let validPayload: ValidateFields = {
        Name: newGridData[index].EditedFullName,
        Email: newGridData[index].EditedEmailID,
        Phone: newGridData[index].EditedPhoneNumber,
        Role: newGridData[index].EditedRole,
        Regions: e,
      };
      isSave = validateAllFields(validPayload, newGridData[index].email_id);
    }
    const updatedItem = {
      ...newGridData[index],
      SelectRegions: e,
      IsSave: !isSave,
    };

    // Update the item in the new array
    newGridData[index] = updatedItem;
    // Set the new array as the new state, which will trigger a re-render
    setGridData(newGridData);
  };

  function handleEditClick(type: string, index: number) {
    if (type === "Edit") {
      const newGridData = [...gridData];

      let RegionsSelect = [
        ...newGridData[index].SelectRegions,
        ...PendingRegions,
      ];
      // Create a copy of the object you want to mutate
      const updatedItem = {
        ...newGridData[index],
        IsEdited: !newGridData[index].IsEdited,
        IsSave: false,
        RegionOptions: RegionsSelect,
      };
      newGridData.forEach((val, ind) => {
        if (index !== ind) {
          val.IsEdited = false;
        }
      });
      // Update the item in the new array
      newGridData[index] = updatedItem;

      // Set the new array as the new state, which will trigger a re-render
      setGridData(newGridData);
    } else if (type === "Cancel") {
      const newGridData = [...gridData];
      let regions: MultiRegions[] = [];
      if (newGridData[index]?.regions?.length > 0) {
        newGridData[index]?.regions?.map((y) => {
          let SelectReg = regionOptions.filter((x) => (x.label === y));
          regions.push(SelectReg[0]);
        });
      }
      // Create a copy of the object you want to mutate
      const updatedItem = {
        ...newGridData[index],
        IsEdited: !newGridData[index].IsEdited,
        EditedFullName: newGridData[index].full_name,
        EditedEmailID: newGridData[index].email_id,
        EditedPhoneNumber: newGridData[index].phone_number,
        EditedRole: newGridData[index].role,
        SelectRegions: regions,
      };
      // Update the item in the new array
      newGridData[index] = updatedItem;
      // Set the new array as the new state, which will trigger a re-render
      setGridData(newGridData);
    }
  }

  const handleChangeGrid = (event: any, index: number, field: string) => {
    const newGridData = [...gridData];
    const { value } = event.target;
    // Create a copy of the object you want to mutate
    let updatedItem: any;
    let isSave: any;

    if (field === "name") {
      let validPayload: ValidateFields = {
        Name: value,
        Email: newGridData[index].EditedEmailID,
        Phone: newGridData[index].EditedPhoneNumber,
        Role: newGridData[index].EditedRole,
        Regions: newGridData[index].SelectRegions,
      };
      isSave = validateAllFields(validPayload, newGridData[index].email_id);
      updatedItem = {
        ...newGridData[index],
        EditedFullName: value,
        IsSave: !isSave,
      };
    } else if (field === "phone") {
      let validPayload: ValidateFields = {
        Name: newGridData[index].EditedFullName,
        Email: newGridData[index].EditedEmailID,
        Phone: value,
        Role: newGridData[index].EditedRole,
        Regions: newGridData[index].SelectRegions,
      };
      isSave = validateAllFields(validPayload, newGridData[index].email_id);
      updatedItem = {
        ...newGridData[index],
        EditedPhoneNumber: formatPhoneNumber(value),
        IsSave: !isSave,
      };
    } else if (field === "email") {
      let validPayload: ValidateFields = {
        Name: newGridData[index].EditedFullName,
        Email: value,
        Phone: newGridData[index].EditedPhoneNumber,
        Role: newGridData[index].EditedRole,
        Regions: newGridData[index].SelectRegions,
      };
      isSave = validateAllFields(validPayload, newGridData[index].email_id);
      updatedItem = {
        ...newGridData[index],
        EditedEmailID: value,
        IsSave: !isSave,
      };
    } else if (field === "role") {
      let validPayload: ValidateFields = {
        Name: newGridData[index].EditedFullName,
        Email: newGridData[index].EditedEmailID,
        Phone: newGridData[index].EditedPhoneNumber,
        Role: value,
        Regions: newGridData[index].SelectRegions,
      };
      isSave = validateAllFields(validPayload, newGridData[index].email_id);
      updatedItem = {
        ...newGridData[index],
        EditedRole: value,
        IsSave: !isSave,
      };
    }
    // Update the item in the new array
    newGridData[index] = updatedItem;
    setGridData(newGridData);
  };

  const handleRegionChange = (selectedOptions: any) => {
    setSelectedRegions(selectedOptions);
  };

  const handleAddClick = async () => {
    let NewUsedRegions = [...selectedRegions, ...UsedRegions];
    const PendingRegions = regionOptions.filter(
      (option) =>
        !NewUsedRegions.some((usedRegion) => usedRegion.value === option.value)
    );
    SetPendingRegions(PendingRegions);
    SetUsedRegions(NewUsedRegions);
    let regions: regionInput[] = [];
    selectedRegions.map((val) => {
      regions.push({ region: val?.label, region_id: val?.value });
    });
    const managePayload: gridUserDataModel = {
      full_name: formData.full_name,
      email_id: formData.email_id,
      phone_number: formData.phone_number,
      role: formData.role,
      regions: regions,
      admin_uuid: "",
      action: "create",
    };
    setShowLoader(true);
    const response = await manageUsers(managePayload);
    if (response?.code === 200) {
      setFormData({
        full_name: "",
        email_id: "",
        phone_number: "",
        role: "Admin",
        regions: [],
      });
      setShowLoader(false);
      setSelectedRegions([]);
      setHandleGrid(!handleGrid);
      setToastMessage({
        message: "User Created Successfully",
        type: "Success",
        desc: "",
      });
      setShowToast(true);
    } else {
      setShowLoader(false);
      setToastMessage({
        message: "Failed to create user",
        type: "error",
        desc: "",
      });
      setShowToast(true);
    }
  };

  const handleKeyDown = (e: any) => {
    // Check if the key pressed is 'Enter'
    if (e.key === "Enter" || e.keyCode === 13) {
      // Call the function you want to execute when Enter is pressed
      getGridData();
    }
  };
  const handleCloseModal = () => {
    setShowConfirmation(false);
  };
  const handleDeleteUser = async () => {
    const managePayload: gridUserDataModel = {
      full_name: "",
      email_id: "",
      phone_number: "",
      role: deletePayload.role,
      regions: deletePayload.Regions,
      admin_uuid: deletePayload.admin_uuid,
      action: "delete",
    };
    setShowConfirmation(false);
    setShowLoader(true);
    try {
      const response = await manageUsers(managePayload);
      if (response?.code === 200) {
        setToastMessage({
          message: "User Inactivated Successfully",
          type: "Success",
          desc: "",
        });
        setShowToast(true);
      } else {
        setToastMessage({
          message: "Failed to Inactivate User",
          type: "error",
          desc: "",
        });
        setShowToast(true);
      }
    } catch (error) {
    } finally {
      setShowConfirmation(false)
      setHandleGrid(!handleGrid);
    }
  };


  const bindGrid = () => {
    return gridData.map((user, ind) => {
      return (
        <tr key={user.admin_uuid}>
          {user.IsEdited ? (
            <td className="cust-input-field-width">
              <input
                type="text"
                value={user.EditedFullName}
                onChange={(e: any) => handleChangeGrid(e, ind, "name")}
                className="form-control service-form-field color-black-v2 font-13 font-regular "
                placeholder="Enter Full Name"
              />
            </td>
          ) : (
            <td>{user.EditedFullName}</td>
          )}
          {user.IsEdited ? (
            <td className="cust-input-field-width">
              <input
                type="email"
                disabled={user.IsEdited}
                value={user.EditedEmailID}
                onChange={(e: any) => handleChangeGrid(e, ind, "email")}
                className="form-control service-form-field color-black-v2 font-13 font-regular "
                placeholder="Enter Email ID"
              />
            </td>
          ) : (
            <td>{user.EditedEmailID}</td>
          )}
          {user.IsEdited ? (
            <td className="cust-input-field-width">
              <input
                type="text"
                value={user.EditedPhoneNumber}
                onChange={(e: any) => {
                  handleChangeGrid(e, ind, "phone")
                }}
                className="form-control service-form-field color-black-v2 font-13 font-regular "
                placeholder="Enter Phone #"
              />
            </td>
          ) : (
            <td>{`+1 ` + user.phone_number}</td>
          )}
          {user.IsEdited ? (
            <td className="cust-input-field-width">
              <select
                name="example"
                value={user.EditedRole}
                onChange={(e: any) => handleChangeGrid(e, ind, "role")}
                className=" d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular "
              >
                <option value="Select">Select</option>
                {/* <option value="Admin">Admin</option>
                <option value="Regional Manager">Regional Manager</option>
                <option value="Business Administrator">Business Administrator</option> */}
                {bindRolesDropdown()}
              </select>
            </td>
          ) : (
            <td>{user.EditedRole}</td>
          )}
          {user.IsEdited ? (
            <td>
              {user.EditedRole === "Regional Manager" ?
                <MultiSelect
                  options={user.RegionOptions}
                  //className="w-100 DropDown-Font-Size"
                  //id="PC"
                  // disabled={user.EditedRole === "Admin"}
                  value={user.SelectRegions}
                  onChange={(e: any) => handleGridRegion(e, ind)}
                  labelledBy={"Select"}
                />
                :
                // <MultiSelect
                //   options={user.RegionOptions}
                //   //className="w-100 DropDown-Font-Size"
                //   //id="PC"
                //   disabled={user.EditedRole === "Admin"}
                //   value={user.SelectRegions}
                //   onChange={(e: any) => handleGridRegion(e, ind)}
                //   labelledBy={"Select"}
                // />
                // <input
                //   type="text"
                //   className="form-control service-form-field color-black-v2 font-13 font-regular "
                //   disabled={true}
                // />
                null
              }

            </td>
          ) : (
            // <td>{user.RegionsGrid}</td>
            <td>
            { user.RegionsGrid && <TooltipText text={user.RegionsGrid} maxLength={15} />}
            </td>
          )}
          <td className="text-center">
            <span
              className={`font-semibold font-13 ${user.status === "Active" ? "success-color" : "alert-color"
                }`}
            >
              {user.status}
            </span>
          </td>
          {user.IsEdited ? (
            <td className="text-center">
              <div className="d-flex align-items-center gap-3">
                <button
                  className="btn green-btn px-4 font-12 font-semibold"
                  disabled={user.IsSave}
                  onClick={() => handleSaveClick("Save", ind)}
                >
                  Save
                </button>
                <button
                  className="btn red-btn px-3 font-12 font-semibold"
                  onClick={() => handleEditClick("Cancel", ind)}
                >
                  Cancel
                </button>
              </div>
            </td>
          ) : (
            <td className="text-center">
              <div className="d-flex align-items-center gap-3">
                <button
                  className="btn green-btn px-4 font-12 font-semibold"
                  onClick={() => handleEditClick("Edit", ind)}
                >
                  Edit
                </button>
                <button
                  className="btn red-btn px-3 font-12 font-semibold"
                  disabled={user.status !== "Active"}
                  onClick={() => handleSaveClick("Delete", ind)}
                >
                  Inactivate
                </button>
              </div>
            </td>
          )}
        </tr>
      );
    });
    // Assuming there's some logic to bind the grid data
    // Logic to bind gridData to UI components.
  };

  const Roles = [{ role: "Admin" }, { role: "Regional Manager" }, { role: "Business Administrator" }]

  const bindRolesDropdown = () => {
    return Roles?.map((data) => {
      return (
        <>
          <option value={data.role}>{data.role}</option>
        </>
      )
    })
  }

  return showLoader ? (
    <Loader />
  ) : (
    <div className="container-fluid p-4">
      <div className="d-flex align-items-center justify-content-between flex-wrap gap-2">
        <h2 className="color-black font-20 font-bold mb-0">Manage Users</h2>
        <div className="d-flex align-items-center justify-content-md-center gap-3 cust-flexwrap mt-2 mt-md-0">
          <div className="position-relative ">
            <input
              type="search"
              name="search"
              // className="custom-search bg-transparent custom-input-search font-14 font-medium color-black"
              className="custom-input-search font-14 font-medium color-black custom-search"

              placeholder="Search here"
              value={search}
              onKeyDown={handleKeyDown}
              onChange={(e) => handleChange("search", e.target.value)}
            />
            <button
              className="btn bg-transparent border-0 focus-none search-icon"
              onClick={handleSearchClick}
            >
              <img
                className="tab-icon"
                src="../images/search-icon.svg"
                alt="search"
              />
            </button>
          </div>
          <button
            className="btn common-btn px-3 show"
            aria-expanded="true"
            data-bs-auto-close="outside"
            onClick={handleApplyFilter}
          >
            <img src="../images/filter-icons.svg" alt="filter-icons" />
          </button>

          {isShowFilterPopup && (
            <AdvancedFilter
              contractorOptions={[]}
              ratingOptions={[]}
              onApply={ApplyFilterClick}
              onCancel={handleCloseAdvancedFilter}
              filter={filterData}
              type="Users"
              regions={regionsArray}
            />
          )}
        </div>
      </div>
      <div className="table-responsive theme-table bg-white custom-scroll my-4 w-100">
        <table className="table table-borderless mb-0 acc-view">
          <thead>
            <tr>
              <th className="ps-4 text-nowrap cursor-pointer" onClick={() =>
                setSortData({
                  Column: "ad.admin_name ",
                  Direction:
                    sortData.Direction === "ASC" ? "DESC" : "ASC",
                })
              }>
                Full Name
                <span className="ms-1 cursor-pointer">
                  <img
                    src={
                      sortData.Column === "ad.admin_name "
                        ? sortData.Direction === "ASC"
                          ? "../images/sort-arrow-down.svg"
                          : "../images/sort-up-icon.svg"
                        : "../images/sort-up-icon.svg"
                    }
                    alt={
                      sortData.Direction === "ASC"
                        ? "sort-arrow-down"
                        : "sort-arrow-up"
                    }
                  />
                </span>
              </th>
              <th className="ps-4 text-nowrap cursor-pointer" onClick={() =>
                setSortData({
                  Column: "ad.admin_email ",
                  Direction:
                    sortData.Direction === "ASC" ? "DESC" : "ASC",
                })
              }>
                Email ID
                <span className="ms-1 cursor-pointer">
                  <img
                    src={
                      sortData.Column === "ad.admin_email "
                        ? sortData.Direction === "ASC"
                          ? "../images/sort-arrow-down.svg"
                          : "../images/sort-up-icon.svg"
                        : "../images/sort-up-icon.svg"
                    }
                    alt={
                      sortData.Direction === "ASC"
                        ? "sort-arrow-down"
                        : "sort-arrow-up"
                    }
                  />
                </span>
              </th>
              <th className="text-nowrap cursor-pointer" onClick={() =>
                setSortData({
                  Column: "ad.admin_phone_number ",
                  Direction:
                    sortData.Direction === "ASC" ? "DESC" : "ASC",
                })
              }>
                Phone #
                <span className="ms-1 cursor-pointer">
                  <img
                    src={
                      sortData.Column === "ad.admin_phone_number "
                        ? sortData.Direction === "ASC"
                          ? "../images/sort-arrow-down.svg"
                          : "../images/sort-up-icon.svg"
                        : "../images/sort-up-icon.svg"
                    }
                    alt={
                      sortData.Direction === "ASC"
                        ? "sort-arrow-down"
                        : "sort-arrow-up"
                    }
                  />
                </span>
              </th>
              <th className="text-nowrap cursor-pointer" onClick={() =>
                setSortData({
                  Column: "ad.admin_role ",
                  Direction:
                    sortData.Direction === "ASC" ? "DESC" : "ASC",
                })
              }>
                Role
                <span className="ms-1 cursor-pointer">
                  <img
                    src={
                      sortData.Column === "ad.admin_role "
                        ? sortData.Direction === "ASC"
                          ? "../images/sort-arrow-down.svg"
                          : "../images/sort-up-icon.svg"
                        : "../images/sort-up-icon.svg"
                    }
                    alt={
                      sortData.Direction === "ASC"
                        ? "sort-arrow-down"
                        : "sort-arrow-up"
                    }
                  />
                </span>
              </th>
              <th className=" text-nowrap">Regions</th>
              <th className=" text-nowrap text-center cursor-pointer" onClick={() =>
                setSortData({
                  Column: "ad.is_active",
                  Direction:
                    sortData.Direction === "ASC" ? "DESC" : "ASC",
                })
              }>
                Status
                <span className="ms-1 cursor-pointer">
                  <img
                    src={
                      sortData.Column === "ad.is_active"
                        ? sortData.Direction === "ASC"
                          ? "../images/sort-arrow-down.svg"
                          : "../images/sort-up-icon.svg"
                        : "../images/sort-up-icon.svg"
                    }
                    alt={
                      sortData.Direction === "ASC"
                        ? "sort-arrow-down"
                        : "sort-arrow-up"
                    }
                  />
                </span>
              </th>
              <th className=" text-nowrap text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="cust-input-field-width">
                <input
                  type="text"
                  className="form-control service-form-field color-black-v2 font-13 font-regular "
                  value={formData.full_name}
                  onChange={(e) => handleChange("full_name", e.target.value)}
                  placeholder="Enter Full Name"
                />
              </td>
              <td className="cust-input-field-width">
                <input
                  type="email"
                  className="form-control service-form-field color-black-v2 font-13 font-regular "
                  value={formData.email_id}
                  onChange={(e) => handleChange("email_id", e.target.value.toLowerCase())}
                  placeholder="Enter Email ID"
                />
              </td>
              <td className="cust-input-field-width">
                <input
                  type="text"
                  className="form-control service-form-field color-black-v2 font-13 font-regular "
                  value={formData.phone_number}
                  onChange={(e) => handleChange("phone_number", e.target.value)}
                  placeholder="Enter Phone #"
                />
              </td>
              <td className="cust-input-field-width">
                <select
                  name="role"
                  onChange={(selectedOption) =>
                    handleChange("role", selectedOption?.target.value)
                  }
                  value={formData?.role}
                  className=" d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular "
                >
                  {/* <option value="Admin">Admin</option>
                  <option value="Regional Manager">Regional Manager</option>
                  <option value="Business Administrator">Business Administrator</option> */}
                  {bindRolesDropdown()}
                </select>
              </td>
              <td className="cust-input-field-width">
                {formData.role === "Regional Manager" ?
                  < MultiSelect
                    options={PendingRegions}
                    //className="w-100 DropDown-Font-Size"
                    //id="PC"
                    // disabled={
                    //   formData.role === "Admin" || PendingRegions.length === 0
                    // }
                    value={selectedRegions}
                    onChange={handleRegionChange}
                    labelledBy={"Select"}
                  />
                  :
                  null
                }

              </td>
              <td />
              <td className="text-center">
                <button
                  className="btn dark-blue-btn px-4 font-12 font-semibold"
                  disabled={isAddButtonDisabled}
                  onClick={() => handleAddClick()}
                >
                  Add
                </button>
              </td>
            </tr>
            {gridData.length > 0 ? bindGrid() : null}
          </tbody>
        </table>
      </div>
      {totalCount > 0 ? (
        <p className="font-14 font-medium color-grey-v7">
          Showing{" "}
          <span className="font-semibold color-black">
            {String(gridData.length).padStart(2, "0")}
          </span>{" "}
          out of{" "}
          <span className="font-semibold color-black">
            {String(totalCount).padStart(2, "0")}
          </span>{" "}
          {totalCount > 1 ? "Items" : "Item"}

          {gridData.length < totalCount &&
            gridData.length >= 20 && (
              <div className="d-flex align-items-center justify-content-center">
                <button
                  className="btn px-4 color-white font-semibold font-14 primary-btn px-4 rounded-3 text-center"
                  disabled={gridData.length === totalCount}
                  onClick={() => {
                    setLimit(limit + 20);
                  }}
                >
                  Load More
                </button>
              </div>
            )}
        </p>
      ) : null}
      {showConfirmation && (
        <FlagPopUp
          showModal={true}
          onConfirmFlag={handleDeleteUser}
          onClose={handleCloseModal}
          type="Users"
        />
      )}
      {showConfirmation && <div className="modal-backdrop fade show"></div>}
      {showToast && (
        <Toast
          setToastDetails={setShowToast}
          toastType={toastMessage.type === "Success" ? "Success" : "error"}
          toastMessage={toastMessage.message}
          toastDescription={toastMessage.desc}
        // You can dynamically set this based on the situation
        />
      )}
    </div>
  );
}
