
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Contractor,
  ContractorAction,
  ContractorActionResponse,
} from "../../Interface/ContractorGrid";
import { approveRejectApi } from "../../Service/ServiceRequest/ManageContractor/ManageContractorService";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/LoaderComponent";
import TooltipText from "../JobsAndBids/TooltipText";
import { encryptStorage } from "../../Configs/Constant";

interface AccountRequestsTabProps {
  data: Contractor[];
  loading: boolean;
  fetchGridData: () => void;
  handleSort: (sortBy: string, sortOrder: "ASC" | "DESC") => void;
  currentSortBy: string;
  currentSortOrder: "ASC" | "DESC";
  hasMore: boolean;
  onLoadMore: () => void;
  totalContractors: number;
  noOfContractors: number;
  displayToast: (message: string) => void; // Accept displayToast prop
  activeTab: "account-requests" | "all-contractors" | "invitee-responses";
}

function AccountRequestsTab({
  data,
  loading,
  fetchGridData,
  handleSort,
  currentSortBy,
  currentSortOrder,
  hasMore,
  onLoadMore,
  totalContractors,
  noOfContractors,
  displayToast,
  activeTab,
}: AccountRequestsTabProps) {
  const navigate = useNavigate();
  
  const handleAction = async (
    contractorId: string,
    action: "approve" | "reject"
  ) => {
    const actionData: ContractorAction = {
      contractor_id: contractorId,
      action: action,
    };

    try {
      const response: ContractorActionResponse = await approveRejectApi(
        actionData
      );
      if (response.code === 200) {
        fetchGridData();
        displayToast(
          `Contractor ${
            action === "approve" ? "approved" : "rejected"
          } successfully`
        );
      } else  if (response.code !== 200) {
        fetchGridData();
        displayToast(
          `Failed to update the contractor approval`
        );
      }
    } catch (error) {
      console.error("Error performing action:", error);
      displayToast("An error occurred while updating the contractor status");
    }
  };

  const onSort = (column: string) => {
    let newSortOrder: "ASC" | "DESC";
    if (currentSortBy === column) {
      newSortOrder = currentSortOrder === "DESC" ? "ASC" : "DESC";
    } else {
      newSortOrder = "DESC";
    }
    handleSort(column, newSortOrder);
  };

  const renderSortIcon = (column: string) => {
    let iconSrc = "../images/sort-arrow-down.svg";
    if (currentSortBy === column) {
      iconSrc =
        currentSortOrder === "DESC"
          ? "../images/sort-up-icon.svg"
          : "../images/sort-arrow-down.svg";
    }

    return (
      <span className="ms-1 cursor-pointer" onClick={() => onSort(column)}>
        <img
          src={iconSrc}
          alt={
            currentSortBy === column
              ? `sort-${currentSortOrder.toLowerCase()}`
              : "sort-arrow-down"
          }
        />
      </span>
    );
  };

  return (
    <>
      <div
        className="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        {/* Loader for initial fetch */}
        {loading && data.length === 0 && <Loader />}

        {!loading && data.length === 0 && (
          <div className="text-center mt-4">No account requests found</div>
        )}

        {data.length > 0 && (
          <div className="table-responsive theme-table bg-white custom-scroll mb-4 w-100">
            <table className="table table-borderless mb-0 acc-view">
              <thead>
                <tr>
                  <th
                    className="ps-4 text-nowrap cursor-pointer"
                    onClick={() => onSort("dba_name")}
                  >
                    DBA Name
                    {renderSortIcon("dba_name")}
                  </th>
                  <th
                    className="text-nowrap cursor-pointer"
                    onClick={() => onSort("business_address")}
                  >
                    Business Address
                    {renderSortIcon("business_address")}
                  </th>
                  <th
                    className="text-nowrap cursor-pointer"
                    onClick={() => onSort("email_address")}
                  >
                    Email &amp; Phone
                    {renderSortIcon("email_address")}
                  </th>
                  <th
                    className="text-nowrap cursor-pointer"
                    onClick={() => onSort("operating_states")}
                  >
                    Operating States
                    {renderSortIcon("operating_states")}
                  </th>
                  <th
                    className="text-nowrap cursor-pointer"
                    onClick={() => onSort("services_offered")}
                  >
                    # Services
                    {renderSortIcon("services_offered")}
                  </th>
                  <th
                    className="text-nowrap cursor-pointer"
                    onClick={() => onSort("license_validity")}
                  >
                    License Validity
                    {renderSortIcon("license_validity")}
                  </th>
                  <th
                    className="text-nowrap cursor-pointer"
                    onClick={() => onSort("account_status")}
                  >
                    Account Status
                    {renderSortIcon("account_status")}
                  </th>
                  <th className="text-nowrap text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((contractor, index) => (
                  <tr key={index}>
                    <td className="ps-4">
                      <a
                        className="link-text text-decoration-none"
                        onClick={() => {
                          encryptStorage.setItem(
                            "manageContractorProfileID",
                            contractor.contractor_id
                          );
                          navigate("/manage-contractors-profile", {
                            state: contractor.contractor_id,
                          });
                        }}
                      >
                        {contractor.dba_name || "-"}
                      </a>
                      <span className="font-12 font-medium color-grey-v3 d-block mt-2">
                        {contractor.contractor_status || "-"}
                      </span>
                    </td>
                    <td>
                    <TooltipText text={contractor.business_address.address_line_1 ? `${contractor.business_address.address_line_1}, ${contractor.business_address.city_state}` : "-"} maxLength={15} />
                    </td>
                    <td>
                      <span className="d-block font-13 color-black font-medium mb-2">
                        {contractor.email_address || "-"}
                      </span>
                      <span className="font-13 color-black font-medium">
                        {contractor.phone_number || "-"}
                      </span>
                    </td>
                    <td>
                      {contractor.operating_states && contractor.operating_states.filter(state => state.trim() !== "").length > 0
                        ? contractor.operating_states.filter(state => state.trim() !== "").join(", ")
                        : "-"
                      }
                    </td>
                    <td>
                      <span className="link-text">
                        {contractor.services_offered || "-"}
                      </span>
                    </td>
                    <td>
                      {contractor.license_validity &&
                      contractor.license_validity.length > 0
                        ? getLicenseValidityStatus(contractor.license_validity)
                        : "-"}
                    </td>
                    <td>{contractor.account_status || "-"}</td>
                    <td>
                      <div className="d-flex align-items-center gap-3 justify-content-center">
                        <button
                          className="btn green-btn px-4 font-12 font-semibold"
                          onClick={() =>
                            handleAction(contractor.contractor_id, "approve")
                          }
                        >
                          Approve
                        </button>
                        <button
                          className="btn red-btn px-4 font-12 font-semibold"
                          onClick={() =>
                            handleAction(contractor.contractor_id, "reject")
                          }
                        >
                          Reject
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <p className="font-14 font-medium color-grey-v7">
          Showing{" "}
          <span className="font-semibold color-black">{noOfContractors}</span>{" "}
          out of{" "}
          <span className="font-semibold color-black">{totalContractors}</span>{" "}
          Items
        </p>

        {hasMore && (
          <div className="d-flex align-items-center justify-content-center mt-4">
            <button
              className="btn px-4 color-white font-semibold font-14 primary-btn px-4 rounded-3 text-center"
              onClick={onLoadMore}
              disabled={loading}
            >
              {loading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </>
              ) : (
                "Load More"
              )}
            </button>
          </div>
        )}
      </div>
    </>
  );
}

const getLicenseValidityStatus = (licenses: string[]): string => {
  const validCount = licenses.filter(
    (license) => license.toLowerCase() === "valid"
  ).length;
  const invalidCount = licenses.length - validCount;

  if (validCount === licenses.length) {
    return "Valid";
  } else if (invalidCount === licenses.length) {
    return "Invalid";
  } else {
    return `${invalidCount} of ${licenses.length} are Invalid`;
  }
};

export default AccountRequestsTab;