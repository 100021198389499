import React from "react";
import {  ContractorProfileInterface, OnBoardingDropdownInterface } from "../../../Interface/ManageContractor";
import { extractWebsiteName } from "../../../Configs/Constant";
import TooltipText from "../../JobsAndBids/TooltipText";

const OnboardingContact = ({
  profileData,
}: {
  profileData: ContractorProfileInterface;
}) => {

  const jobTitle : OnBoardingDropdownInterface | undefined = profileData?.dropdowns?.find((data : OnBoardingDropdownInterface)=>data?.dropdown_name?.toLowerCase() === "job title")
  const heardAboutUs : OnBoardingDropdownInterface | undefined = profileData?.dropdowns?.find((data : OnBoardingDropdownInterface)=>data?.dropdown_name?.toLowerCase() === "heard about us")


  return (
    <div
      className="tab-pane fade show active"
      id="onboard-contact"
      role="tabpanel"
      aria-labelledby="onboard-contact-tab"
    >
      <h3 className="font-18 color-black font-semibold mb-4">
        Onboarding Contact
      </h3>
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">
            First Name
          </p>
          <p className="font-medium color-black-v1 mb-0 font-14">
            {/* David */}
            {profileData?.contractor_details?.first_name}
          </p>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">
            Last Name
          </p>
          <p className="font-medium color-black-v1 mb-0 font-14">
            {/* Jeffrey */}
            {profileData?.contractor_details?.last_name}
          </p>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">
            Job Title
          </p>
          <p className="font-medium color-black-v1 mb-0 font-14">
            {/* Warehouse Security */}
            {/* {profileData?.dropdowns?.find((ele:OnBoardingDropdownInterface)=>ele.dropdown_name==="Job Title")?.dropdown_value} */}
            {jobTitle?.dropdown_value?.toLowerCase() === "other" ? jobTitle?.others_name : jobTitle?.dropdown_value}
          </p>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">
            Email ID
          </p>
          <p className="font-medium color-black-v1 mb-0 font-14">
            {/* Event Management */}
            {profileData?.contractor_details?.contractor_email}
          </p>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">
            Phone #
          </p>
          <p className="font-medium color-black-v1 mb-0 font-14">
            {/* (433) 362-2829 */}
            {/* {profileData?.contractor_details?.phone_number &&
              formatPhoneNumber(profileData?.contractor_details?.phone_number)} */}
              {profileData?.contractor_details?.phone_number}
          </p>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">
            Password
          </p>
          <p className="font-medium color-black-v1 mb-0 font-14">******</p>
        </div>
      </div>
      <h3 className="font-18 color-black font-semibold mb-4">
        Business Information
      </h3>
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">
            Legal Business Name
          </p>
          <p className="font-medium color-black-v1 mb-0 font-14">
            {/* Warehouse Security */}
            <TooltipText text={profileData?.contractor_details?.legal_business_name} maxLength={25} />

            
          </p>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">
            DBA Business Name
          </p>
          <p className="font-medium color-black-v1 mb-0 font-14">
            {/* Warehouse Security */}
            <TooltipText text={profileData?.contractor_details?.dba_business_name} maxLength={25} />

          </p>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">
            Address Name 1
          </p>
          <p className="font-medium color-black-v1 mb-0 font-14">
            {/* 123 Spring West Street */}
            <TooltipText text={profileData?.contractor_details?.business_address_line_one} maxLength={25} />

          </p>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">
            Address Name 2
          </p>
          <p className="font-medium color-black-v1 mb-0 font-14">
            <TooltipText text={profileData?.contractor_details?.business_address_line_two ? profileData?.contractor_details?.business_address_line_two: "-"} maxLength={25} />
          </p>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">City</p>
          <p className="font-medium color-black-v1 mb-0 font-14">{profileData?.contractor_details?.business_city}</p>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">State</p>
          <p className="font-medium color-black-v1 mb-0 font-14">{profileData?.contractor_details?.business_state}</p>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">ZIP</p>
          <p className="font-medium color-black-v1 mb-0 font-14">{profileData?.contractor_details?.business_zip_code}</p>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">
            Website/Social Media Links
          </p>
          <a
            href={profileData?.contractor_details?.website_media_link}
            target="_blank"
            rel="noreferrer"
            className="font-medium purple-color mb-0 font-14 d-flex align-items-center gap-2 text-decoration-none"
          >
            {/* <img src="../images/insta.svg" alt="insta" /> */}
            {/* Instagram */}
            {profileData?.contractor_details?.website_media_link !=="" ? extractWebsiteName(profileData?.contractor_details?.website_media_link): "-"}
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">
            Heard About us from
          </p>
          <p className="font-medium color-black-v1 mb-0 font-14">
            {/* Another Security Provider */}
            {heardAboutUs?.dropdown_value?.toLowerCase() === "other" ? (heardAboutUs?.others_name ==="" ? "-": <TooltipText text={heardAboutUs?.others_name} maxLength={25} /> ) : (heardAboutUs?.dropdown_value ===""? "-": heardAboutUs?.dropdown_value)}
          </p>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">
            Year Founded
          </p>
          <p className="font-medium color-black-v1 mb-0 font-14">
          {/* 2000 */}
          {profileData?.contractor_details?.year_founded ? profileData?.contractor_details.year_founded : "-"}
          </p>
        </div>
        <div className="col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">
            Are you affiliated with any existing vendors in our network?
          </p>
          <p className="font-medium color-black-v1 mb-0 font-14">
            {/* Event Management */}
            {profileData?.contractor_details?.affiliated_with ? profileData?.contractor_details?.affiliated_with : "-"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OnboardingContact;
