
// Imports and interface definitions
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState} from 'react';
import { GridData } from '../../Interface/JobsAndBids';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/LoaderComponent';
import ContractorReinitiate from './ContractorReinitiate';
import TooltipText from './TooltipText';
import ContractorNameDisplay from './ContractorNameDisplay'; // Import the ContractorNameDisplay component

interface RejectedTabProps {
  role: string;
  data: GridData[];
  totalCount: number;
  onLoadMore: () => void;
  hasMore: boolean;
  loading: boolean;
  customerUUID?: string;
  contractorUUID?: string;
  renderSortIcon: (column: string) => JSX.Element;
  handleSort: (column: string) => void;
  // reloadGrid: () => void; // Add this prop
}

// Component definition
const RejectedTab: React.FC<RejectedTabProps> = ({
  role,
  data,
  totalCount,
  onLoadMore,
  hasMore,
  loading,
  customerUUID,
  contractorUUID,
  renderSortIcon,
  handleSort,
}) => {
  const navigate = useNavigate();
  const [isContractorReinitiateModalOpen, setIsContractorReinitiateModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState<GridData | null>(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const handleReinitiate = (job: GridData) => {
    setSelectedJob(job);
    setIsConfirmationModalOpen(true);
  };

  const closeReinitiateModal = () => {
    setIsContractorReinitiateModalOpen(false);
    setSelectedJob(null);
    onLoadMore(); // Refresh the grid
  };

  const proceedWithReinitiate = () => {
    if (selectedJob?.bid_owner === 'PPS') {
      navigate('/admin-reinitiate', { state: { id: selectedJob.service_request_uuid, status: 'rejected' } });
    } else if (selectedJob?.bid_owner === 'Contractor') {
      // Assuming you have the bid information in `selectedJob`
      setIsContractorReinitiateModalOpen(true);
    }
    setIsConfirmationModalOpen(false); // Close the confirmation modal
  };

  const renderBidAmount = (bidAmountArray: number[]) => {
    if (bidAmountArray.length === 0) {
      return 'Yet to be Decided';
    }
    if (bidAmountArray.length === 1) {
      return `$${bidAmountArray[0].toFixed(2)}`;
    }
    const minBid = Math.min(...bidAmountArray);
    const maxBid = Math.max(...bidAmountArray);
    return `$${minBid.toFixed(2)} - $${maxBid.toFixed(2)}`;
  };

  return (
    <div className="tab-pane fade show active" id="pills-rejected" role="tabpanel" aria-labelledby="pills-rejected-tab">
      <div className="table-responsive theme-table bg-white custom-scroll my-4 w-100 vertical-align-top">
        {data?.length > 0 ? (
          <table className="table table-borderless mb-0 acc-view">
            <thead>
              {customerUUID ? (
                <tr>
                  <th className="text-nowrap cursor-pointer" onClick={() => handleSort("sr.service_request")}>Job Title {renderSortIcon("sr.service_request")}</th>
                  <th className="text-nowrap cursor-pointer" onClick={() => handleSort("sr.service_city")}>Location {renderSortIcon("sr.service_city")}</th>
                  <th className="text-nowrap cursor-pointer" onClick={() => handleSort("sr.created_on")}>Job Raised On {renderSortIcon("sr.created_on")}</th>
                  {role === "Admin" && (
                    <th className="text-nowrap cursor-pointer" onClick={() => handleSort("contractor_name")}>Approved Contractor {renderSortIcon("contractor_name")}</th>
                  )}
                  <th className="text-nowrap cursor-pointer" onClick={() => handleSort("rejected_reason")}>Rejection Code {renderSortIcon("rejected_reason")}</th>
                  <th className="text-nowrap text-center">Actions</th>
                </tr>
              ) : contractorUUID ? (
                <tr>
                  <th className="text-nowrap cursor-pointer" onClick={() => handleSort("sr.service_request")}>Job Title {renderSortIcon("sr.service_request")}</th>
                  <th className="text-nowrap cursor-pointer" onClick={() => handleSort("sr.service_city")}>Location {renderSortIcon("sr.service_city")}</th>
                  <th className="text-nowrap cursor-pointer" onClick={() => handleSort("sr.created_on")}>Job Raised On {renderSortIcon("sr.created_on")}</th>
                  <th className="text-nowrap text-end" onClick={() => handleSort("bid_amount_value")}>Bid Amount {renderSortIcon("bid_amount_value")}</th>
                  <th className="text-nowrap cursor-pointer" onClick={() => handleSort("approved_by")}>Approved By {renderSortIcon("approved_by")}</th>
                  <th className="text-nowrap cursor-pointer" onClick={() => handleSort("rejected_reason")}>Rejection Code {renderSortIcon("rejected_reason")}</th>
                  <th className="text-nowrap text-center">Actions</th>
                </tr>
              ) : (
                <tr>
                  <th className="text-nowrap cursor-pointer" onClick={() => handleSort("sr.service_request")}>Job Title {renderSortIcon("sr.service_request")}</th>
                  <th className="text-nowrap cursor-pointer" onClick={() => handleSort("sr.service_city")}>Location {renderSortIcon("sr.service_city")}</th>
                  <th className="text-nowrap cursor-pointer" onClick={() => handleSort("sr.created_on")}>Job Raised On {renderSortIcon("sr.created_on")}</th>
                  {role === 'Admin' && <th className="text-nowrap cursor-pointer" onClick={() => handleSort("contractor_name")}>Approved Contractor {renderSortIcon("contractor_name")}</th>}
                  <th className="text-nowrap text-end" onClick={() => handleSort("bid_amount_value")}>Bid Amount {renderSortIcon("bid_amount_value")}</th>
                  <th className="text-nowrap cursor-pointer" onClick={() => handleSort("approved_by")}>Approved By {renderSortIcon("approved_by")}</th>
                  <th className="text-nowrap cursor-pointer" onClick={() => handleSort("rejected_reason")}>Rejection Code {renderSortIcon("rejected_reason")}</th>
                  <th className="text-nowrap text-center">Actions</th>
                </tr>
              )}
            </thead>
            {customerUUID ? (
              <tbody>
                {data?.map((job, index) => {
                  // Split contractor names and remove empty spaces
                  const contractorNamesArr = job.contractor_name
                    ? job.contractor_name.split(',').map(name => name.trim() === '' ? 'PPS Contractor' : name.trim())
                    : ['PPS Contractor'];

                  return (
                    <tr key={index}>
                      <td>
                        <a
                          className="link-text text-decoration-none"
                          onClick={() =>
                            navigate("/admin-reinitiate", {
                              state: {
                                id: job.service_request_uuid,
                                status: 'rejected',
                                amount: job.amount
                              },
                            })
                          }
                        >
                          <TooltipText text={job.service_request} maxLength={15} />
                        </a>
                      </td>
                      <td>{`${job.city}, ${job.state}`}</td>
                      <td>{dayjs(job.created_on).format("MMM DD, YYYY")}</td>
                      {role === "Admin" && (
                        <td>
                          <ContractorNameDisplay contractorNames={contractorNamesArr} />
                          <p className="font-11 font-medium primary-text-color-light mb-0">
                            {job.assignment_type}
                          </p>
                        </td>
                      )}
                      <td>
                        <p className="font-13 font-medium color-black mb-2">
                          {job.rejected_reason.trim() || "NA"}
                        </p>
                      </td>
                      <td className="text-center">
                        <button className="btn blue-btn px-4 font-12 font-semibold" onClick={() => handleReinitiate(job)}>
                          Re-initiate
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : contractorUUID ? (
              <tbody>
                {data?.map((job, index) => {
                  // Split contractor names and remove empty spaces
                  const contractorNamesArr = job.contractor_name
                    ? job.contractor_name.split(',').map(name => name.trim() === '' ? 'PPS Contractor' : name.trim())
                    : ['PPS Contractor'];

                  return (
                    <tr key={index}>
                      <td>
                        <a
                          className="link-text text-decoration-none"
                          onClick={() =>
                            navigate("/admin-reinitiate", {
                              state: {
                                id: job.service_request_uuid,
                                status: 'rejected',
                                amount: job.amount
                              },
                            })
                          }
                        >
                          <TooltipText text={job.service_request} maxLength={15} />
                        </a>
                      </td>
                      <td>{`${job.city}, ${job.state}`}</td>
                      <td>{dayjs(job.created_on).format("MMM DD, YYYY")}</td>
                      <td className="text-end">
                        {renderBidAmount(job.bid_amount_value)}
                      </td>
                      <td>
                        <p className="font-13 font-medium color-black mb-2">
                          {job.approved_by.trim() || "NA"}
                        </p>
                        <p className="font-11 font-medium primary-text-color-light mb-0">
                          Admin
                        </p>
                      </td>
                      <td>
                        <p className="font-13 font-medium color-black mb-2">
                          {job.rejected_reason.trim() || "NA"}
                        </p>
                      </td>
                      <td className="text-center">
                        <button className="btn blue-btn px-4 font-12 font-semibold" onClick={() => handleReinitiate(job)}>
                          Re-initiate
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody>
                {data?.map((job, index) => {
                  // Split contractor names and remove empty spaces
                  const contractorNamesArr = job.contractor_name
                    ? job.contractor_name.split(',').map(name => name.trim() === '' ? 'PPS Contractor' : name.trim())
                    : ['PPS Contractor'];

                  return (
                    <tr key={index}>
                      <td>
                        <a
                          className="link-text text-decoration-none cursor-pointer"
                          onClick={() =>
                            navigate("/admin-reinitiate", {
                              state: {
                                id: job.service_request_uuid,
                                status: 'rejected',
                                tab: "rejected",
                                amount: job.amount
                              }
                            })
                          }
                        >
                          <TooltipText text={job.service_request} maxLength={15} />
                        </a>
                      </td>
                      <td>{`${job.city}, ${job.state}`}</td>
                      <td>{dayjs(job.created_on).format('MMM DD, YYYY')}</td>
                      {role === 'Admin' && (
                        <td>
                          <ContractorNameDisplay contractorNames={contractorNamesArr} />
                          <p className="font-11 font-medium primary-text-color-light mb-0">
                            {job.assignment_type}
                          </p>
                        </td>
                      )}
                      <td className="text-end">
                        {renderBidAmount(job.bid_amount_value)}
                      </td>
                      <td>
                        <p className="font-13 font-medium color-black mb-2">
                          {job.approved_by.trim() || "NA"}
                        </p>
                        <p className="font-11 font-medium primary-text-color-light mb-0">
                          Admin
                        </p>
                      </td>
                      <td>
                        <p className="font-13 font-medium color-black mb-2">
                          {job.rejected_reason.trim() || "NA"}
                        </p>
                      </td>
                      <td className="text-center">
                        <button className="btn blue-btn px-4 font-12 font-semibold" onClick={() => handleReinitiate(job)}>
                          Re-initiate
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        ) : (
          <div className="text-center py-5">
            <img className="no-record-img mb-3" src="../images/no-record.gif" alt="No record" />
            <p className="font-medium font-20 color-grey-v1 mb-0">
              No records found
            </p>
          </div>
        )}
      </div>
      {hasMore && (
        <div className="d-flex align-items-center justify-content-center mt-4">
          <button
            className="btn px-4 color-white font-semibold font-14 primary-btn rounded-3 text-center"
            onClick={onLoadMore}
            disabled={loading}
          >
            {loading ? (
              <>
                <Loader />
              </>
            ) : 'Load More'}
          </button>
        </div>
      )}
      <p className="font-14 font-medium color-grey-v7">
        Showing <span className="font-semibold color-black">{data?.length}</span> out of <span className="font-semibold color-black">{totalCount}</span> Item{data?.length !== 1 ? 's' : ''}
      </p>
      {isConfirmationModalOpen && (
        <div
          className="modal fade show"
          id="exampleModal16"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content border-radius-10">
              <div className="modal-header border-0 px-4 pt-4 align-items-start">
                <h5
                  className="modal-title font-bold font-18 color-black-v2"
                  id="exampleModalLabel"
                >
                  Confirmation on Reinitiate
                </h5>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  onClick={() => setIsConfirmationModalOpen(false)}
                  aria-label="Close"
                />
              </div>
              <div className="modal-body px-4 py-0">
                <p className="font-medium font-16 mb-0 color-black cust-lh mb-4">
                  Job request rejected for{" "}
                  <span className="font-semibold">
                    “Unsatisfactory Past Performance”
                  </span>
                </p>
                <p className="font-medium font-16 mb-0 color-black cust-lh mb-3">
                  Reinitiating this request will send this request again for all the
                  contractors except the specific contractor who’ve been approved
                  currently.
                </p>
              </div>
              <div className="modal-footer gap-2 border-0 px-4 pb-4 pt-2 justify-content-end">
                <button
                  className="btn px-4 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn"
                  onClick={() => setIsConfirmationModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                  onClick={proceedWithReinitiate}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isContractorReinitiateModalOpen && selectedJob && (
        <ContractorReinitiate
          serviceRequestId={selectedJob.service_request_uuid}
          isOpen={isContractorReinitiateModalOpen}
          isClose={closeReinitiateModal}
        />
      )}
    </div>
  );
};


export default RejectedTab;