import React, { useState } from 'react';
import { FeedbacksRating } from '../../Interface/ManageFeedbackGrid';
import { filter } from '../../Interface/ManageUserGrid';

interface AdvancedFilterProps {
  contractorOptions: string[];
  ratingOptions: FeedbacksRating[];
  onApply: any;
  onCancel: () => void;
  filter: any;
  type?: string;
  regions?: string[];
}

const AdvancedFilter: React.FC<AdvancedFilterProps> = ({
  contractorOptions,
  ratingOptions,
  onApply,
  onCancel,
  filter,
  type,
  regions,
}) => {
  const [selectedContractor, setSelectedContractor] = React.useState<string>(filter.contractor);
  const [selectedRating, setSelectedRating] = React.useState<number>(filter.rating);
  const [selectedUsers, setSelectedUsers] = useState<filter>({
    Role: filter.Role, Region: filter.Region, Status: filter.Status
  });
  const handleApplyClick = () => {
    if (type === "Feedback") {
      onApply(selectedContractor, selectedRating);
    }
    else if (type === "Users") {
      onApply(selectedUsers);
    }
  };

  const handleCancelFilter = () => {
    if (type === "Feedback") {
      setSelectedContractor('')
      setSelectedRating(0)
      onApply("", 0)
    }
    else if (type === "Users") {
      setSelectedUsers({ Role: '', Region: '', Status: '' })
      onApply({ Role: '', Region: '', Status: '' })
    }

  };

  return (
    <>
      {type === "Feedback" &&
        <div className="dropdown-menu dd-box type-one-box shadow-sm p-4 border-0 show" >
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="font-16 font-semibold color-black mb-0">Advanced Filter</h5>
            <button className="btn-close font-12" onClick={onCancel} aria-label="Close" />
          </div>
          <div className="my-3">
            <label htmlFor="Rating" className="form-label color-grey-v1 font-12 font-medium">Feedback Rate</label>
            <select
              value={selectedRating}
              onChange={(e) => setSelectedRating(parseInt(e.target.value))}
              className="w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular bg-transparent"
            >
              <option value="0">Select Rating</option>
              {ratingOptions.map((rating, index) => (
                <option key={index} value={rating.Rating}>{rating.Rating_Name}</option>
              ))}
            </select>
          </div>
          <div className="my-3">
            <label htmlFor="Contractor" className="form-label color-grey-v1 font-12 font-medium">Contractor</label>
            <select
              value={selectedContractor}
              onChange={(e) => setSelectedContractor(e.target.value)}
              className="w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular bg-transparent"
            >
              <option value="">Select Contractor</option>
              {contractorOptions.map((contractor, index) => (
                <option key={index} value={contractor}>{contractor}</option>
              ))}
            </select>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <button className="btn px-4 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn" onClick={handleCancelFilter}>Cancel</button>
            <button className="btn px-4 color-white font-semibold font-14 primary-btn px-4 rounded-3" onClick={handleApplyClick}>Apply</button>
          </div>
        </div>
      }
      {type === "Users" &&
        <div className="dropdown-menu dd-box type-one-box shadow-sm p-4 border-0 show" data-popper-placement="bottom-start" >
          <div className="col-md-12">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="font-16 font-semibold color-black mb-0">Advanced Filter</h5>
              <button className="btn-close font-12" data-bs-dismiss="modal" onClick={onCancel} aria-label="Close" />
            </div>
            <div className="row mt-3">
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <label htmlFor="Rating" className="form-label color-grey-v1 font-12 font-medium">Role
                  </label>
                  <select name="example" id="Incident"
                    value={selectedUsers.Role}
                    onChange={(e) => setSelectedUsers({ ...selectedUsers, Role: e.target.value })}
                    className="w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular bg-transparent">
                    <option value="">Select</option>
                    <option value="Admin">Admin</option>
                    <option value="Regional Manager">Regional Manager</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <label htmlFor="Rating" className="form-label color-grey-v1 font-12 font-medium">Status
                  </label>
                  <select name="example" id="Incident"
                    value={selectedUsers.Status}
                    onChange={(e) => setSelectedUsers({ ...selectedUsers, Status: e.target.value })}
                    className="w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular bg-transparent">
                    <option value="">Select</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                </div>

              </div>
              <div className="col-md-12 col-sm-12">
                <div className="mb-3">
                  <label htmlFor="Rating" className="form-label color-grey-v1 font-12 font-medium">Region
                  </label>
                  <select name="example" id="Incident"
                    value={selectedUsers.Region}
                    onChange={(e) => setSelectedUsers({ ...selectedUsers, Region: e.target.value })}
                    className="w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular bg-transparent">
                    <option value="">Select Region</option>
                    {regions && regions.map((regions, index) => (
                      <option key={index} value={regions}>{regions}</option>
                    ))}
                  </select>
                </div>

              </div>
            </div>
            <div className="d-flex justify-content-end mt-4">
              <button className="btn px-4 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn" onClick={handleCancelFilter}>Cancel</button>
              <button className="btn px-4 color-white font-semibold font-14 primary-btn px-4 rounded-3" onClick={handleApplyClick}>Apply</button>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default AdvancedFilter;


