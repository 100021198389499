/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useState } from "react";
import WaitingApprovalTab from "./WaitingApprovalTab";
import OpenTab from "./OpenTab";
import InProgressTab from "./InProgressTab";
import RejectedTab from "./RejectedTab";
import ClosedTab from "./ClosedTab";
import { getJobsAndBids } from "../../Service/JobsAndBidsServices/JobsAndBidsServices";
import { GridData } from "../../Interface/JobsAndBids";
import FilterComponent from "./FilterComponent";
import { rmApproveRejectBids } from "../../Service/ServiceRequestView/ServiceRequestView";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { encryptStorage, ppsContext } from "../../Configs/Constant";
import { contextValueInterface } from "../../Interface/HeaderDetails";
import Loader from "../Loader/LoaderComponent";
import { useLocation } from "react-router-dom";
import { CustomToast } from "../Common/Toast";

interface Filter {
  from?: string;
  to?: string;
  status?: string;
  contractor_name?: string;
  contractor_uuid?: string | null;
  client_uuid?: string | null;
  raised_on_from?: string;
  raised_on_to?: string;
  bid_amount?: number | null;
  approved_by?: string;
}

interface RequestPayload {
  type: string;
  tab: string;
  search: string;
  limit: number;
  sort: string;
  filter: Filter;
}

const filterConfigurations: any = {
  Admin: {
    "awaiting approval": [
      {
        key: "requested_on_date_range",
        label: "Requested on Date Range",
        type: "dateRange",
      },
      {
        key: "contractor",
        label: "Contractor",
        type: "dropdown",
        options: "contractor_names",
      },
    ],
    open: [
      {
        key: "raised_on_date_range",
        label: "Raised On Date Range",
        type: "dateRange",
      },
      {
        key: "requested_on_date_range",
        label: "Requested on Date Range",
        type: "dateRange",
      },
    ],
    "in progress": [
      {
        key: "requested_on_date_range",
        label: "Requested on Date Range",
        type: "dateRange",
      },
      {
        key: "contractor",
        label: "Contractor",
        type: "dropdown",
        options: "contractor_names",
      },
      { key: "bid_amount", label: "Bid Amount", type: "number" },
      {
        key: "approved_by",
        label: "Approved by",
        type: "dropdown",
        options: [],
      },
    ],
    rejected: [
      {
        key: "raised_on_date_range",
        label: "Raised On Date Range",
        type: "dateRange",
      },
      {
        key: "contractor",
        label: "Contractor",
        type: "dropdown",
        options: "contractor_names",
      },
      { key: "bid_amount", label: "Bid Amount", type: "number" },
      {
        key: "approved_by",
        label: "Approved by",
        type: "dropdown",
        options: [],
      },
    ],
    closed: [
      {
        key: "raised_on_date_range",
        label: "Raised On Date Range",
        type: "dateRange",
      },
      {
        key: "requested_on_date_range",
        label: "Requested on Date Range",
        type: "dateRange",
      },
      {
        key: "contractor",
        label: "Contractor",
        type: "dropdown",
        options: "contractor_names",
      },
      {
        key: "approved_by",
        label: "Approved by",
        type: "dropdown",
        options: "admin_names",
      },
    ],
  },
  RM: {
    "awaiting approval": [
      {
        key: "raised_on_date_range",
        label: "Raised On Date Range",
        type: "dateRange",
      },
      {
        key: "requested_on_date_range",
        label: "Requested on Date Range",
        type: "dateRange",
      },
    ],
    open: [
      {
        key: "raised_on_date_range",
        label: "Raised On Date Range",
        type: "dateRange",
      },
      {
        key: "requested_on_date_range",
        label: "Requested on Date Range",
        type: "dateRange",
      },
    ],
    "in progress": [
      {
        key: "raised_on_date_range",
        label: "Raised On Date Range",
        type: "dateRange",
      },
      {
        key: "requested_on_date_range",
        label: "Requested on Date Range",
        type: "dateRange",
      },
      { key: "bid_amount", label: "Bid Amount", type: "number" },
      {
        key: "approved_by",
        label: "Approved by",
        type: "dropdown",
        options: "admin_names",
      },
    ],
    rejected: [
      {
        key: "raised_on_date_range",
        label: "Raised On Date Range",
        type: "dateRange",
      },
      {
        key: "contractor",
        label: "Contractor",
        type: "dropdown",
        options: "contractor_names",
      },
      { key: "bid_amount", label: "Bid Amount", type: "number" },
      {
        key: "approved_by",
        label: "Approved by",
        type: "dropdown",
        options: "admin_names",
      },
    ],
    closed: [
      {
        key: "raised_on_date_range",
        label: "Raised On Date Range",
        type: "dateRange",
      },
      {
        key: "requested_on_date_range",
        label: "Requested on Date Range",
        type: "dateRange",
      },
      {
        key: "contractor",
        label: "Contractor",
        type: "dropdown",
        options: "contractor_names",
      },
      {
        key: "approved_by",
        label: "Approved by",
        type: "dropdown",
        options: "admin_names",
      },
    ],
  },
};

const JobsAndBids = (props?: {
  contractorUUID?: string;
  customerUUID?: string;
}) => {
  const location = useLocation();

  const initialFilterState: Filter = {
    from: "",
    to: "",
    status: "",
    contractor_name: "",
    contractor_uuid:
      location.pathname === "/manage-contractors-profile"
        ? encryptStorage.getItem("manageContractorProfileID") || null
        : "",
    client_uuid:
      location.pathname === "/manage-clients-profile"
        ? encryptStorage.getItem("manageCustomerProfileID") || null
        : "",
    raised_on_from: "",
    raised_on_to: "",
    bid_amount: null,
    approved_by: "",
  };
  const [selectedTab, setSelectedTab] = useState("awaiting approval");
  const [gridData, setGridData] = useState<GridData[]>([]);
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [contractorNames, setContractorNames] = useState<string[]>([]);
  const [adminNames, setAdminNames] = useState<string[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [limit, setLimit] = useState(20);
  const [currentSortBy, setCurrentSortBy] = useState<string>("sr.created_on");
  const [currentSortOrder, setCurrentSortOrder] = useState<string>("DESC");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState(""); // Term used for input
  const [fetchSearchTerm, setFetchSearchTerm] = useState(""); // Term used for fetching data
  const [customerUUID, setCustomerUUID] = useState(initialFilterState.client_uuid !== null ? initialFilterState.client_uuid : "");
  const [contractorUUID, setContractorUUID] = useState(initialFilterState.contractor_uuid !== null ? initialFilterState.contractor_uuid : "");
  const [isTimerToast, setIsTimerToast] = useState<boolean>(false)
  const [showToastProp, setShowToastProp] = useState<any>({ title: "", type: "", message: "" });

  // const [searchTerm, setSearchTerm] = useState(''); // Adding searchTerm state

  const [filters, setFilters] = useState<any>(initialFilterState);

  const contextValue: contextValueInterface = useContext(ppsContext);
  const role = contextValue.userDetails.role; // Assuming userDetails contains a property named 'role'
  const userRole =
    role === "Admin" ? "Admin" : role === "Regional Manager" ? "RM" : "";

  const fetchGridData = useCallback(async (
    appliedFilters = filters,
    tab = selectedTab,
    limitCount = limit,
    search = fetchSearchTerm,
    sortBy = currentSortBy,
    sortOrder = currentSortOrder // Add sortBy and sortOrder as arguments
  ) => {
    try {
      const requestPayload: RequestPayload = {
        type: "admin",
        tab,
        search,
        limit: limitCount,
        sort: `${sortBy} ${sortOrder}`,
        filter: {
          ...appliedFilters,
          client_uuid: customerUUID,
          contractor_uuid: contractorUUID,
        },
      };

      const response = await getJobsAndBids(requestPayload);
      setLoading(true);  // Set loading to true before starting the fetch
      if (response?.code === 200) {
        setGridData(response?.data?.grid_details);
        setContractorNames(response?.data?.contractor_names || []);
        setAdminNames(response?.data?.admin_names || []);
        setTotalCount(response?.data?.total_counts || 0);
      } else if (response?.statusCode === 204) {
        setGridData([]);
        setContractorNames([]);
        setAdminNames([]);
      }
    } catch (error) {
      console.error("Error fetching grid data:", error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters,
    selectedTab,
    limit,
    fetchSearchTerm,
    customerUUID,
    contractorUUID,
  ]);

  useEffect(() => {
    location?.state?.tab && setSelectedTab(location?.state?.tab);
    if (location?.state?.tab) {
      fetchGridData(initialFilterState, location?.state?.tab, 20);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.tab]);

  useEffect(() => {
    if (initialFilterState.contractor_uuid !== null && initialFilterState.contractor_uuid !== undefined && initialFilterState.contractor_uuid !== "") {
      setSelectedTab('in progress')
    }
    if (initialFilterState.client_uuid !== null && initialFilterState.client_uuid !== undefined && initialFilterState.client_uuid !== "") {
      setSelectedTab('awaiting approval')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCustomerUUID(initialFilterState.client_uuid !== null ? initialFilterState.client_uuid : "");
    setContractorUUID(initialFilterState.contractor_uuid !== null ? initialFilterState.contractor_uuid : "");
    fetchGridData(filters, selectedTab, limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchGridData, selectedTab, limit, filters]);

  const handleTabClick = (tab: string) => {
    setLoading(true);  // Set loading to true when switching tabs
    setSelectedTab(tab);
    setFilters(initialFilterState);
    setLimit(20);  // reset limit on tab change
    fetchGridData(initialFilterState, tab, 20);  // Fetch data for the new tab
  };

  const handleLoadMore = () => {
    const newLimit = limit + 20; // increase limit by 20
    setLimit(newLimit);
    setLoadingMore(true);
    fetchGridData(filters, selectedTab, newLimit);
    setLoadingMore(false);
  };

  const handleApplyFilter = (appliedFilters: Filter) => {
    setFilters(appliedFilters);
    setShowFilter(false);
    setLoading(true); 
    setLimit(20); // reset limit on filter application
    fetchGridData(appliedFilters, selectedTab, 20);
    
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const handleCancelFilter = () => {
    setFilters(initialFilterState);
    setShowFilter(false);
    setLoading(true); 
    setLimit(20); // reset limit on filter cancellation
    fetchGridData(initialFilterState, selectedTab, 20);
  };

  const getFilterConfiguration = (role: string, tab: string) => {
    return filterConfigurations[role]?.[tab] || [];
  };

  const handleSort = (column: string) => {
    setLoading(true); // Start by setting the loading to true to show a loading indicator

    const sortOrder = currentSortBy === column && currentSortOrder === "DESC" ? "ASC" : "DESC";
    setCurrentSortBy(column);
    setCurrentSortOrder(sortOrder);

    // Use setTimeout with 0 delay to ensure states are updated before fetching data
    setTimeout(() => {
      fetchGridData(filters, selectedTab, 20, fetchSearchTerm, column, sortOrder);
    }, 0);
  };

  const renderSortIcon = (column: string) => {
    let iconSrc = "../images/sort-arrow-down.svg";
    if (currentSortBy === column) {
      iconSrc =
        currentSortOrder === "DESC"
          ? "../images/sort-up-icon.svg"
          : "../images/sort-arrow-down.svg";
    }
    return <img src={iconSrc} alt="sort-icon" />;
  };

  const handleApproveRejectJob = async (selectedJob: any, action: any) => {
    if (selectedJob) {
      const payload = {
        service_request_uuid: selectedJob.service_request_uuid,
        action: action,
        is_extended: selectedJob.is_extended
      };

      try {
        const response = await rmApproveRejectBids(payload);
        if (response?.code === 200) {
          setToastMessage(
            `The job has been successfully ${action.toLowerCase()}.`
          );
          setShowToast(true);
          fetchGridData(filters, selectedTab, limit);
          // Consider refreshing the grid data here if necessary
        } else {
          setToastMessage(`Failed to ${action.toLowerCase()} the job.`);
          setShowToast(true);
        }
      } catch (error) {
        console.error(`Failed to ${action.toLowerCase()} the job:`, error);
        setToastMessage(
          `An error occurred. Could not ${action.toLowerCase()} the job.`
        );
        setShowToast(true);
      }
      setTimeout(() => {
        setShowToast(false);
      }, 3000);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = () => {
    setFetchSearchTerm(searchTerm); // Update the term used for fetching data
    fetchGridData(filters, selectedTab, limit, searchTerm); // Fetch data with the new term
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className="container-fluid p-4">
      {!props?.contractorUUID && !props?.customerUUID && (
        <h2 className="color-black font-20 font-bold mb-4">
          Manage Jobs & Bids
        </h2>
      )}

      <div className={`${((customerUUID === undefined || customerUUID === "") && (contractorUUID === undefined || contractorUUID === "")) && "mt-4"} d-flex align-items-center justify-content-between flex-wrap gap-3`}>
        <div className="tab-scroll">
          <ul
            className="nav nav-pills cust-nav-pills flex-nowrap"
            id="pills-tab"
            role="tablist"
          >
            {!props?.contractorUUID && (
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link cust-nav-link ${selectedTab === "awaiting approval" ? "active" : ""
                    } text-nowrap`}
                  id="pills-Waiting-tab"
                  type="button"
                  role="tab"
                  aria-controls="pills-Waiting"
                  aria-selected={selectedTab === "awaiting approval"}
                  onClick={() => handleTabClick("awaiting approval")}
                >
                  Waiting Approval
                </button>
              </li>
            )}

            {!props?.contractorUUID && (
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link cust-nav-link ${selectedTab === "open" ? "active" : ""
                    } text-nowrap`}
                  id="pills-home-tab"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected={selectedTab === "open"}
                  onClick={() => handleTabClick("open")}
                >
                  Open
                </button>
              </li>
            )}

            <li className="nav-item" role="presentation">
              <button
                className={`nav-link cust-nav-link ${selectedTab === "in progress" ? "active" : ""
                  } text-nowrap`}
                id="pills-profile-tab"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected={selectedTab === "in progress"}
                onClick={() => handleTabClick("in progress")}
              >
                In Progress
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link cust-nav-link ${selectedTab === "rejected" ? "active" : ""
                  } text-nowrap`}
                id="pills-contact-tab"
                type="button"
                role="tab"
                aria-controls="pills-contact"
                aria-selected={selectedTab === "rejected"}
                onClick={() => handleTabClick("rejected")}
              >
                Rejected
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link cust-nav-link ${selectedTab === "closed" ? "active" : ""
                  } text-nowrap`}
                id="pills-closed-tab"
                type="button"
                role="tab"
                aria-controls="pills-closed"
                aria-selected={selectedTab === "closed"}
                onClick={() => handleTabClick("closed")}
              >
                Closed
              </button>
            </li>
          </ul>
        </div>

        {!props?.contractorUUID && !props?.customerUUID && (
          <div className="d-flex align-items-center justify-content-md-center gap-3 flex-wrap mt-2 mt-md-0">
            <div className="position-relative">
              <input
                type="search"
                className="custom-input-search font-14 font-medium color-black custom-search"
                placeholder="Search here"
                value={searchTerm}
                onChange={handleSearchChange}
                onKeyPress={handleKeyPress}
              />
              <button
                className="btn bg-transparent border-0 focus-none search-icon"
                onClick={handleSearch}
              >
                <img
                  className="tab-icon"
                  src="../images/search-icon.svg"
                  alt="search"
                />
              </button>
            </div>
            <button
              className="btn common-btn px-3"
              onClick={() => setShowFilter(!showFilter)}
            >
              <img src="../images/filter-icons.svg" alt="filter-icons" />
            </button>
            {showFilter && (
              <FilterComponent
                activeTab={selectedTab}
                onApplyFilter={handleApplyFilter}
                onClose={handleCloseFilter}
                onCancel={handleCancelFilter}
                filterConfig={getFilterConfiguration(userRole, selectedTab)}
                contractorNames={contractorNames}
                adminNames={adminNames}
                initialFilters={filters}
              />
            )}
          </div>
        )}
      </div>

      <div className="tab-content pt-1" id="pills-tabContent">
        {loading ? (
          <Loader />
        ) : (
          <>
            {gridData?.length === 0 ? (
              <div className="d-flex align-items-center justify-content-center p-5 flex-column">
                <img
                  className="no-record-img"
                  src="../images/no-record.gif"
                  alt="No record"
                />
                <p className="font-medium font-20 color-grey-v1 mb-0 mt-3 text-center">
                  It looks like there are currently no records to display
                </p>
              </div>
            ) : (
              <>
                <div
                  className={`tab-pane fade ${selectedTab === "awaiting approval" ? "show active" : ""
                    }`}
                >
                  <WaitingApprovalTab
                    role={userRole}
                    data={gridData}
                    totalCount={totalCount}
                    onLoadMore={handleLoadMore}
                    hasMore={gridData?.length < totalCount}
                    loading={loadingMore}
                    renderSortIcon={renderSortIcon}
                    handleSort={handleSort}
                    onApproveRejectJob={handleApproveRejectJob}
                  />
                </div>
                <div
                  className={`tab-pane fade ${selectedTab === "open" ? "show active" : ""
                    }`}
                >
                  <OpenTab
                    role={userRole}
                    data={gridData}
                    totalCount={totalCount}
                    onLoadMore={handleLoadMore}
                    hasMore={gridData?.length < totalCount}
                    loading={loadingMore}
                    setShowToast={setShowToast}
                    setToastMessage={setToastMessage}
                    isTimerToast={isTimerToast}
                    setShowToastProp={setShowToastProp}
                    showToastProp={showToastProp}
                    setIsTimerToast={setIsTimerToast}
                    fetchGridData={fetchGridData}
                    renderSortIcon={renderSortIcon}
                    handleSort={handleSort}
                    customerUUID={customerUUID}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                  />
                </div>
                <div
                  className={`tab-pane fade ${selectedTab === "in progress" ? "show active" : ""
                    }`}
                >
                  <InProgressTab
                    role={userRole}
                    data={gridData}
                    totalCount={totalCount}
                    onLoadMore={handleLoadMore}
                    hasMore={gridData?.length < totalCount}
                    loading={loadingMore}
                    customerUUID={customerUUID}
                    renderSortIcon={renderSortIcon}
                    handleSort={handleSort}
                    contractorUUID={contractorUUID}
                  />
                </div>
                <div
                  className={`tab-pane fade ${selectedTab === "rejected" ? "show active" : ""
                    }`}
                >
                  <RejectedTab
                    role={userRole}
                    data={gridData}
                    totalCount={totalCount}
                    onLoadMore={handleLoadMore}
                    hasMore={gridData?.length < totalCount}
                    loading={loadingMore}
                    renderSortIcon={renderSortIcon}
                    handleSort={handleSort}
                    customerUUID={customerUUID}
                    contractorUUID={contractorUUID}
                  />
                </div>
                <div
                  className={`tab-pane fade ${selectedTab === "closed" ? "show active" : ""
                    }`}
                >
                  <ClosedTab
                    role={userRole}
                    data={gridData}
                    totalCount={totalCount}
                    onLoadMore={handleLoadMore}
                    hasMore={gridData?.length < totalCount}
                    loading={loadingMore}
                    currentSortBy={currentSortBy}
                    currentSortOrder={currentSortOrder}
                    renderSortIcon={renderSortIcon}
                    handleSort={handleSort}
                    customerUUID={customerUUID}
                    contractorUUID={contractorUUID}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
      {showToast && (
        <div
          className="position-fixed top-0 start-50 translate-middle-x p-3"
          style={{ zIndex: 9999 }}
        >
          <div
            className="toast cust-toast w-100 p-1 toast-border fade show bg-white"

            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="toast-body d-flex align-items-center justify-content-between gap-5">
              <div className="d-flex align-items-center gap-3">
                <img src="../images/toast-sucess.svg" alt="Successfully" />
                <div className="d-block">
                  <p className="mb-0 font-4 font-semibold color-black text-nowrap mb-2">
                    Action Successful
                  </p>
                  <p className="font-medium font-14 mb-0 color-grey-v3 text-nowrap">
                    {toastMessage}
                  </p>
                </div>
              </div>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowToast(false)}
                aria-label="Close"
              />
            </div>
          </div>
        </div>
      )}
      {isTimerToast && <CustomToast
        props={showToastProp}
        setShowToast={setIsTimerToast}
      />}
    </div>
  );
};

export default JobsAndBids;
