import { useEffect, useState } from "react";
import { ServiceProfileFormProps } from "../../../Interface/EditContractor/Signup";


export function ServiceProfileForm({ formPayload, handleInputChange, bindInterestedAssignmentsDropdown, bindReasonForJoiningDropdown, bindExpriencedIndustryDropdown, bindPastServicesDropdown, showOthers, handlePrevious, handleNext, currentStep, serviceProfileErrors, handleCancel, PastServiceData, IndustriesData, Assignments, ReasonForJoining }: ServiceProfileFormProps) {
    const PastService = PastServiceData.find((ele) => ele.service_name === "Others")
    const Industries = IndustriesData.find((ele) => ele.industry_name === "Other")
    const [NextButtonServiceProfile, setNextButtonServiceProfile] = useState(false)
    // PS_CSU__2
    useEffect(() => {
        HandleValid()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formPayload, PastServiceData, IndustriesData, Assignments, ReasonForJoining, serviceProfileErrors]);

    const MAX_OTHERS_LENGTH = 255;

    // PS_CSU__7
    const HandleValid = () => {
        let isFieldValid = false;
        let isNoError = false;
        // /PastServiceData: OfficerService[]

        // Check if all required fields are filled
        if (PastServiceData.length !== 0 && IndustriesData.length !== 0 && ReasonForJoining.length !== 0) {
            isFieldValid = true
        }
        if (serviceProfileErrors.exprienced_industry === "" && serviceProfileErrors.past_services === "" && serviceProfileErrors.interested_assignments === "" && serviceProfileErrors.reason_for_joining === "") {
            isNoError = true
        }
        if (showOthers.industry) {
            let otherIndustry = IndustriesData.find((industry) => industry.industry_name === "Other")
            if (otherIndustry?.others !== "") {
                isFieldValid = true
            } else {
                isFieldValid = false
            }
        }
        if (showOthers.past_service) {
            let otherPastService = PastServiceData.find((pastService) => pastService.service_name === "Others")
            if (otherPastService?.others !== "") {
                isFieldValid = true
            } else {
                isFieldValid = false
            }
        }

        setNextButtonServiceProfile((prev) => (isFieldValid && isNoError))
    }

    return (
        <>
            <div className="d-flex align-items-center gap-3 mb-5">
                <h2 className="color-black font-600 font-18 mb-0">Industry Experience</h2>
            </div>
            <div className="col-lg-6 mb-4 mb-lg-5">
                <label htmlFor="IA" className="font-semibold font-16 color-black-v2 mb-2">
                    Interested Assignments
                </label>
                {/* <select
                    name="example"
                    id="IA"
                    className="w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular"
                >
                </select> */}
                {bindInterestedAssignmentsDropdown()}

                {serviceProfileErrors.interested_assignments !== "" &&
                    <span className="font-14 font-medium alert-text">
                        {serviceProfileErrors.interested_assignments}
                    </span>
                }
            </div>
            <div className="col-lg-6 mb-4 mb-lg-5">
                <label htmlFor="lPPS" className="font-semibold font-16 color-black-v2 mb-2">
                    Reason for joining PPS Contractor?{" "}
                    <span className="madatory-text">*</span>
                </label>
                {/* <select
                    name="example"
                    id="lPPS"
                    className="w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular"
                >
                    <option value="option1">Select</option>
                </select> */}
                {bindReasonForJoiningDropdown()}
                {serviceProfileErrors.reason_for_joining !== "" &&
                    <span className="font-14 font-medium alert-text">
                        {serviceProfileErrors.reason_for_joining}
                    </span>
                }
            </div>
            <div className="col-lg-6 mb-4 mb-lg-5">
                <label htmlFor="EI" className="font-semibold font-16 color-black-v2 mb-2">
                    Experienced Industries
                    <span className="madatory-text">*</span>
                </label>
                {/* <select
                    name="example"
                    id="EI"
                    className="w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular"
                >
                    <option value="option1">Select</option>
                    
                </select> */}
                {bindExpriencedIndustryDropdown()}
                {serviceProfileErrors.exprienced_industry !== "" &&
                    <span className="font-14 font-medium alert-text">
                        {serviceProfileErrors.exprienced_industry}
                    </span>
                }
            </div>
            {showOthers.industry &&
                <div className="col-lg-6 mb-4 mb-lg-5">
                    <label
                        htmlFor="lname"
                        className="font-semibold font-16 color-black-v2 mb-2"
                    >
                        Please Specify Industry<span className="madatory-text">*</span>
                    </label>
                    <input
                        type="text"
                        id="lname"
                        maxLength={MAX_OTHERS_LENGTH}
                        className={`form-control service-form-field color-black-v2 font-14 font-medium ${serviceProfileErrors.industry_others !== "" ? 'critical-field' : ''}`}
                        placeholder="Enter other industry"
                        name="industry"
                        value={Industries?.others}
                        onChange={(e) => { handleInputChange(e.target.name, e.target.value) }}
                    />
                    {serviceProfileErrors.industry_others !== "" &&
                        <span className="font-14 font-medium alert-text">
                            {serviceProfileErrors.industry_others}
                        </span>
                    }
                </div>}
            <div className="col-lg-6 mb-4 mb-lg-5">
                <label
                    htmlFor="service-2"
                    className="font-semibold font-16 color-black-v2 mb-2"
                >
                    Services have you offered in the past year?
                    <span className="madatory-text">*</span>
                </label>
                {bindPastServicesDropdown()}
                {serviceProfileErrors.past_services !== "" &&
                    <span className="font-14 font-medium alert-text">
                        {serviceProfileErrors.past_services}
                    </span>
                }

            </div>
            {showOthers.past_service &&
                <div className="col-lg-6 mb-4 mb-lg-5">
                    <label
                        htmlFor="lname"
                        className="font-semibold font-16 color-black-v2 mb-2"
                    >
                        Please Specify Past Service <span className="madatory-text">*</span>
                    </label>
                    <input
                        type="text"
                        id="lname"
                        maxLength={MAX_OTHERS_LENGTH}
                        className={`form-control service-form-field color-black-v2 font-14 font-medium ${serviceProfileErrors.past_servcies_others !== "" ? 'critical-field' : ''}`}
                        placeholder="Enter past service"
                        name="past_service"
                        value={PastService?.others}
                        onChange={(e) => { handleInputChange(e.target.name, e.target.value) }}
                    />
                    {serviceProfileErrors.past_servcies_others !== "" &&
                        <span className="font-14 font-medium alert-text">
                            {serviceProfileErrors.past_servcies_others}
                        </span>
                    }
                </div>}
            <div className="col-lg-12 mb-4 mb-lg-5">
                <label className="font-semibold font-16 color-black-v2 mb-2">
                    Is there anything about your organization you would like to highlight?
                </label>
                <textarea
                    className="form-control service-form-field w-100 custom-textarea font-14 font-regular"
                    placeholder="Enter your Highlights here"
                    defaultValue={""}
                    name="organization_highlight"
                    maxLength={MAX_OTHERS_LENGTH}
                    value={formPayload.organization_highlight}
                    onChange={(e) => { handleInputChange(e.target.name, e.target.value) }}
                />
            </div>
            <div className="col-lg-12 mb-4 mb-lg-5">
                <label className="font-semibold font-16 color-black-v2 mb-2">
                    Please share any extra training you offer for your guards beyond standard
                    licensing
                </label>
                <textarea
                    className="form-control service-form-field w-100 custom-textarea font-14 font-regular"
                    placeholder="Enter the name of training you offer"
                    defaultValue={""}
                    name="extra_training"
                    maxLength={MAX_OTHERS_LENGTH}
                    value={formPayload.extra_training}
                    onChange={(e) => { handleInputChange(e.target.name, e.target.value) }}
                />
            </div>
            <div className="modal-footer justify-content-between border-0 gap-4 pb-5">
                <button className="btn px-0 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn"
                    onClick={() => { handleCancel() }}>
                    Cancel
                </button>
                <div className="d-flex gap-3">
                    <button className="btn tertiary-btn px-4 color-white font-semibold font-14 px-4"
                        onClick={handlePrevious}
                        disabled={currentStep === 0}>
                        Previous
                    </button>
                    <button className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                        onClick={() => {
                            handleNext()
                        }}
                        disabled={!NextButtonServiceProfile}
                    >
                        Next
                    </button>
                    {/* {isEdit &&
                        <button className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                            // disabled={!buttonSubmit}
                            onClick={() => { SaveFromDetails() }}
                        >
                            Update
                        </button>
                    } */}
                </div>
            </div>
        </>
    )
}