
import React, { useState, useEffect } from 'react';
import { InviteContractorData } from '../../Interface/ContractorGrid';
import { inviteContractorApi } from '../../Service/ServiceRequest/ManageContractor/ManageContractorService';
import { ToastInterface } from '../../Interface/ManageContractor';

interface InviteContractorProps {
    onCloseIcon: () => void;
    onClose: () => void;
    onInviteSent: () => void;
    isEdit?: boolean;
    existingData?: InviteContractorData;
    setToastDetails: React.Dispatch<React.SetStateAction<ToastInterface>>
}

const InviteContractor: React.FC<InviteContractorProps> = ({ onClose, onCloseIcon, onInviteSent, isEdit = false, existingData, setToastDetails }) => {
    const [formData, setFormData] = useState<InviteContractorData>({
        contractor_id: '',
        dba_name: '',
        user_full_name: '',
        email_id: '',
        phone_number: '',
        mode: 'create',
    });
    const [loading, setLoading] = useState<boolean>(false); // Loading state
    const [emailError, setEmailError] = useState<string>('');
    const [phoneError, setPhoneError] = useState<string>('');
    useEffect(() => {
        if (isEdit && existingData) {
            setFormData(existingData);
        }
    }, [isEdit, existingData]);

    const emailRegex = /^[a-zA-Z0-9._%+-]+@(?!gmail\.com|yahoo\.com|hotmail\.com|outlook\.com|aol\.com)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const formatPhoneNumber = (phoneNumber: string): string => {
        if (!phoneNumber) return '';

        let cleaned = phoneNumber.startsWith('+1')
            ? phoneNumber.slice(2).replace(/\D/g, '')
            : phoneNumber.replace(/\D/g, '');

        cleaned = cleaned.slice(0, 10);

        const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

        if (match) {
            const [, areaCode, middle, last] = match;
            let formatted = "+1 ";
            if (areaCode) formatted += `(${areaCode}`;
            if (middle) formatted += `) ${middle}`;
            if (last) formatted += `-${last}`;
            return formatted;
        }

        return cleaned;
    };

    const validateEmail = (email: string): boolean => {
        return emailRegex.test(email);
    };

    const validatePhoneNumber = (phoneNumber: string): boolean => {
        const cleaned = phoneNumber.replace(/\D/g, '');
        return cleaned.length === 11;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        if (id === 'phone_number') {
            const formattedPhoneNumber = formatPhoneNumber(value);
            setFormData(prevData => ({
                ...prevData,
                [id]: formattedPhoneNumber
            }));

            if (formattedPhoneNumber && !validatePhoneNumber(formattedPhoneNumber)) {
                setPhoneError('Please enter a valid 10-digit phone number');
            } else {
                setPhoneError('');
            }
        } else if (id === 'email_id') {
            setFormData(prevData => ({
                ...prevData,
                [id]: value.toLowerCase()
            }));

            if (value && !validateEmail(value)) {
                setEmailError('Please enter a valid Email ID');
            } else {
                setEmailError('');
            }
        } else {
            setFormData(prevData => ({
                ...prevData,
                [id]: value
            }));
        }
    };

    const handleSubmit = async () => {
        if (!formData.email_id) {
            setEmailError('Please enter your Email ID');
            return;
        }

        if (!validateEmail(formData.email_id)) {
            setEmailError('Please enter a valid Email ID');
            return;
        }

        if (formData.phone_number && !validatePhoneNumber(formData.phone_number)) {
            setPhoneError('Please enter a valid 10-digit phone number');
            return;
        }

        setLoading(true); // Start loading

        try {
            let response;
            if (isEdit) {
                response = await inviteContractorApi(formData);
            } else {
                response = await inviteContractorApi(formData);
            }
            if (response?.code === 200) {
                console.log(isEdit ? 'Contractor updated successfully' : 'Contractor invited successfully');
                onInviteSent(); // Refresh the grid
                onClose(); // Close the modal
                setToastDetails((prevState) => ({
                    ...prevState,
                    isToast: true,
                    toastDescription: isEdit ? 'Contractor updated successfully' : 'Contractor invited successfully',
                    toastMessage: isEdit ? "Updated Successfully" : "Invited Successfully",
                    toastType: "Success",
                }));
            } else if (response?.errors === "Failed to create new contractor: ERROR: duplicate key value violates unique constraint \"contractor_email\" (SQLSTATE 23505); invalid transaction") {
                console.log(isEdit ? 'Contractor updated successfully' : 'Contractor invited successfully');
                onInviteSent(); // Refresh the grid
                onClose(); // Close the modal
                setToastDetails((prevState) => ({
                    ...prevState,
                    isToast: true,
                    toastDescription: "Email Id already exists",
                    toastMessage: isEdit ? "Update Failed" : "Invite Failed",
                    toastType: "Error",
                }));
            }
            else {
                onInviteSent(); // Refresh the grid
                onClose(); // Close the modal
                setToastDetails((prevState) => ({
                    ...prevState,
                    isToast: true,
                    toastDescription: isEdit ? 'Failed to update the contractor' : 'Failed to invite the contractor',
                    toastMessage: isEdit ? "Update Failed" : "Invite Failed",
                    toastType: "Error",
                }));
                console.error(isEdit ? 'Failed to update contractor:' : 'Failed to invite contractor:', response.message);
            }
        } catch (error) {
            console.error(isEdit ? 'Error updating contractor:' : 'Error inviting contractor:', error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <>
            <div
                className="modal fade show"
                id="exampleModal3"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-modal="true"
                data-backdrop="static"
                data-keyboard="false"
                role="dialog"
                style={{
                    display: "block", backgroundColor: "rgba(0,0,0,0.5)"
                }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border-radius-10">
                        <div className="modal-header border-0 px-4 pt-4 align-items-start">
                            <h5
                                className="modal-title font-semibold font-18 color-black-v2 mb-2"
                                id="exampleModalLabel"
                            >
                                {isEdit ? 'Edit Contractor' : 'Add New Contractor'}
                            </h5>
                            <button
                                type="button"
                                className="btn-close custom-btn-close mt-1"
                                onClick={onCloseIcon}
                                aria-label="Close"
                                disabled={loading} // Disable the button while loading
                            />
                        </div>
                        <div className="modal-body px-4 py-2">
                            <div className="row align-items-start">
                                <div className="col-md-6 custom-mb">
                                    <div className="position-relative">
                                        <label
                                            htmlFor="dba_name"
                                            className="font-semibold font-14 primary-text-color mb-2"
                                        >
                                            DBA Name
                                        </label>
                                        <input
                                            type="text"
                                            id="dba_name"
                                            className="form-control bg-white color-black-v2 font-14 font-medium invoice-form-field"
                                            placeholder="DBA Name"
                                            value={formData.dba_name}
                                            onChange={handleChange}
                                            maxLength={60}
                                            disabled={loading} // Disable the input while loading
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 custom-mb">
                                    <div className="position-relative">
                                        <label
                                            htmlFor="user_full_name"
                                            className="font-semibold font-14 primary-text-color mb-2"
                                        >
                                            User Full Name
                                        </label>
                                        <input
                                            type="text"
                                            id="user_full_name"
                                            className="form-control bg-white color-black-v2 font-14 font-medium invoice-form-field"
                                            placeholder="Enter Full Name"
                                            value={formData.user_full_name}
                                            onChange={handleChange}
                                            maxLength={60}
                                            disabled={loading} // Disable the input while loading
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 custom-mb">
                                    <div className="position-relative">
                                        <label
                                            htmlFor="email_id"
                                            className="font-semibold font-14 primary-text-color mb-2"
                                        >
                                            Email ID
                                        </label>
                                        <input
                                            type="email"
                                            id="email_id"
                                            className={`form-control bg-white color-black-v2 font-14 font-medium invoice-form-field ${emailError ? 'is-invalid' : ''}`}
                                            placeholder="Enter Email ID"
                                            value={formData.email_id}
                                            onChange={handleChange}
                                            disabled={loading} // Disable the input while loading
                                        />
                                        {emailError && <span className="invalid-feedback font-14">{emailError}</span>}
                                    </div>
                                </div>
                                <div className="col-md-6 custom-mb">
                                    <div className="position-relative">
                                        <label
                                            htmlFor="phone_number"
                                            className="font-semibold font-14 primary-text-color mb-2"
                                        >
                                            Phone #
                                        </label>
                                        <input
                                            type="tel"
                                            id="phone_number"
                                            className={`form-control bg-white color-black-v2 font-14 font-medium invoice-form-field ${phoneError ? 'is-invalid' : ''}`}
                                            placeholder="Enter Phone #"
                                            value={formData.phone_number}
                                            onChange={handleChange}
                                            disabled={loading} // Disable the input while loading
                                        />
                                        {phoneError && <span className="invalid-feedback font-14">{phoneError}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer gap-2 border-0 px-4 py-4 justify-content-end">
                            <button
                                className="btn px-4 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn"
                                onClick={onCloseIcon}
                                disabled={loading} // Disable the button while loading
                            >
                                Cancel
                            </button>
                            <button
                                className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                                onClick={handleSubmit}
                            // disabled={loading || emailError || !formData.email_id || (formData.phone_number && phoneError)} // Disable button if there's an error, loading, or required fields are missing or phone number is invalid if entered.
                            >
                                {loading ? 'Loading...' : (isEdit ? 'Update' : 'Send Invite')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InviteContractor;