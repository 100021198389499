/* eslint-disable @typescript-eslint/no-unused-vars */
import { client } from "../../Client/Client";
import { jobsAndBids } from "../../Configs/BackendRoutes";
import {backendUrl as URL } from "../../Configs/Constant";

export const getBidInfoApi = async (data:any) => {
    const config = {
        method: 'POST',
        url: URL + jobsAndBids.getBidDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };
    const response = await client(config);
    return response;
};

export const approveBidApi = async (data:any) => {
    const config = {
        method: 'POST',
        url: URL + jobsAndBids.approveBidDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };
    const response = await client(config);
    return response;
};


export const reinitiateApi = async (data:any) => {
    const config = {
        method: 'POST',
        url: URL + jobsAndBids.reinitiateBids,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };
    const response = await client(config);
    return response;
};


