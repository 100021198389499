
import { DocumentFromProps } from '../../../Interface/EditContractor/Signup';


export const DocumentsForm: React.FC<DocumentFromProps> = ({
    error, handleFileUpload, renderDocument, documentsDetails, disablefileUpload
}) => {
   return (
       <>
           <div className="d-flex align-items-center gap-3 mb-5">
               <h2 className="color-black font-600 font-18 mb-0">Document Uploads</h2>
           </div>

           {/* Render all documents by type */}
           {['State licenses', 'W9', 'COI'].map((type) => (
               <div className="col-lg-12 mb-4 mb-lg-5" key={type}>
                   <label className="font-semibold font-16 color-black-v2 mb-2">
                       Upload your {type === 'State licenses' ? 'State License' : (type === 'COI' ? 'Certificate of Insurance' : type)}
                   </label>
                   {type === 'State licenses' && (
                       <p className="font-regular font-12 color-grey cust-mb">
                           Please upload a license for each state in which your company operates (if a license is required in that state).
                       </p>

                    )}
                    
                    {type === 'W9' && (
                        <p className="font-regular font-12 color-grey cust-mb">
                            Please upload a signed, dated W-9.
                        </p>
                    )}
                    {type === 'COI' && (
                        <p className="font-regular font-12 color-grey cust-mb">
                            PLEASE NOTE: PPS Inc. must be listed as an additional insured and be listed as the certificate holder.
                            Prior to uploading your insurance documentation, please reference the 'Insurance Requirement Chart' below to identify the type of policy(ies) required, as different types of assignments have different insurance requirements.
                        </p>
                    )}
                    {type === "State licenses" &&
                        <div className="file-upload">
                            {error.licenses && <div className="alert alert-danger">{error.licenses}</div>}
                            <div className="file-upload-area d-flex flex-column justify-content-center align-items-center">
                                <div className="my-auto text-center width-15">
                                    <img src="../images/upload-icon.svg" alt="upload-icon" className="icon-filter" />
                                    <p className="font-12 font-medium purple-color mt-2">
                                        Drag and Drop or <span> Click to upload </span>
                                    </p>
                                </div>
                                <div className="mt-auto">
                                    <p className="font-12 font-semibold black-v3 mb-0">Max File size: 5MB</p>
                                </div>
                            </div>

                           <input
                               type="file"
                               className="curser-pointer file-input"
                               onChange={(e) => handleFileUpload(e, type)}
                               accept=".pdf"
                           />
                       </div>
                   }
                   {type === "COI" && !disablefileUpload.coi &&
                       <div className="file-upload">
                           {error.COI && <div className="alert alert-danger">{error.COI}</div>}
                           <div className="file-upload-area d-flex flex-column justify-content-center align-items-center">
                               <div className="my-auto text-center width-15">
                                   <img src="../images/upload-icon.svg" alt="upload-icon" className="icon-filter" />
                                   <p className="font-12 font-medium purple-color mt-2">
                                       Drag and Drop or <span> Click to upload </span>
                                   </p>
                               </div>
                               <div className="mt-auto">
                                   <p className="font-12 font-semibold black-v3 mb-0">Max File size: 5MB</p>
                               </div>
                           </div>

                           <input
                               type="file"
                               className="curser-pointer file-input"
                               onChange={(e) => handleFileUpload(e, type)}
                               accept=".pdf"
                           />
                       </div>
                   }
                   {type === "W9" && !disablefileUpload.w9 &&
                       <div className="file-upload">
                           {error.W9 && <div className="alert alert-danger">{error.W9}</div>}
                           <div className="file-upload-area d-flex flex-column justify-content-center align-items-center">
                               <div className="my-auto text-center width-15">
                                   <img src="../images/upload-icon.svg" alt="upload-icon" className="icon-filter" />
                                   <p className="font-12 font-medium purple-color mt-2">
                                       Drag and Drop or <span> Click to upload </span>
                                   </p>
                               </div>
                               <div className="mt-auto">
                                   <p className="font-12 font-semibold black-v3 mb-0">Max File size: 5MB</p>
                               </div>
                           </div>

                           <input
                               type="file"
                               className="curser-pointer file-input"
                               onChange={(e) => handleFileUpload(e, type)}
                               accept=".pdf"
                           />
                       </div>
                   }

                    <div className="row mt-2">
                        {documentsDetails?.filter(doc => doc.document_type === type).map(renderDocument)}
                    </div>
                </div>
            ))}
        </>
    );
};
