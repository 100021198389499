/* eslint-disable jsx-a11y/anchor-is-valid */
import { calculateDaysLeft, invoiceDownloadS3File } from "../../../Configs/Constant";
import { ContractorDocumentsInterface } from "../../../Interface/ManageContractor";

const Documents = ({
  documents,
}: {
  documents: ContractorDocumentsInterface[];
}) => {
  const bindStateLicense: () => JSX.Element[] | undefined = () => {
    return documents
    ?.filter((e) => e.document_type.toLowerCase() === "state licenses")
      .map((e) => {
        return (
          <div
            className="documents-container d-flex align-items-center justify-content-between py-2 px-3 gap-5"
            key={e.contractor_documents_uuid}
            style={{cursor: "pointer"}}
            onClick={()=>{
              invoiceDownloadS3File(e.document_url)
            }}
          >
            <a
              
              className="d-flex align-items-center gap-1 text-decoration-none link-color font-medium font-14"
            >
              <img src="../images/pdf-icon.svg" alt="pdf-icon" />
              {/* California License */}
              {e.file_name}
            </a>
            <div className="d-block">
            {e.expiry_date && ( calculateDaysLeft(e.expiry_date)) <=0 ? (<p className="mb-0 color-black font-12 font-medium">Expired</p>) : (<>
              <p className="mb-0 color-black font-10 font-regular">
                Expires In
              </p>
              <span className="color-black font-12 font-semibold">
                {e.expiry_date && ( calculateDaysLeft(e.expiry_date)) } Days
              </span>
              </>)}
            </div>
          </div>
        );
      });
  };

  const bindWNine = () => {
    return documents
    ?.filter((e) => e.document_type === "W9")
      .map((e) => {
        return (
          <div
            className="documents-container d-flex align-items-center justify-content-between py-2 px-3 gap-5"
            key={e.contractor_documents_uuid}
            style={{cursor: "pointer"}}
            onClick={()=>{
              invoiceDownloadS3File(e.document_url)
            }}
          >
            <a
              
              className="d-flex align-items-center gap-1 text-decoration-none link-color font-medium font-14"
            >
              <img src="../images/pdf-icon.svg" alt="pdf-icon" />
              {e.file_name}
            </a>
            {/* <div className="d-block">
              <p className="mb-0 color-black font-10 font-regular">
                Expires In
              </p>
              <span className="color-black font-12 font-semibold">
                {e.expiry_date && daysUntilExpiry(e.expiry_date)} Days
              </span>
            </div> */}
          </div>
        );
      });
  };

  const bindCOI = () => {
    return documents
      ?.filter((e) => e.document_type === "COI")
      .map((e) => {
        return (
          <div
            className="documents-container d-flex align-items-center justify-content-between py-2 px-3 gap-5"
            key={e.contractor_documents_uuid}
            style={{cursor: "pointer"}}
            onClick={()=>{
              invoiceDownloadS3File(e.document_url)
            }}
          >
            <a
         
              className="d-flex align-items-center gap-1 text-decoration-none link-color font-medium font-14"
            >
              <img src="../images/pdf-icon.svg" alt="pdf-icon" />
              {e.file_name}
            </a>
            <div className="d-block">
            {e.expiry_date && ( calculateDaysLeft(e.expiry_date)) <=0 ? (<p className="mb-0 color-black font-12 font-medium">Expired</p>) : (<>
              <p className="mb-0 color-black font-10 font-regular">
                Expires In
              </p>
              <span className="color-black font-12 font-semibold">
                {e.expiry_date && ( calculateDaysLeft(e.expiry_date)) } Days
              </span>
              </>)}
            </div>
          </div>
        );
      });
  };

  return (
    <div
      // added -"show active"
      className="tab-pane fade show active"
      id="Documents"
      role="tabpanel"
      aria-labelledby="Documents-tab"
    >
      <h3 className="font-18 color-black font-semibold mb-4">Documents</h3>
      <h3 className="font-18 color-black font-semibold mb-4">State License</h3>
      <div className="d-flex align-items-center gap-3 flex-wrap mb-5">
      {documents?.filter((ele:ContractorDocumentsInterface)=>ele.document_type.toLowerCase()==="state licenses")?.length !== 0 && bindStateLicense()}
        {/* <div className="documents-container d-flex align-items-center justify-content-between py-2 px-3 gap-5">
          <a
            href="#"
            className="d-flex align-items-center gap-1 text-decoration-none link-color font-medium font-14"
          >
            <img src="../images/pdf-icon.svg" alt="pdf-icon" />
            California License
          </a>
          <div className="d-block">
            <p className="mb-0 color-black font-10 font-regular">Expires In</p>
            <span className="color-black font-12 font-semibold">20 Days</span>
          </div>
        </div> */}
        {/* <div className="documents-container d-flex align-items-center justify-content-between py-2 px-3 gap-5">
        <a
          href="#"
          className="d-flex align-items-center gap-1 text-decoration-none link-color font-medium font-14"
        >
          <img src="../images/pdf-icon.svg" alt="pdf-icon" />
          Texas License
        </a>
        <div className="d-block">
          <p className="mb-0 color-black font-10 font-regular">
            Expires In
          </p>
          <span className="color-black font-12 font-semibold">
            20 Days
          </span>
        </div>
      </div>
      <div className="documents-container d-flex align-items-center justify-content-between py-2 px-3 gap-5">
        <a
          href="#"
          className="d-flex align-items-center gap-1 text-decoration-none link-color font-medium font-14"
        >
          <img src="../images/pdf-icon.svg" alt="pdf-icon" />
          Florida License
        </a>
        <div className="d-block">
          <p className="mb-0 color-black font-10 font-regular">
            Expires In
          </p>
          <span className="color-black font-12 font-semibold">
            290 Days
          </span>
        </div>
      </div>
      <div className="documents-container d-flex align-items-center justify-content-between py-2 px-3 gap-5">
        <a
          href="#"
          className="d-flex align-items-center gap-1 text-decoration-none link-color font-medium font-14"
        >
          <img src="../images/pdf-icon.svg" alt="pdf-icon" />
          Ohio License
        </a>
        <div className="d-block">
          <p className="mb-0 color-black font-10 font-regular">
            Expires In
          </p>
          <span className="color-black font-12 font-semibold">
            370 Days
          </span>
        </div>
      </div> */}
      </div>
      <h3 className="font-18 color-black font-semibold mb-4">W-9</h3>
      <div className="d-flex align-items-center gap-3 flex-wrap mb-5">
        {/* <div className="documents-container d-flex align-items-center justify-content-between py-2 px-3 gap-5">
          <a
            href="#"
            className="d-flex align-items-center gap-1 text-decoration-none link-color font-medium font-14"
          >
            <img src="../images/pdf-icon.svg" alt="pdf-icon" />
            California License
          </a>
          <div className="d-block">
            <p className="mb-0 color-black font-10 font-regular">Expires In</p>
            <span className="color-black font-12 font-semibold">20 Days</span>
          </div>
        </div> */}
        {documents?.filter((ele:ContractorDocumentsInterface)=>ele.document_type==="W9")?.length !== 0 && bindWNine()}
      </div>
      <h3 className="font-18 color-black font-semibold mb-4">
        Certificate of Insurance
      </h3>
      <div className="d-flex align-items-center gap-3 flex-wrap mb-5">
        {/* <div className="documents-container d-flex align-items-center justify-content-between py-2 px-3 gap-5">
          <a
            href="#"
            className="d-flex align-items-center gap-1 text-decoration-none link-color font-medium font-14"
          >
            <img src="../images/pdf-icon.svg" alt="pdf-icon" />
            California License
          </a>
          <div className="d-block">
            <p className="mb-0 color-black font-10 font-regular">Expires In</p>
            <span className="color-black font-12 font-semibold">20 Days</span>
          </div>
        </div> */}
        {documents?.filter((ele:ContractorDocumentsInterface)=>ele.document_type==="COI")?.length !== 0 && bindCOI()}
      </div>
    </div>
  );
};

export default Documents;
