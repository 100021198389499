import {
  ContractorProfileInterface,
  ContractorTeamDetailsInterface,
  TeamNameHeaderInterface,
} from "../../../Interface/ManageContractor";

const Contacts = ({
  profileData,
}: {
  profileData: ContractorProfileInterface;
}) => {
  const bindAfterHrsContact = () => {
    const teamNameHeader: TeamNameHeaderInterface = {
      "new work opportunities": "Who do we contact with new work opportunities? (Headquarters)",
      "guard punctuality or missed shifts": "Who do we contact about guard punctuality or missed shifts? (Headquarters)",
      "timesheets/invoice docs": "Who do we send timesheets/invoice docs to? (Headquarters)",
      "license or insurance questions": "Who do we contact with license or insurance questions? (Headquarters)",
  };

    return profileData?.team_details?.map(
      (data: ContractorTeamDetailsInterface) => {
        return (
          <div className="row" key={data.contractor_team_detail_uuid}>
            <h3 className="font-18 color-black font-semibold mb-4">
              {teamNameHeader[data.team_name?.toLowerCase()]}
            </h3>
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
              <p className="font-14 font-semibold primary-text-color mb-2">
                Name
              </p>
              <p className="font-medium color-black-v1 mb-0 font-14">
                {/* Joanna Clark */}
                {data?.incharge_name}
              </p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
              <p className="font-14 font-semibold primary-text-color mb-2">
                Phone #
              </p>
              <p className="font-medium color-black-v1 mb-0 font-14">
                {data.incharge_phone}
              </p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
              <p className="font-14 font-semibold primary-text-color mb-2">
                Email ID
              </p>
              <p className="font-medium color-black-v1 mb-0 font-14">
                {/* Joannaclark@gmail.com */}
                {data?.incharge_email}
              </p>
            </div>
          </div>
        );
      }
    );
  };
  return (
    <div
      // added -  "show active"
      className="tab-pane fade show active"
      id="Contact"
      role="tabpanel"
      aria-labelledby="Contact-tab"
    >
      <h3 className="font-18 color-black font-semibold mb-4">
        Company Contact Information
      </h3>
      <div className="col-12 mb-5">
        <p className="font-14 font-semibold primary-text-color mb-3">
          Are you the only contact for all communications ?
        </p>
        <p className="font-medium color-black-v1 mb-0 font-14">
          {/* Yes, I handle all communication. */}
          {profileData?.contractor_details?.is_only_contact}
        </p>
      </div>
      {profileData?.team_details?.length > 0 && bindAfterHrsContact()}
      <h3 className="font-18 color-black font-semibold mb-4">
        After Hours Contact
      </h3>
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">
            First Name
          </p>
          <p className="font-medium color-black-v1 mb-0 font-14">
            {/* Joanna Clark */}
            {profileData?.contractor_details?.after_hours_contact_name}
          </p>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">
            Phone #
          </p>
          <p className="font-medium color-black-v1 mb-0 font-14">
            {/* {profileData?.contractor_details?.after_hours_contact_phone &&
              formatPhoneNumber(
                profileData?.contractor_details?.after_hours_contact_phone
              )} */}
              {
                profileData?.contractor_details?.after_hours_contact_phone
              }
          </p>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
          <p className="font-14 font-semibold primary-text-color mb-2">
            Email ID
          </p>
          <p className="font-medium color-black-v1 mb-0 font-14">
            {/* Joannaclark@gmail.com */}
            {profileData?.contractor_details?.after_hours_contact_email}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
