// PS_04: Import interfaces from ManageClientsInterface.ts

import { useContext, useEffect, useState } from "react";
import {
  ApproveClientRequestInterface,
  ClientGridDataInterface,
  FilterInterface,
  GetClientsGridDataRequestInterface,
  IndustryDataInterface,
  InviteUpdateClientFormDataPayloadInterface,
  ManageClientsTabName,
  sortOrderInterfaceType,
  ToastInterface,
} from "../../Interface/ManageClient";
import {
  fetchManageClientsGridDataService,
  PostApproveRejectClientService,
  postInviteUpdateClientService,
} from "../../Service/ManageClients/ManageClientsService";
import AllClientsGrid from "./ClientsGridTab/AllClientsGrid";
import NewClientRequestsGrid from "./ClientsGridTab/NewClientRequestsGrid";
import InviteeResponsesGrid from "./ClientsGridTab/InviteeResponsesGrid";
import ClientFilterComponent from "./ClientFilterComponent";
import ConfirmationPopUp from "../ConfirmationPopUp";
import InviteClientForm from "./InviteClientForm";
import Toast from "../Toast";
import Loader from "../Loader";
import { ppsContext } from "../../Configs/Constant";
import { contextValueInterface } from "../../Interface/HeaderDetails";
import { useLocation } from "react-router-dom";

function ManageClients() {
  const contextValue: contextValueInterface = useContext(ppsContext)
  const {state}= useLocation();
  const initialGridPayload: GetClientsGridDataRequestInterface = {
    search: "",
    load_more: 20,
    sort: {
      column: "created_on",
      order: "desc",
    },
    tab_name: state ? state : contextValue.userDetails.role.toLowerCase() === "admin" ? "NEW_CLIENT_REQUESTS": "ALL_CLIENTS",
    filter: {
      industry_uuid: "",
      date_range: {
        from_date: "",
        to_date: "",
      },
      status: "",
      source: "",
    },
  };

  const initialInviteUpdateFormPayload: InviteUpdateClientFormDataPayloadInterface =
    {
      post_type: "invite",
      customer_uuid: "",
      customer_dba: "",
      first_name: "",
      last_name: "",
      business_email: "",
      industry_uuid: "",
      other_industry: "",
      phone_number: "+1",
      is_mark_active: null,
    };
  const initialSortOrder: sortOrderInterfaceType = {
    customer_dba: false,
    first_name: false,
    referred_by: false,
    industry_name: false,
    region: false,
    created_on: false,
    source: false,
    service_request_count: false,
    business_email: false,
  };

  const initialToastDetails : ToastInterface ={
    isToast: false,
    toastDescription: "",
    toastMessage: "",
    toastType: "",
  }

  // PS_05: Declare and initialize state variables
  const [manageClientsGridData, setManageClientsGridData] = useState<
    ClientGridDataInterface[]
  >([] as ClientGridDataInterface[]);

  const [industryData, setIndustryData] = useState<IndustryDataInterface[]>(
    [] as IndustryDataInterface[]
  );

  const [totalCount, setTotalCount] = useState<number>(0);

  const [manageClientsGridPayload, setManageClientsGridPayload] =
    useState<GetClientsGridDataRequestInterface>(initialGridPayload);

  const [showClientForm, setShowClientForm] = useState<boolean>(false);

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [sortOrder, setSortOrder] =
    useState<sortOrderInterfaceType>(initialSortOrder);

  const [searchTerm, setSearchTerm] = useState("");

  const [
    inviteUpdateClientFormDataPayload,
    setInviteUpdateClientFormDataPayload,
  ] = useState<InviteUpdateClientFormDataPayloadInterface>(
    initialInviteUpdateFormPayload
  );

  // const [validationErrorMessage, setValidationErrorMessage] =
  //   useState<ValidationErrorsInterfaceType>(initialValidationErrorMessage);

  const [manageClientsGridFilter, setManageClientsGridFilter] =
    useState<FilterInterface>(initialGridPayload?.filter);

  const [callGridAPI, setCallGridAPI] = useState<string>("");
  const [showDeletePopUp, setShowDeletePopUp] = useState<boolean>(false);
  const [toastDetails, setToastDetails] = useState<ToastInterface>(initialToastDetails)

  const [isLoader, setIsLoader] = useState<boolean>(false)

  // PS_06: Define useEffect hook with manageClientsGridPayload in dependency array
  useEffect(() => {
    fetchManageClientsGridData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageClientsGridPayload]);

  
  useEffect(() => {
    if (callGridAPI === "call") {
      fetchManageClientsGridData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callGridAPI]);

  // PS_07: Call fetchManageClientsGridData() inside useEffect
  const fetchManageClientsGridData = async () => {
    setIsLoader(true)
    try {
      const response = await fetchManageClientsGridDataService(
        manageClientsGridPayload
      );
      if (response?.status === "Success") {
        if (response?.meta?.total_count !== undefined) {
          setTotalCount(response?.meta?.total_count);
          setManageClientsGridData(response?.data?.grid_data); // PS_14, PS_25, PS_38, PS_49
        }
        setIndustryData(response?.data?.industry_data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCallGridAPI(() => "");
      setIsLoader(false)
    }
  };

  const postApproveRejectClient: (
    payload: ApproveClientRequestInterface
  ) => Promise<void> = async (payload: ApproveClientRequestInterface) => {
    try {
      setIsLoader(true)
      const response = await PostApproveRejectClientService(payload);
      setIsLoader(false)
      if (response?.status?.toLowerCase() === "success") {
        if (response?.message === "Client Rejected Successfully") {
          setToastDetails((prevState)=>({
            ...prevState,
            isToast: true,
            toastDescription: "You can view the rejected clients in all clients",
            toastMessage: response?.message,
            toastType: "Success"
          }))          
        } else if (response?.message === "Client Approved Successfully") {
          setToastDetails((prevState)=>({
            ...prevState,
            isToast: true,
            toastDescription: "User ID will be shared via mail",
            toastMessage: response?.message,
            toastType: "Success"
          }))  
        } 
        // PS_14, PS_25, PS_38, PS_49
        setCallGridAPI(() => "call");
      } else if (response?.code !== 200) {
        setToastDetails((prevState)=>({
          ...prevState,
          isToast: true,
          toastDescription: "Failed to update approval status",
          toastMessage: "Failed",
          toastType: "Error"
        }))  
        setCallGridAPI(() => "call");
      }
    } catch (error) {
      console.log(error);
    }
  };
  // PS_17: handleSearch

  const handleOnKeyDownSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setManageClientsGridPayload({
        ...manageClientsGridPayload,
        search: e.currentTarget?.value?.trim(),
      }); // PS_18
    }
  };

  const handleSearch = () => {
    setManageClientsGridPayload({
      ...manageClientsGridPayload,
      search: searchTerm.trim(),
    }); // PS_18
  };

  // PS_28: handleFilterChange
  const handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (event.target.name === "from_date" || event.target.name === "to_date") {
      setManageClientsGridFilter((prevState) => ({
        ...prevState,
        date_range: {
          ...prevState.date_range,
          [event.target.name]: event.target.value,
        },
      })); // PS_29
    } else {
      setManageClientsGridFilter((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
    }
  };

  // PS_30: applyFilter
  const applyFilter: () => void = () => {
    setManageClientsGridPayload((prevState) => ({
      ...prevState,
      filter: manageClientsGridFilter,
    })); // PS_31
    setShowFilter(false);
  };

  // PS_30: clearFilter
  const clearFilter: () => void = () => {
    setManageClientsGridPayload((prevState) => ({
      ...prevState,
      filter: initialGridPayload?.filter,
    })); // PS_31
    setManageClientsGridFilter(initialGridPayload?.filter); // PS_31
    setShowFilter(false)
  };

  // PS_41: handleSort
  const handleSort: (column: string, order: boolean) => void = (
    column: string,
    order: boolean
  ) => {
    setManageClientsGridPayload({
      ...manageClientsGridPayload,
      sort: {
        column,
        // order: "desc",
        order: order ? "asc" : "desc",
      }, // PS_42
    });
  };

  // PS_52: handleLoadMore
  const handleLoadMore: () => void = () => {
    if (manageClientsGridData?.length < totalCount) {
      setManageClientsGridPayload((prevState) => ({
        ...prevState,
        load_more: prevState?.load_more + 20,
      })); // PS_53
    }
  }; 
  

  // PS_47, PS_48, PS_49
  // Close is handled in line of the html
  // const handleEditClient = (clientData: ClientGridDataInterface) => {
  //   setInviteUpdateClientFormDataPayload((prevState) => ({
  //     ...prevState,
  //     business_email: clientData.business_email.toLowerCase(),
  //     customer_dba: clientData.customer_dba,
  //     customer_name: clientData.customer_name,
  //     customer_uuid: clientData.customer_uuid,
  //     industry_uuid: clientData.industry_uuid,
  //     is_mark_active: clientData.IsMarkActive,
  //     phone_number: clientData.phone_number,
  //     post_type: "update",
  //   }));
  //   setShowClientForm(true);
  // };

  // PS_41, PS_42, PS_43, PS_44, PS_45
  const handleDeleteClient: () => Promise<void> = async () => {
    setIsLoader(true)
    try {
      const response = await postInviteUpdateClientService(
        inviteUpdateClientFormDataPayload
      );
      setIsLoader(false)
      if (response?.status === "Success") {
        if (response?.message === "Client Deleted Successfully") {
          setToastDetails((prevState) => ({
            ...prevState,
            isToast: true,
            toastDescription: response?.message,
            toastMessage: "Client Deleted",
            toastType: "Success"
          }))
        }
        setCallGridAPI(() => "call");
        setInviteUpdateClientFormDataPayload(initialInviteUpdateFormPayload);
      }
    } catch (error) {
      console.error("Error deleting client:", error);
    }
  };

  const handleDeletePopUp: (confirmation: string) => void = (
    confirmation: string
  ) => {
    if (confirmation === "close" || confirmation === "no") {
      setShowDeletePopUp(false);
    } else if (confirmation === "yes") {
      handleDeleteClient();
      setShowDeletePopUp(false);
    }
  };

  return isLoader ? (<Loader/>) : (
    <>
      <div className="container-fluid p-4">
        {showDeletePopUp && (
          <ConfirmationPopUp
            handlePopUp={handleDeletePopUp}
            title={"Delete Confirmation"}
            message={"Are you sure want to delete this Client?"}
          />
        )} 
        {toastDetails?.isToast && (
          <Toast
            props={{
              isToast: toastDetails?.isToast,
              setToastDetails: setToastDetails,
              toastMessage: toastDetails?.toastMessage,
              toastType: toastDetails?.toastType,
              toastDescription: toastDetails?.toastDescription
            }}
          />
        )}
        <h2 className="color-black font-20 font-bold">Manage Clients</h2>
        {/* tab structure */}
        <div className="mt-4 d-flex align-items-center justify-content-between flex-wrap gap-3">
          <div className="tab-scroll">
            <ul
              className="nav nav-pills cust-nav-pills flex-nowrap"
              id="pills-tab"
              role="tablist"
            >
              {contextValue.userDetails.role.toLowerCase() === "admin" && <li className="nav-item" role="presentation">
                <button
                  className={`nav-link cust-nav-link ${
                    manageClientsGridPayload?.tab_name ===
                      ManageClientsTabName.NewClientRequests && "active"
                  } text-nowrap`}
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  onClick={() => {
                    setManageClientsGridPayload((prevState) => ({
                      ...prevState,
                      tab_name: ManageClientsTabName.NewClientRequests,
                      filter: initialGridPayload.filter,
                      sort: initialGridPayload.sort,
                    }));
                    setManageClientsGridFilter(initialGridPayload.filter);
                    setSortOrder(initialSortOrder);
                  }}
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  New Client Requests
                </button>
              </li>}
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link cust-nav-link ${
                    manageClientsGridPayload.tab_name ===
                      ManageClientsTabName.AllClients && "active"
                  } text-nowrap`}
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  onClick={() => {
                    setManageClientsGridPayload((prevState) => ({
                      ...prevState,
                      tab_name: ManageClientsTabName.AllClients,
                      filter: initialGridPayload.filter,
                      sort: initialGridPayload.sort,
                    }));
                    setManageClientsGridFilter(initialGridPayload.filter);
                    setSortOrder(initialSortOrder);
                  }}
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  All Clients
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link cust-nav-link ${
                    manageClientsGridPayload.tab_name ===
                      ManageClientsTabName.InviteeResponses && "active"
                  } text-nowrap`}
                  id="pills-contact-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-contact"
                  type="button"
                  role="tab"
                  onClick={() => {
                    setManageClientsGridPayload((prevState) => ({
                      ...prevState,
                      tab_name: ManageClientsTabName.InviteeResponses,
                      filter: initialGridPayload.filter,
                      sort: initialGridPayload.sort,
                    }));
                    setManageClientsGridFilter(initialGridPayload.filter);
                    setSortOrder(initialSortOrder);
                  }}
                  aria-controls="pills-contact"
                  aria-selected="false"
                >
                  Invitee Responses
                </button>
              </li>
            </ul>
          </div>
          <div className="d-flex align-items-center justify-content-md-center gap-3 flex-wrap mt-2 mt-md-0">
            {/* search */}
            <div className="position-relative">
              <input
                type="search"
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  // e.code == "Enter" ? handleSearch(e) : <></>;
                  handleOnKeyDownSearch(e);
                }}
                onInput={(e: React.FormEvent<HTMLInputElement>) => {
                  setSearchTerm((e.target as HTMLInputElement).value);
                  // setManageClientsGridPayload((prevState) => ({
                  //   ...prevState,
                  //   search: (e.target as HTMLInputElement).value,
                  // }));
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchTerm(e.target.value);
                }}
                value={searchTerm}
                className=" custom-input-search font-14 font-medium color-black custom-search"
                placeholder="Search here"
              />
              <button
                className="btn bg-transparent border-0 focus-none search-icon"
                onClick={() => {
                  handleSearch();
                }}
              >
                <img
                  className="tab-icon"
                  src="../images/search-icon.svg"
                  alt="search"
                />
              </button>
            </div>
            {/* filter */}
            <button
              type="button"
              onClick={() => {
                setShowFilter(!showFilter);
              }}
              className="btn common-btn px-3"
              // data-bs-toggle="dropdown"
              aria-expanded="true"
              
            >
              <img src="../images/filter-icons.svg" alt="filter-icons" />
            </button>
            {showFilter && (
              <ClientFilterComponent
                handleFilterChange={handleFilterChange}
                manageClientsGridFilter={manageClientsGridFilter}
                manageClientsGridPayload={manageClientsGridPayload}
                applyFilter={applyFilter}
                clearFilter={clearFilter}
                setShowFilter={setShowFilter}
                industryData={industryData}
                setManageClientsGridFilter = {setManageClientsGridFilter}
              />
            )}
            {/* service request */}
            <button
              className="btn border-0 d-flex align-items-center gap-3 color-white font-semibold font-14 primary-btn px-4 rounded-3"
              onClick={() => {
                setInviteUpdateClientFormDataPayload((prevState) => ({
                  ...initialInviteUpdateFormPayload,
                  post_type: "invite",
                }));
                setShowClientForm(true);
                // setValidationErrorMessage(initialValidationErrorMessage);
              }}
            >
              <img src="../images/plus-white-icon.svg" alt="Plus Icon" />
              Add New Client
            </button>
          </div>
        </div>
        {manageClientsGridData?.length === 0 ? (
          <div className="d-flex align-items-center justify-content-center p-5 flex-column">
            <img
              className="no-record-img"
              src="../images/no-record.gif"
              alt="No reord"
            />
            {/* <img src="../images/no-record.svg" alt="no-record"> */}
            <p className="font-medium font-20 color-grey-v1 mb-0 mt-3 text-center">
              No Records Found
            </p>
          </div>
        ) : (
          <div className="tab-content pt-1" id="pills-tabContent">
            {/* 1st tab */}
            {manageClientsGridPayload?.tab_name ===
              ManageClientsTabName.NewClientRequests && (
              <NewClientRequestsGrid
                gridData={manageClientsGridData}
                totalCount={totalCount}
                handleSort={handleSort}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                handleLoadMore={handleLoadMore}
                postApproveRejectClient={postApproveRejectClient}
                industryData={industryData}
              />
            )}
            {/* 2nd tab */}
            {manageClientsGridPayload.tab_name ===
              ManageClientsTabName.AllClients && (
              <AllClientsGrid
                gridData={manageClientsGridData}
                setInviteUpdateClientFormDataPayload={
                  setInviteUpdateClientFormDataPayload
                }
                totalCount={totalCount}
                handleSort={handleSort}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                handleLoadMore={handleLoadMore}
                setShowDeletePopUp={setShowDeletePopUp}
                industryData={industryData}
              />
            )}
            {/* 3rd tab */}
            {manageClientsGridPayload.tab_name ===
              ManageClientsTabName.InviteeResponses && (
              <InviteeResponsesGrid
                gridData={manageClientsGridData}
                totalCount={totalCount}
                handleSort={handleSort}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                handleLoadMore={handleLoadMore}
                setInviteUpdateClientFormDataPayload={
                  setInviteUpdateClientFormDataPayload
                }
                setShowDeletePopUp={setShowDeletePopUp}
                // handleEditClient={handleEditClient}
                setShowClientForm={setShowClientForm}
                industryData = {industryData}
              />
            )}
          </div>
        )}
      </div>
      {showClientForm && (
        <InviteClientForm
          setShowClientForm={setShowClientForm}
          setCallGridAPI={setCallGridAPI}
          industryData={industryData}
          receivedPayload={inviteUpdateClientFormDataPayload}
          setToastDetails = {setToastDetails}
          setIsLoader={setIsLoader}
        />
      )}
    </>
  );
}

export default ManageClients;
