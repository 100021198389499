import { client } from "../../Client/Client";

import { manageClientServices } from "../../Configs/BackendRoutes";
import {   backendUrl } from "../../Configs/Constant";
// import { URL as URL } from "../../Configs/Constant";
import { APIResponseInterface, ApproveClientRequestInterface, GetClientsGridDataRequestInterface, InviteUpdateClientFormDataPayloadInterface, RetrieveProfileDataPayloadInterface } from "../../Interface/ManageClient";


// PS_08, PS_19, PS_32, PS_43, PS_54: Service to fetch Manage Clients Grid data
export const fetchManageClientsGridDataService = async (payload: GetClientsGridDataRequestInterface ) => {
    // PS_09, PS_20, PS_33, PS_44, PS_55

      const config = {
        method: 'POST',
        url: backendUrl + manageClientServices.retrieveClientDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: payload
    };
    const response : APIResponseInterface= await client(config);

    return response;
};

// PS_83: Service to approve/reject clients
export const PostApproveRejectClientService = async (payload: ApproveClientRequestInterface) => {
    // PS_84
  const config = {
    method: 'POST',
    url: backendUrl+ manageClientServices.approveRejectClient,
    headers: {
        'Content-Type': 'application/json'
    },
    data: payload
};
const response : APIResponseInterface= await client(config);

return response;
};

// PS_31, PS_36
export const postInviteUpdateClientService = async (payload: InviteUpdateClientFormDataPayloadInterface)=> {
    const config = {
        method: 'POST',
        url: backendUrl + manageClientServices.inviteUpdateClient,
        headers: {
            'Content-Type': 'application/json'
        },
        data: payload
    };
    const response : APIResponseInterface= await client(config);

    return response;
};


export const getUserProfileDataService = async (data: RetrieveProfileDataPayloadInterface) => {
    const config = {
        method: 'POST',
        url: backendUrl + manageClientServices.retrieveProfileData,
        headers: {
            'Content-Type': 'application/json'
        },
        data
    };
    const response : APIResponseInterface | undefined= await client(config);
    return response;
};