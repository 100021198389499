

const ConfirmationPopUp = (props: {handlePopUp: (confirmation: string)=>void, title: string, message: string}) => {
    
  return (
    <div
    className="modal fade show"
    id="exampleModal11"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content border-radius-10">
          <div className="modal-header border-0 px-4 pt-4 align-items-center">
            <h5
              className="modal-title font-bold font-18 color-black-v2"
              id="exampleModalLabel"
            >
              {props.title}
            </h5>
            <button
              type="button"
              className="btn-close custom-btn-close"
              aria-label="Close"
              onClick={()=>{
                props.handlePopUp("close")
              }}
            />
          </div>
          <div className="modal-body px-4 py-0">
            <p className="font-medium font-16 mb-0 color-black cust-lh mb-3">
              {props.message}
            </p>
          </div>
          <div className="modal-footer gap-2 border-0 px-4 pb-4 pt-2 justify-content-end">
            <button className="btn tertiary-btn px-4 color-white font-semibold font-14 primary-btn px-4" onClick={()=>{props.handlePopUp("no")}}>
              No
            </button>
            <button
              className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
              onClick={()=>{
                props.handlePopUp("yes")
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopUp;
