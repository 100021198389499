// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ServiceRequestView from "../Components/JobsAndBids/ServiceRequestView"
import { ManageClientsServicesInterface } from "../Interface/ManageClient"
import { ManageInvoiceServicesInterface } from "../Interface/ManageInvoice"


export const Summary = "/v1/api/admin/summary"

export const manageUserServices = {
    manageUserDetails: "/v1/api/admin/retrieve-users",
    manageUserPost: "/v1/api/admin/manage-users",
}

export const manageFeedbackServices = {
    getFeedbackDetails: "/v1/api/admin/retrieve-feedbacks",
    FlagContractor: "/v1/api/admin/flag-contractor",
}

export const getHeaderDetails = {
    getHeaderDet: "/v1/api/admin/retrieve-user-details",
    readNotifications: "/v1/api/login/read-notifications"
}

export const manageClientServices: ManageClientsServicesInterface = {
    retrieveClientDetails: "/v1/api/admin/retrieve-client-details",
    approveRejectClient: "/v1/api/admin/approve-reject-client",
    inviteUpdateClient: "/v1/api/admin/invite-client",
    retrieveProfileData: "/v1/api/admin/retrieve-profile-details",
}

export const manageInvoiceServices: ManageInvoiceServicesInterface = {
    acceptRejectInvoice: "/v1/api/admin/accept-reject-invoice",
    retrieveInvoiceDetails: "/v1/api/admin/retrieve-invoice-details",
}

export const manageContractor = {
    getContractorDetails: "/v1/api/admin/retrieve-all-contractors",
    approveOrRejectContractors: "/v1/api/admin/approve-reject-contractors",
    inviteContractors: "/v1/api/admin/post-contractor"
}

export const jobsAndBids = {
    getJobsAndBids : "/v1/api/protect/manage-service-request",
    rmApproveRejectBids : "/v1/api/protect/rm-approve-reject",
    ServiceRequestView : "/v1/api/protect/service-request",
    UpdateOfferPrice : "/v1/api/contractor/post-offer-price",
    getBidDetails : "/v1/api/admin/service-request-bid-info",
    approveBidDetails : "/v1/api/admin/approve-bids",
    reinitiateBids : "/v1/api/admin/reinitiate-bids",
}

export const SignInEndpoint = {
    SignIn: "/v1/api/login/sign-in",
    RefeshToken: "/v1/api/refesh-token"
}

export const SignUpEndpoint = {
    SignUp: "/v1/api/login/sign-up",
    GetRegion: "/v1/api/login/region",
    GetDropdown: "/v1/api/login/dropdowns/contractor",
}

export const jobsAndBidsService = {
    manageJobsAndBids: "/v1/api/protect/manage-service-request",
    getServiceRequest: "/v1/api/protect/service-request",
    updateJobsAndBidsTimer: "/v1/api/protect/update-response-time"
}