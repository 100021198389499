/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from "react";
import { GridData } from "../../Interface/JobsAndBids";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { useNavigate } from "react-router-dom";
import CountdownTimer from "./CountdownTimer"; // Adjust the path as needed
import ViewBids from "./ViewBids";
import Loader from "../Loader/LoaderComponent";
import JobsAndBidsTimer from "./JobsAndBidsTimer";
import OfficerRequestType from "./OfficerRequestType";
import TooltipText from "./TooltipText";

// Extend dayjs to use plugins
dayjs.extend(duration);

dayjs.extend(relativeTime);

interface OpenTabProps {
  role: string;
  data: GridData[];
  totalCount: number;
  onLoadMore: () => void;
  hasMore: boolean;
  loading: boolean;
  setToastMessage: any;
  showToastProp: any;
  setShowToast: any;
  isTimerToast: any;
  setIsTimerToast: any;
  setShowToastProp: any;
  customerUUID?: string;
  renderSortIcon: (column: string) => JSX.Element;
  handleSort: (column: string) => void;
  fetchGridData: any;
  setSelectedTab: any;
  selectedTab: any;
}

const isUrgent = (startDate: string, createdOn: string) => {
  const createdDate = dayjs(createdOn);
  const serviceDate = dayjs(startDate);
  const urgent = serviceDate.diff(createdDate, "day") <= 7;
  // console.log(
  //   `Job Starting on ${startDate} (created on ${createdOn}) is urgent: ${urgent}`
  // );
  return urgent;
};


export const calculateTimeDifference = (createdOn: string) => {
  const startDurationInSeconds = 10800; // 3 hours in seconds
  const now = dayjs();
  const createdTime = dayjs(createdOn);
  const elapsedTimeInSeconds = now.diff(createdTime, "second");

  return Math.max(0, startDurationInSeconds - elapsedTimeInSeconds);
};

const OpenTab: React.FC<OpenTabProps> = ({
  role,
  data,
  totalCount,
  onLoadMore,
  hasMore,
  loading,
  setToastMessage,
  setShowToastProp,
  setIsTimerToast,
  customerUUID,
  renderSortIcon,
  handleSort,
  fetchGridData,
  setSelectedTab,
  selectedTab,

}) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedServiceRequestId, setSelectedServiceRequestId] = useState<
    string | null
  >(null);
  const [isEditTimer, setIsEditTimer] = useState<boolean>(false);
  const [responseTime, setResponseTime] = useState<string>("");
  const [serviceRequestId, setServiceRequestId] = useState<string>("");

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedServiceRequestId(null);
  };

  return (
    <div
      className="tab-pane fade show"
      id="pills-home"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      <div className="table-responsive theme-table bg-white custom-scroll my-4 w-100 vertical-align-top">
        <table className="table table-borderless mb-0 acc-view">
          <thead>
            {customerUUID ? (
              <tr>
                <th className="text-nowrap cursor-pointer" onClick={() => handleSort("sr.service_request")}>Job Title {renderSortIcon("sr.service_request")}</th>
                <th className="text-nowrap cursor-pointer" onClick={() => handleSort("sr.service_city")}>Location {renderSortIcon("sr.service_city")}</th>
                <th className="text-nowrap cursor-pointer" onClick={() => handleSort("sr.created_on")}>Job Raised On {renderSortIcon("sr.created_on")}</th>
                <th className="text-nowrap cursor-pointer" onClick={() => handleSort("sr.service_start_date")}>Requested On {renderSortIcon("sr.service_start_date")}</th>
                <th className="text-nowrap cursor-pointer" onClick={() => handleSort("amount")}># Bids Quoted {renderSortIcon("amount")}</th>
                <th className="text-nowrap text-center">Actions</th>
              </tr>
            ) : (
              <tr>
                <th className="text-nowrap cursor-pointer" onClick={() => handleSort("sr.service_request")}>Job Title {renderSortIcon("sr.service_request")}</th>
                <th className="text-nowrap cursor-pointer" onClick={() => handleSort("sr.service_city")}>Location {renderSortIcon("sr.service_city")}</th>
                <th className="text-nowrap cursor-pointer" onClick={() => handleSort("sr.created_on")}>Job Raised On {renderSortIcon("sr.created_on")}</th>
                <th className="text-nowrap cursor-pointer" onClick={() => handleSort("sr.service_start_date")}>Requested On {renderSortIcon("sr.service_start_date")}</th>
                {role === "RM" && <th className="text-nowrap cursor-pointer" onClick={() => handleSort("sr.service_request")}>Officer Type {renderSortIcon("sr.service_request")}</th>}
                {role === "RM" && (
                  <th className="text-nowrap cursor-pointer" onClick={() => handleSort("security_people_count")}>Security People Count {renderSortIcon("security_people_count")}</th>
                )}
                {role === "Admin" && (
                  <th className="text-nowrap cursor-pointer" onClick={() => handleSort("cd.customer_dba")}>Client DBA Name {renderSortIcon("cd.customer_dba")}</th>
                )}
                {role === "Admin" && <th className="text-nowrap cursor-pointer" onClick={() => handleSort("security_people_count")}># Officers {renderSortIcon("security_people_count")}</th>}
                {role === "Admin" && (
                  <th className="text-nowrap cursor-pointer" onClick={() => handleSort("officers_type")}>Officers Type {renderSortIcon("officers_type")}</th>
                )}
                {role === "RM" && <th className="text-nowrap cursor-pointer" onClick={() => handleSort("amount")}>Bid Amount {renderSortIcon("amount")}</th>}
                {role === "Admin" && (
                  <th className="text-nowrap text-center">Actions</th>
                )}
              </tr>
            )}
          </thead>

          {customerUUID ? (
            <tbody>
              {data?.length > 0 ? (
                data.map((job, index) => {
                  const remainingTimeInSeconds = calculateTimeDifference(job.created_on);
                  const urgent = isUrgent(job.service_start_date, job.created_on);

                  return (
                    <tr key={index}>
                      <td className="ps-4">
                        <div className="d-block">
                          <a
                            className="link-text text-decoration-none"
                            onClick={() =>
                              navigate("/service-request-view", {
                                state: {
                                  id: job.service_request_uuid,
                                  status: "open",
                                  amount: job.amount
                                },
                              })
                            }
                          >

                            <TooltipText text={job.service_request} maxLength={15} />
                          </a>
                          {urgent && (
                            <span className="batch-red font-semibold ms-2">
                              Urgent
                            </span>
                          )}
                          <div className="d-block mt-1" key={Math.random()}>
                            <img
                              className="me-1"
                              src="../images/hour-glass.svg"
                              alt="hour-glass"
                            />
                            <CountdownTimer
                              initialSeconds={remainingTimeInSeconds}
                            />
                          </div>
                        </div>
                      </td>
                      <td>{`${job.city}, ${job.state}`}</td>
                      <td>{dayjs(job.created_on).format("MMM DD, YYYY")}</td>
                      <td>{dayjs(job.service_start_date).format("MMM DD, YYYY")}</td>
                      <td>{job.bid_count}</td>
                      {role === "Admin" && (
                        <td className="text-center">
                          <button
                            className="btn blue-btn cust-btn-width px-4 font-12 font-semibold me-2"
                            onClick={() =>
                              navigate("/service-request-view", {
                                state: {
                                  id: job.service_request_uuid,
                                  status: "open",
                                  amount: job.amount
                                },
                              })
                            }
                          >
                            View
                          </button>
                        </td>
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={role === "Admin" ? 9 : 8} className="text-center">
                    <div className="d-flex align-items-center justify-content-center p-5 flex-column">
                      <img
                        className="no-record-img"
                        src="../images/no-record.gif"
                        alt="No record"
                      />
                      <p className="font-medium font-20 color-grey-v1 mb-0 mt-3 text-center">
                        It looks like there are currently no feedback entries to
                        display
                      </p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          ) : (
            <tbody>
              {data?.length > 0 ? (
                data.map((job, index) => {
                  const remainingTimeInSeconds = calculateTimeDifference(job?.response_time);
                  const urgent = isUrgent(job.service_start_date, job.created_on);

                  const officerTypes = Array.isArray(job.officers_type)
                    ? job.officers_type
                    : [job.officers_type];
                  const securityPeopleCounts = Array.isArray(job.security_people_count)
                    ? job.security_people_count.join(", ")
                    : job.security_people_count;

                  return (
                    <tr key={index}>
                      <td className="ps-4">
                        <div className="d-block">
                          <a
                            className="link-text text-decoration-none cursor-pointer"
                            onClick={() =>
                              navigate("/service-request-view", {
                                state: {
                                  id: job.service_request_uuid,
                                  status: "open",
                                  tab: "open",
                                  amount: job.amount
                                },
                              })
                            }
                          >

                            <TooltipText text={job.service_request} maxLength={15} />
                          </a>
                          {urgent && (
                            <span className="batch-red font-semibold ms-2">
                              Urgent
                            </span>
                          )}
                          <div className="d-block mt-1" key={Math.random()}>
                            <img
                              className="me-1"
                              src="../images/hour-glass.svg"
                              alt="hour-glass"
                            />
                            <CountdownTimer
                              initialSeconds={remainingTimeInSeconds}
                            />
                          </div>
                        </div>
                      </td>
                      <td>{`${job.city}, ${job.state}`}</td>
                      <td>{dayjs(job.created_on).format("MMM DD, YYYY")}</td>
                      <td>{dayjs(job.service_start_date).format("MMM DD, YYYY")}</td>
                      {role === "RM" && <td><OfficerRequestType officerTypes={officerTypes} /></td>}
                      {role === "RM" && <td>{securityPeopleCounts}</td>}
                      {role === "Admin" && <td>{job.doing_business}</td>}
                      {role === "Admin" && <td>{securityPeopleCounts}</td>}
                      {role === "Admin" && <td><OfficerRequestType officerTypes={officerTypes} /></td>}
                      {role === "RM" && (
                        <td>
                          {job.amount !== null
                            ? `$${job.amount.toFixed(2)}`
                            : "Yet to be Decided"}
                        </td>
                      )}
                      {role === "Admin" && (
                        <td className="text-center">
                          <button
                            className="btn blue-btn cust-btn-width px-4 font-12 font-semibold me-2"
                            onClick={() =>
                              navigate("/service-request-view", {
                                state: {
                                  id: job.service_request_uuid,
                                  status: "open",
                                  tab: "open",
                                  amount: job.amount
                                },
                              })
                            }
                          >
                            View
                          </button>
                          <button
                            className="btn green-btn cust-btn-width px-4 font-12 font-semibold me-2"
                            onClick={() => {
                              setIsEditTimer(!isEditTimer);
                              setResponseTime(job?.response_time);
                              setServiceRequestId(job?.service_request_uuid);
                            }}
                          >
                            Edit Timer
                          </button>
                        </td>
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={role === "Admin" ? 9 : 8} className="text-center">
                    <div className="d-flex align-items-center justify-content-center p-5 flex-column">
                      <img
                        className="no-record-img"
                        src="../images/no-record.gif"
                        alt="No record"
                      />
                      <p className="font-medium font-20 color-grey-v1 mb-0 mt-3 text-center">
                        It looks like there are currently no feedback entries to
                        display
                      </p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>
      {hasMore && (
        <div className="d-flex align-items-center justify-content-center mt-4">
          <button
            className="btn px-4 color-white font-semibold font-14 primary-btn rounded-3 text-center"
            onClick={onLoadMore}
            disabled={loading}
          >
            {loading ? (
              <>
                <Loader />
              </>
            ) : (
              "Load More"
            )}
          </button>
        </div>
      )}
      <p className="font-14 font-medium color-grey-v7">
        Showing{" "}
        <span className="font-semibold color-black">{data?.length}</span> out of{" "}
        <span className="font-semibold color-black">{totalCount}</span> Item
        {data?.length !== 1 ? "s" : ""}
      </p>

      {isModalOpen && selectedServiceRequestId && (
        <ViewBids
          isModalOpen={isModalOpen}
          closeModal={handleCloseModal}
          serviceRequestId={selectedServiceRequestId}
        />
      )}

      {isEditTimer && (
        <JobsAndBidsTimer
          isEditTimer={isEditTimer}
          setIsEditTimer={setIsEditTimer}
          remainingTimeInSeconds={calculateTimeDifference(responseTime)}
          responseTime={responseTime}
          serviceRequestId={serviceRequestId}
          setShowToast={setIsTimerToast}
          setToastMessage={setToastMessage}
          setShowToastProp={setShowToastProp}
          fetchGridData={fetchGridData}
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
        />
      )}
    </div>
  );
};



export default OpenTab
