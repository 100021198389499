import { useEffect } from "react";

const Toast = (props: any) => {
    const { setToastDetails, toastType, toastMessage, toastDescription } = props;
    const toastImage = {
        success: '/images/toast-sucess.svg',
        error: '/images/toast-error.svg',
        info: '/images/toast-info.svg',
    }
    useEffect(() => {
        setTimeout(() => {
            setToastDetails(false);
        }, 3000);
    })

    return (
        <div
            className="position-fixed top-0 start-50 translate-middle-x p-3"
            style={{ zIndex: 9999 }}
        >
            <div
                id="liveToast"
                className={`toast cust-toast w-100 p-1 ${toastType === "Success" ? "toast-border" : "toast-error-border"} fade show bg-white`}
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
            >
                <div className="toast-body d-flex align-items-center justify-content-between gap-5">
                    <div className="d-flex align-items-center gap-3">
                        <img src={toastType === "Success" ? toastImage.success : toastImage.error} alt="Successfully" />
                        <div className="d-block">
                            {toastMessage !== "" ? <p className="mb-0 font-4 font-semibold color-black text-nowrap mt-0">
                                {toastMessage}
                            </p> : null}
                            {toastDescription !== "" ? <p className="font-medium font-14 mb-0 color-grey-v3 text-nowrap mt-2">
                                {toastDescription}
                            </p> : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Toast;