import { useState, useEffect, useCallback } from "react";
import { getSummaryData } from "../../Service/ServiceRequest/AdminServices";
import { DashboardData } from "../../Interface/AdminInterfaces";
import JobRequestChart from "./Charts/jobRequest";
import JobRequestDistributionPie from "./Charts/jobDistribution";
import FeedbackPie from "./Charts/feedback";
import JobCreationFunnel from "./Charts/jobCreationFunnel";
import { useNavigate } from "react-router-dom";
// import Loader from "../Loader/Loader";
import { padZero } from "../../Configs/Constant";
import Loader from "../Loader";
import SummaryNoRecordFound from "../NoRecordFound/SummaryNoRecordFound";

// https://www.asami.ai/chat?chatID=81DD6D97-E424-488F-833C-3203EAFC6E39

export function SummaryDashboard() {

  const [payload, setPayload] = useState({ start_date: '', end_date: '' });
  const [filterData, setFilterData] = useState('This Month');
  const [errorMessage, setErrorMessage] = useState('');
  const [summaryData, setSummaryData] = useState<DashboardData>();
  const [currentMonthCount, setcurrentMonthCount] = useState(0);
  const [prevMonthCount, setprevMonthCount] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [HideComparison, setHideComparison] = useState(false);
  const [isEmty, setIsEmtpy] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const navigate = useNavigate()
  // PS_07
  const fetchSummaryData = useCallback(async () => {
    setShowLoader(true)
    let newPayload: any
    if (filterData === "Custom Date Range") {
      newPayload = payload
    }
    const { start_date, end_date } = calculateDateRange(filterData);
    newPayload = { start_date, end_date };

    setPayload(newPayload);

    try {
      const data = await getSummaryData(newPayload);
      checkZeroData(data);
      setSummaryData(data);
      setcurrentMonthCount(data?.no_of_job_requests?.current_month)
      setprevMonthCount(data?.no_of_job_requests?.prev_month)
      setShowLoader(false)
      setShowDropdown(false)
      if (data?.no_of_job_requests?.current_month === data?.no_of_job_requests?.prev_month) {
        setHideComparison(true)
        setShowLoader(false)
        setShowDropdown(false)
      }

    } catch (error) {
      setShowLoader(false)
      setShowDropdown(false)
      setErrorMessage('Error fetching data');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  useEffect(() => {
    fetchSummaryData();
  }, [filterData, fetchSummaryData]);

  const handleFilterChange = (selectedFilter: string) => {
    setFilterData(selectedFilter);
    const { start_date, end_date } = calculateDateRange(selectedFilter);
    setPayload({ start_date, end_date });
  };

  // PS_39
  const handleApplyClick = () => {
    const valid = validateDateRange(startDate, endDate);
    if (!valid) {
      setErrorMessage('End date must after start date');
    } else {
      setErrorMessage('');
      handleFilterChange('Custom Date Range')
      setShowCustomDate(false)
      setShowDropdown(false)
      setPayload({ start_date: startDate, end_date: endDate });
    }
  };

  // PS_37
  const validateDateRange = (start_date: string, end_date: string) => {
    return new Date(end_date) > new Date(start_date);
  };

  // PS_43
  const handleClearClick = () => {
    setStartDate("");
    setEndDate("");
    setFilterData('This Month')
    setShowCustomDate(false)
    setShowDropdown(false)
  };

  // PS_22
  const calculateDateRange = (filter: string) => {
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    let start_date, end_date;

    switch (filter) {
      case 'Today':
        start_date = end_date = date.toISOString().split('T')[0];
        break;

      case 'Last 7 days':
        start_date = new Date(new Date().setDate(date.getDate() - 7)).toISOString().split('T')[0];
        end_date = date.toISOString().split('T')[0];
        break;

      case 'This Month':
        start_date = new Date(y, m, 2).toISOString().split('T')[0];
        end_date = new Date(y, m + 1, 0).toISOString().split('T')[0];
        break;

      case 'Last Month':
        start_date = new Date(y, m - 1, 2).toISOString().split('T')[0];
        end_date = new Date(y, m, 1).toISOString().split('T')[0];
        break;

      case 'Last 3 Months':
        const startOfLast3Months = new Date(date);
        startOfLast3Months.setMonth(startOfLast3Months.getMonth() - 2);
        start_date = new Date(startOfLast3Months.getFullYear(), startOfLast3Months.getMonth(), 1).toISOString().split('T')[0];
        end_date = new Date(y, m, 1).toISOString().split('T')[0];
        break;

      default:
        start_date = end_date = date.toISOString().split('T')[0];
    }
    return { start_date, end_date };
  };

  const checkZeroData = (summaryData: DashboardData) => {

    let valid = true
    if (summaryData?.bids.bids_to_be_reviewed === 0 && summaryData?.bids.client_rejected_bids === 0 && summaryData?.new_clients_and_accounts.new_clients === 0 && summaryData?.new_clients_and_accounts.new_client_request === 0 && summaryData?.new_clients_and_accounts.new_contractor_request === 0 && summaryData?.no_of_job_requests.current_month === 0 && summaryData?.no_of_job_requests.prev_month === 0 && summaryData?.job_creation_funnel.bids_completed === 0 && summaryData?.job_creation_funnel.job_requests_submitted === 0 && summaryData?.job_creation_funnel.user_accepted_bids === 0 && summaryData?.job_request_distribution.external === 0 && summaryData?.job_request_distribution.internal === 0 && summaryData?.Feedbacks.one === 0 && summaryData?.Feedbacks.two === 0 && summaryData?.Feedbacks.three === 0 && summaryData?.Feedbacks.four === 0 && summaryData?.Feedbacks.five === 0) {
      valid = false
    }
    for (let index = 0; index < summaryData.no_of_job_requests.weeks.length; index++) {
      if (summaryData.no_of_job_requests.weeks[index].value === 0) {
        valid = false
      }

    }
    setIsEmtpy(valid)
  }

  const [showDropdown, setShowDropdown] = useState(false)
  const [showCustomDate, setShowCustomDate] = useState(false)

  return (

    <>
      {showLoader && <Loader></Loader>}
      {isEmty ?
        <SummaryNoRecordFound></SummaryNoRecordFound>
        :
        <>
          <div className="container-fluid dashboard-bg">
            <div className="row px-sm-4 px-2 pb-0 pt-4 justify-content-end">
              <div className="col-lg-2 col-md-3 col-sm-4 position-relative">
                <div className="position-relative">
                  <button
                    type="button"
                    className="position-relative form-select service-form-field cust-form-arrow rounded-1 color-black-v1 font-14 font-regular text-nowrap font-15 font-semibold color-grey-v4 text-start show"
                    // data-bs-toggle="dropdown"
                    aria-expanded={showDropdown}
                    onClick={() => {
                      setShowDropdown(!showDropdown)
                      if (filterData !== "Custom Date Range") {
                        setStartDate("")
                        setEndDate("")
                      }
                    }}
                  >
                    {filterData}
                  </button>
                  <ul
                    // className="dropdown-menu custom-dashboard-drp cust-min-width w-100 border-0 px-2 mt-2 show"
                    className={`dropdown-menu custom-dashboard-drp cust-min-width w-100 border-0 px-2 mt-2 ${showDropdown ? "show" : ""}`}
                    aria-labelledby="dropdownMenuLink"
                  >
                    <li className="font-medium font-14 color-grey-v7" onClick={() => handleFilterChange('Today')}>Today</li>
                    <li className="font-medium font-14 color-grey-v7" onClick={() => handleFilterChange('Last 7 days')}>Last 7 days</li>
                    <li className="font-medium font-14 color-grey-v7" onClick={() => handleFilterChange('This Month')}>This Month</li>
                    <li className="font-medium font-14 color-grey-v7" onClick={() => handleFilterChange('Last Month')}>Last Month</li>
                    <li className="font-medium font-14 color-grey-v7" onClick={() => handleFilterChange('Last 3 Months')}>Last 3 Months</li>
                    {showCustomDate && null}
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item custom-dashboard-drp border-0 shadow-none">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button cust-accordion-btn border-0 font-medium font-14 color-grey-v7 px-3"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                            onClick={() => {
                              if (filterData !== "Custom Date Range") {
                                setStartDate("")
                                setEndDate("")
                              }
                            }}
                          >
                            Custom Date Range
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body p-2 py-3">
                            <h4 className="font-14 color-black-v2 font-medium mb-2">From</h4>
                            <input
                              type="date"
                              id="Date"
                              max={endDate ? new Date(endDate).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]}

                              value={startDate}
                              className="form-control service-form-field color-black-v2 font-14 font-regular mb-3"
                              onChange={(e) => setStartDate(e.target.value)}
                              onKeyDown={(e) => e?.preventDefault()}

                            />
                            <h4 className="font-14 color-black-v2 font-medium mb-2">To</h4>
                            <input
                              type="date"
                              id="Date"
                              min={startDate ? new Date(startDate).toISOString().split('T')[0] : ""}
                              max={new Date().toISOString().split('T')[0]}
                              value={endDate}
                              className="form-control service-form-field color-black-v2 font-14 font-regular"
                              onChange={(e) => setEndDate(e.target.value)}
                              onKeyDown={(e) => e?.preventDefault()}

                            />
                            {errorMessage !== "" &&
                              <span className="font-14 font-medium alert-text">{errorMessage}</span>}
                            <div className="d-flex gap-2 border-0 px-4 pb-2 pt-3 justify-content-center">
                              <button className="btn px-4 font-semibold font-14 shadow-none bg-transparent text-decoration-underline cust-text-underline secondary-btn"
                                onClick={handleClearClick}>
                                Clear
                              </button>
                              <button className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                                onClick={handleApplyClick}>
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </ul>
                </div>
              </div>
            </div>

            <div className="row p-4 px-sm-4 px-2 pt-3 pt-sm-0">
              <div className="col-xl-5 ps-xl-1">
                <p className="font-semibold font-18 color-black mb-2">Bids</p>
                <div className="dashboard-sub-container overflow-hidden">
                  <div className="row ">
                    <div className="col-md-6 custom-padding dashboard-mb">
                      <div className="mt-3 mb-4 px-4 cust-border-right d-flex align-items-center gap-3">
                        <img
                          className="dashboard-icon"
                          src="../images/bid-icon.svg"
                          alt="bid-icon"
                        />
                        <div className="d-block">
                          <span className="font-22 font-semibold color-black">{summaryData?.bids.bids_to_be_reviewed ? padZero(summaryData?.bids.bids_to_be_reviewed) : "0"}</span>
                          <p className="font-13 font-semibold color-grey-v4 mb-0 text-truncate cust-width">
                            Bids to be Reviewed
                          </p>
                        </div>
                      </div>
                      <p
                        className="py-2 px-4 dashed-border-top d-flex align-items-center justify-content-between text-decoration-none view-detail"
                        onClick={() => { navigate("/jobs-and-bids", { state: { type: "Bids to be Reviewed" } }) }}
                      >
                        <span className="font-13 color-grey-v4 font-semibold">
                          View Details
                        </span>
                        <img
                          className="user-action-icon"
                          src="../images/grey-arrow.svg"
                          alt="grey-arrow"
                        />
                      </p>
                    </div>
                    <div className="col-md-6 custom-padding dashboard-mb">
                      <div className="mt-3 mb-4 px-4 cust-border-right d-flex align-items-center gap-3">
                        <img
                          className="dashboard-icon"
                          src="../images/rejected-bids.svg"
                          alt="rejected-bids"
                        />
                        <div className="d-block">
                          <span className="font-22 font-semibold color-black">
                            {summaryData?.bids.client_rejected_bids ? padZero(summaryData?.bids.client_rejected_bids) : "0"}
                          </span>
                          <p className="font-13 font-semibold color-grey-v4 mb-0 text-truncate cust-width">
                            Client Rejected Bids
                          </p>
                        </div>
                      </div>
                      <p
                        onClick={() => { navigate("/jobs-and-bids", { state: { type: "Client Request Bids" } }) }}
                        className="py-2 px-4 dashed-border-top d-flex align-items-center justify-content-between text-decoration-none view-detail overflow-hidden"
                      >
                        <span className="font-13 color-grey-v4 font-semibold">
                          View Details
                        </span>
                        <img
                          className="user-action-icon"
                          src="../images/grey-arrow.svg"
                          alt="grey-arrow"
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 pe-xl-1 mt-4 mt-xl-0">
                <p className="font-semibold font-18 color-black mb-2">
                  New Clients &amp; Account Creation Requests
                </p>
                <div className="dashboard-sub-container overflow-hidden">
                  <div className="row">
                    <div className="col-md-6 col-lg-4 custom-padding dashboard-mb">
                      <div className="mt-3 mb-4 px-4 cust-border-right d-flex align-items-center gap-3">
                        <img
                          className="dashboard-icon"
                          src="../images/new-client-icon.svg"
                          alt="new-client-icon"
                        />
                        <div className="d-block">
                          <span className="font-22 font-semibold color-black">
                            {summaryData?.new_clients_and_accounts.new_clients ? padZero(summaryData?.new_clients_and_accounts.new_clients) : "0"}
                          </span>
                          <p className="font-13 font-semibold color-grey-v4 mb-0 text-truncate cust-width"
                          >
                            New Clients
                          </p>
                        </div>
                      </div>
                      <p
                        onClick={() => { navigate("/manage-clients", { state: { type: "New Clients" } }) }}
                        className="py-2 px-4 dashed-border-top d-flex align-items-center justify-content-between text-decoration-none view-detail"
                      >
                        <span className="font-13 color-grey-v4 font-semibold">
                          View Details
                        </span>
                        <img
                          className="user-action-icon"
                          src="../images/grey-arrow.svg"
                          alt="grey-arrow"
                        />
                      </p>
                    </div>
                    <div className="col-md-6 col-lg-4 custom-padding dashboard-mb">
                      <div className="mt-3 mb-4 px-4 cust-border-right d-flex align-items-center gap-3">
                        <img
                          className="dashboard-icon"
                          src="../images/new-clent-request-icon.svg"
                          alt="newclient-request-icon"
                        />
                        <div className="d-block">
                          <span className="font-22 font-semibold color-black">
                            {summaryData?.new_clients_and_accounts.new_client_request ? padZero(summaryData?.new_clients_and_accounts.new_client_request) : "0"}
                          </span>
                          <p className="font-13 font-semibold color-grey-v4 mb-0 text-nowrap text-truncate cust-width">
                            New Client Requests
                          </p>
                        </div>
                      </div>
                      <p
                        onClick={() => { navigate("/manage-clients", { state: { type: "New Client Request" } }) }}
                        className="py-2 px-4 dashed-border-top d-flex align-items-center justify-content-between text-decoration-none view-detail"
                      >
                        <span className="font-13 color-grey-v4 font-semibold">
                          View Details
                        </span>
                        <img
                          className="user-action-icon"
                          src="../images/grey-arrow.svg"
                          alt="grey-arrow"
                        />
                      </p>
                    </div>
                    <div className="col-md-6 col-lg-4 custom-padding dashboard-mb">
                      <div className="mt-3 mb-4 px-4 cust-border-right d-flex align-items-center gap-3">
                        <img
                          className="dashboard-icon"
                          src="../images/new-contractor-request.svg"
                          alt="new-contractor-request"
                        />
                        <div className="d-block">
                          <span className="font-22 font-semibold color-black">
                            {summaryData?.new_clients_and_accounts.new_contractor_request ? padZero(summaryData?.new_clients_and_accounts.new_contractor_request) : "0"}
                          </span>
                          <p className="font-13 font-semibold color-grey-v4 mb-0 text-nowrap text-truncate cust-width"
                            title="New Contractor Requests">
                            New Contractor Req...
                          </p>
                        </div>
                      </div>
                      <p
                        onClick={() => { navigate("/contractor-grid", { state: { type: "New Contractor Request" } }) }}
                        className="py-2 px-4 dashed-border-top d-flex align-items-center justify-content-between text-decoration-none view-detail"
                      >
                        <span className="font-13 color-grey-v4 font-semibold">
                          View Details
                        </span>
                        <img
                          className="user-action-icon"
                          src="../images/grey-arrow.svg"
                          alt="grey-arrow"
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 ps-xl-1 mt-4">
                <div className="dashboard-sub-container overflow-hidden pt-3 pb-4 px-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="mb-0 color-black font-15 font-semibold">
                      # of Job Requests
                    </p>
                    {!HideComparison &&
                      <p className="mb-0 color-grey-v4 font-15 font-semibold">
                        Last Month Comparison
                      </p>
                    }

                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <p className="color-black font-34 font-semibold mb-0">
                      {summaryData?.no_of_job_requests.current_month ? padZero(summaryData?.no_of_job_requests.current_month) : "0"}
                    </p>
                    {
                      currentMonthCount > prevMonthCount ? (
                        <span className="font-34 font-semibold d-flex align-items-center gap-2 dark-green-color">

                          <img src="../images/chart-arrow.svg" className="" alt="arrow" />{padZero(currentMonthCount - prevMonthCount)}
                        </span>
                      )
                        : currentMonthCount < prevMonthCount ? (
                          <span className="font-34 font-semibold d-flex align-items-center gap-2 dark-red-color">

                            <img src="../images/chart-arrow.svg" className="" alt="arrow" />{padZero(prevMonthCount - currentMonthCount)}
                          </span>
                        )
                          : null
                    }
                  </div>
                  {/* chart scroll */}
                  <div className="chart-area">
                    {summaryData?.no_of_job_requests.weeks.length &&
                      <JobRequestChart props={summaryData?.no_of_job_requests.weeks} ></JobRequestChart>
                    }
                  </div>
                </div>
              </div>
              <div className="col-xl-8 pe-xl-1 mt-4">
                <div className="dashboard-sub-container overflow-hidden pt-3 pb-4 px-4">
                  <p className="mb-0 color-black font-15 font-semibold">
                    Job Creation Funnel
                  </p>
                  {/* chart scroll */}
                  <div className="chart-area job-creation chart scroll" style={{ height: "302px" }}>
                    {summaryData?.job_creation_funnel?.bids_completed === 0 && summaryData?.job_creation_funnel?.job_requests_submitted === 0 && summaryData?.job_creation_funnel?.user_accepted_bids === 0 ?
                      null
                      : <JobCreationFunnel props={summaryData?.job_creation_funnel}></JobCreationFunnel>
                    }
                    {/* // } */}
                  </div>
                </div>
              </div>
              <div className="col-xl-6 ps-xl-1 mt-4">
                <div className="dashboard-sub-container overflow-hidden pt-3 pb-4 px-4 h-100">
                  <p className="mb-4 color-black font-15 font-semibold">
                    Job Request Distribution
                  </p>
                  <div className="row">
                    <div className="col-lg-4 col-md-6 d-flex justify-content-center"
                    >
                      <div style={{ width: "600px", height: "160px" }} className="chart-area">
                        {summaryData?.job_request_distribution.external === 0 && summaryData.job_request_distribution.internal === 0 ?
                          null : <JobRequestDistributionPie props={summaryData?.job_request_distribution}></JobRequestDistributionPie>
                        }
                      </div>

                    </div>
                    <div className="col-lg-8 col-md-6 d-flex justify-content-center">
                      <div className="d-flex align-items-center justify-content-center flex-wrap gap-2 gap-sm-5 mb-3">
                        <div className="d-flex d-sm-block gap-2 align-items-center">
                          <span className="font-semibold font-14 color-grey-v4 d-flex align-items-center gap-2 mb-sm-3">
                            <span className="legend legend-green" />
                            External Contractors
                          </span>
                          <span className="font-24 font-semibold color-grey-v5 ms-2">
                            {summaryData?.job_request_distribution.external ? padZero(summaryData?.job_request_distribution.external) : "0"}
                          </span>
                        </div>
                        <div className="d-flex d-sm-block gap-2 align-items-center">
                          <span className="font-semibold font-14 color-grey-v4 d-flex align-items-center gap-2 mb-sm-3">
                            <span className="legend legend-yellow" />
                            PPS Contractors
                          </span>
                          <span className="font-24 font-semibold color-grey-v5 ms-2">
                            {summaryData?.job_request_distribution.internal ? padZero(summaryData?.job_request_distribution.internal) : "0"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 ps-xl-1 mt-4">
                <div className="dashboard-sub-container overflow-hidden pt-3 pb-4 px-4">
                  <p className="mb-4 color-black font-15 font-semibold">Feedback Trend</p>
                  <div className="row mt-3 gap-sm-0 gap-3">
                    <div className="col-md-4 col-sm-6 d-flex justify-content-center">
                      <div style={{ width: "600px", height: "160px" }}>
                        <FeedbackPie props={summaryData?.Feedbacks}></FeedbackPie>
                      </div>
                    </div>
                    <div className="col-md-8 col-sm-6">
                      <div className="d-flex align-items-center justify-content-center gap-4 flex-wrap">
                        <div className="d-flex align-items-center gap-3">
                          <span className="dashboard-emoji-sty">
                            <img
                              className="icon-sty"
                              src="../images/extremely-bad-emoji.svg"
                              alt="Very Disatisfied"
                            />
                          </span>
                          <div className="d-block">
                            <p className="font-12 font-semibold color-grey-v6 mb-1">
                              Very Dissatisfied
                            </p>
                            <span className="font-15 font-semibold color-black">{summaryData?.Feedbacks.one ? padZero(summaryData?.Feedbacks.one) : "0"}</span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-3">
                          <span className="dashboard-emoji-sty">
                            <img
                              className="icon-sty"
                              src="../images/not-satisfied-emoji.svg"
                              alt="Disatisfied"
                            />
                          </span>
                          <div className="d-block">
                            <p className="font-12 font-semibold color-grey-v6 mb-1">
                              Dissatisfied
                            </p>
                            <span className="font-15 font-semibold color-black">{summaryData?.Feedbacks.two ? padZero(summaryData?.Feedbacks.two) : "0"}</span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-3">
                          <span className="dashboard-emoji-sty">
                            <img
                              className="icon-sty"
                              src="../images/neutral-emoji.svg"
                              alt="Very Disatisfied"
                            />
                          </span>
                          <div className="d-block">
                            <p className="font-12 font-semibold color-grey-v6 mb-1">
                              Neutral
                            </p>
                            <span className="font-15 font-semibold color-black">{summaryData?.Feedbacks.three ? padZero(summaryData?.Feedbacks.three) : "0"}</span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-3">
                          <span className="dashboard-emoji-sty">
                            <img
                              className="icon-sty"
                              src="../images/satisfied-emoji.svg"
                              alt="Satisfied"
                            />
                          </span>
                          <div className="d-block">
                            <p className="font-12 font-semibold color-grey-v6 mb-1">
                              Satisfied
                            </p>
                            <span className="font-15 font-semibold color-black">{summaryData?.Feedbacks.four ? padZero(summaryData?.Feedbacks.four) : "0"}</span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-3">
                          <span className="dashboard-emoji-sty">
                            <img
                              className="icon-sty"
                              src="../images/extremely-good-emoji.svg"
                              alt="Very Satisfied"
                            />
                          </span>
                          <div className="d-block">
                            <p className="font-12 font-semibold color-grey-v6 mb-1">
                              Very Satisfied
                            </p>
                            <span className="font-15 font-semibold color-black">{summaryData?.Feedbacks.five ? padZero(summaryData?.Feedbacks.five) : "0"}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >
        </>
      }
    </>
  )
}