
import React, { useEffect, useState } from "react";
import { PersonnalFormProps } from "../../../Interface/EditContractor/Signup";


// PS_CSU__68

export function PersonnalForm({ ServciesData, selectedServices, setSelectedServices, setShowOthers, showOthers, setFormPayload, PaymentDiscountsData, formPayload, handlePrevious, handleNext, currentStep, setAcknowledgements, Acknowledgements, isEdit, SaveFromDetails, bindSecurityScopeDropdown, bindCompanySizeDropdown, serviceProfileErrors, handleInputChange, DropdownData, dropdownSelected }: PersonnalFormProps) {
    const serviceIcons: { [key: string]: string } = {
        "Armed": "./../images/armed-guards.svg",
        "Unarmed": "./../images/unarmed-guards.svg",
        "Concierge": "./../images/concierge.svg",
        "Plain-clothed": "./../images/plain-clothed.svg",
        "Off-duty PD": "./../images/off-duty.svg",
        "Marked Vehicle": "./../images/marked-vehicle.svg",
        "Private Investigator": "./../images/private-investigatore.svg",
        "Mobile Patrol": "./../images/mobile-patrol.svg",
        "Robotics": "./../images/robot.svg",
        "Executive Protection": "./../images/executive-protection.svg",
        "Others": "./../images/more.svg",
        // Add more icons for other services as needed
    };
    const [nextButtonEnabled, setNextButtonEnabled] = useState(false)
    const isOnlyLetters = (str: string) => /^[A-Za-z\s]*$/.test(str);
    const isOnlyNumbers = (str: string) => /^\d*$/.test(str);

    const MAX_OTHERS_LENGTH = 255;
    const MAX_PAYROLL_LENGTH = 10;

    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [companySize, setCompanySize] = useState("")
    // PS_CSU__69
    const handleServiceSelection = (serviceUuid: string) => {
        setSelectedServices(prevServices => {
            const serviceIndex = prevServices.findIndex(s => s.service_uuid === serviceUuid);
            let newServices;
            if (serviceIndex !== -1) {
                newServices = prevServices.filter(s => s.service_uuid !== serviceUuid);
            } else {
                const newService = ServciesData.find(s => s.service_uuid === serviceUuid);
                if (newService) {
                    newServices = [...prevServices, {
                        service_name: newService.service_name,
                        service_uuid: newService.service_uuid,
                        guard_count: '',
                        others: ''
                    }];
                } else {
                    newServices = prevServices;
                }
            }

            const othersService = newServices.find(s => s.service_name === "Others");
            setShowOthers(prev => ({
                ...prev,
                current_service: !!othersService
            }));

            // Trigger validation immediately after updating the state
            validateForm(newServices, formData);

            return newServices;
        });
    };
    // PS_CSU__70
    const handleOthersInputChange = (value: string) => {
        if (value.length <= MAX_OTHERS_LENGTH && isOnlyLetters(value)) {
            setSelectedServices(prevServices => {
                const updatedServices = prevServices.map(service => {
                    if (service.service_name === "Others") {
                        return { ...service, others: value };
                    }
                    return service;
                });

                // Trigger validation immediately after updating the state
                validateForm(updatedServices, formData);

                return updatedServices;
            });
            setErrors(prev => ({ ...prev, others: '' }));
        } else {
            setErrors(prev => ({ ...prev, others: 'Please enter your Officer Request Type' }));
        }
    };

    // PS_CSU__71
    const handleOfficerServiceInputChange = (serviceUuid: string, value: string) => {
        if (value.length <= MAX_PAYROLL_LENGTH && isOnlyNumbers(value)) {
            setSelectedServices(prevServices => {
                const updatedServices = prevServices.map(service => {
                    if (service.service_uuid === serviceUuid) {
                        return { ...service, guard_count: value };
                    }
                    return service;
                });

                // Trigger validation immediately after updating the state
                validateForm(updatedServices, formData);

                return updatedServices;
            });
            setErrors(prev => ({ ...prev, [serviceUuid]: '' }));
        } else {
            setErrors(prev => ({ ...prev, [serviceUuid]: 'Please Enter the Security People Count' }));
        }
        if (value === "") {
            setErrors(prev => ({ ...prev, [serviceUuid]: 'Please Enter the Security People Count' }));
        }
    };



    const getOthersValue = () => {
        const othersService = selectedServices.find(service => service.service_name === "Others");
        return othersService ? othersService.others : '';
    };

    // PS_CSU__73
    const bindServiceButtons = () => {
        return ServciesData.map((service) => (
            <button
                key={service.service_uuid}
                className={`outline-btn font-medium font-12 m-2 d-flex align-items-center gap-2 ${selectedServices.some(s => s.service_uuid === service.service_uuid) ? 'active' : ''
                    }`}
                onClick={() => handleServiceSelection(service.service_uuid)}
            >
                {serviceIcons[service.service_name] && (
                    <img src={serviceIcons[service.service_name]} alt={service.service_name} />
                )}
                {service.service_name}
            </button>
        ));
    };

    // PS_CSU__74
    const bindInputFields = () => {
        return selectedServices.map(service => (
            <div key={service.service_uuid} className="col-lg-6 mb-4 mb-lg-5">
                <label htmlFor={service.service_uuid} className="font-semibold font-16 color-black-v2 mb-2">
                    # of {service.service_name} guards on payroll?
                    <span className="madatory-text">*</span>
                </label>
                <input
                    type="text"
                    id={service.service_uuid}
                    className={`form-control service-form-field color-black-v2 font-14 font-regular ${errors[service.service_uuid] ? 'critical-field' : ''
                        }`}
                    placeholder={`Enter ${service.service_name} Guard Count`}
                    value={service.guard_count}
                    onChange={(e) => {
                        if (/^\d+$/.test(e.target.value) || e.target.value === "") {
                            handleOfficerServiceInputChange(service.service_uuid, e.target.value)
                        }
                    }}
                    maxLength={MAX_PAYROLL_LENGTH}
                />
                {errors[service.service_uuid] &&
                    <span className="font-14 font-medium alert-text">
                        {errors[service.service_uuid]}
                    </span>
                }
            </div>
        ));
    };

    // Find the 30-day payment option
    const defaultDiscount = PaymentDiscountsData.find(discount => discount.payment_days === "30 days");

    // Set the default discount when the component mounts
    useEffect(() => {
        if (defaultDiscount) {
            setFormPayload(prevPayload => ({
                ...prevPayload,
                payment_discounts: defaultDiscount.payment_discounts_uuid
            }));
        }
        let companySize = DropdownData.find((ele) => ele.dropdown_name === "Company Size")

        if (companySize !== undefined) {
            setCompanySize(companySize.dropdown_uuid)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [DropdownData]);


    // PS_CSU__77 don't remove this
    // const renderDiscountOptions = () => {
    //     return PaymentDiscountsData.map((discount) => (
    //         <label
    //             key={discount.payment_discounts_uuid}
    //             className={`form-check-label form-control service-form-field py-2 mb-3 ps-5 position-relative ${discount.payment_discounts_uuid === formPayload.payment_discounts ? 'active-discount' : ''
    //                 }`}
    //             htmlFor={`discount-${discount.payment_discounts_uuid}`}
    //         >
    //             <input
    //                 className="form-check-input custom-form-check-input"
    //                 type="radio"
    //                 name="discountOptions"
    //                 id={`discount-${discount.payment_discounts_uuid}`}
    //                 value={formPayload.payment_discounts}
    //                 checked={formPayload.payment_discounts === discount?.payment_discounts_uuid}
    //                 // checked={discount.payment_days === "30 days"}
    //                 // disabled={true}
    //                 onChange={() => {setFormPayload(prevPayload => ({
    //                     ...prevPayload,
    //                     payment_discounts: discount.payment_discounts_uuid
    //                 })); }}
    //             />
    //             <span className="font-semibold font-12 color-black mb-2">
    //                 {discount.fee_percentage}% {Number(discount.fee_percentage) > 0 ? 'Discount' : 'Fee'} on Invoiced amount
    //             </span>
    //             <span className="font-regular font-12 color-black mb-0 d-block">
    //                 Complete payment in {discount.payment_days}
    //             </span>
    //         </label>
    //     ));
    // };

    const [formData, setFormData] = useState({
        off_duty_police: formPayload.off_duty_police,
        acknowledgements: {
            subcontracting: Acknowledgements.subcontracting,
            drugScreen: Acknowledgements.drugScreen,
            randomDrugScreening: Acknowledgements.randomDrugScreening,
            backgroundCheck: Acknowledgements.backgroundCheck
        }
    });

    // ... (other functions remain the same)

    useEffect(() => {
        validateForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData, selectedServices, dropdownSelected, companySize]);


    const validateForm = (services = selectedServices, data = formData) => {

        const othersService = services.find(service => service.service_name === 'Others');
        console.log(othersService, "othersService");
        let isOthersValid = true;
        if (othersService) {
            isOthersValid = othersService ? othersService.others.trim() !== '' : true;
        }

        let isValid = services.length > 0 &&
            Object.values(data.acknowledgements).every(value => value === 'true') &&
            services.every(service => service.guard_count.trim() !== '');

        console.log(DropdownData, "DropdownData");

        let companySize = DropdownData.find((ele) => ele.dropdown_name === "Company Size")
        if (companySize === undefined) {
            isValid = false
        } else if (companySize.dropdown_uuid === "") {
            isValid = false
        }

        console.log(isValid, isOthersValid, dropdownSelected.security_scope, "isValid, isOthersValid, dropdownSelected.security_scope");

        setNextButtonEnabled(isValid && isOthersValid && dropdownSelected.security_scope);
        if (isValid && isOthersValid) {
            updateFormPayload();
        }
    };


    // PS_CSU__76
    const updateFormPayload = () => {
        setFormPayload(prevPayload => ({
            ...prevPayload,
            acknowledgements: 'Acknowledged',
            off_duty_police: formData.off_duty_police
        }));
    };


    // PS_CSU__72
    const handleInputChangePersonnal = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        // if (name === "company_size") {
        //     if (value === "") {
        //         setDropdownData((prev)=>({...prev,dropdown_uuid: ele.dropdown_uuid,
        //             dropdown_name: ele.dropdown_name,
        //             others: value}))
        //         setErrors(prev => ({ ...prev, company_size: "Please enter your company size" }));
        //     } else {
        //         setErrors(prev => ({ ...prev, company_size: "" }));
        //     }
        // }

        setFormData(prevData => {
            let newData;
            if (name.startsWith('acknowledgements.')) {
                const acknowledgementKey = name.split('.')[1] as keyof typeof prevData.acknowledgements;
                newData = {
                    ...prevData,
                    acknowledgements: {
                        ...prevData.acknowledgements,
                        [acknowledgementKey]: value
                    }
                };
            } else {
                newData = {
                    ...prevData,
                    [name]: value
                };
            }
            return newData;
        });
        if (name.startsWith('acknowledgements.')) {
            const acknowledgementKey = name.split('.')[1] as keyof typeof formData.acknowledgements;
            setAcknowledgements((prev) => ({ ...prev, [acknowledgementKey]: value }))
        }
    };

    // PS_CSU__78
    const handleCancel = () => {
        // Reset selected services
        setSelectedServices([]);

        // Reset showOthers
        setShowOthers({ ...showOthers, current_service: false });

        // Reset form data
        setFormData({
            off_duty_police: "",
            acknowledgements: {
                subcontracting: "",
                drugScreen: "",
                randomDrugScreening: "",
                backgroundCheck: ""
            }
        });

        // Reset form payload
        setFormPayload(prevPayload => ({
            ...prevPayload,
            acknowledgements: "",
            off_duty_police: "",
            payment_discounts_uuid: ""
        }));

        // Reset errors
        setErrors({});

        // Disable next button
        setNextButtonEnabled(false);
    };

    return (
        <>
            <div className="d-flex align-items-center gap-3 mb-5">
                <h2 className="color-black font-600 font-18 mb-0">Security Personnel Information</h2>
            </div>
            <div className="col-lg-12 mb-4 mb-lg-5">
                <label className="font-semibold font-18 color-black-v2 mb-2">
                    Officer Request Type <span className="madatory-text">*</span>
                </label>
                <p className="font-regular font-12 color-grey cust-mb">
                    Which request do you need?
                </p>
                <div className="d-flex align-items-center flex-wrap">
                    {bindServiceButtons()}
                </div>
            </div>

            {showOthers.current_service && (
                <div className="col-lg-6 mb-4 mb-lg-5">
                    <label
                        htmlFor="othersInput"
                        className="font-semibold font-16 color-black-v2 mb-2"
                    >
                        Please Specify <span className="madatory-text">*</span>
                    </label>
                    <input
                        type="text"
                        id="othersInput"

                        className={`form-control service-form-field color-black-v2 font-14 font-medium ${errors.others ? 'critical-field' : ''
                            }`}
                        placeholder="Enter other service"
                        name="others"
                        value={getOthersValue()}

                        onChange={(e) => {
                            if (/^[a-zA-Z\s]+$/.test(e.target.value) || e.target.value === "") {
                                handleOthersInputChange(e.target.value)
                            }
                        }}
                        maxLength={MAX_OTHERS_LENGTH}
                    />
                    {errors.others &&
                        <span className="font-14 font-medium alert-text">
                            {errors.others}
                        </span>
                    }
                </div>
            )}
            {bindInputFields()}
            <div className="col-lg-6 mb-4 mb-lg-5">
                <label className="font-semibold font-16 color-black-v2 mb-2">
                    Security Scopes{" "}
                    <span className="madatory-text">*</span>
                </label>
                {/* <select
                    name="example"
                    id="lPPS"
                    className="w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular"
                >
                    <option value="option1">Select</option>
                </select> */}
                {bindSecurityScopeDropdown()}
                {serviceProfileErrors.security_scope !== "" &&
                    <span className="font-14 font-medium alert-text">
                        {serviceProfileErrors.security_scope}
                    </span>
                }
            </div>
            <div className="col-lg-6 mb-4 mb-lg-5">
                <label
                    htmlFor="company_size"
                    className="font-semibold font-16 color-black-v2 mb-2"
                >
                    Company Size <span className="madatory-text">*</span>
                </label>
                <select
                    id="company_size"
                    className={`w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular ${serviceProfileErrors?.company_size !== "" ? 'critical-field' : ''}`}
                    name="company_size"
                    value={companySize}
                    onChange={(e) => { handleInputChange(e.target.name, e.target.value) }}
                >
                    <option value="">Select</option>
                    {bindCompanySizeDropdown()}
                </select>
                {serviceProfileErrors?.company_size !== "" &&
                    <span className="font-14 font-medium alert-text">
                        {serviceProfileErrors?.company_size}
                    </span>
                }
            </div>
            <div className="col-lg-12 mb-4 mb-lg-5">
                <label htmlFor="agp" className="font-semibold font-16 color-black-v2 mb-3">
                    Ability to place off-duty Police Officers
                </label>
                <div className="d-flex gap-4 align-items-center">
                    <div className="form-check">
                        <input
                            className="form-check-input custom-radio-input"
                            type="radio"
                            name="off_duty_police"
                            id="offDutyPoliceYes"
                            value="Yes"
                            checked={formData.off_duty_police === "Yes"}
                            onChange={handleInputChangePersonnal}
                        />
                        <label className="font-semibold font-14 color-black-v2 mt-1 ps-2" htmlFor="offDutyPoliceYes">
                            Yes
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input custom-radio-input"
                            type="radio"
                            name="off_duty_police"
                            id="offDutyPoliceNo"
                            value="No"
                            checked={formData.off_duty_police === "No"}
                            onChange={handleInputChangePersonnal}
                        />
                        <label className="font-semibold font-14 color-black-v2 mt-1 ps-2" htmlFor="offDutyPoliceNo">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <div className="col-lg-12 mb-4 mb-lg-5">
                <label className="font-semibold font-18 color-black-v2 mb-5">
                    Acknowledgements <span className="madatory-text">*</span>
                </label>
                <label htmlFor="agp" className="font-semibold font-16 color-black-v2 mb-3">
                    Subcontracting of any PPS work orders assigned to your company is strictly
                    prohibited. Do you agree to comply with this requirement?{" "}
                    <span className="madatory-text">*</span>
                </label>
                <div className="d-flex gap-4 align-items-center">
                    <div className="form-check">
                        <input
                            className="form-check-input custom-radio-input"
                            type="radio"
                            name="acknowledgements.subcontracting"
                            id="subcontractingYes"
                            value="true"
                            checked={formData.acknowledgements.subcontracting === "true"}
                            onChange={handleInputChangePersonnal}
                        />
                        <label
                            className="font-semibold font-14 color-black-v2 mt-1 ps-2"
                            htmlFor="subcontractingYes"
                        >
                            Yes
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input custom-radio-input"
                            type="radio"
                            name="acknowledgements.subcontracting"
                            id="subcontractingNo"
                            value="false"
                            checked={formData.acknowledgements.subcontracting === "false"}
                            onChange={handleInputChangePersonnal}
                        />
                        <label
                            className="font-semibold font-14 color-black-v2 mt-1 ps-2"
                            htmlFor="subcontractingNo"
                        >
                            No
                        </label>
                    </div>
                </div>
            </div>            <div className="col-lg-12 mb-4 mb-lg-5">
                <label htmlFor="agp" className="font-semibold font-16 color-black-v2 mb-3">
                    Any guard assigned to a PPS work order must have a negative drug screen on
                    file with your company BEFORE arriving at our client’s location for their
                    first shift. Do you agree to comply with this requirement?{" "}
                    <span className="madatory-text">*</span>
                </label>
                <div className="d-flex gap-4 align-items-center">
                    <div className="form-check">
                        <input
                            className="form-check-input custom-radio-input"
                            type="radio"
                            name="acknowledgements.drugScreen"
                            id="drugScreenYes"
                            value="true"
                            checked={formData.acknowledgements.drugScreen === "true"}
                            onChange={handleInputChangePersonnal}
                        />
                        <label
                            className="font-semibold font-14 color-black-v2 mt-1 ps-2"
                            htmlFor="subcontractingYes"
                        >
                            Yes
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input custom-radio-input"
                            type="radio"
                            name="acknowledgements.drugScreen"
                            id="drugScreenNo"
                            value="false"
                            checked={formData.acknowledgements.drugScreen === "false"}
                            onChange={handleInputChangePersonnal}
                        />
                        <label
                            className="font-semibold font-14 color-black-v2 mt-1 ps-2"
                            htmlFor="subcontractingNo"
                        >
                            No
                        </label>
                    </div>
                </div>
            </div>
            <div className="col-lg-12 mb-4 mb-lg-5">
                <label htmlFor="agp" className="font-semibold font-16 color-black-v2 mb-3">
                    At your discretion, do you agree to request additional random drug
                    screening of any guards assigned to a PPS work order?{" "}
                    <span className="madatory-text">*</span>
                </label>
                <div className="d-flex gap-4 align-items-center">
                    <div className="form-check">
                        <input
                            className="form-check-input custom-radio-input"
                            type="radio"
                            name="acknowledgements.randomDrugScreening"
                            id="randomDrugScreeningYes"
                            value="true"
                            checked={formData.acknowledgements.randomDrugScreening === "true"}
                            onChange={handleInputChangePersonnal}
                        />
                        <label
                            className="font-semibold font-14 color-black-v2 mt-1 ps-2"
                            htmlFor="subcontractingYes"
                        >
                            Yes
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input custom-radio-input"
                            type="radio"
                            name="acknowledgements.randomDrugScreening"
                            id="randomDrugScreeningNo"
                            value="false"
                            checked={formData.acknowledgements.randomDrugScreening === "false"}
                            onChange={handleInputChangePersonnal}
                        />
                        <label
                            className="font-semibold font-14 color-black-v2 mt-1 ps-2"
                            htmlFor="subcontractingNo"
                        >
                            No
                        </label>
                    </div>
                </div>
            </div>
            <div className="col-lg-12 mb-4 mb-lg-5">
                <label htmlFor="agp" className="font-semibold font-16 color-black-v2 mb-3">
                    Any guard assigned to a PPS work order must have a successfully passed
                    industry standard background check on file with your company BEFORE
                    arriving at our client’s location for their first shift. Do you agree to
                    comply with this requirement?
                    <span className="madatory-text">*</span>
                </label>
                <div className="d-flex gap-4 align-items-center">
                    <div className="form-check">
                        <input
                            className="form-check-input custom-radio-input"
                            type="radio"
                            name="acknowledgements.backgroundCheck"
                            id="backgroundCheckYes"
                            value="true"
                            checked={formData.acknowledgements.backgroundCheck === "true"}
                            onChange={handleInputChangePersonnal}
                        />
                        <label
                            className="font-semibold font-14 color-black-v2 mt-1 ps-2"
                            htmlFor="subcontractingYes"
                        >
                            Yes
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input custom-radio-input"
                            type="radio"
                            name="acknowledgements.backgroundCheck"
                            id="backgroundCheckNo"
                            value="false"
                            checked={formData.acknowledgements.backgroundCheck === "false"}
                            onChange={handleInputChangePersonnal}
                        />
                        <label
                            className="font-semibold font-14 color-black-v2 mt-1 ps-2"
                            htmlFor="subcontractingNo"
                        >
                            No
                        </label>
                    </div>
                </div>
            </div>

            {/* Repeat similar structure for other acknowledgements (drugScreen, randomDrugScreening, backgroundCheck) */}
            {/* don't remove this  */}
            {/* <div className="col-lg-6 mb-4 mb-lg-5">
                <label className="font-semibold font-16 color-black-v2 mb-2">
                    Apply Discounts
                </label>
                <p className="font-regular font-12 color-grey cust-mb">
                    Receive discounts on services by making timely payments.
                </p>
                <div className="row align-items-center flex-wrap">
                    <div className="col-md-12">
                        {renderDiscountOptions()}
                    </div>
                </div>
            </div> */}
            <div className="modal-footer justify-content-between border-0 gap-4 pb-5">
                <button className="btn px-0 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn"
                    onClick={() => { handleCancel() }}>
                    Cancel
                </button>
                <div className="d-flex gap-3">
                    <button className="btn tertiary-btn px-4 color-white font-semibold font-14 px-4"
                        onClick={handlePrevious}
                        disabled={currentStep === 0}>
                        Previous
                    </button>
                    <button className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                        onClick={() => {
                            handleNext()

                        }}
                        disabled={!nextButtonEnabled}
                    >
                        Next
                    </button>
                    {/* {isEdit &&
                        <button className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                            // disabled={!buttonSubmit}
                            onClick={() => { SaveFromDetails() }}
                        >
                            Update
                        </button>
                    } */}
                </div>
            </div>
        </ >
    )
}