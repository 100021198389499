import React, { useEffect, useState } from "react";
import {
  IndustryDataInterface,
  InviteUpdateClientFormDataPayloadInterface,
  ToastInterface,
  ValidationErrorsInterfaceType,
} from "../../Interface/ManageClient";
import {
  formatPhoneNumberOnChange,
  isRegexValidMobile,
  validateEmail,
} from "../../Configs/Constant";
import { postInviteUpdateClientService } from "../../Service/ManageClients/ManageClientsService";

const InviteClientForm = (props: {
  setShowClientForm: React.Dispatch<React.SetStateAction<boolean>>;
  setCallGridAPI: React.Dispatch<React.SetStateAction<string>>;
  industryData?: IndustryDataInterface[];
  receivedPayload: InviteUpdateClientFormDataPayloadInterface;
  setToastDetails: React.Dispatch<React.SetStateAction<ToastInterface>>;
  setIsLoader: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const initialValidationErrorMessage: ValidationErrorsInterfaceType = {
    business_email: "",
    // customer_dba: "",
    // customer_name: "",
    // industry_uuid: "",
    phone_number: "",
  };

  const initialInviteUpdateFormPayload: InviteUpdateClientFormDataPayloadInterface =
  {
    post_type: "invite",
    customer_uuid: "",
    customer_dba: "",
    first_name: "",
    last_name: "",
    business_email: "",
    industry_uuid: "",
    other_industry: "",
    phone_number: "+1",
    is_mark_active: null,
  };

  const [
    inviteUpdateClientFormDataPayload,
    setInviteUpdateClientFormDataPayload,
  ] = useState<InviteUpdateClientFormDataPayloadInterface>(
    initialInviteUpdateFormPayload
  );

  const [validationErrorMessage, setValidationErrorMessage] =
    useState<ValidationErrorsInterfaceType>(initialValidationErrorMessage);

  const [isOtherIndustry, setIsOtherIndustry] = useState<boolean>(false);

  useEffect(
    () => {
      if (props.receivedPayload.customer_uuid !== "") {
        setInviteUpdateClientFormDataPayload(() => ({
          ...props.receivedPayload,
        }));
        if (
          props?.industryData?.find(
            (ele) => ele?.industry_name.toLowerCase() === "other"
          )?.industry_uuid === props.receivedPayload.industry_uuid
        ) {
          setIsOtherIndustry(true);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // PS_27, PS_28, PS_29, PS_30, PS_37, PS_38
  const handleFormSubmit = async () => {
    // e.preventDefault();
    const errors = formValidation();
    if (inviteUpdateClientFormDataPayload.phone_number === "+1") {
    }
    if (
      !Object.values(validationErrorMessage).find((e) => e !== "") &&
      errors
    ) {
      try {
        props.setShowClientForm(false);
        props.setIsLoader(true);
        setInviteUpdateClientFormDataPayload(initialInviteUpdateFormPayload);
        setIsOtherIndustry(false);
        const response = await postInviteUpdateClientService({
          ...inviteUpdateClientFormDataPayload,
          phone_number:
            inviteUpdateClientFormDataPayload.phone_number === "+1"
              ? ""
              : inviteUpdateClientFormDataPayload.phone_number,
          business_email: inviteUpdateClientFormDataPayload.business_email.toLowerCase()
        });
        if (response?.status?.toLowerCase() === "success") {
          if (response?.message === "Email Id already exists") {
            props.setToastDetails((prevState) => ({
              ...prevState,
              isToast: true,
              toastDescription: response?.message,
              toastMessage: "Failed to invite Client",
              toastType: "Error",
            }));
          } else {
            if (response?.message === "Client Invited Successfully") {
              props.setToastDetails((prevState) => ({
                ...prevState,
                isToast: true,
                toastDescription: response?.message,
                toastMessage: "Invited Successfully",
                toastType: "Success",
              }));
            } else if (response?.message === "Client Updated Successfully") {
              props.setToastDetails((prevState) => ({
                ...prevState,
                isToast: true,
                toastDescription: response?.message,
                toastMessage: "Updated Successfully",
                toastType: "Success",
              }));
            }
          }
        } else if (response?.code !== 200) {
          props.setToastDetails((prevState) => ({
            ...prevState,
            isToast: true,
            toastDescription: inviteUpdateClientFormDataPayload.post_type === "invite" ? "Failed to invite the client" : "Failed to update the client",
            toastMessage: inviteUpdateClientFormDataPayload.post_type === "invite" ? "Invite Failed" : "Update Failed",
            toastType: "Error",
          }));
        }
        // Handle a successful update

      } catch (error) {
        console.error("Error updating client:", error);
      } finally {
        // props.setIsLoader(false);
        props.setCallGridAPI(() => "call");
      }
    }
  };

  const formValidation = (event?: any) => {
    let valid = true;
    if (event) {
      // Inline validation
      if (event.target.name === "business_email") {
        if (event.target.value?.trim() === "") {
          setValidationErrorMessage((prevState) => ({
            ...prevState,
            business_email: "",
          }));
        } else if (
          event.target.value.trim() !== "" &&
          !validateEmail(event.target.value)
        ) {
          setValidationErrorMessage((prevState) => ({
            ...prevState,
            business_email: "Please enter valid email",
          }));
          valid = false;
        } else {
          setValidationErrorMessage((prevState) => ({
            ...prevState,
            business_email: "",
          }));
          valid = true;
        }
      }
      if (event.target.name === "phone_number") {
        if (event.target.value?.trim() === "") {
          setValidationErrorMessage((prevState) => ({
            ...prevState,
            phone_number: "",
          }));
        } else if (event.target.value === "+1") {
          setValidationErrorMessage((prevState) => ({
            ...prevState,
            phone_number: "",
          }));
          setInviteUpdateClientFormDataPayload((prevState) => ({
            ...prevState,
            phone_number: "",
          }));
        } else if (
          event.target.value?.trim() !== "" &&
          !isRegexValidMobile(event.target.value)
        ) {
          setValidationErrorMessage((prevState) => ({
            ...prevState,
            phone_number: "Please enter valid Phone Number",
          }));
        } else {
          setValidationErrorMessage((prevState) => ({
            ...prevState,
            phone_number: "",
          }));
        }
      }
    } else {
      if (!validateEmail(inviteUpdateClientFormDataPayload.business_email)) {
        setValidationErrorMessage((prevState) => ({
          ...prevState,
          business_email: "Please enter valid Email ID",
        }));
        valid = false;
      }
      if (inviteUpdateClientFormDataPayload.business_email === "") {
        setValidationErrorMessage((prevState) => ({
          ...prevState,
          business_email: "Please enter your Email ID",
        }));
        valid = false;
      }
      if (inviteUpdateClientFormDataPayload.phone_number !== "") {
        if (inviteUpdateClientFormDataPayload.phone_number === "+1") {
          setValidationErrorMessage((prevState) => ({
            ...prevState,
            phone_number: "",
          }));
        } else if (
          !isRegexValidMobile(inviteUpdateClientFormDataPayload.phone_number)
        ) {
          setValidationErrorMessage((prevState) => ({
            ...prevState,
            phone_number: "Please enter valid Phone Number",
          }));
        } else {
          setValidationErrorMessage((prevState) => ({
            ...prevState,
            phone_number: "",
          }));
        }
      }
    }
    return valid;
  };

  // PS_22, PS_23
  const handleOnChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (e.target.name === "phone_number") {
      if (e.target.value === "") {
        setInviteUpdateClientFormDataPayload({
          ...inviteUpdateClientFormDataPayload,
          [e.target.name]: e.target.value,
        });
      } else {
        setInviteUpdateClientFormDataPayload({
          ...inviteUpdateClientFormDataPayload,
          [e.target.name]: formatPhoneNumberOnChange(e.target.value.trim()),
        });
      }
    } else if (e.target.name === "customer_dba") {
      // if (/^[a-zA-Z]*$/.test(e.target.value)) {
      setInviteUpdateClientFormDataPayload({
        ...inviteUpdateClientFormDataPayload,
        [e.target.name]: e.target.value,
      });
      // }
    } else if (e.target.name === "industry_uuid") {
      let industryid = props?.industryData?.find(
        (ele) => ele?.industry_uuid === e.target.value
      );
      setInviteUpdateClientFormDataPayload((prevState) => ({
        ...prevState,
        industry_uuid: e.target.value,
      }));
      if (industryid?.industry_name?.toLowerCase() === "other") {
        setIsOtherIndustry(true);
      } else {
        setIsOtherIndustry(false);
        setInviteUpdateClientFormDataPayload((prevState) => ({
          ...prevState,
          other_industry: "",
        }));
      }
    } else if (
      e.target.name === "first_name" ||
      e.target.name === "last_name"
    ) {
      if (/^[a-zA-Z]*$/.test(e.target.value)) {
        setInviteUpdateClientFormDataPayload({
          ...inviteUpdateClientFormDataPayload,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      setInviteUpdateClientFormDataPayload({
        ...inviteUpdateClientFormDataPayload,
        [e.target.name]: e.target.value,
      });
    }
  };

  return (
    <div
      className="modal fade show"
      id="exampleModal3"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-modal="true"
      data-backdrop="static"
      data-keyboard="false"
      role="dialog"
      style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
    >
      <div className="modal-dialog modal-dialog-centered   ">
        <div className="modal-content border-radius-10">
          <div className="modal-header border-0 px-4 pt-4 align-items-start">
            <h5
              className="modal-title font-semibold font-18 color-black-v2 mb-2"
              id="exampleModalLabel"
            >
              {inviteUpdateClientFormDataPayload.customer_uuid === "" &&
                "Add New Client"}
              {inviteUpdateClientFormDataPayload.customer_uuid !== "" &&
                "Edit Client"}
            </h5>
            <button
              type="button"
              className="btn-close custom-btn-close mt-1"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setInviteUpdateClientFormDataPayload(
                  initialInviteUpdateFormPayload
                );
                props.setShowClientForm(false);
              }}
            />
          </div>
          <div className="modal-body px-4 py-2">
            <div className="row">
              <div className="col-md-6 mb-4">
                <div className="position-relative">
                  <label
                    htmlFor="name"
                    className="font-semibold font-14 primary-text-color mb-2"
                  >
                    DBA Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    maxLength={60}
                    name="customer_dba"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleOnChange(e);
                    }}
                    value={inviteUpdateClientFormDataPayload.customer_dba}
                    className="form-control bg-white color-black-v2 font-14 font-medium invoice-form-field"
                    placeholder="DBA Name"
                  />
                  {/* {validationErrorMessage.customer_dba !== "" && (
                  <span className="font-14 font-medium alert-text">
                    {validationErrorMessage.customer_dba}
                  </span>
                )} */}
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <div className="position-relative">
                  <label
                    htmlFor="Industry"
                    className="font-semibold font-14 primary-text-color mb-2"
                  >
                    Industry
                  </label>
                  <select
                    name="industry_uuid"
                    id="Industry"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      handleOnChange(e);
                    }}
                    value={inviteUpdateClientFormDataPayload.industry_uuid}
                    className="w-100 d-block form-select invoice-form-field cust-form-arrow color-black-v1 font-14 font-medium bg-white"
                  >
                    <option value={""}>Select</option>
                    {props.industryData?.map((data) => {
                      return (
                        <option
                          key={data?.industry_uuid}
                          value={data?.industry_uuid}
                        >
                          {data?.industry_name}
                        </option>
                      );
                    })}
                  </select>
                  {/* {validationErrorMessage.industry_uuid !== "" && (
                  <span className="font-14 font-medium alert-text">
                    {validationErrorMessage.industry_uuid}
                  </span>
                )} */}
                </div>
              </div>
              {(isOtherIndustry ||
                inviteUpdateClientFormDataPayload.other_industry !== "") && (
                  <div className="col-md-6 mb-4">
                    <div className="position-relative">
                      <label
                        htmlFor="otherIndustry"
                        className="font-semibold font-14 primary-text-color mb-2"
                      >
                        Other Industry
                      </label>
                      <input
                        maxLength={60}
                        type="text"
                        id="otherIndustry"
                        name="other_industry"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleOnChange(e);
                        }}
                        value={inviteUpdateClientFormDataPayload.other_industry}
                        className="form-control bg-white color-black-v2 font-14 font-medium invoice-form-field"
                        placeholder="Enter Industry Name"
                        defaultValue="James Williams"
                      />
                      {/* {validationErrorMessage.customer_name !== "" && (
                    <span className="font-14 font-medium alert-text">
                      {validationErrorMessage.customer_name}
                    </span>
                  )} */}
                    </div>
                  </div>
                )}
              <div className="col-md-6 mb-4">
                <div className="position-relative">
                  <label
                    htmlFor="firstName"
                    className="font-semibold font-14 primary-text-color mb-2"
                  >
                    First Name
                  </label>
                  <input
                    maxLength={60}
                    type="text"
                    id="firstName"
                    name="first_name"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleOnChange(e);
                    }}
                    value={inviteUpdateClientFormDataPayload.first_name}
                    className="form-control bg-white color-black-v2 font-14 font-medium invoice-form-field"
                    placeholder="Enter First Name"
                  />
                  {/* {validationErrorMessage.customer_name !== "" && (
                  <span className="font-14 font-medium alert-text">
                    {validationErrorMessage.customer_name}
                  </span>
                )} */}
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <div className="position-relative">
                  <label
                    htmlFor="lastName"
                    className="font-semibold font-14 primary-text-color mb-2"
                  >
                    Last Name
                  </label>
                  <input
                    maxLength={60}
                    type="text"
                    id="lastName"
                    name="last_name"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleOnChange(e);
                    }}
                    value={inviteUpdateClientFormDataPayload.last_name}
                    className="form-control bg-white color-black-v2 font-14 font-medium invoice-form-field"
                    placeholder="Enter Last Name"
                  />
                  {/* {validationErrorMessage.customer_name !== "" && (
                  <span className="font-14 font-medium alert-text">
                    {validationErrorMessage.customer_name}
                  </span>
                )} */}
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <div className="position-relative">
                  <label
                    htmlFor="email"
                    className="font-semibold font-14 primary-text-color mb-2"
                  >
                    Email ID
                  </label>
                  <span className="madatory-text"> *</span>
                  <input
                    type="email"
                    maxLength={60}
                    id="email"
                    disabled={
                      inviteUpdateClientFormDataPayload.post_type === "update"
                        ? true
                        : false
                    }
                    name="business_email"
                    value={inviteUpdateClientFormDataPayload?.business_email?.toLowerCase()}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleOnChange(e);
                      formValidation(e);
                    }}
                    className="form-control bg-white color-black-v2 font-14 font-medium invoice-form-field"
                    placeholder="Enter Email ID"
                  />
                  {validationErrorMessage.business_email !== "" && (
                    <span className="font-14 font-medium alert-text">
                      {validationErrorMessage.business_email}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <div className="position-relative">
                  <label
                    htmlFor="number"
                    className="font-semibold font-14 primary-text-color mb-2"
                  >
                    Phone #
                  </label>
                  <input
                    type="tel"
                    id="number"
                    // pattern={"^\\+1 \\(\\d{3}\\) \\d{3}-\\d{4}$"}
                    name="phone_number"
                    maxLength={17}
                    // onFocus={(e)=>{
                    //   if (e.target.value === "") {
                    //     setInviteUpdateClientFormDataPayload((prevState:  InviteUpdateClientFormDataPayloadInterface)=>({
                    //       ...prevState,
                    //       phone_number: "
                    //     }))
                    //   }
                    // }}

                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleOnChange(e);
                      formValidation(e);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "delete") {
                        formValidation(e);
                      }
                    }}
                    value={inviteUpdateClientFormDataPayload.phone_number}
                    className="form-control bg-white color-black-v2 font-14 font-medium invoice-form-field"
                    placeholder="Enter Phone #"
                  />
                  {validationErrorMessage.phone_number !== "" && (
                    <span className="font-14 font-medium alert-text">
                      {validationErrorMessage.phone_number}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer gap-2 border-0 px-4 py-4 justify-content-end">
            <button
              className="btn px-4 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn"
              onClick={() => {
                setInviteUpdateClientFormDataPayload(
                  initialInviteUpdateFormPayload
                );
                props.setShowClientForm(false);
              }}
            >
              Cancel
            </button>
            {/* {inviteUpdateClientFormDataPayload.post_type === "invite" ? (
            <button
              className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal3"
              onClick={() => {
                handleFormSubmit();
              }}
            >
              Send Invite
            </button>
          ) : (
            <button
              className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal3"
              onClick={() => {
                handleFormSubmit();
              }}
            >
              Send Invite
            </button>
          )} */}

            {inviteUpdateClientFormDataPayload.customer_uuid !== "" ? (
              <button
                className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                onClick={() => {
                  handleFormSubmit();
                }}
              >
                Update
              </button>
            ) : (
              <button
                className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                onClick={() => {
                  handleFormSubmit();
                }}
              >
                Send Invite
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteClientForm;
