
import { PieChart, Pie, Cell, Label, ResponsiveContainer } from "recharts";
const COLORS = ["#74CE7B", "#F2C365"];

export default function JobRequestDistributionPie({ props }: any) {
  const data = {
    total: props?.external + props?.internal,
    chartdata: [
      { name: "External Contractor", value: props?.external },
      { name: "PPS Contractor", value: props?.internal },
    ],
  };

  // Calculate the highest count and its percentage
  const highestCount = Math.max(...data.chartdata.map(item => item.value));
  const highestPercentage = ((highestCount / data.total) * 100).toFixed(0);
  const highestIndex = data.chartdata.findIndex(item => item.value === highestCount);
  const highestColor = COLORS[highestIndex % COLORS.length];

  // Determine text color based on background color
  const textColor = highestColor === "#74CE7B" ? "#74CE7B" : "#F2C365";

  let LIGHT_BACKGROUND = "#FDF9E7";
  if (highestColor === "#74CE7B") {
    LIGHT_BACKGROUND = "#E7F7E8" // light green
  }

  return (
    <ResponsiveContainer
      className={"user-category-image"}
      width="100%"
      height="100%"
    >
      <PieChart>
        {/* Outer Pie */}
        <Pie
          data={data.chartdata}
          cx={100}
          cy={90}
          startAngle={210}
          endAngle={-30}
          innerRadius={78}
          outerRadius={90}
          cornerRadius={10}
          fill="#8884d8"
          paddingAngle={1}
          isAnimationActive={true}
          dataKey="value"
          style={{ outline: "none" }}
        >
          {data.chartdata.map((_entry: any, index: any) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}

          {/* Inner Pie (thin slice for the base line) */}
          <Pie
            data={[{ value: 1 }]} // Dummy data for creating the line
            cx={100}
            dataKey="value"
            cy={90}
            startAngle={200}
            endAngle={-30}
            innerRadius={80}
            outerRadius={10} // Make it very thin
            fill={LIGHT_BACKGROUND}
          />

          <Label
            content={({ viewBox }: any) => {
              const { cx, cy } = viewBox;
              return (
                <g>
                  {/* <path
                    d={`M ${cx - 60},${cy - 20}
                     A 60,60 0 0,1
                      ${cx + 60},${cy + 35} 
                      L ${cx - 60 },${cy + 35}
                       L ${cx - 60},${cy + 35} Z`}
                    fill={LIGHT_BACKGROUND}
                  /> */}
                  {/* <path
                    d={`M ${cx - 60},${cy - 20} 
                    A 60,60 0 0,1 
                    ${cx + 60},${cy + 35} 
                    L ${cx},${cy + 35} 
                    L ${cx - 60},${cy + 20} Z`}
                    fill={LIGHT_BACKGROUND}
                  /> */}
                  <path
                    d={`M ${cx},${cy - 60}
                     A 60,60 0 1,1 
                     ${cx},${cy + 60} 
                     A 60,60 0 1,1 
                     ${cx},${cy - 60}`}
                    fill={LIGHT_BACKGROUND}
                  />
                  <text
                    x={cx}
                    y={cy - 10}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fontSize="26"
                    fontWeight="700"
                    fill={textColor}
                  >
                    {highestPercentage}%
                  </text>
                  <text
                    x={cx}
                    y={cy + 20}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fontSize="10"
                    fontWeight="600"
                    fill="#666"
                  >
                    {data.chartdata[highestIndex].name}
                  </text>
                  {/* <circle cx={cx} cy={cy} r={60} fill={LIGHT_BACKGROUND} />
                  <text
                    x={cx}
                    y={cy - 10}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fontSize="26"
                    fontWeight="700"
                    fill={textColor}
                  >
                    {highestPercentage}%
                  </text> */}
                </g>
              );
            }}
          />
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}