import { useEffect } from "react";
import { ToastInterface } from "../Interface/ManageClient";

const Toast = (props: any) => {

    const { setToastDetails, toastType, toastMessage, toastDescription } = props.props;

    useEffect(() => {
        setTimeout(() => {
            setToastDetails((prev: any) => ({ prev, isToast: false }));
        }, 3000);
    })

    return (
        <div
            className="position-fixed top-0 start-50 translate-middle-x p-3"
            style={{ zIndex: 9999 }}
        >
            <div
                id="liveToast"
                className="toast cust-toast w-100 p-1 toast-border fade show bg-white"
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
            >
                <div className="toast-body d-flex align-items-center justify-content-between gap-5">
                    <div className="d-flex align-items-center gap-3">
                        {toastType === "Error" && <img src={"/images/toast-error.svg"} alt="Failed" />}
                        {toastType === "Success" && <img src={"/images/toast-sucess.svg"} alt="Successfully" /> }
                        <div className="d-block">
                            <p className="mb-0 font-4 font-semibold color-black text-nowrap mb-2">
                                {toastMessage}
                            </p>
                            <p className="font-medium font-14 mb-0 color-grey-v3 text-nowrap">
                                {toastDescription}
                            </p>
                        </div>
                    </div>
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="toast"
                        aria-label="Close"
                        onClick={()=>{
                            setToastDetails((prevState: ToastInterface)=>({...prevState, isToast: false}))
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default Toast;