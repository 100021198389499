/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect } from 'react';
import { City, ICity } from 'country-state-city';
import { Addresses, BranchDetailsProps, BranchFormProps } from '../../../Interface/EditContractor/Signup';


const BranchDetails: React.FC<BranchDetailsProps> = ({ index, onRemove, address, updateAddress, bindStateDropdown, bindCityDropdownBranch, touched, errors }) => (
    <>
        {index > 0 && (
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h2 className="color-black font-600 font-18 m-0">Branch Details</h2>
                <button className="icon-btn d-flex align-items-center justify-content-center" onClick={() => onRemove(index)}>
                    <img src="../images/remove-icon.svg" alt="icon" className="icon-11 me-1" /> Remove
                </button>
            </div>
        )}
        <div className="col-lg-6 mb-4 mb-lg-5">
            <label htmlFor={`addl1-${index}`} className="font-semibold font-16 color-black-v2 mb-2">
                Address Line 1 <span className="madatory-text">*</span>
            </label>
            <input
                type="text"
                id={`addl1-${index}`}
                className="form-control service-form-field color-black-v2 font-14 font-regular"
                placeholder="Address Line 1"
                value={address?.address_line_1}
                name={`address_line_1_${index}`}
                maxLength={255}
                onChange={(e) => updateAddress(index, 'address_line_1', e.target.value)}
            />
            {touched[`address_line_1_${index}`] && errors[`address_line_1_${index}`] &&
                <span className="font-14 font-medium alert-text">{errors[`address_line_1_${index}`]}</span>}
        </div>
        <div className="col-lg-6 mb-4 mb-lg-5">
            <label htmlFor={`add2-${index}`} className="font-semibold font-16 color-black-v2 mb-2">
                Address Line 2
                {/* <span className="madatory-text">*</span> */}
            </label>
            <input
                type="text"
                id={`add2-${index}`}
                className="form-control service-form-field color-black-v2 font-14 font-regular"
                placeholder="Address Line 2"
                value={address?.address_line_2}
                name={`address_line_2_${index}`}
                maxLength={255}
                onChange={(e) => updateAddress(index, 'address_line_2', e.target.value)}
            />
            {touched[`address_line_2_${index}`] && errors[`address_line_2_${index}`] &&
                <span className="font-14 font-medium alert-text">{errors[`address_line_2_${index}`]}</span>}
        </div>
        <div className="col-lg-4 mb-4 mb-lg-5">
            <label htmlFor={`zip-${index}`} className="font-semibold font-16 color-black-v2 mb-2">
                ZIP <span className="madatory-text">*</span>
            </label>
            <input
                type="text"
                id={`zip-${index}`}
                className="form-control service-form-field color-black-v2 font-14 font-regular"
                placeholder="Enter ZIP Code"
                value={address?.zip_code}
                name={`zip_code_${index}`}
                maxLength={9}
                onChange={(e) => {
                    if (/^\d+$/.test(e.target.value)) {
                        updateAddress(index, 'zip_code', e.target.value)
                    } else if (e.target.value === "") {
                        updateAddress(index, 'zip_code', e.target.value)
                    }
                }}
            />
            {touched[`zip_code_${index}`] && errors[`zip_code_${index}`] &&
                <span className="font-14 font-medium alert-text">{errors[`zip_code_${index}`]}</span>}
        </div>
        <div className="col-lg-4 mb-4 mb-lg-5 ">
            <label htmlFor={`state-${index}`} className="font-semibold font-16 color-black-v2 mb-2">
                State <span className="madatory-text">*</span>
            </label>
            <select

                id={`state-${index}`}
                className={`form-control service-form-field color-black-v2 font-14 font-regular `}
                value={address?.state}
                name={`state_${index}`}
                onChange={(e) => updateAddress(index, 'state', e.target.value)}
            >
                <option value="">Select</option>
                {bindStateDropdown()}
            </select>
            {touched[`state_${index}`] && errors[`state_${index}`] &&
                <span className="font-14 font-medium alert-text">{errors[`state_${index}`]}</span>}
        </div>
        <div className="col-lg-4 mb-4 mb-lg-5">
            <label htmlFor={`city-${index}`} className="font-semibold font-16 color-black-v2 mb-2">
                City <span className="madatory-text">*</span>
            </label>
            <select

                id={`city-${index}`}
                className={`form-control service-form-field color-black-v2 font-14 font-regular `}

                value={address?.city}
                disabled={bindCityDropdownBranch(address?.state, index, "disable") as boolean}
                name={`city_${index}`}
                onChange={(e) => updateAddress(index, 'city', e.target.value)}
            >
                <option value="">Select</option>
                {bindCityDropdownBranch(address.state, index)}
            </select>
            {touched[`city_${index}`] && errors[`city_${index}`] &&
                <span className="font-14 font-medium alert-text">{errors[`city_${index}`]}</span>}
        </div>

    </>
);
// PS_CSU__54
export const BranchForm: React.FC<BranchFormProps> = ({ formPayload, setFormPayload, bindStateDropdown, handleInputChange, handlePrevious, handleNext, currentStep, setAddresses, addresses }) => {
    const [hasBranches, setHasBranches] = useState(formPayload.branches);
    const [NextButtonEnable, setNextButtonEnable] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [touched, setTouched] = useState<{ [key: string]: boolean }>({});
    const [CityDisable, setCityDisable] = useState<(boolean | undefined)[]>([true])

    // PS_CSU__55
    useEffect(() => {
        setFormPayload(prevState => ({
            ...prevState,
            address: addresses
        }));
        // setFormPayload((prev) => ({ ...prev, branches: hasBranches }))
        handleValidation();
        //call parent component to get the state and city value
        addresses.forEach(address => {
            // handleInputChange('state', address.state)
            // handleInputChange('city', address.city)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addresses, hasBranches]);

    // useEffect(() => {
    //     if (NextButtonEnable) {
    //         console.log(nextButtonEnabled, "nextButtonEnabled");

    //         // let isvalid = CityDisable.every((ele) => ele === true || ele === undefined)
    //         // setNextButtonEnable(isvalid)
    //     }
    // }, [NextButtonEnable, CityDisable, addresses])

    // PS_CSU__56
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        debugger
        const hasOtherBranches = event.target.id === "Yes";

        setHasBranches(event.target.id);
        setFormPayload((prev) => ({ ...prev, branches: event.target.id }))

        if (hasOtherBranches) {
            setAddresses([addresses[0]]);
        } else {
            setAddresses([{
                address_line_1: '',
                address_line_2: '',
                zip_code: '',
                city: '',
                state: ''
            }]);
        }
    };

    // PS_CSU__57
    const addBranch = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setNextButtonEnable(false)
        setAddresses(prevAddresses => [...prevAddresses, {
            address_line_1: '',
            address_line_2: '',
            zip_code: '',
            city: '',
            state: ''
        }]);

        setCityDisable((prev) => [...prev, true])
    };

    // PS_CSU__58
    const removeBranch = (index: number) => {
        setAddresses(prevAddresses => prevAddresses.filter((_, i) => i !== index));
        let temp = CityDisable
        temp[index] = undefined
        setCityDisable(temp)
    };

    // PS_CSU__59
    const updateAddress = (index: number, field: keyof Addresses, value: string) => {
        let error = "";
        error = validateField(field, value);
        if (field === "city" && value) {
            let tempCity = CityDisable
            tempCity[index] = true
            setCityDisable(tempCity)
        }
        if (typeof index === 'number') {
            setAddresses(prevAddresses =>
                prevAddresses.map((address, i) =>
                    i === index ? { ...address, [field]: value } : address
                )
            );

            if (field === "state") {
                setErrors(prev => ({
                    ...prev,
                    [`${"city"}_${index}`]: ""
                }));
                setAddresses(prevAddresses =>
                    prevAddresses.map((address, i) =>
                        i === index ? { ...address, [field]: value, city: "" } : address
                    )
                );
            }
            setErrors(prev => ({
                ...prev,
                [`${field}_${index}`]: error
            }));
            setTouched(prev => ({
                ...prev,
                [`${field}_${index}`]: true
            }));
        }
    };


    // PS_CSU__60
    const validateField = (name: string, value: string) => {

        let error = '';
        switch (name) {
            case 'address_line_1':
                if (value.trim() === "") {
                    error = "Please enter Address Line 1";
                } else {
                    error = "";
                }
                break;
            // case 'address_line_2':
            //     if (value.trim() === "") {
            //         error = "Please enter Address Line 2";
            //     } else {
            //         error = "";
            //     }
            //     break;
            case 'state':
                if (value.trim() === "") {
                    error = "Please enter your State";
                    // Clear city error if state is invalid
                } else {
                    error = "";
                    // Clear city error if state is valid
                }
                break;
            case 'city':
                if (value.trim() === "") {
                    error = "Please select your City";
                } else {
                    error = "";
                }
                break;
            case 'zip_code':
                if (!/^\d{5,10}$/.test(value) || "") {
                    error = "Please enter ZIP Code";
                } else {
                    error = "";
                }
                break;
            default:
                break;
        }
        return error;
    };

    //removed city because in some cases it can be 0. 
    // PS_CSU__61
    const handleValidation = () => {

        let isvalid = true
        if (hasBranches === "Yes") {
            for (let i = 0; i < addresses.length; i++) {
                if (addresses[i].address_line_1 !== "" && addresses[i].zip_code !== "" && addresses[i].state !== "") {
                    isvalid = true
                    isvalid = CityDisable.every((ele) => ele === true || ele === undefined)
                    if (isvalid) {
                        isvalid = /^[0-9]{5,10}$/.test(addresses[i].zip_code)
                    }
                } else {
                    isvalid = false
                    break
                }
            }
        } else if (hasBranches === "No") {
            isvalid = true
        } else if (hasBranches === "") {
            isvalid = false
        }
        console.log(isvalid, "isvalid");

        setNextButtonEnable(isvalid)
    }

    // PS_CSU__62
    const handleCancel = () => {
        // Reset the form
        setFormPayload(prevState => ({
            ...prevState,
            address: [], branches: ""
        }));
        setAddresses([{
            address_line_1: '',
            address_line_2: '',
            zip_code: '',
            city: '',
            state: ''
        }]);
        setHasBranches(() => (""))
        setNextButtonEnable(false)
        setErrors({})

    };


    const bindCityDropdownBranch = (state: string, index: number, disable = "") => {
        const citiesList = City.getCitiesOfState('US', state); // get the cities for the given state

        if (citiesList.length === 0) {
            // setDisableCity(true);
            let tempCity = CityDisable
            tempCity[index] = true
            setCityDisable(tempCity)
        } else if (addresses[index].city === "") {

            // setDisableCity(false);
            let tempCity = CityDisable
            tempCity[index] = false
            setCityDisable(tempCity)
        }
        if (disable === "disable") {
            return citiesList.length ? false : true
        }

        return citiesList.map((city: ICity) => (
            <option key={city.name} value={city.name}>
                {city.name}
            </option>
        ));
    };

    return (
        <>
            <h2 className="color-black font-600 font-18 mb-4">Branch Name/Locations</h2>
            <div className="col-lg-12 mb-4 mb-lg-5">
                <label className="font-semibold font-16 color-black-v2 mb-3">
                    Does this security company have branch offices?
                    <span className="madatory-text">*</span>
                </label>
                <div className="">
                    <div className="form-check mb-3">
                        <input
                            className="form-check-input custom-radio-input"
                            type="radio"
                            name="flexRadioDefault-3"
                            id="Yes"
                            checked={hasBranches === "Yes"}
                            onChange={handleRadioChange}
                        />
                        <label className="font-medium font-14 color-black-v2 mt-1 ps-2" htmlFor="Yes">
                            Yes, we have other branch offices.
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input custom-radio-input"
                            type="radio"
                            name="flexRadioDefault-3"
                            id="No"
                            checked={hasBranches === "No"}
                            onChange={handleRadioChange}
                        />
                        <label className="font-medium font-14 color-black-v2 mt-1 ps-2" htmlFor="No">
                            No, we only have the one address (Headquarters).
                        </label>
                    </div>
                </div>
            </div>
            {hasBranches === "Yes" && (
                <>
                    <h2 className="color-black font-600 font-18 mb-4">Branch Details</h2>
                    {addresses?.map((address, index) => (
                        <BranchDetails
                            key={index}
                            index={index}
                            onRemove={removeBranch}
                            address={address}
                            updateAddress={updateAddress}
                            bindStateDropdown={bindStateDropdown}
                            bindCityDropdownBranch={bindCityDropdownBranch}
                            touched={touched}
                            errors={errors}
                        />
                    ))}
                    <a className="font-15 font-medium link text-decoration-none" onClick={addBranch}>
                        + Add Another Address
                    </a>

                </>
            )}
            <div className="modal-footer justify-content-between border-0 gap-4 pb-5">
                <button className="btn px-0 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn"
                    onClick={handleCancel}>
                    Cancel
                </button>
                <div className="d-flex gap-3">
                    <button className="btn tertiary-btn px-4 color-white font-semibold font-14 px-4"
                        onClick={handlePrevious}
                        disabled={currentStep === 0}>
                        Previous
                    </button>
                    <button className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                        onClick={() => {

                            handleNext()

                        }}

                        disabled={!NextButtonEnable}
                    >
                        Next
                    </button>
                    {/* {isEdit &&
                        <button className="btn primary-btn px-4 color-white font-semibold font-14 primary-btn px-4"
                            // disabled={!buttonSubmit}
                            onClick={() => { SaveFromDetails() }}
                        >
                            Update
                        </button>
                    } */}
                </div>
            </div>
        </>
    );
};