
import React, { useState, useEffect } from 'react';

interface FilterProps {
    activeTab: 'account-requests' | 'all-contractors' | 'invitee-responses';
    onApplyFilter: (filters: any) => void;
    onClose: () => void; // onClose takes no arguments
    onCancel: () => void;
    stateNames: string[];
    initialFilters: any;
}

const FilterComponent: React.FC<FilterProps> = ({
    activeTab,
    onApplyFilter,
    onClose,
    onCancel,
    stateNames,
    initialFilters,
}) => {
    const [tempFilters, setTempFilters] = useState<any>(initialFilters);

    useEffect(() => {
        setTempFilters(initialFilters);
    }, [activeTab, initialFilters]);

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
        dateType?: string
    ) => {
        const { name, value } = e.target;
        setTempFilters((prev: any) => {
            if (dateType) {
                return {
                    ...prev,
                    [dateType]: {
                        ...prev[dateType],
                        [name]: value,
                    },
                };
            } else {
                return { ...prev, [name]: value };
            }
        });
    };

    const handleNumericInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (/^\d*$/.test(value)) {
            setTempFilters((prev: any) => ({ ...prev, [name]: value === '' ? '' : parseInt(value) }));
        }
    };

    const handleApply = () => {
        onApplyFilter(tempFilters);
        onClose(); // Close the modal without resetting the filters
    };

    const handleClose = () => {
        onClose();
    };

    const renderDateInputs = (dateType: string) => (
        <>
            <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                    <label htmlFor={`${dateType}_from`} className="form-label color-grey-v1 font-12 font-medium">
                        From
                    </label>
                    <input
                        type="date"
                        id={`${dateType}_from`}
                        name="from"
                        max={tempFilters[dateType]?.to ? new Date(tempFilters[dateType]?.to).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]}
                        className="form-control service-form-field color-black-v2 font-14 font-regular bg-transparent filter-field"
                        onChange={e => handleInputChange(e, dateType)}
                        value={tempFilters[dateType]?.from || ''}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                    <label htmlFor={`${dateType}_to`} className="form-label color-grey-v1 font-12 font-medium">
                        To
                    </label>
                    <input
                        type="date"
                        id={`${dateType}_to`}
                        name="to"
                        min={tempFilters[dateType]?.from ? new Date(tempFilters[dateType]?.from).toISOString().split('T')[0] : ""}
                        max={new Date().toISOString().split('T')[0]}
                        className="form-control service-form-field color-black-v2 font-14 font-regular bg-transparent filter-field"
                        onChange={e => handleInputChange(e, dateType)}
                        value={tempFilters[dateType]?.to || ''}
                    />
                </div>
            </div>
        </>
    );

    const renderOperatingStatesSelect = () => (
        <select
            name="operating_states"
            id="operating_states"
            className="w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular bg-transparent"
            onChange={handleInputChange}
            value={tempFilters.operating_states || ''}
        >
            <option value="">Select</option>
            {stateNames.map((state, index) => (
                <option key={index} value={state}>
                    {state}
                </option>
            ))}
        </select>
    );

    const renderAccountRequestsFilter = () => (
        <>
            <div className="col-md-12 col-sm-12">
                <div className="mb-3">
                    <label htmlFor="operating_states" className="form-label color-grey-v1 font-12 font-medium">
                        Operating States
                    </label>
                    {renderOperatingStatesSelect()}
                </div>
            </div>
            <div className="col-md-12 col-sm-12">
                <div className="mb-3">
                    <label htmlFor="license_validity" className="form-label color-grey-v1 font-12 font-medium">
                        License validity
                    </label>
                    <select
                        name="license_validity"
                        id="license_validity"
                        className="w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular bg-transparent"
                        onChange={handleInputChange}
                        value={tempFilters.license_validity || ''}
                    >
                        <option value="">Select</option>
                        <option value="valid">Valid</option>
                        <option value="invalid">Invalid</option>
                    </select>
                </div>
            </div>
            <div className="col-md-12">
                <label className="form-label color-grey-v1 font-12 font-medium">Requested on</label>
            </div>
            {renderDateInputs('requested_on')}
        </>
    );

    const renderAllContractorsFilter = () => (
        <>
            <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                    <label htmlFor="operating_states" className="form-label color-grey-v1 font-12 font-medium">
                        Operating States
                    </label>
                    {renderOperatingStatesSelect()}
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                    <label htmlFor="services_offered" className="form-label color-grey-v1 font-12 font-medium">
                        Services Offered
                    </label>
                    <input
                        type="text"
                        id="services_offered"
                        name="services_offered"
                        className="form-control bg-white color-black-v2 font-14 font-medium invoice-form-field"
                        placeholder="Enter Services Offered"
                        onChange={handleNumericInputChange}
                        value={tempFilters.services_offered === '' ? '' : (tempFilters.services_offered || '')}
                    />
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                    <label htmlFor="license_validity" className="form-label color-grey-v1 font-12 font-medium">
                        License Validity
                    </label>
                    <select
                        name="license_validity"
                        id="license_validity"
                        className="w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular bg-transparent"
                        onChange={handleInputChange}
                        value={tempFilters.license_validity || ''}
                    >
                        <option value="">Select</option>
                        <option value="valid">Valid</option>
                        <option value="invalid">Invalid</option>
                    </select>
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                    <label htmlFor="account_status" className="form-label color-grey-v1 font-12 font-medium">
                        Account Status
                    </label>
                    <select
                        name="account_status"
                        id="account_status"
                        className="w-100 d-block form-select service-form-field cust-service-form-arrow color-black-v1 font-14 font-regular bg-transparent"
                        onChange={handleInputChange}
                        value={tempFilters.account_status || ''}
                    >
                        <option value="">Select</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                    </select>
                </div>
            </div>
            <div className="col-md-12">
                <label className="form-label color-grey-v1 font-12 font-medium">Requested on</label>
            </div>
            {renderDateInputs('requested_on')}
        </>
    );

    const renderInviteeResponsesFilter = () => (
        <>
            <div className="col-md-12">
                <label className="form-label color-grey-v1 font-12 font-medium">Invited on</label>
            </div>
            {renderDateInputs('invited_on')}
        </>
    );

    return (
        <div className="dropdown-menu dd-box type-three-box shadow-sm p-4 border-0 show" style={{ position: 'absolute', transform: 'translate3d(0, 0, 0)' }}>
            <div className="col-md-12">
                <div className="d-flex justify-content-between align-items-center">
                    <h5 className="font-16 font-semibold color-black mb-0">Advanced Filter</h5>

                    {/* Close Button */}
                    <button className="btn-close font-12" aria-label="Close" onClick={handleClose} />
                </div>
                <div className="row mt-3">
                    {/* Render filters based on the active tab */}
                    {activeTab === 'account-requests' && renderAccountRequestsFilter()}
                    {activeTab === 'all-contractors' && renderAllContractorsFilter()}
                    {activeTab === 'invitee-responses' && renderInviteeResponsesFilter()}
                </div>
                <div className="d-flex justify-content-end mt-4">
                    {/* Cancel Button */}
                    <button className="btn px-4 font-semibold font-14 shadow-none text-decoration-underline cust-text-underline secondary-btn" onClick={onCancel}>
                        Clear
                    </button>
                    {/* Apply Button */}
                    <button className="btn px-4 color-white font-semibold font-14 primary-btn px-4 rounded-3" onClick={handleApply}>
                        Apply
                    </button>
                </div>
            </div>
        </div>
    );
}

export default FilterComponent;