
import { PieChart, Pie, Cell, Label, ResponsiveContainer } from "recharts";

const COLORS = ["#F7988B", "#FFB775", "#F5D86F", "#C7E497", "#91D498"];

export default function FeedbackPie({ props }: any) {

    const data = [
        { name: "one", value: props?.one },
        { name: "two", value: props?.two },
        { name: "three", value: props?.three },
        { name: "four", value: props?.four },
        { name: "five", value: props?.five }
    ];
    
    // const data = [
    //     { name: "one", value: 11 },
    //     { name: "two", value: 2 },
    //     { name: "three", value: 3 },
    //     { name: "four", value: 4 },
    //     { name: "five", value: 4 }
    // ];

    // const total = data.reduce((sum, entry) => sum + entry.value, 0);

    // Calculate the highest count and its percentage

    const highestEntry = data.reduce((max, entry) => entry.value > max.value ? entry : max, data[0]);
    // const highestCount = highestEntry.value;
    // const highestPercentage = Math.round((highestCount / total) * 100);


    return (
        <ResponsiveContainer
            className={"user-category-image"}
            width="100%"
            height="100%"
        >
            <PieChart>
                <Pie
                    data={data}
                    cx={90}
                    cy={70}
                    startAngle={0}
                    endAngle={360}
                    innerRadius={61}
                    outerRadius={73}
                    cornerRadius={5}
                    fill="#8884d8"
                    paddingAngle={1}
                    isAnimationActive={true}
                    dataKey="value"
                    style={{ outline: "none" }}
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                    <Label
                        content={({ viewBox }: any) => {
                            const { cx, cy } = viewBox;
                            return (
                                <g>
                                    {highestEntry.name === "one" ?
                                        (
                                            <image
                                                x={cx - 20}
                                                y={cy - 30}
                                                textAnchor="middle"
                                                dominantBaseline="middle"
                                                className="feedback-icon-sty"
                                                xlinkHref="../images/extremely-bad-emoji.svg"
                                            />
                                        )
                                        : highestEntry.name === "two" ?
                                            (
                                                <image
                                                    x={cx - 20}
                                                    y={cy - 30}
                                                    textAnchor="middle"
                                                    dominantBaseline="middle"
                                                    className="feedback-icon-sty"
                                                    xlinkHref="../images/not-satisfied-emoji.svg"
                                                />
                                            )
                                            : highestEntry.name === "three" ?
                                                (
                                                    <image
                                                        x={cx - 20}
                                                        y={cy - 30}
                                                        textAnchor="middle"
                                                        dominantBaseline="middle"
                                                        className="feedback-icon-sty"
                                                        xlinkHref="../images/neutral-emoji.svg"
                                                    />
                                                )
                                                : highestEntry.name === "four" ?
                                                    (
                                                        <image
                                                            x={cx - 20}
                                                            y={cy - 30}
                                                            textAnchor="middle"
                                                            dominantBaseline="middle"
                                                            className="feedback-icon-sty"
                                                            xlinkHref="../images/satisfied-emoji.svg"
                                                        />
                                                    )
                                                    : highestEntry.name === "five" ?
                                                        (
                                                            <image
                                                                x={cx - 20}
                                                                y={cy - 30}
                                                                textAnchor="middle"
                                                                dominantBaseline="middle"
                                                                className="feedback-icon-sty"
                                                                xlinkHref="../images/extremely-good-emoji.svg"
                                                            />
                                                        )
                                                        : null
                                    }
                                    <text
                                        x={cx}
                                        y={cy + 25}
                                        textAnchor="middle"
                                        dominantBaseline="middle"
                                        fontSize="11px"
                                        fontWeight="600"
                                        fill="#333"
                                    >
                                        Overall Rating
                                    </text>
                                </g>
                            );
                        }}
                    />
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
}