import React, { useState, useEffect, useCallback } from 'react';
import { BidInfoRequest } from '../../Interface/BidApproval';
import { getBidInfoApi, reinitiateApi } from '../../Service/BidApprovalServices/BidInfoService';

// Interfaces
interface Bid {
    contractor_id: string;
    contractor_name: string;
    contractor_type: string;
    bid_id: string;
    bid_amount: string;
    total_amount_with_margin: string;
    margin_percentage: string;
    margin_value: string;
    fee_percentage: string;
}

interface ContractorReinitiateProps {
    serviceRequestId: string;
    isClose: () => void;
    isOpen: boolean;
}

function ContractorReinitiate({ serviceRequestId, isOpen, isClose }: ContractorReinitiateProps) {
    const [bids, setBids] = useState<Bid[]>([]);
    const [bidRequest, setBidRequest] = useState<BidInfoRequest>({
        type: "reinitiate",
        service_request_id: serviceRequestId,
        user_id: "SampleId",
        sort_by: '',
        sort_order: '',
        limit: 50,
    });
    const [marginPercentage, setMarginPercentage] = useState<string>('');
    const [marginAmount, setMarginAmount] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<string>('');

    const fetchBids = useCallback(async () => {
        try {
            const response = await getBidInfoApi(bidRequest);
            if (response.code === 200) {
                setBids(response.data.bids);
            } else {
                console.error('Error fetching bids:', response.message);
            }
        } catch (error) {
            console.error('Error fetching bids:', error);
        }
    }, [bidRequest]);

    useEffect(() => {
        fetchBids();
    }, [fetchBids]);

    const handleSort = (newSortBy: string) => {
        setBidRequest(prev => {
            const newSortOrder = prev.sort_by === newSortBy && prev.sort_order === 'desc' ? 'asc' : 'desc';
            return {
                ...prev,
                sort_by: newSortBy,
                sort_order: newSortOrder
            };
        });
    };

    const calculateTotalWithMargin = (bid: Bid, marginPercentage?: string, marginAmount?: string) => {
        const bidValue = parseFloat(bid.bid_amount.replace(/[^0-9.]/g, ''));
        const totalAmountWithMarginValue = parseFloat(bid.total_amount_with_margin.replace(/[^0-9.]/g, ''));

        let resultAmount = bidValue;

        if (marginPercentage && parseFloat(marginPercentage) > 0) {
            const percentage = parseFloat(marginPercentage) / 100;
            resultAmount = bidValue * (1 + percentage);
        } else if (marginAmount && parseFloat(marginAmount) > 0) {
            const amount = parseFloat(marginAmount);
            resultAmount = bidValue + amount;
        } else if (totalAmountWithMarginValue > 0) {
            resultAmount = totalAmountWithMarginValue;
        }

        return `$${resultAmount.toFixed(2)}/hr`;
    };

    const handleMarginPercentageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value === '' || /^\d*\.?\d*$/.test(value)) {
            setMarginPercentage(value);
            setMarginAmount('');
        }
    };

    const handleMarginAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value === '' || /^\d*\.?\d*$/.test(value)) {
            setMarginAmount(value);
            setMarginPercentage('');
        }
    };

    const handleApprove = async (bid: Bid) => {
        setIsLoading(true);
        const bidValue = parseFloat(bid.bid_amount.replace(/[^0-9.]/g, ''));
        let marginPercentageValue = 0;
        let marginAmountValue = 0;

        // Calculate margin values but use bidValue as new_offer_price
        let marginFinalValue = bidValue;

        if (marginPercentage) {
            marginPercentageValue = parseFloat(marginPercentage);
            marginFinalValue = bidValue * (1 + marginPercentageValue / 100);
        } else if (marginAmount) {
            marginAmountValue = parseFloat(marginAmount);
            marginFinalValue = bidValue + marginAmountValue;
        }

        const approvalRequestData = {
            type: 'rejected',
            bid_person: 'contractor',
            service_request_id: bidRequest.service_request_id,
            contractor_id: bid.contractor_id,
            new_offer_price: bidValue, // Original bid price
            margin_percentage: marginPercentageValue,
            margin_amount: bidValue,
            margin_final_value: marginFinalValue // Final value including margin
        };

        try {
            const response = await reinitiateApi(approvalRequestData);

            if (response.code === 200) {
                setToastMessage('Bid reinitiated successfully');
                setShowToast(true);
                await fetchBids();
                setTimeout(() => setShowToast(false), 3000);
                isClose(); // Close the modal after success
            } else {
                console.error('Error reinitiating bid:', response.message);
                setToastMessage('Error reinitiating bid');
                setShowToast(true);
                setTimeout(() => setShowToast(false), 3000);
            }
        } catch (error) {
            console.error('Error reinitiating bid:', error);
            setToastMessage('Error reinitiating bid');
            setShowToast(true);
            setTimeout(() => setShowToast(false), 3000);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="modal fade show" id="exampleModal11" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog" style={{ display: isOpen ? "block" : "none", backgroundColor: "rgba(0,0,0,0.5)" }}>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content border-radius-10">
                        <div className="modal-header border-0 px-4 pt-4 align-items-start">
                            <div className="d-block">
                                <h5 className="modal-title font-bold font-20 color-black-v2 mb-2" id="exampleModalLabel">
                                    Manage and Approve Bids
                                </h5>
                                <p className="font-14 font-medium color-grey mb-0">
                                    Select the bid which needs to be finalized, you can configure the margin as well
                                </p>
                            </div>
                            <button
                                type="button"
                                className="btn-close custom-btn-close mt-1"
                                onClick={isClose}
                                aria-label="Close"
                            />
                        </div>

                        <div className="modal-body px-4 py-2">
                            <div className="row my-2">
                                <div className="col-md-6 mb-4">
                                    <div className="position-relative">
                                        <label htmlFor="MarginPercentage" className="font-semibold font-14 primary-text-color mb-2">
                                            Margin %
                                        </label>
                                        <div className="d-flex align-items-center invoice-form-field p-0">
                                            <button className="border-0 h-100 amount-btn margin"></button>
                                            <input
                                                type="text"
                                                id="MarginPercentage"
                                                className="form-control bg-grey color-black-v2 font-14 font-medium invoice-form-field border-0 rounded-0"
                                                value={marginPercentage}
                                                onChange={handleMarginPercentageChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 mb-4">
                                    <div className="position-relative">
                                        <label htmlFor="MarginAmount" className="font-semibold font-14 primary-text-color mb-2">
                                            Margin Amount (USD)
                                        </label>
                                        <div className="d-flex align-items-center invoice-form-field p-0">
                                            <button className="border-0 h-100 amount-btn"></button>
                                            <input
                                                type="text"
                                                id="MarginAmount"
                                                className="form-control bg-grey color-black-v2 font-14 font-medium invoice-form-field border-0 rounded-0"
                                                value={marginAmount}
                                                onChange={handleMarginAmountChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <p className="font-15 font-semibold color-red mb-0">
                                    Bids once approved will be notified to the user immediately
                                </p>

                                <div className="table-responsive theme-table bg-white custom-scroll my-4 w-100 vertical-align-top popup-scroll table-scroll" id="scrollableDiv">
                                    <table className="table table-borderless mb-0 acc-view" style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th className="text-nowrap text-start" onClick={() => handleSort('contractor_name')}>
                                                    Contractor
                                                </th>
                                                <th className="text-nowrap text-start" onClick={() => handleSort('fee_percentage')}>
                                                    Payment Terms
                                                </th>
                                                <th className="text-nowrap text-end" onClick={() => handleSort('bid_amount')}>
                                                    Bid Amount
                                                </th>
                                                <th className="text-nowrap text-end" onClick={() => handleSort('total_amount_with_margin')}>
                                                    Including Margin Value
                                                </th>
                                                <th className="text-nowrap text-center">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bids.map((bid) => (
                                                <tr key={bid.bid_id}>
                                                    <td className="text-start">
                                                        <p className="font-13 font-medium color-black mb-2">
                                                            {bid.contractor_name}
                                                        </p>
                                                        <p className="font-11 font-medium primary-text-color-light mb-2">
                                                            {bid.contractor_type}
                                                        </p>
                                                    </td>
                                                    <td className="text-start">
                                                        <p className="text-wrap font-13 font-medium color-black mb-2">
                                                            {bid.fee_percentage}% Discount in Invoiced amount
                                                        </p>
                                                    </td>
                                                    <td className="text-end">
                                                        ${parseInt(bid.bid_amount.replace(/[^0-9.]/g, ''), 10)}/hr
                                                    </td>
                                                    <td className="text-end">
                                                        {calculateTotalWithMargin(bid, marginPercentage, marginAmount)}
                                                    </td>
                                                    <td className="text-center">
                                                        <button
                                                            className="btn green-btn px-4 font-12 font-semibold me-2"
                                                            onClick={() => handleApprove(bid)}
                                                            disabled={isLoading}
                                                        >
                                                            {isLoading ? (
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            ) : (
                                                                'Approve'
                                                            )}
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showToast && (
                <div
                    className="position-fixed top-0 start-50 translate-middle-x p-3"
                    style={{ zIndex: 999 }}
                >
                    <div
                        id="liveToast"
                        className="toast cust-toast w-100 p-1 toast-border show"
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                    >
                        <div className="toast-body d-flex align-items-center justify-content-between gap-5">
                            <div className="d-flex align-items-center gap-3">
                                <img src="../images/toast-sucess.svg" alt="Successfully" />
                                <div className="d-block">
                                    <p className="mb-0 font-4 font-semibold color-black text-nowrap mb-2">
                                        Bid Approval Status
                                    </p>
                                    <p className="font-medium font-14 mb-0 color-grey-v3 text-nowrap">
                                        {toastMessage}
                                    </p>
                                </div>
                            </div>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => setShowToast(false)}
                                aria-label="Close"
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ContractorReinitiate;