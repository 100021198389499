import { client } from "../Client/Client";
import { SignInEndpoint } from "../Configs/BackendRoutes";
import { backendUrl as URL} from '../Configs/Constant'

export const SignInService = async (payload: any) => {
    const config = {
        method: 'POST',
        url: URL+ SignInEndpoint.SignIn ,
        headers: {
            'Content-Type': 'application/json'
        },
        data: payload
    };
    const response = await client(config);

    return response;
};